import { theme } from "@chakra-ui/react";
import { button as Button, buttonTheme } from "./button";
import { input as Input, inputTheme } from "./input";
import { textarea as Textarea, textareaTheme } from "./textarea";
import { select as Select, selectTheme } from "./select";
import { checkbox as Checkbox, checkboxTheme } from "./checkbox";

const baseComponentsTheme = theme.components;

/**
 * Overrides chakra theme
 */
export const componentsTheme = {
  ...baseComponentsTheme,
  Button: buttonTheme,
  Input: inputTheme,
  Textarea: textareaTheme,
  Select: selectTheme,
  Checkbox: checkboxTheme,
};

export const extendedThemeComponents = {
  Button,
  Input,
  Textarea,
  Select,
  Checkbox,
};
