import { Button,chakra, HStack, Icon, Input, InputGroup, InputRightElement, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { CheckIcon, SearchIcon } from "assets/icons";

import { usePatients, usePatientsSearch } from "modules/Patients/api";
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { IoIosAddCircleOutline, IoMdSchool } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { UserMedical } from "types/user";
import { FaCheckCircle, FaEdit, FaLink, FaSchool } from "react-icons/fa";
import { BsCheck, BsThreeDotsVertical } from "react-icons/bs";
import { LuCalendarCheck2 } from "react-icons/lu";
import { Patient } from "types/patient";
import { Service, _Service } from "api/api";
import axios from "axios";
import { MdArrowBack, MdEdit } from "react-icons/md";
import { BiChat, BiSearch, BiTrash } from "react-icons/bi";
import { useToast } from '@chakra-ui/react'
import { LoadingOverlay } from "shared/LoadingPage/LoadingPage";
import Renderif from "utils/renderif";
import { FaRegUserCircle } from "react-icons/fa";
import { GiBubbles } from "react-icons/gi";
import { DDMMYYYY } from "modules/Community/CommunityView";

// interface PatientState {
// 	patients: Patient[]
// }
function color() {
	return (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
}
  
  





export default function CursosView() {
  const navigate = useNavigate()

  const toast = useToast();

//   const {setUser} = useContext(AuthContext)
//   const {notify} = useContext(AlertContext)
  
  const [state, setState] = useState({
	cursos:[],
	loading:false,
    firstName: "",
    lastName: "",
    email: "",
    password: "Mydiet.123",
    phone: "",
    ocupation: "",
    civilState: "",
    comentarios:[] as any
  })

  useEffect(()=>{
	init()
  },[])

  const init = async () => {

	let _cursos = await _Service('GET', 'courses')
    console.log(_cursos)
    let comentarios = await _Service('GET', `commentary`)
    // state.allcomentarios = comentarios.data;
    state.comentarios = comentarios.data.filter((item:any)=> item.parentId === null).sort((a:any,b:any) => b.id - a.id)
    

  
	state.cursos = _cursos.data.sort((a:any,b:any) => b.id - a.id)
	setState({...state})
  }

  const onDeleteCurso = async (id:any) => {

	let _delete = await _Service('DELETE', `courses/${id}`)
	console.log(_delete)
	init();
	toast({
		title: "Curso",
		description: "Cursos eliminado exitosamente.",
		status: "success",
		position: "bottom-left",
	  });
	// state.cursos = _cursos.data.sort((a:any,b:any) => b.id - a.id)
	// setState({...state})

  }




  return (
    <div className='container-fluid overflow-auto h-100'>

		<Renderif isTrue={state.loading}>
            <LoadingOverlay></LoadingOverlay>
        </Renderif>

        <div className="container-md mx-auto p-0 m-0 h-100">
            <div className="d-flex h-100">
                <div className="col-8">

				<div className="container-fluid p-0">

					<div className="d-flex">
						
						<div>
							<div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
								// onSavePatient()
								navigate('create')
								
							}}>
								<div>CREAR CURSO</div>
							</div>
						</div>
						<div className="col"></div>
					</div>
								
                    <table className="mt-2">
                        <thead className="bg-light rounded-3">
                            <tr>
                                <td>
                                    Curso
                                </td>
								<td>
                                    Plan
                                </td>
                                <td>Especialidad</td>
                              
                                <td>Duración</td>
								<td></td>
								<td></td>
                                
                                
                            </tr>
                        </thead>
                        
                        <tbody>
                            {state.cursos.map((item: any, i)=>{
                                console.log(item)
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='bv-icon bv-icon--mid'>
                                                    <IoMdSchool size={20} color="#2CAD6E"></IoMdSchool>
                                                </div>
                                                <div className='ps-2'>{item?.title}</div>

                                            </div>
                                        </td>
                                      
										<td>{item?.plan?.title}</td>
                                        <td>{item?.plan?.specialty}</td>
                                        <td>{item?.duration}</td>
                                 
                                        <td className='text-center'>
                                            <div className="bv-icon bv-icon--md cursor-pointer" onClick={()=>{
                                                navigate('create', {state:{curso:item}})
                                            }}>
                                                <FaEdit size={18} color="#2CAD6E"></FaEdit>
                                            </div>
                                        </td>
										<td className='text-center'>
                                            <div className="bv-icon bv-icon--md cursor-pointer" onClick={()=>{
                                                onDeleteCurso(item.id)
                                            }}>
                                                <BiTrash size={18} color="red"></BiTrash>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            
                           
                           
                            
                        
                        </tbody>
                    </table>
            	</div>



				</div>
                <div className="col-4">

					<div className="d-flex flex-column h-100 bg-light mx-2 p-3 r-2">

						<div className="container-fluid">

							<div className="d-flex">
								<div className="text-green bold f-16">Comunidad</div>
								<div className="col"></div>
								<div className="text-green text-decoration-underline cursor-pointer" onClick={()=>{
									navigate('/community')
								}}>ir a la comunidad</div>
							</div>

						</div>

						<div className="d-flex flex-column mt-1 overflow-auto hide-scroll">
							{
								state.comentarios.map((item:any, i:number)=>{
									return (
										<div key={i} className="d-flex p-2 r-2 bv-shadow mt-2 bg-white">
											<div className="pt-1">
												<BiChat color="#2CAD6E" size={27}></BiChat>
											</div>

											<div className="col ps-2">
												<div className="text-green bold">{item?.user?.firstName} {item?.user?.lastName}</div>
												<div className="f-12 text-gray">{item?.course?.title}</div>
												<div className="f-14 text-ellipsis-3">{item?.content}</div>
												
												<div className="d-flex mt-3">
													<div className="f-12 text-gray">{DDMMYYYY(item.createdAt)}</div>
													<div className="col"></div>
													<div className="pill pill--gren cursor-pointer" onClick={()=>{
                                                        	navigate('/community', {state:{comment:item}})
                                                    }}>Responder</div>
												</div>
											
											</div>




										</div>
									)
								})
							}
						</div>

					</div>


				</div>
            </div>
        </div>

   

    


    </div>
  )
}



// export { CursosView }