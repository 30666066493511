import { theme } from "@chakra-ui/react";

const baseInputTheme = theme.components.Input;

const baseStyle = {
  field: {
    width: "323px",
    height: "45px",
    fontColor: "#E5E5E5",
    border: "1px solid",
    borderColor: "red",
  },
};

const defaultProps = {
  variant: "filled",
};

const variants = {
  filled: {
    field: {
      border: "1px solid",
      borderColor: "#E5E5E5",
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
        border: "1px solid",
        borderColor: "#E5E5E5",
        bg: "white",
      },
      _invalid: {
        border: "1px solid",
        borderColor: "red",
      },
    },
  },
};

export const inputTheme = {
  ...baseInputTheme,
  variants: {
    ...baseInputTheme.variants,
    filled: { ...baseInputTheme.variants.filled, ...variants.filled },
  },
};

export const input = {
  baseStyle,
  variants,
  defaultProps,
};
