import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMedicalRecord } from "modules/Patients/api";
import { useUpdateMedicalRecord } from "modules/Patients/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Textarea } from "shared";
import { MedicalRecord } from "types/medicalrecord";

const Diagnostic: React.FC = () => {
	const { id: patientId } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const {
		register,
		reset,
		getValues,
	} = useForm<MedicalRecord>({
		mode: "onSubmit",
	});

	const { mutateAsync: updateMedicalRecord } = useUpdateMedicalRecord(patientId!);
	const { data: medicalRecord, refetch: refetchMedicalRecord } = useMedicalRecord(patientId!);

	React.useLayoutEffect(() => {
		refetchMedicalRecord();
	}, []);

	React.useEffect(() => {
		reset(medicalRecord?.data);
	}, [medicalRecord]);

	const save = async () => {
		const data = getValues() as MedicalRecord;
		const medicalRecordSnapshot = medicalRecord!.data;
		medicalRecordSnapshot.diagnostic = data.diagnostic;

		try {
			await updateMedicalRecord(medicalRecordSnapshot);
			toast({
				title: `"Expediente Médico"`,
				description: "Expediente guardado con éxito",
				status: "success",
				position: "bottom-left",
			})
			goBack();
		} catch (error) {
			toast({
				title: "Ocurrió un error al crear el expediente medico",
				status: "error",
				position: "bottom-left",
			})
		}
	}

	const goBack = () => {
		navigate(-7);
	}

	return (
		<React.Fragment>
			<HStack>
				<BackButton path={-1} />
				<Text textColor="#C4C4C4" fontSize="22px">Historia Clínica Nutricional - Diagnóstico Nutricional</Text>
			</HStack>
			<Box background="#E0E0E0" width="100%" height="90%" borderRadius="10px" padding="34px" marginTop="10px">
				<VStack height="100%">
					<VStack flex="1" width="100%">
						<Textarea label="Describa aquí su diagnóstico" {...register("diagnostic")} />
					</VStack>
					<HStack>
						<Button variant="gray" onClick={goBack}>Cancelar</Button>
						<Button onClick={save}>Finalizar</Button>
					</HStack>
				</VStack>
			</Box>
		</React.Fragment>
	);
};

export { Diagnostic };