import axios, { AxiosResponse, AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

export type CreateCategoryParams = Readonly<{
  file: FormData;
}>;

const useCreateCategory = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, CreateCategoryParams>(
    (params) =>
      axios.post("meal-category", params.file, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    { onSuccess: () => queryClient.invalidateQueries(["categories"]) }
  );
};

export { useCreateCategory };
