import axios, { AxiosResponse, AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

type CreatePlanParams = Readonly<{
  file: FormData;
}>;

const useCreatePlan = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, CreatePlanParams>(
    (params) =>
      axios.post("plan", params.file, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    { onSuccess: () => queryClient.invalidateQueries(["plan"]) }
  );
};

export { useCreatePlan };
