import { createIcon } from "@chakra-ui/react";

const MealsIcon = createIcon({
  displayName: "MealsIcon",
  viewBox: "0 0 31 31",
  path: [
    <path
      d="M29.5909 5.63636H24.0691V1.40909C24.0691 0.634091 23.432 0 22.6533 0C21.8746 0 21.2375 0.634091 21.2375 1.40909V5.63636H15.7157C14.8804 5.63636 14.2291 6.355 14.2999 7.18636L14.4698 8.89136C19.6801 10.0891 24.0691 13.7668 24.0691 19.7273L24.0974 31H26.5044C27.6937 31 28.6706 30.0841 28.8122 28.9286L30.9925 7.18636C31.0775 6.355 30.4262 5.63636 29.5909 5.63636ZM19.8216 28.1818H1.41583C0.637124 28.1818 0 28.8159 0 29.5909C0 30.3659 0.637124 31 1.41583 31H19.8216C20.6003 31 21.2375 30.3659 21.2375 29.5909C21.2375 28.8159 20.6003 28.1818 19.8216 28.1818ZM1.55741 19.7273H19.68C20.5579 19.7273 21.2516 18.9382 21.0817 18.0927C20.1614 13.5414 15.3901 11.2586 10.6187 11.2586C5.84738 11.2586 1.09019 13.5414 0.155741 18.0927C-0.0141583 18.9382 0.693757 19.7273 1.55741 19.7273ZM19.8216 22.5455H1.41583C0.637124 22.5455 0 23.1795 0 23.9545C0 24.7295 0.637124 25.3636 1.41583 25.3636H19.8216C20.6003 25.3636 21.2375 24.7295 21.2375 23.9545C21.2375 23.1795 20.6003 22.5455 19.8216 22.5455Z"
      fill="currentColor"
    />,
  ],
});

export { MealsIcon };
