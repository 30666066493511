import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";

type ReactRouterProviderProps = {
  children?: React.ReactNode;
};

const ReactRouter: React.FC<ReactRouterProviderProps> = ({ children }) => {
  return <Router>{children}</Router>;
};

export default ReactRouter;
