import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { PlanDTO } from "types/plan";


function usePlan(id: string) {
  return useQuery<AxiosResponse<PlanDTO>, AxiosError>(["plan", id], () =>
    axios.get(`plan/${id}`)
  );
}

export { usePlan };
