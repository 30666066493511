import { createIcon } from "@chakra-ui/react";

const PersonIcon = createIcon({
  displayName: "PersonIcon",
  viewBox: "0 0 24 30",
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6663 6.66667C18.6663 10.3433 15.6763 13.3333 11.9997 13.3333C8.32301 13.3333 5.33301 10.3433 5.33301 6.66667C5.33301 2.99 8.32301 0 11.9997 0C15.6763 0 18.6663 2.99 18.6663 6.66667ZM23.6663 28.3333C23.6663 29.2533 22.9213 30 21.9997 30H1.99967C1.07801 30 0.333008 29.2533 0.333008 28.3333C0.333008 21.9 5.56801 16.6667 11.9997 16.6667C18.4313 16.6667 23.6663 21.9 23.6663 28.3333Z"
      fill="currentColor"
    />,
  ],
});

export { PersonIcon };
