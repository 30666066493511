import * as React from "react";
import { ErrorPage } from "shared";

import AdminRoutes from "./AdminRoutes";

type AuthenticatedRoutesProps = {
  role: string | null;
};

const AuthenticatedRoutes: React.FC<AuthenticatedRoutesProps> = ({ role }) => {
  if (role === "admin") return <AdminRoutes />;

  return <ErrorPage />;
};

export default AuthenticatedRoutes;
