import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMedicalRecord } from "modules/Patients/api";
import { useUpdateMedicalRecord } from "modules/Patients/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Input, Select } from "shared";
import { LifestyleIntakeIndicators } from "types/medicalrecord";

const Lifestyle: React.FC = () => {
	const { id: patientId } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const {
		register,
		reset,
		getValues,
	} = useForm<LifestyleIntakeIndicators>({
		mode: "onSubmit",
	});

	const { mutateAsync: updateMedicalRecord } = useUpdateMedicalRecord(patientId!);
	const { data: medicalRecord, refetch: refetchMedicalRecord } = useMedicalRecord(patientId!);

	React.useLayoutEffect(() => {
		refetchMedicalRecord();
	}, []);

	React.useEffect(() => {
		reset(medicalRecord?.data.lifestyleIntakeIndicators);
	}, [medicalRecord]);

	const save = async () => {
		const data = getValues() as LifestyleIntakeIndicators;
		const medicalRecordSnapshot = medicalRecord!.data;
		medicalRecordSnapshot.lifestyleIntakeIndicators = data;

		try {
			await updateMedicalRecord(medicalRecordSnapshot);
			toast({
				title: `"Expediente Médico"`,
				description: "Expediente guardado con éxito",
				status: "success",
				position: "bottom-left",
			})
		} catch (error) {
			toast({
				title: "Ocurrió un error al crear el expediente medico",
				status: "error",
				position: "bottom-left",
			})
		}
	}

	const goBack = () => {
		navigate(-6);
	}

	const next = async () => {
		navigate('diagnostic');
	}

	return (
		<React.Fragment>
			<HStack>
				<BackButton path={-1} />
				<Text textColor="#C4C4C4" fontSize="22px">Historia Clínica Nutricional - Indicadores dietéticos 02 y estilo de vida</Text>
			</HStack>
			<Box background="#E0E0E0" width="100%" height="90%" borderRadius="10px" padding="34px" marginTop="10px">
				<VStack height="100%">
					<VStack flex="1" width="100%">
						<Text fontSize="22px" textColor="#C4C4C4" alignSelf="flex-start">Frecuencias de Consumo</Text>
						<VStack alignItems="flex-start" width="100%">
							<HStack width="100%" justifyContent="space-between">
								<HStack width="50%" justifyContent="space-between">
									<Text textColor="#6E807C" fontSize="14px">Frutas</Text>
									<Select label="" {...register("intake.fruits")}>
										<option value="diary">Diario</option>
										<option value="weekly">Semanal</option>
										<option value="sometimes">A veces</option>
										<option value="never">Nunca</option>
									</Select>
								</HStack>
								<HStack width="50%" justifyContent="space-between">
									<Text textColor="#6E807C" fontSize="14px">Leguminosas</Text>
									<Select label="" {...register("intake.legumes")}>
										<option value="diary">Diario</option>
										<option value="weekly">Semanal</option>
										<option value="sometimes">A veces</option>
										<option value="never">Nunca</option>
									</Select>
								</HStack>
							</HStack>
							<HStack width="100%" justifyContent="space-between">
								<HStack width="50%" justifyContent="space-between">
									<Text textColor="#6E807C" fontSize="14px">Verduras</Text>
									<Select label="" {...register("intake.vegetables")}>
										<option value="diary">Diario</option>
										<option value="weekly">Semanal</option>
										<option value="sometimes">A veces</option>
										<option value="never">Nunca</option>
									</Select>
								</HStack>
								<HStack width="50%" justifyContent="space-between">
									<Text textColor="#6E807C" fontSize="14px">Lácteos</Text>
									<Select label="" {...register("intake.dairy")}>
										<option value="diary">Diario</option>
										<option value="weekly">Semanal</option>
										<option value="sometimes">A veces</option>
										<option value="never">Nunca</option>
									</Select>
								</HStack>
							</HStack>
							<HStack width="100%" justifyContent="space-between">
								<HStack width="50%" justifyContent="space-between">
									<Text textColor="#6E807C" fontSize="14px">Productos de Origen Animal</Text>
									<Select label="" {...register("intake.animalOriginProducts")}>
										<option value="diary">Diario</option>
										<option value="weekly">Semanal</option>
										<option value="sometimes">A veces</option>
										<option value="never">Nunca</option>
									</Select>
								</HStack>
								<HStack width="50%" justifyContent="space-between">
									<Text textColor="#6E807C" fontSize="14px">Grasas con Proteína</Text>
									<Select label="" {...register("intake.proteinFat")}>
										<option value="diary">Diario</option>
										<option value="weekly">Semanal</option>
										<option value="sometimes">A veces</option>
										<option value="never">Nunca</option>
									</Select>
								</HStack>
							</HStack>
							<HStack width="100%" justifyContent="space-between">
								<HStack width="50%" justifyContent="space-between">
									<Text textColor="#6E807C" fontSize="14px">Cereales y Tubérculos</Text>
									<Select label="" {...register("intake.cereal")}>
										<option value="diary">Diario</option>
										<option value="weekly">Semanal</option>
										<option value="sometimes">A veces</option>
										<option value="never">Nunca</option>
									</Select>
								</HStack>
							</HStack>
						</VStack>
						<Text fontSize="22px" textColor="#C4C4C4" alignSelf="flex-start">Estilo de Vida</Text>
						<VStack width="100%">
							<HStack width="100%">
								<Select label="Actividad Fisica" {...register("lifestyle.physicalActivity")} >
									<option value="sedentary">Sedentaria</option>
									<option value="soft">Ligera</option>
									<option value="moderate">Moderada</option>
									<option value="high">Alta</option>
								</Select>
								<Input label="Tipo de actividad" {...register("lifestyle.activityType")} />
								<Input label="Frequencia" {...register("lifestyle.physicalActivityStart")} />
							</HStack>
							<HStack width="100%" justifyContent="space-between">
								<HStack width="50%" justifyContent="space-between">
									<Input label="Alcohol" {...register("lifestyle.alcohol")} />
								</HStack>
								<HStack width="50%" justifyContent="space-between">
									<Input label="Café" {...register("lifestyle.coffee")} />
								</HStack>
							</HStack>
							<HStack width="100%" justifyContent="space-between">
								<HStack width="50%" justifyContent="space-between">\
									<Input label="Tabaco" {...register("lifestyle.tobacco")} />
								</HStack>
								<HStack width="50%" justifyContent="space-between">\
									<Input label="Tes" {...register("lifestyle.tea")} />
								</HStack>
							</HStack>
						</VStack>
					</VStack>
					<HStack>
						<Button variant="gray" onClick={goBack}>Cancelar</Button>
						<Button onClick={save}>Guardar</Button>
						<Button onClick={next}>Siguiente</Button>
					</HStack>
				</VStack>
			</Box>
		</React.Fragment>
	);
};

export { Lifestyle };