import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMedicalRecord } from "modules/Patients/api";
import { useUpdateMedicalRecord } from "modules/Patients/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Checkbox, Input, Select, Textarea } from "shared";
import { AppointmentInformationData } from "types/medicalrecord";

const AppointmentInformation: React.FC = () => {
	const { id: patientId } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const {
		register,
		reset,
		getValues,
	} = useForm<AppointmentInformationData>({
		mode: "onSubmit",
	});

	const { mutateAsync: updateMedicalRecord } = useUpdateMedicalRecord(patientId!);
	const { data: medicalRecord, refetch: refetchMedicalRecord } = useMedicalRecord(patientId!);

	React.useLayoutEffect(() => {
		refetchMedicalRecord();
	}, []);

	React.useEffect(() => {
		reset(medicalRecord?.data.appointmentInformation);
	}, [medicalRecord]);

	const save = async () => {
		const data = getValues() as AppointmentInformationData;
		const medicalRecordSnapshot = medicalRecord!.data;
		medicalRecordSnapshot.appointmentInformation = data;

		try {
			await updateMedicalRecord(medicalRecordSnapshot);
			toast({
				title: `"Expediente Médico"`,
				description: "Expediente guardado con éxito",
				status: "success",
				position: "bottom-left",
			})
		} catch (error) {
			toast({
				title: "Ocurrió un error al crear el expediente medico",
				status: "error",
				position: "bottom-left",
			})
		}
	}

	const goBack = () => {
		navigate(-2);
	}

	const next = async () => {
		navigate('hereditary');
	}

	return (
		<React.Fragment>
			<HStack>
				<BackButton path={-1} />
				<Text textColor="#C4C4C4" fontSize="22px">Historia Clínica Nutricional - Motivo de la consulta (situacion actual) y antecedentes patológicos </Text>
			</HStack>
			<Box background="#E0E0E0" width="100%" height="90%" borderRadius="10px" padding="34px" marginTop="10px">
				<VStack height="100%">
					<VStack flex="1" width="100%">
						<Textarea label="Describa aqui el motivo de la consulta" resize="none" {...register("meetingReason")} />
						<HStack width="100%" justifyContent="space-evenly">
							<VStack alignItems="flex-start">
								<Checkbox {...register("illnessesMarkup.diarrhea")}>Diarrea</Checkbox>
								<Checkbox {...register("illnessesMarkup.vomit")}>Vómito</Checkbox>
								<Checkbox {...register("illnessesMarkup.poliuria")}>Poliuria</Checkbox>
							</VStack>
							<VStack alignItems="flex-start">
								<Checkbox {...register("illnessesMarkup.constipation")}>Estreñimiento</Checkbox>
								<Checkbox {...register("illnessesMarkup.pirosis")}>Pirosis</Checkbox>
								<Checkbox {...register("illnessesMarkup.polifargia")}>Polifagia</Checkbox>
							</VStack>
							<VStack alignItems="flex-start">
								<Checkbox {...register("illnessesMarkup.gastritis")}>Gastritis</Checkbox>
								<Checkbox {...register("illnessesMarkup.colitis")}>Colitis</Checkbox>
								<Checkbox {...register("illnessesMarkup.polidipsia")}>Polidipsia</Checkbox>
							</VStack>
							<VStack alignItems="flex-start">
								<Checkbox {...register("illnessesMarkup.ulcerus")}>Ulcera</Checkbox>
								<Checkbox {...register("illnessesMarkup.irritableColon")}>Colon Irritable</Checkbox>
								<Checkbox {...register("illnessesMarkup.weightLoss")}>Pérdida de Peso</Checkbox>
							</VStack>
							<VStack alignItems="flex-start" alignSelf="flex-start">
								<Checkbox {...register("illnessesMarkup.nausea")}>Nauseas</Checkbox>
							</VStack>
						</HStack>
						<HStack width="100%" justifyContent="space-evenly">
							<Input width="328px" label="Otros" {...register("illnessesMarkup.others")} />
							<Input label="Observaciones" {...register("illnessesMarkup.observations")} />
						</HStack>
						<Input label="¿Padece alguna enfermedad diagnosticada?" {...register("diagnosedIllness")} />
						<HStack width="100%" justifyContent="space-evenly">
							<Input label="¿Toma algún medicamento? ¿Cuál y que dósis?" width="328px" {...register("medicineMarkup.medicine")} />
							<HStack justifyContent="space-evenly" flex="1">
								<VStack alignItems="flex-start">
									<Checkbox {...register("medicineMarkup.laxant")}>Laxante</Checkbox>
									<Checkbox {...register("medicineMarkup.antiacid")}>Antiácido</Checkbox>
								</VStack>
								<VStack alignItems="flex-start">
									<Checkbox {...register("medicineMarkup.diuretic")}>Diurético</Checkbox>
									<Checkbox {...register("medicineMarkup.analgesic")}>Analgésico</Checkbox>
								</VStack>
							</HStack>
						</HStack>
						<HStack width="100%" justifyContent="space-evenly">
							<Input label="Suplementos" width="328px" {...register("supplements")} />
							<Input label="Cirugías" {...register("surgeries")} />
						</HStack>
					</VStack>
					<HStack>
						<Button variant="gray" onClick={goBack}>Cancelar</Button>
						<Button onClick={save}>Guardar</Button>
						<Button onClick={next}>Siguiente</Button>
					</HStack>
				</VStack>
			</Box>
		</React.Fragment>
	);
};

export { AppointmentInformation };