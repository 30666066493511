import { chakra, HStack, Spacer, VStack, Text, Button, useToast, Box, Icon, useDisclosure, Flex } from "@chakra-ui/react";
import { TrashIcon } from "assets/icons/Trash";
import { usePlan } from "modules/Plans/api";
import { useCreatePlan, useDeletePlan } from "modules/Plans/api/mutations";
import { useUpdatePlan } from "modules/Plans/api/mutations/useUpdatePlan";
import { useCategories } from "modules/Plans/api/queries/useCategories";
import React, { useLayoutEffect, useRef } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdClose, MdOutlineCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Input, InputFile, LoadingPage, Select, Textarea } from "shared";
import { SCROLL_BAR_STYLES } from "theme/styling/scrollbar";
import { PlanDocument, PlanDTO } from "types/plan";
import { DocumentModal } from "../Document";
import { Service, _Service } from "api/api";
import { Category } from "types/categories";
import { FaTrash } from "react-icons/fa";
import { LoadingOverlay } from "shared/LoadingPage/LoadingPage";
import Renderif from "utils/renderif";
import { FaFilePdf, FaRegFilePdf } from 'react-icons/fa6';

type DocumentBadgeProps = Readonly<{
	label: string;
	onClick(): void;
}>;

const DocumentBadge: React.FC<DocumentBadgeProps> = ({ label, onClick }) => (
	<HStack
		role="button"
		borderRadius="full"
		border="2px solid"
		borderColor="green.15"
		maxW="-webkit-max-content"
		p="0.2rem 0.5rem"
		onClick={onClick}
		_active={{ transform: "scale(0.95)" }}
	>
		<chakra.span color="gray.15" fontWeight="500">
			{label}
		</chakra.span>
		<Icon as={MdOutlineCancel} color="green.15" boxSize="1.5rem" />
	</HStack>
);



type PlanState = {
	_categories: string[];
	_categoriesSelected: string[];

	_subCategoriesSelected: string[];
	_subCategories: string[];
	_suscriptionSelected: string[];
	_specialitySelected: string
	loading:boolean
};


const Plan: React.FC = () => {
	const { id } = useParams();
	const { data: plan, refetch: refetchPlan } = usePlan(id ?? '');
	const { mutateAsync: deletePlanAsync } = useDeletePlan(id ?? '');
	const { register, reset, getValues } = useForm<PlanDTO>();
	const { data: categories } = useCategories();
	const { mutateAsync: createPlanAsync } = useCreatePlan();
	const { mutateAsync: updatePlanAsync } = useUpdatePlan(id ?? '');
	const toast = useToast();
	const navigate = useNavigate();
	const documentInputRef = useRef<HTMLInputElement>(null);
	const [planFoto, setPlanFoto] = useState<File>();
	const [documents, setDocuments] = useState<PlanDocument[]>([]);
	const [currentDocument, setCurrentDocument] = useState<File>();
	const documentModalDisclosure = useDisclosure();



	const [state, setSatate] = useState<PlanState>({
		_categories:[
			"SOP",
			"Endometriosis",
			"Control de Peso",
			"Resistencia a la insulina",
			"Diabetes",
			"Salud 360",
			"General",
			"Healthy Kids"
		],
		_categoriesSelected:[],
		_subCategories:[
			"Hiperproteica",
			"Cetogenica",
			"Libre de gluten",
      		"Paleolítica y mediterránea",
			"Antiinflamatoria",
			"Basada en Plantas",
			"Low Carb",
			"General"
		],
		_subCategoriesSelected:[],
		_suscriptionSelected:[],
		_specialitySelected:"",
		loading:false
		
	})

	useEffect(()=>{
		console.log(documents)
	},[documents])
	


	useEffect(() => {

		const init = async () => {
			

			if(id){
				let plan = await _Service('GET', 'plans/'+id)
				console.log(plan)
				
				reset(plan.data)
				state._categoriesSelected = plan.data.category;
				state._subCategoriesSelected = plan.data.subCategory;
				state._specialitySelected = plan.data.specialty

				let _subs = [] as any 
				plan.data.stripeSubscriptions.map((item:any)=>{
					console.log(item.stripeSubscription.id)
					_subs.push(item.stripeSubscription.id.toString())
				})
				state._suscriptionSelected = _subs
			
				setDocuments(plan?.data.documents || []);
			}
			// let _categories = await Service('GET', 'meal-category');
			// let _subcategories = await Service('GET', 'meal-sub-category');
			// console.log(_subcategories)
			// setSatate({...state,  _subCategories:_subcategories.data})
		};
		init();
		return () => {}

	}, []);



	const onInputPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
		const _files = Object.values(event.target.files!);
		setPlanFoto(_files[0]);
	};

	const onDeletePlan = async () => {
		try {
			await _Service('DELETE', `plans/${id}`)
			toast({
				title: `Plan ha sido eliminado`,
				status: "success",
				position: "bottom-left",
			});
			navigate(-1);
		} catch (error) {
			toast({
				title: `Plan no ha podido ser eliminado`,
				status: "error",
				position: "bottom-left",
			});
		}
	};

	const onCancel = () => {
		navigate(-1);
	};

	const onSave = async () => {
		setSatate({...state, loading:true})
		if(state._suscriptionSelected.length === 0){
			toast({title: "Selecciona una suscripción",status: "error",position: "bottom-left",});
			return
		}
		// setSatate({...state, loading:true})
		const data = getValues() as PlanDTO;
		const formData = new FormData();


		const updatedPlan = {
			Name: data.title,
			// Price: 0,
			// Plus: data.plus,
			Plus:'Plus',
			Description: data.descripcion,
			MealCategories:[],
			MealSubCategories:[],


			// documents
		};

		formData.append("metadata", JSON.stringify(updatedPlan));

		if (id) {

			let _image = null;
			let _documents = [] as any

			let upload_file;

			if(planFoto){
				let body = new FormData();
				body.append("file", planFoto);
				body.append("infoName", "");

				upload_file = await _Service('POSTMEDIA', 'files/upload', body)
				_image = upload_file.data.id;
			}else{
				_image = data.image.id
			}

			if(documents.length > 0){
				console.log(documents)
				for (let i = 0; i < documents.length; i++) {
					if(documents[i].file !== undefined){
						let body = new FormData();
						body.append("file", documents[i].file);
						body.append("infoName", documents[i].name);
						upload_file = await _Service('POSTMEDIA', 'files/upload', body)
						_documents.push({
							"id":upload_file.data.id
						});
					}else{
						_documents.push({
							"id":documents[i].id
						})
					}
				}
			}

			let subs = [] as any
			state._suscriptionSelected.map((sub:any)=>{
				subs.push({
					id:parseInt(sub)
				})
			})
			
			let body = {
				title: data.title,
				category: state._categoriesSelected,
				image:{
					"id": _image
				},
				subCategory: state._subCategoriesSelected,
				documents:_documents,
				type: "GENERAL",
				descripcion: data.descripcion,
				stripeSubscriptions:subs,
				specialty:state._specialitySelected


			}
			console.log(body)
	

			try {
				
				let _plan_update = await _Service('PUT', `plans/${id}`, JSON.stringify(body))
				console.log(_plan_update)
				if(_plan_update.status === 200){
					toast({
						title: "El plan se ha actualizado satisfactoriamente",
						status: "success",
						position: "bottom-left",
					});
					navigate(-1);
				}else{
					setSatate({...state, loading:false})
				toast({
					title: "No se ha podido actualizar el plan",
					status: "error",
					position: "bottom-left",
				});

				}
			
		
			} catch (error) {
				setSatate({...state, loading:false})
				toast({
					title: "No se ha podido actualizar el plan",
					status: "error",
					position: "bottom-left",
				});
			}
		} else {
			
			
			let _image = null;
			let _documents = []

			let upload_file;

			if(planFoto){
				let body = new FormData();
				body.append("file", planFoto);

				upload_file = await _Service('POSTMEDIA', 'files/upload', body)
				_image = upload_file.data.id;
			}
			if(documents.length > 0){
				for (let i = 0; i < documents.length; i++) {
					let body = new FormData();
					body.append("file", documents[i].file);
					upload_file = await _Service('POSTMEDIA', 'files/upload', body)
					_documents.push({
						"id":upload_file.data.id
					});
				}
			}

			let subs = [] as any
			state._suscriptionSelected.map((sub:any)=>{
				subs.push({
					"id":parseInt(sub)
				})
			})

			let body = {
				title: data.title,
				category: state._categoriesSelected,
				subCategory: state._subCategoriesSelected,
				image: {
					"id": _image
				},
				type: "GENERAL",
				documents: _documents,
				descripcion: data.descripcion,
				stripeSubscriptions:subs,
				specialty:state._specialitySelected
			}
		


			// if (!planFoto) {
			// 	toast({
			// 		title: "Todavía no has añadido una foto",
			// 		status: "error",
			// 		position: "bottom-left",
			// 	});
			// 	return;
			// }
			// formData.append("image", planFoto);

			 
			// const documentFiles = documents.filter(x => x.file).map(x => x.file!);
			// documentFiles.forEach(file => formData.append("documents", file));
			

			try {
			
				
				let _plan = await _Service('POST', 'plans', JSON.stringify(body))
				if(_plan.status === 201){
					toast({
						title: "El plan se ha creado satisfactoriamente",
						status: "success",
						position: "bottom-left",
					});
					navigate(-1);
				}else{
					setSatate({...state, loading:false})
					toast({
						title: "No se ha podido crear el plan",
						status: "error",
						position: "bottom-left",
					});
				}
				console.log(_plan)
			
			} catch {
				setSatate({...state, loading:false})
				toast({
					title: "No se ha podido crear el plan",
					status: "error",
					position: "bottom-left",
				});
			}
		}
	};

	const onRemoveDocument = (name: string) => {
		const index = documents.findIndex(element => element.name === name);

		let newDocuments = [...documents];
		newDocuments.splice(index, 1);
		setDocuments(newDocuments);
	};

	const openFileWindow = () => {
		if (documentInputRef.current) {
			documentInputRef.current.click();
		}
	};

	const addDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileObj = event.target.files && event.target.files[0];
		if (!fileObj) {
			return;
		}
		event.target.value = '';

		setCurrentDocument(fileObj);
		documentModalDisclosure.onOpen();
	};

	const onDocumentNamed = (document: PlanDocument) => {
		let newDocuments = [...documents, document];
		setCurrentDocument(undefined);
		setDocuments(newDocuments);
	}

	
	// return (<></>)
	return (
		
		<VStack h="100%" w="100%">
			
			<Renderif isTrue={state.loading}>
				<LoadingOverlay></LoadingOverlay>
			</Renderif>
		

			<HStack w="100%">
				<BackButton path="/plans" />
				<chakra.span color="gray.10" fontSize="xl">
					{id ? plan?.data.title : 'Nuevo Plan'}
				</chakra.span>
				<Spacer />
				{id &&
					<HStack _hover={{ cursor: 'pointer' }} onClick={onDeletePlan}>
           
					<Text textColor="#F33939">Eliminar</Text>
					<div className="md-icon md-icon--mid">
					  <FaTrash color="#F33939" ></FaTrash>
					</div>
				  </HStack>
				}
			</HStack>

		

			<VStack w="100%" h="93%" p={5} bg="#efefef" borderRadius="md" boxShadow="md">
				

				<Flex w="100%" gap={2}>
					
					<Box flex={1}>
						<Input label="Titulo" {...register("title")} />
					</Box>

					<Box flex={1}>
						<Select 
						
							label="Categoria"
							value={""}
							onChange={(evt)=>{
							console.log(evt.currentTarget.value);
							let _categorie = state._categories.find((item) => item === evt.currentTarget.value);
							

							if(state._categoriesSelected.find((item) => item === evt.currentTarget.value)){
								return
							}


							state._categoriesSelected.push(_categorie as string);
							setSatate({...state});
							
							}}
						>
								<option value="">Seleccionar</option>
							
							{state._categories.map((item, i)=>{
								return (
								
									<option value={item} key={i}>{item}</option>
								)
							})}
							
							
							

							
							
						</Select>
						<div className="d-flex flex-wrap">
							{
							state._categoriesSelected.map((item,i)=>{
								return (
								<div key={i} className="pill pill--gren">
									<div>{item}</div>
									<div className="md-icon cursor-pointer" onClick={()=>{
									state._categoriesSelected = state._categoriesSelected.filter((cat)=> cat !== item);
									setSatate({...state});
									}}>
									<MdClose size={14}></MdClose>
									</div>
								</div>
								)
							})
							}
						</div>
					</Box>
					<Box flex={1}>
						<Select 
							label="Sub Categoria"
							value={""}
							onChange={(evt)=>{
							console.log(evt.currentTarget.value);
							let _categorie = state._subCategories.find((item) => item === evt.currentTarget.value);
							

							if(state._subCategoriesSelected.find((item) => item === evt.currentTarget.value)){
								return
							}


							state._subCategoriesSelected.push(_categorie as string);
							setSatate({...state});
							
							}}
						>
							<option value="">Seleccionar</option>
							{state._subCategories.map((item, i)=>{
								return (
								
									<option value={item} key={i}>{item}</option>
								)
							})}
							

							
							
						</Select>
						<div className="d-flex flex-wrap">
							{
							state._subCategoriesSelected.map((item,i)=>{
								return (
								<div key={i} className="pill pill--gren">
									<div>{item}</div>
									<div className="md-icon cursor-pointer" onClick={()=>{
									state._subCategoriesSelected = state._subCategoriesSelected.filter((cat)=> cat !== item);
									setSatate({...state});
									}}>
									<MdClose size={14}></MdClose>
									</div>
								</div>
								)
							})
							}
						</div>
					</Box>
					
				</Flex>


				<Flex w="100%" gap={2}>

					<Box flex={4}>
						<InputFile
							label="Foto del Plan (Max 30MB)"
							value={plan?.data.imageRef || planFoto?.name || 'Escoge una foto'}
							onChange={onInputPhoto}
						/>
						
					</Box>

					<Box flex={4}>
						<Select 
							label="Suscripción"
							value={""}
							onChange={(evt)=>{

								
								if(state._suscriptionSelected.find((item) => item === evt.currentTarget.value)){
									return
								}
							
	
	
								state._suscriptionSelected.push(evt.currentTarget.value as string);
								setSatate({...state});

							
							
							}}
						>
							<option value="">Seleccionar</option>
							<option value="1">PLAN FUNCIONAL</option>
							<option value="2">PLAN FUNCIONAL PLUS</option>
							
							

							
							
						</Select>
						<div className="d-flex flex-wrap">
							{
							state._suscriptionSelected.map((item,i)=>{
								return (
								<div key={i} className="pill pill--gren">
									<div>{item === '1' ? 'PLAN FUNCIONA' : 'PLAN FUNCIONAL PLUS'}</div>
									<div className="md-icon cursor-pointer" onClick={()=>{
									state._suscriptionSelected = state._suscriptionSelected.filter((cat)=> cat !== item);
									setSatate({...state});
									}}>
									<MdClose size={14}></MdClose>
									</div>
								</div>
								)
							})
							}
						</div>
					</Box>

					<Box flex={4}>
						<Select 
							label="Especialidad"
							value={state._specialitySelected}
							onChange={(evt)=>{

								
							
	
	
								state._specialitySelected = evt.currentTarget.value
								setSatate({...state});

							
							
							}}
						>
							<option value="">Seleccionar</option>
							<option value="NUTRITION">NUTRICIÓN</option>
							<option value="PSYCHOLOGY">PSICOLOGIA</option>
							<option value="GYNECOLOGY">GINECOLOGIA</option>
							
							

							
							
						</Select>
						{/* <div className="d-flex flex-wrap">
							{
							state._suscriptionSelected.map((item,i)=>{
								return (
								<div key={i} className="pill pill--gren">
									<div>{item === '1' ? 'PLAN FUNCIONA' : 'PLAN FUNCIONAL PLUS'}</div>
									<div className="md-icon cursor-pointer" onClick={()=>{
									state._suscriptionSelected = state._suscriptionSelected.filter((cat)=> cat !== item);
									setSatate({...state});
									}}>
									<MdClose size={14}></MdClose>
									</div>
								</div>
								)
							})
							}
						</div> */}
					</Box>
					
					
					{/* <Box flex={1}>
						<Input label="Precio" placeholder="$0.00" {...register("price")} type="number" />
					</Box> */}
				
				
				</Flex>
				



			
				<VStack w="100%">
					<chakra.span
						color="gray.25"
						alignSelf="flex-start"
						fontSize="small"
					>
						Documentos (Max 30MB)
					</chakra.span>
					
					<HStack
						w="100%"
						bg="white"
						borderRadius="md"
						p="0.25rem"
						alignItems="center"
						display="flex"
					>
						<HStack width="100%" alignItems="center" overflowX="auto" sx={SCROLL_BAR_STYLES}>
							{documents?.map((document, index) => { 
								console.log(document.name)
								return <></>
								return(
								
								<DocumentBadge
									label={document.name!}
									key={`document-badge-${index}`}
									onClick={() => onRemoveDocument(document.name!)}
								/>
							)})}
						</HStack>
						<Box display="flex">
							<input ref={documentInputRef} type="file" style={{ display: 'none' }} onChange={addDocument} accept="application/pdf" />
							<Icon
								justifySelf="flex-end"
								as={IoIosAddCircleOutline}
								role="button"
								color="green.15"
								boxSize="2rem"
								_active={{ transform: "scale(0.8)" }}
								onClick={openFileWindow}
							/>
						</Box>
					</HStack>
					<div className="w-100">
						<div className="d-flex flex-wrap w-100">
							{
							documents.map((item,i)=>{
								console.log(item);
								return (
								<div key={i} className="pill pill--gren">
									<div className=" d-flex cursor-pointer" onClick={()=>{
										window.open(`https://mydiet-assets-2023.s3.amazonaws.com/${item.name}`, '_blank')
									}}>
										<FaRegFilePdf size={16}></FaRegFilePdf>
										<div className="ps-1">{item.infoName}</div>
									</div>

									<div className="mx-2"></div>
									<div className="md-icon cursor-pointer" onClick={()=>{
										onRemoveDocument(item.name)
									}}>
									
									<MdClose size={14}></MdClose>
									</div>
								</div>
								)
							})
							}
						</div>
					</div>
				</VStack>

		


				<HStack w="100%">
					<Textarea label="Breve descripción de este plan" h="200px" {...register("descripcion")} resize="none" />
				</HStack>
				<HStack flex="1" alignItems="end">
					<Button variant="gray" onClick={onCancel}>Cancelar</Button>
					<Button onClick={onSave}>Guardar</Button>
				</HStack>
			</VStack>


			{documentModalDisclosure.isOpen && (
				<DocumentModal
					isOpen={documentModalDisclosure.isOpen}
					onClose={documentModalDisclosure.onClose}
					file={currentDocument!}
					onFinish={onDocumentNamed}
				/>
			)}


		</VStack>
	);
}

export { Plan };