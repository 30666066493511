/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Center,
  Icon,
  Spinner,
  chakra,
  Flex,
} from "@chakra-ui/react";
import { IoIosAddCircleOutline } from "react-icons/io";

import { LoadingPage } from "shared";
import { SCROLL_BAR_STYLES } from "theme/styling/scrollbar";

import { Meal } from "types/meal";
import { useMeals, MealsParams } from "../../api";
import { MealSearchBar } from "../MealSearchBar";
import { Recipe } from "./Recipe";
import { Category } from "types/categories";
import { Service, _Service } from "api/api";
import { FaEdit, FaPen, FaPlus, FaTrash } from "react-icons/fa";
import Renderif from "utils/renderif";
import { IoIosCloseCircle } from "react-icons/io";
import { FaFilter } from "react-icons/fa6";

interface RecipesListState {
	_categories: Category[];
	_subcategories: Category[];
  _recipes: any[],
  _show_valoraciones: boolean,
  _recipe_active: any,
  _show_filter:boolean,
  _filters: any[],
  _filterCategory: any[],
  _filterSubCategory : any[]
};

const RecipeList: React.FC = () => {
  const navigate = useNavigate();

  const [mealList, setMealList] = React.useState<Meal[]>();
  const [params, setParams] = React.useState<MealsParams>({
    page: 0,
    search: undefined,
  });

  const { data, isLoading, refetch } = useMeals(params);

  const isMealsLoading = params.page === 0 && isLoading;
  const isMealsPageLoading = params.page > 0 && isLoading;

  const [state, setSatate] = React.useState<RecipesListState>({
    _categories: [],
    _subcategories: [],
    _recipes:[],
    _show_valoraciones:false,
    _recipe_active:null,
    _show_filter:false,
    _filters: [
      // "Desayuno",
      // "Comida",
      // "Cena"
    ],
    _filterCategory:[
      // "SOP",
			// "Endometriosis",
			// "Control de Peso",
			// "Resistencia a la insulina",
			// "Diabetes",
      // "Salud 360",
			// "General"
    ],
    _filterSubCategory:[
      // "Hiperproteica",
			// "Cetogenica",
			// "Antiinflamatoria",
      // "Libre de gluten",
      // "Paleolítica y mediterránea",
			// "Basada en Plantas",
			// "Low Carb",
			// "General"
    ]

	})

  React.useLayoutEffect(() => {
    refetch();
  }, []);

  const onSearch = React.useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const filter = event.target.value;
      setMealList([]);

      if (!filter) {
        setParams({ page: 0, search: undefined });
        return;
      }

      setParams({ page: 0, search: filter });
    }, 275),
    []
  );

  // React.useEffect(() => {
  //   if (data?.data.content) {
  //     setMealList(data?.data.content);
  //   }
  // }, [data?.data.content]);

  React.useEffect(() => {
   
    const init = async () => {
      // let _categories = await Service('GET', 'meal-category');
			// let _subcategories = await Service('GET', 'meal-sub-category');

      let _recipes = await _Service('GET', 'recipes');
  
      
      setSatate({...state, _recipes:_recipes.data.reverse()})
		};
		init();
    return () => {}
  }, []);



  const setMealTime = (meal: Meal) => {
    let mealTimes: any = {
      afternoonSnack:'Colación de la Tarde',
      morningSnack:'Colación de la Mañana',
      breakfast:'Desayuno',
      meal:'Comida',
      dinner:'Cena',
    }
    let mealTime = '';
    for (const [key, value] of Object.entries(meal.mealTimes)) {
         
      if(value){
       
        mealTime = mealTimes[key];
    
      }
    }
    return mealTime;
  }
  const setCategory = (categories: []) => {
      console.log("AA")
    return <>a</>
    // return state._categories.find((cat) => cat.id === id)?.name

  }

  const filter = (data:any) => {
    if(state._filters.length === 0){
      return data;
    }
    let _data = data.filter((item:any)=> {
      if(
      item.mealTime.some((r:any) => state._filters.includes(r))){
        return true
      }
      return false
    })

    
    if(state._filterCategory.length > 0){
      _data = _data.filter((item:any)=> {
        if(item.mealCategories.some((r:any) => state._filterCategory.includes(r))){
          return true
        }
        return false
      })
    }

    if(state._filterSubCategory.length > 0){
      _data = _data.filter((item:any)=> {
        if(item.mealSubCategories.some((r:any) => state._filterSubCategory.includes(r))){
          return true
        }
        return false
      })
    }


    return _data
  }

  const memoizedMealList = () => {

    return (
      <>
      <Renderif isTrue={state._show_valoraciones} >
        <ValoracionesRecipe meal={state._recipe_active} onClose={()=>{
          setSatate({...state, _show_valoraciones:false, _recipe_active:null})
        }}></ValoracionesRecipe>
      </Renderif>

      <Renderif isTrue={state._show_filter} >

        <div className="bv-modal">
          <div className="bv-modal-body bv-modal-body--small">
          <div className="d-flex">
            <div className="col"></div>
            <div className="bv-icon bv-icon--md cursor-pointer" onClick={()=>{
              setSatate({...state, _show_filter:false})
            }}>
              <IoIosCloseCircle size={30} color="#2CAD6E"></IoIosCloseCircle>
            </div>
          </div>
              <div className="f-14 bold text-green">Tiempo de comida</div>
              <div className="d-flex flex-wrap">
                <div className={`pill ${state._filters.includes('Desayuno') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filters.includes('Desayuno')){
                    state._filters = state._filters.filter((item)=>item !== 'Desayuno')
                  }else{
                    state._filters.push('Desayuno')
                  }
                  setSatate({...state})
                }}>Desayuno</div>
                <div className={`pill ${state._filters.includes('Comida') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filters.includes('Comida')){
                    state._filters = state._filters.filter((item)=>item !== 'Comida')
                  }else{
                    state._filters.push('Comida')
                  }
                  setSatate({...state})
                }}>Comida</div>
                <div className={`pill ${state._filters.includes('Cena') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filters.includes('Cena')){
                    state._filters = state._filters.filter((item)=>item !== 'Cena')
                  }else{
                    state._filters.push('Cena')
                  }
                  setSatate({...state})
                }}>Cena</div>
              </div>
              <div className="mt-3"></div>
              <div className="f-14 bold text-green">Categoria</div>
              <div className="d-flex flex-wrap">
                <div className={`pill ${state._filterCategory.includes('SOP') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterCategory.includes('SOP')){
                    state._filterCategory = state._filterCategory.filter((item)=>item !== 'SOP')
                  }else{
                    state._filterCategory.push('SOP')
                  }
                  setSatate({...state})
                }}>SOP</div>
                <div className={`pill ${state._filterCategory.includes('Endometriosis') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterCategory.includes('Endometriosis')){
                    state._filterCategory = state._filterCategory.filter((item)=>item !== 'Endometriosis')
                  }else{
                    state._filterCategory.push('Endometriosis')
                  }
                  setSatate({...state})
                }}>Endometriosis</div>
                <div className={`pill ${state._filterCategory.includes('Control de Peso') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterCategory.includes('Control de Peso')){
                    state._filterCategory = state._filterCategory.filter((item)=>item !== 'Control de Peso')
                  }else{
                    state._filterCategory.push('Control de Peso')
                  }
                  setSatate({...state})
                }}>Control de Peso</div>
                <div className={`pill ${state._filterCategory.includes('Resistencia a la insulina') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterCategory.includes('Resistencia a la insulina')){
                    state._filterCategory = state._filterCategory.filter((item)=>item !== 'Resistencia a la insulina')
                  }else{
                    state._filterCategory.push('Resistencia a la insulina')
                  }
                  setSatate({...state})
                }}>Resistencia a la insulina</div>
                <div className={`pill ${state._filterCategory.includes('Diabetes') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterCategory.includes('Diabetes')){
                    state._filterCategory = state._filterCategory.filter((item)=>item !== 'Diabetes')
                  }else{
                    state._filterCategory.push('Diabetes')
                  }
                  setSatate({...state})
                }}>Diabetes</div>
                <div className={`pill ${state._filterCategory.includes('Salud 360') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterCategory.includes('Salud 360')){
                    state._filterCategory = state._filterCategory.filter((item)=>item !== 'Salud 360')
                  }else{
                    state._filterCategory.push('Salud 360')
                  }
                  setSatate({...state})
                }}>Salud 360</div>
                <div className={`pill ${state._filterCategory.includes('General') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterCategory.includes('General')){
                    state._filterCategory = state._filterCategory.filter((item)=>item !== 'General')
                  }else{
                    state._filterCategory.push('General')
                  }
                  setSatate({...state})
                }}>General</div>
              </div>
              <div className="mt-3"></div>
              <div className="f-14 bold text-green">Subcategoria</div>
              <div className="d-flex flex-wrap">
                <div className={`pill ${state._filterSubCategory.includes('Hiperproteica') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('Hiperproteica')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'Hiperproteica')
                  }else{
                    state._filterSubCategory.push('Hiperproteica')
                  }
                  setSatate({...state})
                }}>Hiperproteica</div>
                <div className={`pill ${state._filterSubCategory.includes('Cetogenica') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('Cetogenica')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'Cetogenica')
                  }else{
                    state._filterSubCategory.push('Cetogenica')
                  }
                  setSatate({...state})
                }}>Cetogenica</div>
                <div className={`pill ${state._filterSubCategory.includes('Antiinflamatoria') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('Antiinflamatoria')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'Antiinflamatoria')
                  }else{
                    state._filterSubCategory.push('Antiinflamatoria')
                  }
                  setSatate({...state})
                }}>Antiinflamatoria</div>
                <div className={`pill ${state._filterSubCategory.includes('Libre de gluten') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('Libre de gluten')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'Libre de gluten')
                  }else{
                    state._filterSubCategory.push('Libre de gluten')
                  }
                  setSatate({...state})
                }}>Libre de gluten</div>
                <div className={`pill ${state._filterSubCategory.includes('Paleolítica y mediterránea') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('Paleolítica y mediterránea')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'Paleolítica y mediterránea')
                  }else{
                    state._filterSubCategory.push('Paleolítica y mediterránea')
                  }
                  setSatate({...state})
                }}>Paleolítica y mediterránea</div>
                <div className={`pill ${state._filterSubCategory.includes('Basada en Plantas') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('Basada en Plantas')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'Basada en Plantas')
                  }else{
                    state._filterSubCategory.push('Basada en Plantas')
                  }
                  setSatate({...state})
                }}>Basada en Plantas</div>
                <div className={`pill ${state._filterSubCategory.includes('Low Carb') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('Low Carb')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'Low Carb')
                  }else{
                    state._filterSubCategory.push('Low Carb')
                  }
                  setSatate({...state})
                }}>Low Carb</div>
                <div className={`pill ${state._filterSubCategory.includes('General') ? 'pill--gren' :'' } m-0 mt-1 me-1 cursor-pointer`} onClick={()=>{
                  if(state._filterSubCategory.includes('General')){
                    state._filterSubCategory = state._filterSubCategory.filter((item)=>item !== 'General')
                  }else{
                    state._filterSubCategory.push('General')
                  }
                  setSatate({...state})
                }}>General</div>
               
              </div>
          </div>
        </div>
       
      </Renderif>

   

      <div className="d-flex w-100 py-2 px-3 bg-light r-2">
        <Box flex={1} display={'flex'}    alignItems={'center'}>
        
          {/* <div className="d-flex align-items-center cursor-pointer">
              <div className="f-14 bold line-0 ">Nombre de receta</div>
             
          </div> */}

          <div className="d-flex align-items-center">
           <div className="f-14 bold line-0 ">Nombre de receta</div>
           
          </div>
          
        </Box>
        <Box flex={1}>
          <div className="d-flex align-items-center cursor-pointer" onClick={()=>{
            setSatate({...state, _show_filter:true})
          }}>
           <div className="f-14 bold line-0 ">Tiempo de Comida</div>
              <div className="bv-icon bv-icon--mid">
                <FaFilter size={10}></FaFilter>
              </div>
          </div>
          
          </Box>
        <Box flex={1}>
          <div className="d-flex align-items-center cursor-pointer" onClick={()=>{
            setSatate({...state, _show_filter:true})
          }}>
              <div className="f-14 bold line-0 ">Categoria</div>
              <div className="bv-icon bv-icon--mid">
                <FaFilter size={10}></FaFilter>
              </div>
          </div>
          </Box>
        <Box flex={1}>
        <div className="d-flex align-items-center cursor-pointer" onClick={()=>{
            setSatate({...state, _show_filter:true})
          }}>
              <div className="f-14 bold line-0 ">Sub Categoria</div>
              <div className="bv-icon bv-icon--mid">
                <FaFilter size={10}></FaFilter>
              </div>
          </div>
        </Box>
        <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <div className="d-flex align-items-center cursor-pointer">
              <div className="f-14 bold line-0 ">Valoraciones</div>

          </div>
        </Box>
        {/* <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>Me Gusta</Box> */}
        <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <div className="d-flex align-items-center cursor-pointer">
              <div className="f-14 bold line-0 ">Acciones</div>

          </div>
        </Box>

      </div>

           {filter(state._recipes)?.map((meal:any, i:number) => {
           
            let _image = meal.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${meal.image?.name}` : 'assets/receta_default.png'
            console.log(_image.split('.')[1])
            if(_image.split('.')[1] === '46'){
              _image = 'assets/receta_default.png'
            }

            return(
              //  <Recipe key={meal.id} meal={meal} />
              <Flex key={i} w={'100%'} py={'0.5rem'} borderBottom={'1px solid #F3F3F3 '} px={'1rem'}>
                <Box display={'flex'} alignItems={'center'} flex={1}>
                  <Box me={'1rem'}>
                    <img width={'50px'} src={`${_image}`}></img>
                  </Box>
                  <div>{meal.name}</div>
                </Box>
                {/* <Box flex={1}>{setMealTime(meal.mealTipe)}</Box> */}

                <Box display={'flex'} flexWrap={'wrap'} flex={1}>{
                  meal.mealTime.map((item:String,i:number)=>{
                    return (
                      <div key={i} className="pill">{item}</div>
                    )
                  })
                }</Box> 
              

               <Box display={'flex'} flexWrap={'wrap'} flex={1}>{
                  meal.mealCategories.map((item:String,i:number)=>{
                    return (
                      <div key={i} className="pill">{item}</div>
                    )
                  })
                }</Box> 


                <Box flex={1} display={'flex'} flexWrap={'wrap'}>{
                  meal.mealSubCategories.map((item:String,i:number)=>{
                    return (
                      <div key={i} className="pill">{item}</div>
                    )
                  })
                }</Box>

               
                <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <div className="f-14 bold text-decoration-underline cursor-pointer text-green" onClick={()=>{
                    setSatate({...state, _show_valoraciones:true, _recipe_active:meal})
                  }}>Ver valoraciones</div>
                </Box>
                <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Icon
                  mx={'1rem'}
                  as={FaPen}
                  role="button"
                  color="#2CAD6E"
                  boxSize="1.25rem"
         
                  onClick={() => {
                    navigate(`edit/${meal.id}`, {state: meal})
                  }}
                />
                  {/* <Icon
                  as={FaTrash}
                  role="button"
                  color="#C5003E"
                  boxSize="1.25rem"
               
                  onClick={() => {}}
                /> */}
                </Box>
              </Flex>
            ) 
           })}

  
        </>
      // <React.Fragment>
      //   <Box
      //     display="grid"
      //     gridTemplateColumns="repeat(3, 1fr)"
      //     gridColumnGap="20"
      //     gridRowGap="5"
      //     w="100%"
      //     h="90%"
      //     p="10"
      //     overflowY="auto"
      //     id="meal-list"
      //     sx={SCROLL_BAR_STYLES}
      //   >
      //     {mealList?.map((meal) => (
      //       <Recipe key={meal.id} meal={meal} />
      //     ))}
      //   </Box>
      //   {isMealsPageLoading && (
      //     <Center w="100%">
      //       <Spinner size="md" color="green.15" />
      //     </Center>
      //   )}
      // </React.Fragment>
    );
  };

  return (
    <VStack w="100%" h="100%">
      <HStack w="100%" justifyContent="" pb={'1rem'}>
        <chakra.span fontSize="xl" color="gray.10" me={'1rem'}>
          Recetas
        </chakra.span>
        {/* <MealSearchBar placeholder="Busca Aquí" w="20rem" onChange={onSearch} /> */}
        <HStack>
          <Icon
            as={IoIosAddCircleOutline}
            role="button"
            color="green.15"
            boxSize="2rem"
            _active={{ transform: "scale(0.8)" }}
            onClick={() => navigate("create")}
          />
          <chakra.span fontSize="md" color="gray.25">
            Crear Nueva Receta
          </chakra.span>
        </HStack>
      </HStack>
      {!isMealsLoading ? memoizedMealList() : <LoadingPage />}
    </VStack>
  );
};


function ValoracionesRecipe({meal, onClose = () => {}} : any) {


   const [state, setState] = React.useState<any>({
    _valoraciones: [],
    _scores:{
      'FUE_GENIAL':0,
      'ESTUVO_BIEN':3,
      'PODRIA_MEJORAR':0
    },
    history:[]
 

	})

  React.useEffect(()=>{

    init()
    return () => {}
  },[])
  const init = async () => {
    console.log(meal)

  let valoraciones = await _Service('GET', `recipes/${meal.id}/comments`);
  let history = await _Service('GET', `recipes-history/${meal.id}`);
  console.log(history);
  state.history = history.data;
  state._valoraciones = valoraciones.data;

  let scores = {
    'FUE_GENIAL':0,
    'ESTUVO_BIEN':0,
    'PODRIA_MEJORAR':0

  }

  valoraciones.data.map((item:any)=>{
    switch (item.score) {
      case 'PODRIA_MEJORAR':
        scores['PODRIA_MEJORAR'] =  scores['PODRIA_MEJORAR'] + 1
        return;
      case 'ESTUVO_BIEN':
        scores['ESTUVO_BIEN'] =  scores['ESTUVO_BIEN'] + 1
        return
      case 'FUE_GENIAL':
        scores['FUE_GENIAL'] =  scores['FUE_GENIAL'] + 1
        return
      default:
        return
    }
  })

  state._scores = scores;
  setState({...state})


  }
  const getScore = (score:string) => {
    switch (score) {
      case 'PODRIA_MEJORAR':
        return 'Prodria mejorar'
      case 'ESTUVO_BIEN':
        return 'Estuvo bien'
      case 'FUE_GENIAL':
        return '¡Fue Genial!'
      default:
        return ''
    }
  }
  return (
    <div className="bv-modal">
    <div className="bv-modal-body hide-scroll">
      <div className="d-flex">
        <div className="col"></div>
        <div className="bv-icon bv-icon--md cursor-pointer" onClick={()=>{
          onClose()
        }}>
          <IoIosCloseCircle size={30} color="#2CAD6E"></IoIosCloseCircle>
        </div>
      </div>
      <div className="d-flex">
        <div className="col b-r">
          <div className="d-flex align-items-center">
            <img width={'100px'} style={{borderRadius:10}} src={`https://mydiet-assets-2023.s3.amazonaws.com/${meal?.image?.name}`}></img>
            <div className="f-16 bold text-green ps-2">{meal?.name}</div>
          </div>
          <div className="mt-3"></div>
          <div className="f-14 bold">Tiempo de comida</div>
          <div className="d-flex flex-wrap">
            {
               meal?.mealTime.map((item:String,i:number)=>{
                return (
                  <div key={i} className="pill m-0 me-1 mt-1">{item}</div>
                )
              })
            }
          </div>
          <div className="mt-3"></div>
          <div className="f-14 bold">Categoria</div>
          <div className="d-flex flex-wrap">
            {
               meal?.mealCategories.map((item:String,i:number)=>{
                return (
                  <div key={i} className="pill m-0 me-1 mt-1">{item}</div>
                )
              })
            }
           
          </div>
          <div className="mt-3"></div>
          <div className="f-14 bold">Subcategoria</div>
          <div className="d-flex flex-wrap">
            {
               meal?.mealSubCategories.map((item:String,i:number)=>{
                return (
                  <div key={i} className="pill m-0 me-1 mt-1">{item}</div>
                )
              })
            }
          </div>
        </div>
        <div className="col b-r px-3">
          <div className="f-14 bold text-green">Valoraciónes totales: {state._valoraciones.length}</div>
          <div className="mt-3"></div>
          <div className="f-14 bold text-green">¡Fue Geníal!</div>

          <div className="bar">
            <div className="bar-value" style={{width:`${(state._scores['FUE_GENIAL'] * 100 / state._valoraciones.length)}%` }}>
              <div className="text-white bold f-14 ps-2">{state._scores['FUE_GENIAL']}</div>
            </div>
          </div>
          <div className="mt-3"></div>

          <div className="f-14 bold text-green">Estuvo bien</div>
         
          <div className="bar">
            <div className="bar-value" style={{width:`${(state._scores['ESTUVO_BIEN'] * 100 / state._valoraciones.length)}%` }}>
              <div className="text-white bold f-14 ps-2">{state._scores['ESTUVO_BIEN']}</div>
            </div>
          </div>
          <div className="mt-3"></div>
          <div className="f-14 bold text-green">Podria mejorar</div>
          <div className="bar">
            <div className="bar-value" style={{width:`${(state._scores['PODRIA_MEJORAR'] * 100 / state._valoraciones.length)}%` }}>
              <div className="text-white bold f-14 ps-2">{state._scores['PODRIA_MEJORAR']}</div>
            </div>
          </div>
        </div>
        <div className="col ps-3">
          <div className="f-14 bold text-green">Total de cambios: 0</div>
          <div className="mt-3"></div>
          <div className="f-14 text-gray">Sin cambios.</div>
        </div>
      </div>

      <div className="mt-3">
        <table>
          <thead className="">
            <th><div className="f-14 bold text-gray p-1">Paciente</div></th>
            <th><div className="f-14 bold text-gray">Valoración</div></th>
            <th><div className="f-14 bold text-gray">Comentario</div></th>
          </thead>
          <tbody>
            {state._valoraciones.map((valoracion:any, i:number)=>{
              return(
                <tr key={i}>
                  <td><div className="f-14  text-green">{valoracion?.user?.firstName} {valoracion?.user?.lastName}</div></td>
                  <td><div className="f-14  ">{getScore(valoracion?.score)}</div></td>
                  <td><div className="f-14  ">{valoracion?.comments}</div></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}


export { RecipeList };
