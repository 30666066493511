import axios from "axios";
import { useQuery } from "react-query";

import { UserProfile } from "types/user";

const useProfile = (userId: string) =>
  useQuery(["profile"], async () => {
    if (!userId) return;
    return await axios.get<UserProfile>(`profile/${userId}`);
  });

export { useProfile };
