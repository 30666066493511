import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { PlanItemWrapper } from "types/plan";

export type PlanListParams = {
    page: number;
    search?: string;
  };

function usePlanList(params: PlanListParams) {
  return useQuery<AxiosResponse<any>, AxiosError>(["plans", params.search], () =>
    axios.get(`plan`, {
        params: {
            pageSize: 100,
            page: params.page,
            search: params.search
        }
    }), { refetchOnMount: true }
  );
}

export { usePlanList };
