export const _recipes_ = [
    {
        "id": 31,
        "createdAt": "2024-10-22T05:51:33.711Z",
        "updatedAt": "2024-10-22T05:51:33.711Z",
        "name": "Jugoso de Naranja",
        "mealTime": [
            "BREAKFAST"
        ],
        "mealCategories": [
            "FRUIT"
        ],
        "mealSubCategories": [
            "Citrus"
        ],
        "preparation": "Exprimir las naranjas para obtener el jugo fresco.",
        "ingredients": [
            {
                "id": 178,
                "createdAt": "2024-10-22T05:51:33.711Z",
                "updatedAt": "2024-10-22T05:51:33.711Z",
                "portion": 120,
                "quantity": 1,
                "alimentId": 15,
                "recipeId": 31,
                "aliment": {
                    "id": 15,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Brócoli cocido",
                    "cantidadSugerida": "1/2",
                    "cantidadSugeridaNum": 0.5,
                    "unidad": "taza",
                    "pesoBruto": 92,
                    "pesoNeto": 92,
                    "energia": 26,
                    "proteina": 2.7,
                    "lipidos": 0.4,
                    "hidratosDeCarbono": 4.6,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.1,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 2.7,
                    "vitaminaA": 127.4,
                    "acidoAscorbico": 68.4,
                    "acidoFolico": 46,
                    "calcio": 42.5,
                    "hierro": 0.8,
                    "potasio": 268.9,
                    "sodio": 23.6,
                    "fosforo": 54.3,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 32,
        "createdAt": "2024-10-22T05:51:35.084Z",
        "updatedAt": "2024-10-22T05:51:35.084Z",
        "name": "Ensalada Mediterránea",
        "mealTime": [
            "LUNCH"
        ],
        "mealCategories": [
            "VEGETABLE"
        ],
        "mealSubCategories": [
            "Low calorie",
            "High fiber"
        ],
        "preparation": "Mezclar todos los ingredientes con un aderezo ligero de aceite de oliva y limón.",
        "ingredients": [
            {
                "id": 179,
                "createdAt": "2024-10-22T05:51:35.084Z",
                "updatedAt": "2024-10-22T05:51:35.084Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 85,
                "recipeId": 32,
                "aliment": {
                    "id": 85,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Espinaca cruda",
                    "cantidadSugerida": "2",
                    "cantidadSugeridaNum": 2,
                    "unidad": "taza",
                    "pesoBruto": 120,
                    "pesoNeto": 120,
                    "energia": 28,
                    "proteina": 3.4,
                    "lipidos": 0.4,
                    "hidratosDeCarbono": 4.4,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 2.6,
                    "vitaminaA": 562.8,
                    "acidoAscorbico": 33.7,
                    "acidoFolico": 232.8,
                    "calcio": 118.8,
                    "hierro": 3.3,
                    "potasio": 669.6,
                    "sodio": 94.3,
                    "fosforo": 58.8,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 180,
                "createdAt": "2024-10-22T05:51:35.084Z",
                "updatedAt": "2024-10-22T05:51:35.084Z",
                "portion": 30,
                "quantity": 1,
                "alimentId": 58,
                "recipeId": 32,
                "aliment": {
                    "id": 58,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chiles en vinagre",
                    "cantidadSugerida": "150",
                    "cantidadSugeridaNum": 150,
                    "unidad": "gramos",
                    "pesoBruto": 150,
                    "pesoNeto": 150,
                    "energia": 23,
                    "proteina": 0.5,
                    "lipidos": 1,
                    "hidratosDeCarbono": 6.5,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 3.5,
                    "vitaminaA": 0,
                    "acidoAscorbico": 0,
                    "acidoFolico": 0,
                    "calcio": 0,
                    "hierro": 0,
                    "potasio": 0,
                    "sodio": 1434.9,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 33,
        "createdAt": "2024-10-22T05:51:35.983Z",
        "updatedAt": "2024-10-22T05:51:35.983Z",
        "name": "Pollo al Limón",
        "mealTime": [
            "DINNER"
        ],
        "mealCategories": [
            "MEAT_AND_OTHERS"
        ],
        "mealSubCategories": [
            "Low fat",
            "Protein rich"
        ],
        "preparation": "Hornear el pollo con especias y rodajas de limón hasta que esté bien cocido.",
        "ingredients": [
            {
                "id": 181,
                "createdAt": "2024-10-22T05:51:35.983Z",
                "updatedAt": "2024-10-22T05:51:35.983Z",
                "portion": 150,
                "quantity": 1,
                "alimentId": 1566,
                "recipeId": 33,
                "aliment": {
                    "id": 1566,
                    "createdAt": "2024-06-13T01:44:27.315Z",
                    "updatedAt": "2024-06-13T01:44:27.315Z",
                    "grupo": "AOA",
                    "subGrupo": "bajo aporte de grasa",
                    "alimentos": "Pierna de pollo sin piel cocida",
                    "cantidadSugerida": "0.25",
                    "cantidadSugeridaNum": 0.25,
                    "unidad": "pieza",
                    "pesoBruto": 45,
                    "pesoNeto": 26,
                    "energia": 47,
                    "proteina": 6.7,
                    "lipidos": 2.1,
                    "hidratosDeCarbono": 0,
                    "agSaturados": 0.6,
                    "agMonoInsaturados": 0.8,
                    "agPoliInsaturado": 0.5,
                    "colesterol": 22.8,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 4.6,
                    "acidoAscorbico": 0,
                    "acidoFolico": 2.1,
                    "calcio": 2.8,
                    "hierro": 0.4,
                    "potasio": 48.7,
                    "sodio": 20,
                    "fosforo": 38.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 182,
                "createdAt": "2024-10-22T05:51:35.983Z",
                "updatedAt": "2024-10-22T05:51:35.983Z",
                "portion": 30,
                "quantity": 1,
                "alimentId": 12,
                "recipeId": 33,
                "aliment": {
                    "id": 12,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Betabel crudo",
                    "cantidadSugerida": "1/4",
                    "cantidadSugeridaNum": 0.25,
                    "unidad": "pieza",
                    "pesoBruto": 43,
                    "pesoNeto": 39,
                    "energia": 19,
                    "proteina": 0.8,
                    "lipidos": 0.1,
                    "hidratosDeCarbono": 4.3,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.3,
                    "vitaminaA": 0.8,
                    "acidoAscorbico": 7.8,
                    "acidoFolico": 36.4,
                    "calcio": 8.2,
                    "hierro": 0.6,
                    "potasio": 131,
                    "sodio": 23.5,
                    "fosforo": 7.4,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 34,
        "createdAt": "2024-10-22T05:51:36.790Z",
        "updatedAt": "2024-10-22T05:51:36.790Z",
        "name": "Avena con plátano",
        "mealTime": [
            "BREAKFAST"
        ],
        "mealCategories": [
            "CEREAL",
            "FRUIT"
        ],
        "mealSubCategories": [
            "Whole grains",
            "Fresh fruit"
        ],
        "preparation": "Cocinar la avena en agua o leche, agregar rodajas de plátano y una pizca de canela.",
        "ingredients": [
            {
                "id": 183,
                "createdAt": "2024-10-22T05:51:36.790Z",
                "updatedAt": "2024-10-22T05:51:36.790Z",
                "portion": 50,
                "quantity": 1,
                "alimentId": 810,
                "recipeId": 34,
                "aliment": {
                    "id": 810,
                    "createdAt": "2024-06-13T01:44:05.544Z",
                    "updatedAt": "2024-06-13T01:44:05.544Z",
                    "grupo": "Cereales",
                    "subGrupo": "sin grasa",
                    "alimentos": "Pretzels",
                    "cantidadSugerida": "6",
                    "cantidadSugeridaNum": 6,
                    "unidad": "pieza",
                    "pesoBruto": 18,
                    "pesoNeto": 18,
                    "energia": 69,
                    "proteina": 1.7,
                    "lipidos": 0.6,
                    "hidratosDeCarbono": 14.5,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.3,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.6,
                    "vitaminaA": 0,
                    "acidoAscorbico": 0,
                    "acidoFolico": 15.4,
                    "calcio": 6.4,
                    "hierro": 0.8,
                    "potasio": 26.4,
                    "sodio": 312.4,
                    "fosforo": 20.6,
                    "etanol": 0,
                    "ig": 83,
                    "cargaGlicemica": 15
                }
            },
            {
                "id": 184,
                "createdAt": "2024-10-22T05:51:36.790Z",
                "updatedAt": "2024-10-22T05:51:36.790Z",
                "portion": 90,
                "quantity": 1,
                "alimentId": 40,
                "recipeId": 34,
                "aliment": {
                    "id": 40,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chilacayote crudo",
                    "cantidadSugerida": "1.5",
                    "cantidadSugeridaNum": 1.5,
                    "unidad": "pieza",
                    "pesoBruto": 150,
                    "pesoNeto": 135,
                    "energia": 19,
                    "proteina": 1.6,
                    "lipidos": 0.3,
                    "hidratosDeCarbono": 3.6,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.4,
                    "vitaminaA": 8.1,
                    "acidoAscorbico": 9.5,
                    "acidoFolico": 0,
                    "calcio": 23,
                    "hierro": 0.8,
                    "potasio": 0,
                    "sodio": 0,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 35,
        "createdAt": "2024-10-22T05:51:37.589Z",
        "updatedAt": "2024-10-22T05:51:37.589Z",
        "name": "Sopa de Verduras",
        "mealTime": [
            "LUNCH"
        ],
        "mealCategories": [
            "VEGETABLE"
        ],
        "mealSubCategories": [
            "Low calorie",
            "Soup"
        ],
        "preparation": "Hervir todas las verduras hasta que estén tiernas, sazonar con hierbas y sal.",
        "ingredients": [
            {
                "id": 185,
                "createdAt": "2024-10-22T05:51:37.589Z",
                "updatedAt": "2024-10-22T05:51:37.589Z",
                "portion": 200,
                "quantity": 1,
                "alimentId": 31,
                "recipeId": 35,
                "aliment": {
                    "id": 31,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chayote cocido picado",
                    "cantidadSugerida": "1/2",
                    "cantidadSugeridaNum": 0.5,
                    "unidad": "taza",
                    "pesoBruto": 80,
                    "pesoNeto": 80,
                    "energia": 19,
                    "proteina": 0.5,
                    "lipidos": 0.4,
                    "hidratosDeCarbono": 4.1,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 2.2,
                    "vitaminaA": 1.6,
                    "acidoAscorbico": 6.4,
                    "acidoFolico": 14.4,
                    "calcio": 10.4,
                    "hierro": 0.2,
                    "potasio": 138.4,
                    "sodio": 0.8,
                    "fosforo": 23.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 186,
                "createdAt": "2024-10-22T05:51:37.589Z",
                "updatedAt": "2024-10-22T05:51:37.589Z",
                "portion": 80,
                "quantity": 1,
                "alimentId": 114,
                "recipeId": 35,
                "aliment": {
                    "id": 114,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Jitomate",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "pieza",
                    "pesoBruto": 123,
                    "pesoNeto": 116,
                    "energia": 21,
                    "proteina": 1,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 4.5,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.1,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 1.4,
                    "vitaminaA": 48.6,
                    "acidoAscorbico": 14.7,
                    "acidoFolico": 17.3,
                    "calcio": 11.6,
                    "hierro": 0.3,
                    "potasio": 274,
                    "sodio": 5.8,
                    "fosforo": 28.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 187,
                "createdAt": "2024-10-22T05:51:37.589Z",
                "updatedAt": "2024-10-22T05:51:37.589Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 172,
                "recipeId": 35,
                "aliment": {
                    "id": 172,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Verdolaga cocida",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "taza",
                    "pesoBruto": 115,
                    "pesoNeto": 115,
                    "energia": 21,
                    "proteina": 1.7,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 4.1,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.9,
                    "vitaminaA": 107,
                    "acidoAscorbico": 12.1,
                    "acidoFolico": 10.4,
                    "calcio": 89.2,
                    "hierro": 0.9,
                    "potasio": 561.1,
                    "sodio": 51.6,
                    "fosforo": 41.6,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 36,
        "createdAt": "2024-10-22T05:51:38.487Z",
        "updatedAt": "2024-10-22T05:51:38.487Z",
        "name": "Filete de Pescado a la Plancha",
        "mealTime": [
            "DINNER"
        ],
        "mealCategories": [
            "MEAT_AND_OTHERS"
        ],
        "mealSubCategories": [
            "Low fat",
            "High protein"
        ],
        "preparation": "Cocinar el filete de pescado en una plancha caliente con un poco de aceite de oliva, ajo y perejil.",
        "ingredients": [
            {
                "id": 188,
                "createdAt": "2024-10-22T05:51:38.487Z",
                "updatedAt": "2024-10-22T05:51:38.487Z",
                "portion": 150,
                "quantity": 1,
                "alimentId": 1403,
                "recipeId": 36,
                "aliment": {
                    "id": 1403,
                    "createdAt": "2024-06-13T01:44:05.669Z",
                    "updatedAt": "2024-06-13T01:44:05.669Z",
                    "grupo": "AOA",
                    "subGrupo": "muy bajo aporte de grasa",
                    "alimentos": "Perca cocida",
                    "cantidadSugerida": "30",
                    "cantidadSugeridaNum": 30,
                    "unidad": "gramos",
                    "pesoBruto": 30,
                    "pesoNeto": 30,
                    "energia": 35,
                    "proteina": 7.4,
                    "lipidos": 0.4,
                    "hidratosDeCarbono": 0,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.1,
                    "agPoliInsaturado": 0.1,
                    "colesterol": 34.6,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 3,
                    "acidoAscorbico": 0.5,
                    "acidoFolico": 1.8,
                    "calcio": 30.7,
                    "hierro": 0.3,
                    "potasio": 103.2,
                    "sodio": 23.6,
                    "fosforo": 77.1,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 37,
        "createdAt": "2024-10-22T05:51:39.200Z",
        "updatedAt": "2024-10-22T05:51:39.200Z",
        "name": "Yogurt con Miel y Nueces",
        "mealTime": [
            "BREAKFAST"
        ],
        "mealCategories": [
            "MILK"
        ],
        "mealSubCategories": [
            "Dairy",
            "Nuts"
        ],
        "preparation": "Mezclar el yogurt natural con miel y nueces troceadas.",
        "ingredients": [
            {
                "id": 189,
                "createdAt": "2024-10-22T05:51:39.200Z",
                "updatedAt": "2024-10-22T05:51:39.200Z",
                "portion": 250,
                "quantity": 1,
                "alimentId": 270,
                "recipeId": 37,
                "aliment": {
                    "id": 270,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Frutas",
                    "subGrupo": "",
                    "alimentos": "Mamey",
                    "cantidadSugerida": "1/3",
                    "cantidadSugeridaNum": 0.3,
                    "unidad": "pieza",
                    "pesoBruto": 137,
                    "pesoNeto": 85,
                    "energia": 58,
                    "proteina": 1.4,
                    "lipidos": 0.5,
                    "hidratosDeCarbono": 13.7,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 3.8,
                    "vitaminaA": 51.7,
                    "acidoAscorbico": 19.5,
                    "acidoFolico": 0,
                    "calcio": 39,
                    "hierro": 2,
                    "potasio": 0,
                    "sodio": 0,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 190,
                "createdAt": "2024-10-22T05:51:39.200Z",
                "updatedAt": "2024-10-22T05:51:39.200Z",
                "portion": 30,
                "quantity": 1,
                "alimentId": 2186,
                "recipeId": 37,
                "aliment": {
                    "id": 2186,
                    "createdAt": "2024-06-13T01:44:27.466Z",
                    "updatedAt": "2024-06-13T01:44:27.466Z",
                    "grupo": "Grasas",
                    "subGrupo": "con proteína",
                    "alimentos": "Pepitas de calabaza",
                    "cantidadSugerida": "1.5",
                    "cantidadSugeridaNum": 1.5,
                    "unidad": "cda",
                    "pesoBruto": 12,
                    "pesoNeto": 12,
                    "energia": 66,
                    "proteina": 3.6,
                    "lipidos": 5.5,
                    "hidratosDeCarbono": 1.7,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.3,
                    "vitaminaA": 0,
                    "acidoAscorbico": 0,
                    "acidoFolico": 0,
                    "calcio": 0,
                    "hierro": 0,
                    "potasio": 0,
                    "sodio": 0,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 38,
        "createdAt": "2024-10-22T05:51:39.995Z",
        "updatedAt": "2024-10-22T05:51:39.995Z",
        "name": "Ensalada de Pollo",
        "mealTime": [
            "LUNCH"
        ],
        "mealCategories": [
            "VEGETABLE",
            "MEAT_AND_OTHERS"
        ],
        "mealSubCategories": [
            "Salad"
        ],
        "preparation": "Mezclar tiras de pollo cocido con hojas verdes, tomates y aderezo de mostaza.",
        "ingredients": [
            {
                "id": 191,
                "createdAt": "2024-10-22T05:51:39.995Z",
                "updatedAt": "2024-10-22T05:51:39.995Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 1566,
                "recipeId": 38,
                "aliment": {
                    "id": 1566,
                    "createdAt": "2024-06-13T01:44:27.315Z",
                    "updatedAt": "2024-06-13T01:44:27.315Z",
                    "grupo": "AOA",
                    "subGrupo": "bajo aporte de grasa",
                    "alimentos": "Pierna de pollo sin piel cocida",
                    "cantidadSugerida": "0.25",
                    "cantidadSugeridaNum": 0.25,
                    "unidad": "pieza",
                    "pesoBruto": 45,
                    "pesoNeto": 26,
                    "energia": 47,
                    "proteina": 6.7,
                    "lipidos": 2.1,
                    "hidratosDeCarbono": 0,
                    "agSaturados": 0.6,
                    "agMonoInsaturados": 0.8,
                    "agPoliInsaturado": 0.5,
                    "colesterol": 22.8,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 4.6,
                    "acidoAscorbico": 0,
                    "acidoFolico": 2.1,
                    "calcio": 2.8,
                    "hierro": 0.4,
                    "potasio": 48.7,
                    "sodio": 20,
                    "fosforo": 38.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 192,
                "createdAt": "2024-10-22T05:51:39.995Z",
                "updatedAt": "2024-10-22T05:51:39.995Z",
                "portion": 70,
                "quantity": 1,
                "alimentId": 50,
                "recipeId": 38,
                "aliment": {
                    "id": 50,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chile habanero",
                    "cantidadSugerida": "5",
                    "cantidadSugeridaNum": 5,
                    "unidad": "pieza",
                    "pesoBruto": 80,
                    "pesoNeto": 67,
                    "energia": 25,
                    "proteina": 1.5,
                    "lipidos": 0.5,
                    "hidratosDeCarbono": 3.6,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.3,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 1.1,
                    "vitaminaA": 19.8,
                    "acidoAscorbico": 63.2,
                    "acidoFolico": 15.5,
                    "calcio": 12.1,
                    "hierro": 1.6,
                    "potasio": 228.5,
                    "sodio": 4.7,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 39,
        "createdAt": "2024-10-22T05:51:40.792Z",
        "updatedAt": "2024-10-22T05:51:40.792Z",
        "name": "Salmón al Horno",
        "mealTime": [
            "DINNER"
        ],
        "mealCategories": [
            "MEAT_AND_OTHERS"
        ],
        "mealSubCategories": [
            "High protein",
            "Omega-3 fatty acids"
        ],
        "preparation": "Hornear el salmón con especias, limón y un chorrito de aceite de oliva.",
        "ingredients": [
            {
                "id": 193,
                "createdAt": "2024-10-22T05:51:40.792Z",
                "updatedAt": "2024-10-22T05:51:40.792Z",
                "portion": 150,
                "quantity": 1,
                "alimentId": 1402,
                "recipeId": 39,
                "aliment": {
                    "id": 1402,
                    "createdAt": "2024-06-13T01:44:05.669Z",
                    "updatedAt": "2024-06-13T01:44:05.669Z",
                    "grupo": "AOA",
                    "subGrupo": "muy bajo aporte de grasa",
                    "alimentos": "Pechuga sin piel molida",
                    "cantidadSugerida": "30",
                    "cantidadSugeridaNum": 30,
                    "unidad": "gramos",
                    "pesoBruto": 30,
                    "pesoNeto": 30,
                    "energia": 34,
                    "proteina": 7,
                    "lipidos": 0.5,
                    "hidratosDeCarbono": 0,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.1,
                    "agPoliInsaturado": 0.1,
                    "colesterol": 17.4,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 2.4,
                    "acidoAscorbico": 0,
                    "acidoFolico": 1.2,
                    "calcio": 3.6,
                    "hierro": 0.2,
                    "potasio": 71.7,
                    "sodio": 20.4,
                    "fosforo": 56.1,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 40,
        "createdAt": "2024-10-22T05:51:41.497Z",
        "updatedAt": "2024-10-22T05:51:41.497Z",
        "name": "Panqueques de Avena",
        "mealTime": [
            "BREAKFAST"
        ],
        "mealCategories": [
            "CEREAL"
        ],
        "mealSubCategories": [
            "Whole grains"
        ],
        "preparation": "Preparar panqueques con harina de avena y servir con miel pura.",
        "ingredients": [
            {
                "id": 194,
                "createdAt": "2024-10-22T05:51:41.497Z",
                "updatedAt": "2024-10-22T05:51:41.497Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 810,
                "recipeId": 40,
                "aliment": {
                    "id": 810,
                    "createdAt": "2024-06-13T01:44:05.544Z",
                    "updatedAt": "2024-06-13T01:44:05.544Z",
                    "grupo": "Cereales",
                    "subGrupo": "sin grasa",
                    "alimentos": "Pretzels",
                    "cantidadSugerida": "6",
                    "cantidadSugeridaNum": 6,
                    "unidad": "pieza",
                    "pesoBruto": 18,
                    "pesoNeto": 18,
                    "energia": 69,
                    "proteina": 1.7,
                    "lipidos": 0.6,
                    "hidratosDeCarbono": 14.5,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.3,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.6,
                    "vitaminaA": 0,
                    "acidoAscorbico": 0,
                    "acidoFolico": 15.4,
                    "calcio": 6.4,
                    "hierro": 0.8,
                    "potasio": 26.4,
                    "sodio": 312.4,
                    "fosforo": 20.6,
                    "etanol": 0,
                    "ig": 83,
                    "cargaGlicemica": 15
                }
            }
        ],
        "image": null
    },
    {
        "id": 41,
        "createdAt": "2024-10-22T05:51:42.217Z",
        "updatedAt": "2024-10-22T05:51:42.217Z",
        "name": "Tacos de Verduras",
        "mealTime": [
            "LUNCH"
        ],
        "mealCategories": [
            "VEGETABLE"
        ],
        "mealSubCategories": [
            "Mexican cuisine"
        ],
        "preparation": "Saltear las verduras, servir dentro de tortillas de maíz y acompañar con salsa picante.",
        "ingredients": [
            {
                "id": 195,
                "createdAt": "2024-10-22T05:51:42.217Z",
                "updatedAt": "2024-10-22T05:51:42.217Z",
                "portion": 30,
                "quantity": 1,
                "alimentId": 31,
                "recipeId": 41,
                "aliment": {
                    "id": 31,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chayote cocido picado",
                    "cantidadSugerida": "1/2",
                    "cantidadSugeridaNum": 0.5,
                    "unidad": "taza",
                    "pesoBruto": 80,
                    "pesoNeto": 80,
                    "energia": 19,
                    "proteina": 0.5,
                    "lipidos": 0.4,
                    "hidratosDeCarbono": 4.1,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 2.2,
                    "vitaminaA": 1.6,
                    "acidoAscorbico": 6.4,
                    "acidoFolico": 14.4,
                    "calcio": 10.4,
                    "hierro": 0.2,
                    "potasio": 138.4,
                    "sodio": 0.8,
                    "fosforo": 23.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 196,
                "createdAt": "2024-10-22T05:51:42.217Z",
                "updatedAt": "2024-10-22T05:51:42.217Z",
                "portion": 30,
                "quantity": 1,
                "alimentId": 114,
                "recipeId": 41,
                "aliment": {
                    "id": 114,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Jitomate",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "pieza",
                    "pesoBruto": 123,
                    "pesoNeto": 116,
                    "energia": 21,
                    "proteina": 1,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 4.5,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.1,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 1.4,
                    "vitaminaA": 48.6,
                    "acidoAscorbico": 14.7,
                    "acidoFolico": 17.3,
                    "calcio": 11.6,
                    "hierro": 0.3,
                    "potasio": 274,
                    "sodio": 5.8,
                    "fosforo": 28.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 197,
                "createdAt": "2024-10-22T05:51:42.217Z",
                "updatedAt": "2024-10-22T05:51:42.217Z",
                "portion": 50,
                "quantity": 2,
                "alimentId": 78,
                "recipeId": 41,
                "aliment": {
                    "id": 78,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Echalot",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "pieza",
                    "pesoBruto": 40,
                    "pesoNeto": 34,
                    "energia": 25,
                    "proteina": 0.9,
                    "lipidos": 0,
                    "hidratosDeCarbono": 5.8,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 20.6,
                    "acidoAscorbico": 2.8,
                    "acidoFolico": 11.7,
                    "calcio": 12.7,
                    "hierro": 0.4,
                    "potasio": 114.9,
                    "sodio": 4.1,
                    "fosforo": 20.6,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 42,
        "createdAt": "2024-10-22T05:51:43.019Z",
        "updatedAt": "2024-10-22T05:51:43.019Z",
        "name": "Pasta con Salsa de Tomate",
        "mealTime": [
            "DINNER"
        ],
        "mealCategories": [
            "CEREAL"
        ],
        "mealSubCategories": [
            "Pasta"
        ],
        "preparation": "Cocer la pasta al dente, mezclar con salsa de tomate casera y espolvorear queso parmesano.",
        "ingredients": [
            {
                "id": 198,
                "createdAt": "2024-10-22T05:51:43.019Z",
                "updatedAt": "2024-10-22T05:51:43.019Z",
                "portion": 200,
                "quantity": 1,
                "alimentId": 833,
                "recipeId": 42,
                "aliment": {
                    "id": 833,
                    "createdAt": "2024-06-13T01:44:05.544Z",
                    "updatedAt": "2024-06-13T01:44:05.544Z",
                    "grupo": "Cereales",
                    "subGrupo": "sin grasa",
                    "alimentos": "Tortilla",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "pieza",
                    "pesoBruto": 30,
                    "pesoNeto": 30,
                    "energia": 64,
                    "proteina": 1.4,
                    "lipidos": 0.5,
                    "hidratosDeCarbono": 13.6,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.2,
                    "agPoliInsaturado": 0.3,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.6,
                    "vitaminaA": 6,
                    "acidoAscorbico": 0,
                    "acidoFolico": 0,
                    "calcio": 58.8,
                    "hierro": 0.8,
                    "potasio": 0,
                    "sodio": 0,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 52,
                    "cargaGlicemica": 16
                }
            },
            {
                "id": 199,
                "createdAt": "2024-10-22T05:51:43.019Z",
                "updatedAt": "2024-10-22T05:51:43.019Z",
                "portion": 100,
                "quantity": 1.5,
                "alimentId": 115,
                "recipeId": 42,
                "aliment": {
                    "id": 115,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Jitomate bola",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "pieza",
                    "pesoBruto": 123,
                    "pesoNeto": 108,
                    "energia": 19,
                    "proteina": 1,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 4.2,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.1,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 1.3,
                    "vitaminaA": 45.5,
                    "acidoAscorbico": 13.7,
                    "acidoFolico": 16.2,
                    "calcio": 10.8,
                    "hierro": 0.3,
                    "potasio": 256.5,
                    "sodio": 5.4,
                    "fosforo": 26.4,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 43,
        "createdAt": "2024-10-22T05:51:43.825Z",
        "updatedAt": "2024-10-22T05:51:43.825Z",
        "name": "Parfait de Frutas con Granola",
        "mealTime": [
            "BREAKFAST"
        ],
        "mealCategories": [
            "FRUIT",
            "CEREAL"
        ],
        "mealSubCategories": [
            "Dairy"
        ],
        "preparation": "En capas alternas, combinar yogurt, frutas frescas y granola.",
        "ingredients": [
            {
                "id": 200,
                "createdAt": "2024-10-22T05:51:43.825Z",
                "updatedAt": "2024-10-22T05:51:43.825Z",
                "portion": 200,
                "quantity": 1,
                "alimentId": 270,
                "recipeId": 43,
                "aliment": {
                    "id": 270,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Frutas",
                    "subGrupo": "",
                    "alimentos": "Mamey",
                    "cantidadSugerida": "1/3",
                    "cantidadSugeridaNum": 0.3,
                    "unidad": "pieza",
                    "pesoBruto": 137,
                    "pesoNeto": 85,
                    "energia": 58,
                    "proteina": 1.4,
                    "lipidos": 0.5,
                    "hidratosDeCarbono": 13.7,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 3.8,
                    "vitaminaA": 51.7,
                    "acidoAscorbico": 19.5,
                    "acidoFolico": 0,
                    "calcio": 39,
                    "hierro": 2,
                    "potasio": 0,
                    "sodio": 0,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 201,
                "createdAt": "2024-10-22T05:51:43.825Z",
                "updatedAt": "2024-10-22T05:51:43.825Z",
                "portion": 50,
                "quantity": 1,
                "alimentId": 80,
                "recipeId": 43,
                "aliment": {
                    "id": 80,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Ejotes cocidos picados",
                    "cantidadSugerida": "1/2",
                    "cantidadSugeridaNum": 0.5,
                    "unidad": "taza",
                    "pesoBruto": 63,
                    "pesoNeto": 63,
                    "energia": 22,
                    "proteina": 1.2,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 4.9,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.1,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 2,
                    "vitaminaA": 21.9,
                    "acidoAscorbico": 6.1,
                    "acidoFolico": 20.6,
                    "calcio": 27.5,
                    "hierro": 0.4,
                    "potasio": 91.3,
                    "sodio": 0.6,
                    "fosforo": 18.1,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 44,
        "createdAt": "2024-10-22T05:51:44.676Z",
        "updatedAt": "2024-10-22T05:51:44.676Z",
        "name": "Pizza Vegetariana",
        "mealTime": [
            "LUNCH"
        ],
        "mealCategories": [
            "VEGETABLE",
            "CEREAL"
        ],
        "mealSubCategories": [
            "Italian cuisine"
        ],
        "preparation": "Preparar una base de pizza con harina integral, cubrir con salsa de tomate, verduras y queso bajo en grasa.",
        "ingredients": [
            {
                "id": 202,
                "createdAt": "2024-10-22T05:51:44.676Z",
                "updatedAt": "2024-10-22T05:51:44.676Z",
                "portion": 50,
                "quantity": 1,
                "alimentId": 115,
                "recipeId": 44,
                "aliment": {
                    "id": 115,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Jitomate bola",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "pieza",
                    "pesoBruto": 123,
                    "pesoNeto": 108,
                    "energia": 19,
                    "proteina": 1,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 4.2,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0.1,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 1.3,
                    "vitaminaA": 45.5,
                    "acidoAscorbico": 13.7,
                    "acidoFolico": 16.2,
                    "calcio": 10.8,
                    "hierro": 0.3,
                    "potasio": 256.5,
                    "sodio": 5.4,
                    "fosforo": 26.4,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 203,
                "createdAt": "2024-10-22T05:51:44.676Z",
                "updatedAt": "2024-10-22T05:51:44.676Z",
                "portion": 50,
                "quantity": 2,
                "alimentId": 50,
                "recipeId": 44,
                "aliment": {
                    "id": 50,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chile habanero",
                    "cantidadSugerida": "5",
                    "cantidadSugeridaNum": 5,
                    "unidad": "pieza",
                    "pesoBruto": 80,
                    "pesoNeto": 67,
                    "energia": 25,
                    "proteina": 1.5,
                    "lipidos": 0.5,
                    "hidratosDeCarbono": 3.6,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.3,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 1.1,
                    "vitaminaA": 19.8,
                    "acidoAscorbico": 63.2,
                    "acidoFolico": 15.5,
                    "calcio": 12.1,
                    "hierro": 1.6,
                    "potasio": 228.5,
                    "sodio": 4.7,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 45,
        "createdAt": "2024-10-22T05:51:45.482Z",
        "updatedAt": "2024-10-22T05:51:45.482Z",
        "name": "Fajitas de Pollo",
        "mealTime": [
            "DINNER"
        ],
        "mealCategories": [
            "MEAT_AND_OTHERS"
        ],
        "mealSubCategories": [
            "Mexican cuisine"
        ],
        "preparation": "Cocinar tiras de pollo con pimientos y cebolla, servir en tortillas de harina con guacamole.",
        "ingredients": [
            {
                "id": 204,
                "createdAt": "2024-10-22T05:51:45.482Z",
                "updatedAt": "2024-10-22T05:51:45.482Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 1566,
                "recipeId": 45,
                "aliment": {
                    "id": 1566,
                    "createdAt": "2024-06-13T01:44:27.315Z",
                    "updatedAt": "2024-06-13T01:44:27.315Z",
                    "grupo": "AOA",
                    "subGrupo": "bajo aporte de grasa",
                    "alimentos": "Pierna de pollo sin piel cocida",
                    "cantidadSugerida": "0.25",
                    "cantidadSugeridaNum": 0.25,
                    "unidad": "pieza",
                    "pesoBruto": 45,
                    "pesoNeto": 26,
                    "energia": 47,
                    "proteina": 6.7,
                    "lipidos": 2.1,
                    "hidratosDeCarbono": 0,
                    "agSaturados": 0.6,
                    "agMonoInsaturados": 0.8,
                    "agPoliInsaturado": 0.5,
                    "colesterol": 22.8,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 4.6,
                    "acidoAscorbico": 0,
                    "acidoFolico": 2.1,
                    "calcio": 2.8,
                    "hierro": 0.4,
                    "potasio": 48.7,
                    "sodio": 20,
                    "fosforo": 38.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 205,
                "createdAt": "2024-10-22T05:51:45.482Z",
                "updatedAt": "2024-10-22T05:51:45.482Z",
                "portion": 50,
                "quantity": 1,
                "alimentId": 31,
                "recipeId": 45,
                "aliment": {
                    "id": 31,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chayote cocido picado",
                    "cantidadSugerida": "1/2",
                    "cantidadSugeridaNum": 0.5,
                    "unidad": "taza",
                    "pesoBruto": 80,
                    "pesoNeto": 80,
                    "energia": 19,
                    "proteina": 0.5,
                    "lipidos": 0.4,
                    "hidratosDeCarbono": 4.1,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 2.2,
                    "vitaminaA": 1.6,
                    "acidoAscorbico": 6.4,
                    "acidoFolico": 14.4,
                    "calcio": 10.4,
                    "hierro": 0.2,
                    "potasio": 138.4,
                    "sodio": 0.8,
                    "fosforo": 23.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 46,
        "createdAt": "2024-10-22T05:51:46.289Z",
        "updatedAt": "2024-10-22T05:51:46.289Z",
        "name": "Smoothie de Frutos Rojos",
        "mealTime": [
            "BREAKFAST"
        ],
        "mealCategories": [
            "FRUIT"
        ],
        "mealSubCategories": [
            "Beverage",
            "Dairy-free"
        ],
        "preparation": "Licuar los frutos rojos con agua y un poco de miel hasta obtener una mezcla homogénea.",
        "ingredients": [
            {
                "id": 206,
                "createdAt": "2024-10-22T05:51:46.289Z",
                "updatedAt": "2024-10-22T05:51:46.289Z",
                "portion": 150,
                "quantity": 1,
                "alimentId": 35,
                "recipeId": 46,
                "aliment": {
                    "id": 35,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chícharo crudo con vaina",
                    "cantidadSugerida": "1/2",
                    "cantidadSugeridaNum": 0.5,
                    "unidad": "taza",
                    "pesoBruto": 73,
                    "pesoNeto": 28,
                    "energia": 22,
                    "proteina": 1.5,
                    "lipidos": 0.1,
                    "hidratosDeCarbono": 4,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 48,
                    "fibra": 1.4,
                    "vitaminaA": 10.5,
                    "acidoAscorbico": 11,
                    "acidoFolico": 17.9,
                    "calcio": 6.9,
                    "hierro": 0.4,
                    "potasio": 67.2,
                    "sodio": 1.4,
                    "fosforo": 29.8,
                    "etanol": 0,
                    "ig": 2,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 47,
        "createdAt": "2024-10-22T05:51:47.011Z",
        "updatedAt": "2024-10-22T05:51:47.011Z",
        "name": "Hamburguesa de Lentejas",
        "mealTime": [
            "LUNCH"
        ],
        "mealCategories": [
            "LEGUMES"
        ],
        "mealSubCategories": [
            "Vegetarian"
        ],
        "preparation": "Formar hamburguesas con la mezcla de lentejas cocidas, avena y especias, freír hasta que estén doradas.",
        "ingredients": [
            {
                "id": 207,
                "createdAt": "2024-10-22T05:51:47.011Z",
                "updatedAt": "2024-10-22T05:51:47.011Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 1182,
                "recipeId": 47,
                "aliment": {
                    "id": 1182,
                    "createdAt": "2024-06-13T01:44:05.669Z",
                    "updatedAt": "2024-06-13T01:44:05.669Z",
                    "grupo": "Leguminosas",
                    "subGrupo": "",
                    "alimentos": "Alverjón o chícharo seco cocido",
                    "cantidadSugerida": "1/2",
                    "cantidadSugeridaNum": 0.5,
                    "unidad": "taza",
                    "pesoBruto": 98,
                    "pesoNeto": 98,
                    "energia": 116,
                    "proteina": 8.2,
                    "lipidos": 0.4,
                    "hidratosDeCarbono": 20.7,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 2.8,
                    "fibra": 0,
                    "vitaminaA": 0,
                    "acidoAscorbico": 0.4,
                    "acidoFolico": 63.7,
                    "calcio": 13.7,
                    "hierro": 1.3,
                    "potasio": 3548,
                    "sodio": 2,
                    "fosforo": 97,
                    "etanol": 0,
                    "ig": 22,
                    "cargaGlicemica": 5
                }
            }
        ],
        "image": null
    },
    {
        "id": 48,
        "createdAt": "2024-10-22T05:51:47.720Z",
        "updatedAt": "2024-10-22T05:51:47.720Z",
        "name": "Enchiladas Suizas",
        "mealTime": [
            "DINNER"
        ],
        "mealCategories": [
            "CEREAL",
            "MEAT_AND_OTHERS"
        ],
        "mealSubCategories": [
            "Mexican cuisine"
        ],
        "preparation": "Rellenar tortillas con pollo deshebrado, cubrir con salsa verde y queso, hornear hasta que el queso se derrita.",
        "ingredients": [
            {
                "id": 208,
                "createdAt": "2024-10-22T05:51:47.720Z",
                "updatedAt": "2024-10-22T05:51:47.720Z",
                "portion": 200,
                "quantity": 3,
                "alimentId": 78,
                "recipeId": 48,
                "aliment": {
                    "id": 78,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Echalot",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "pieza",
                    "pesoBruto": 40,
                    "pesoNeto": 34,
                    "energia": 25,
                    "proteina": 0.9,
                    "lipidos": 0,
                    "hidratosDeCarbono": 5.8,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 20.6,
                    "acidoAscorbico": 2.8,
                    "acidoFolico": 11.7,
                    "calcio": 12.7,
                    "hierro": 0.4,
                    "potasio": 114.9,
                    "sodio": 4.1,
                    "fosforo": 20.6,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 209,
                "createdAt": "2024-10-22T05:51:47.720Z",
                "updatedAt": "2024-10-22T05:51:47.720Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 1566,
                "recipeId": 48,
                "aliment": {
                    "id": 1566,
                    "createdAt": "2024-06-13T01:44:27.315Z",
                    "updatedAt": "2024-06-13T01:44:27.315Z",
                    "grupo": "AOA",
                    "subGrupo": "bajo aporte de grasa",
                    "alimentos": "Pierna de pollo sin piel cocida",
                    "cantidadSugerida": "0.25",
                    "cantidadSugeridaNum": 0.25,
                    "unidad": "pieza",
                    "pesoBruto": 45,
                    "pesoNeto": 26,
                    "energia": 47,
                    "proteina": 6.7,
                    "lipidos": 2.1,
                    "hidratosDeCarbono": 0,
                    "agSaturados": 0.6,
                    "agMonoInsaturados": 0.8,
                    "agPoliInsaturado": 0.5,
                    "colesterol": 22.8,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 4.6,
                    "acidoAscorbico": 0,
                    "acidoFolico": 2.1,
                    "calcio": 2.8,
                    "hierro": 0.4,
                    "potasio": 48.7,
                    "sodio": 20,
                    "fosforo": 38.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 49,
        "createdAt": "2024-10-22T05:51:48.526Z",
        "updatedAt": "2024-10-22T05:51:48.526Z",
        "name": "Hotcakes de Plátano",
        "mealTime": [
            "BREAKFAST"
        ],
        "mealCategories": [
            "CEREAL"
        ],
        "mealSubCategories": [
            "Pancakes"
        ],
        "preparation": "Mezclar harina, huevo y leche para formar la masa, añadir rodajas de plátano y cocinar en sartén caliente.",
        "ingredients": [
            {
                "id": 210,
                "createdAt": "2024-10-22T05:51:48.526Z",
                "updatedAt": "2024-10-22T05:51:48.526Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 430,
                "recipeId": 49,
                "aliment": {
                    "id": 430,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Cereales",
                    "subGrupo": "sin grasa",
                    "alimentos": "Animalitos",
                    "cantidadSugerida": "9",
                    "cantidadSugeridaNum": 9,
                    "unidad": "pieza",
                    "pesoBruto": 20,
                    "pesoNeto": 20,
                    "energia": 73,
                    "proteina": 1.4,
                    "lipidos": 0.7,
                    "hidratosDeCarbono": 15.4,
                    "agSaturados": 0.3,
                    "agMonoInsaturados": 0.2,
                    "agPoliInsaturado": 0.2,
                    "colesterol": 0,
                    "azucar": 5.6,
                    "fibra": 0.5,
                    "vitaminaA": 0,
                    "acidoAscorbico": 0,
                    "acidoFolico": 44,
                    "calcio": 0,
                    "hierro": 1.7,
                    "potasio": 0,
                    "sodio": 109.3,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 211,
                "createdAt": "2024-10-22T05:51:48.526Z",
                "updatedAt": "2024-10-22T05:51:48.526Z",
                "portion": 90,
                "quantity": 1,
                "alimentId": 40,
                "recipeId": 49,
                "aliment": {
                    "id": 40,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Chilacayote crudo",
                    "cantidadSugerida": "1.5",
                    "cantidadSugeridaNum": 1.5,
                    "unidad": "pieza",
                    "pesoBruto": 150,
                    "pesoNeto": 135,
                    "energia": 19,
                    "proteina": 1.6,
                    "lipidos": 0.3,
                    "hidratosDeCarbono": 3.6,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.4,
                    "vitaminaA": 8.1,
                    "acidoAscorbico": 9.5,
                    "acidoFolico": 0,
                    "calcio": 23,
                    "hierro": 0.8,
                    "potasio": 0,
                    "sodio": 0,
                    "fosforo": 0,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 50,
        "createdAt": "2024-10-22T05:51:49.344Z",
        "updatedAt": "2024-10-22T05:51:49.344Z",
        "name": "Arroz con Pollo",
        "mealTime": [
            "LUNCH"
        ],
        "mealCategories": [
            "CEREAL",
            "MEAT_AND_OTHERS"
        ],
        "mealSubCategories": [
            "Rice"
        ],
        "preparation": "Cocinar el arroz con caldo de pollo y verduras, agregar el pollo cocido en trozos y sazonar al gusto.",
        "ingredients": [
            {
                "id": 212,
                "createdAt": "2024-10-22T05:51:49.344Z",
                "updatedAt": "2024-10-22T05:51:49.344Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 1566,
                "recipeId": 50,
                "aliment": {
                    "id": 1566,
                    "createdAt": "2024-06-13T01:44:27.315Z",
                    "updatedAt": "2024-06-13T01:44:27.315Z",
                    "grupo": "AOA",
                    "subGrupo": "bajo aporte de grasa",
                    "alimentos": "Pierna de pollo sin piel cocida",
                    "cantidadSugerida": "0.25",
                    "cantidadSugeridaNum": 0.25,
                    "unidad": "pieza",
                    "pesoBruto": 45,
                    "pesoNeto": 26,
                    "energia": 47,
                    "proteina": 6.7,
                    "lipidos": 2.1,
                    "hidratosDeCarbono": 0,
                    "agSaturados": 0.6,
                    "agMonoInsaturados": 0.8,
                    "agPoliInsaturado": 0.5,
                    "colesterol": 22.8,
                    "azucar": 0,
                    "fibra": 0,
                    "vitaminaA": 4.6,
                    "acidoAscorbico": 0,
                    "acidoFolico": 2.1,
                    "calcio": 2.8,
                    "hierro": 0.4,
                    "potasio": 48.7,
                    "sodio": 20,
                    "fosforo": 38.2,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 213,
                "createdAt": "2024-10-22T05:51:49.344Z",
                "updatedAt": "2024-10-22T05:51:49.344Z",
                "portion": 100,
                "quantity": 1,
                "alimentId": 825,
                "recipeId": 50,
                "aliment": {
                    "id": 825,
                    "createdAt": "2024-06-13T01:44:05.544Z",
                    "updatedAt": "2024-06-13T01:44:05.544Z",
                    "grupo": "Cereales",
                    "subGrupo": "sin grasa",
                    "alimentos": "Semolina",
                    "cantidadSugerida": "20",
                    "cantidadSugeridaNum": 20,
                    "unidad": "gramos",
                    "pesoBruto": 20,
                    "pesoNeto": 20,
                    "energia": 72,
                    "proteina": 2.5,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 14.6,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.1,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 0.8,
                    "vitaminaA": 0,
                    "acidoAscorbico": 0,
                    "acidoFolico": 14.3,
                    "calcio": 3.3,
                    "hierro": 0.9,
                    "potasio": 37.1,
                    "sodio": 0.2,
                    "fosforo": 27.1,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    },
    {
        "id": 51,
        "createdAt": "2024-10-22T05:51:50.238Z",
        "updatedAt": "2024-10-22T05:51:50.238Z",
        "name": "Risotto de Setas",
        "mealTime": [
            "DINNER"
        ],
        "mealCategories": [
            "CEREAL"
        ],
        "mealSubCategories": [
            "Italian cuisine"
        ],
        "preparation": "Cocinar arroz arborio con caldo de verduras, añadir setas salteadas y queso parmesano hasta que el risotto esté cremoso.",
        "ingredients": [
            {
                "id": 214,
                "createdAt": "2024-10-22T05:51:50.238Z",
                "updatedAt": "2024-10-22T05:51:50.238Z",
                "portion": 150,
                "quantity": 1,
                "alimentId": 83,
                "recipeId": 51,
                "aliment": {
                    "id": 83,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Espárragos",
                    "cantidadSugerida": "6",
                    "cantidadSugeridaNum": 6,
                    "unidad": "pieza",
                    "pesoBruto": 90,
                    "pesoNeto": 90,
                    "energia": 22,
                    "proteina": 2.3,
                    "lipidos": 0.3,
                    "hidratosDeCarbono": 3.8,
                    "agSaturados": 0.1,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0.1,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 2.8,
                    "vitaminaA": 49,
                    "acidoAscorbico": 10,
                    "acidoFolico": 131,
                    "calcio": 18,
                    "hierro": 0.7,
                    "potasio": 144,
                    "sodio": 10,
                    "fosforo": 49,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            },
            {
                "id": 215,
                "createdAt": "2024-10-22T05:51:50.238Z",
                "updatedAt": "2024-10-22T05:51:50.238Z",
                "portion": 70,
                "quantity": 1,
                "alimentId": 86,
                "recipeId": 51,
                "aliment": {
                    "id": 86,
                    "createdAt": "2024-06-13T01:44:05.366Z",
                    "updatedAt": "2024-06-13T01:44:05.366Z",
                    "grupo": "Verduras",
                    "subGrupo": "",
                    "alimentos": "Flor de calabaza cocida",
                    "cantidadSugerida": "1",
                    "cantidadSugeridaNum": 1,
                    "unidad": "taza",
                    "pesoBruto": 134,
                    "pesoNeto": 134,
                    "energia": 20,
                    "proteina": 1.4,
                    "lipidos": 0.2,
                    "hidratosDeCarbono": 4.4,
                    "agSaturados": 0,
                    "agMonoInsaturados": 0,
                    "agPoliInsaturado": 0,
                    "colesterol": 0,
                    "azucar": 0,
                    "fibra": 1.2,
                    "vitaminaA": 116.6,
                    "acidoAscorbico": 6,
                    "acidoFolico": 54,
                    "calcio": 50,
                    "hierro": 1.2,
                    "potasio": 142,
                    "sodio": 8,
                    "fosforo": 46,
                    "etanol": 0,
                    "ig": 0,
                    "cargaGlicemica": 0
                }
            }
        ],
        "image": null
    }
]