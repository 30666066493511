import axios, { AxiosResponse, AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

type UpdateMealParams = Readonly<{
  file: FormData;
}>;

const useUpdatePlan = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, UpdateMealParams>(
    (params) =>
      axios.put(`plan/${id}`, params.file, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    { onSuccess: () => queryClient.invalidateQueries(["plans"]) }
  );
};

export { useUpdatePlan };
