import axios, { AxiosError, AxiosResponse } from "axios";
import { useAuth } from "contexts";
import { useMutation, useQuery } from "react-query";
import { MedicalRecord } from "types/medicalrecord";

function useUpdateMedicalRecord(patientId: string) {
	const user = useAuth();
  return useMutation<AxiosResponse, AxiosError, MedicalRecord>(["medicalrecord"], (medicalRecord) =>
    axios.post(`patient/${patientId}/medical-record/nutritionist/${user.tokens.userId}`, medicalRecord)
  );
}

export { useUpdateMedicalRecord };
