import { theme } from "@chakra-ui/react";
import { input as Input } from "./input";

const baseTextareaTheme = theme.components.Textarea;

const defaultProps = {
  variant: "filled",
};

const variants = {
  filled: Input.variants.filled,
};

export const textareaTheme = {
  ...baseTextareaTheme,
  variants: {
    ...baseTextareaTheme.variants,
    filled: { ...baseTextareaTheme.variants.filled, ...variants.filled },
  },
};

export const textarea = {
  variants,
  defaultProps,
};
