import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { PatientFormDetail } from "types/user";

function usePatientFormDetail(userFormId: string) {
  return useQuery<AxiosResponse<PatientFormDetail>, AxiosError>(["patientFormDetail"], () =>
    axios.get(`user-form/${userFormId}`)
  );
}

export { usePatientFormDetail };