import * as React from "react";
import {
  InputProps as CkInputProps,
  Input as CkInput,
  InputGroup,
  InputRightElement,
  VStack,
  Icon,
  chakra,
} from "@chakra-ui/react";
import { HiPhotograph } from "react-icons/hi";

type InputFileProps = {
  label: string;
  error?: string;
} & Omit<CkInputProps, "type" | "disabled">;

const InputFile = React.forwardRef<HTMLInputElement, InputFileProps>(
  (props, ref) => {
    const {
      error,
      label,
      width = "100%",
      variant = "filled",
      onChange = () => {},
      id =  "createMealPhoto",
      ...restInputProps
    } = props;

    return (

      <div>
        {/* <chakra.span alignSelf="baseline" fontSize="sm" color="gray.25">
          {label}
        </chakra.span> */}
        <div className="f-14 text-gray"> {label}</div>
        


        <InputGroup
         cursor="pointer !important"
          onClick={() => document.getElementById(id)?.click()}
        >
          <CkInput
            variant={variant}
            isInvalid={error ? true : false}
            isDisabled
            cursor="pointer !important"
            pointerEvents="none"
            {...restInputProps}
          />
          
          <InputRightElement
            children={<Icon as={HiPhotograph} color="green.15" />}
          />
          
          <CkInput
          
            id={id}
            hidden
            ref={ref}
            type="file"
            accept="image/png, image/jpeg"
            onChange={(event) => onChange(event)}
          />

        </InputGroup>
        
        <chakra.span
          cursor="pointer"
          color="red.100"
          fontSize="sm"
          alignSelf="baseline"
          lineHeight="1"
        >
          {error}
        </chakra.span>
      </div>
    );
  }
);

InputFile.displayName = "Input";

export { InputFile };
export type { InputFileProps };
