import React, { useEffect, useState } from 'react'
import { Input, Select, Textarea } from 'shared'
import { IoMdAddCircleOutline } from "react-icons/io";
import { Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Service } from 'api/api';

interface PatientForm {
    firstName: string
    lastName: string
    email: string
    phone: string
    civilStatus:string
    occupation:string
    birthDate:string
    specialtyId: string
    sucursalName:string
    objectiveConsultation:string
    familyPatients: FamilyPatient[]
}
interface FamilyPatient{
    fullName:string,
    birthDate:string
}
interface CreatePatientState {
    form: PatientForm,
    memberName: string,
    memberBirthDate: string,
    specialists: any[],
    isLoading: boolean
}
export default function CreatePatient() {
    const navigate = useNavigate();
    const toast = useToast();
    const [state, setSatate] = useState<CreatePatientState>({
        form: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            civilStatus:"",
            occupation: "",
            birthDate:"",
            specialtyId: "",
            sucursalName:"",
            objectiveConsultation:"",
            familyPatients: []
        },
        memberName: "",
        memberBirthDate: "",
        specialists:[],
        isLoading:false
    })

    useEffect(()=>{
        const init = async () => {
           let _specialist = await Service('GET', 'nutritionist/select?nutritionistTypes=1');
           setSatate({...state, specialists:_specialist.data});
           console.log(_specialist);
        };
        init();
        return ()=>{}
    },[])

    const onSubmit = async () => {

  
        // navigate("/patients");
        // // console.log(state.form);
        let body = state.form;
        let _createPatient = await Service('CREATE', 'patient-v1/create-v1', body);
        if(_createPatient.status === 200){
            toast({
                title: 'Crear Paciente',
                description: "Paciente creado con éxito",
                status: "success",
                position: "bottom-left",
              });
              navigate("/patients");
        }else{
            toast({
                title: "Ocurrió un error al crear el paciente",
                status: "error",
                position: "bottom-left",
              });
        }

    }

  return (
    <div className='container r-3 p-5 bg-gray-5'>
        <div className='d-flex'>
            <div className='col pe-1'>
                <Input
                    label="Nombre *"
                    placeholder="Nombre *"
                    value={state?.form?.firstName}
                    onChange={(evt)=>{ 
                        state.form.firstName = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
            <div className='col px-1'>
                <Input
                    label="Apellidos *"
                    placeholder="Apellidos *"
                    value={state?.form?.lastName}
                    onChange={(evt)=>{ 
                        state.form.lastName = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
            <div className='col ps-1'>
                <Input
                    label="Correo Eletrónico"
                    placeholder="Correo Eletrónico *"
                    value={state?.form?.email}
                    onChange={(evt)=>{ 
                        state.form.email = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
        </div>

        <div className='d-flex mt-2'>
            <div className='col pe-1'>
                <Input
                    label="Teléfono *"
                    type="number"
                    placeholder="Teléfono *"
                    value={state?.form?.phone}
                    onChange={(evt)=>{ 
                        state.form.phone = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
            <div className='col px-1'>
                <Input
                    label="Ocupación *"
                    placeholder="Ocupación *"
                    value={state?.form?.occupation}
                    onChange={(evt)=>{ 
                        state.form.occupation = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
            <div className='col ps-1'>
                <Select
                    error={""}
                    label="Estado Civil"
                    value={state?.form?.civilStatus}
                    onChange={(evt)=>{ 
                        state.form.civilStatus = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                    
                >
                    <option value="">Seleccionar</option>
                    <option value="SOLTERO">SOLTERO</option>
                    <option value="CASADO">CASADO</option>
                    <option value="DIVORCIADO">DIVORCIADO</option>
                    <option value="UNIÓN LIBRE">UNIÓN LIBRE</option>
                    <option value="VIUDO">VIUDO</option>
                    <option value="OTRO">OTRO</option>
                </Select>
            </div>
        </div>

        <div className='d-flex mt-2'>
            <div className='col pe-1'>
                <Input
                    label="Fecha de nacimiento *"
                    placeholder="Fecha de nacimiento *"
                    type="date"
                    value={state?.form?.birthDate}
                    onChange={(evt)=>{ 
                        state.form.birthDate = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
            <div className='col'></div>
            <div className='col'></div>
   
        </div>
        <div className='my-5'></div>

        <div className='d-flex mt-2'>
            <div className='col pe-1'>
                <Input
                    label="Miembro de  la familia *"
                    placeholder="Nomre Apellido *"
                    error=''
                    value={state?.memberName}
                    onChange={(evt)=>{ 
                        state.memberName = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
            <div className='col px-1'>
                <Input
                    label="Fecha de Nacimiento *"
                    placeholder="Fecha de Nacimiento *"
                    type={'date'}
                    value={state?.memberBirthDate}
                    onChange={(evt)=>{ 
                        state.memberBirthDate = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
            </div>
            <div className='d-flex flex-column justify-content-end pb-1'>
           

                <div className='md-icon md-icon--big cursor-pointer' onClick={()=>{
                    if(state.memberName !== '' && state.memberBirthDate !== ''){
                        state.form.familyPatients.push({
                            fullName:state.memberName,
                            birthDate:state.memberBirthDate
                        })
                        state.memberName = ''
                        state.memberBirthDate = ''

                        
                        setSatate({...state})
                    }
                }}>
                    <IoMdAddCircleOutline color='#2CAD6E' size={40}></IoMdAddCircleOutline>
                </div>

            </div>
         
        </div>
        {
            state.form.familyPatients.map((item, i)=>{
                return (
                    <div key={i} className='d-flex py-2'>
                        <div className='col'>{item.fullName}</div>
                        <div className='col'>{item.birthDate}</div>
                    </div>
                )
            })
        }
        
        <div className='my-5'></div>
        <div className='d-flex mt-2'>
            <div className='col pe-1'>
                 <Select
                    error={""}
                    label="Asignar Especialista"
                    value={state.form.specialtyId}
                    onChange={(evt)=>{ 
                        state.form.specialtyId = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                    
                >   
                    <option value="">Seleccionar</option>
                    {
                        state.specialists.map((item,i)=>{
                            return (
                                <option key={i} value={item.id}>{item.name}</option>
                            )
                        })
                    }
                </Select>
                
            </div>
            <div className='col px-1'>
                <Input
                    label="Asignar sucursal *"
                    placeholder="Asignar sucursal *"
                    value={state?.form.sucursalName}
                    onChange={(evt)=>{ 
                        state.form.sucursalName = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                />
              
            </div>
            
         
        </div>
        <div className='d-flex mt-2'>
            <div className='col pe-1'>
            <Textarea
                rows={5}
                error={""}
            
                label="Objetivo de la Consulta"
                value={state?.form.objectiveConsultation}
                    onChange={(evt)=>{ 
                        state.form.objectiveConsultation = evt.currentTarget.value;
                        setSatate({...state})
                    }}
                
              
                />
            </div>
           
            
         
        </div>
        <div className='d-flex justify-content-center align-items-center mt-3'>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col me-1'>
                       
                <Button variant="warning" onClick={() => navigate("-1")}>
                Cancelar
                </Button>
            </div>
            <div className='col mx-1'>
                <Button onClick={()=>{
                try {
        
                    onSubmit()
                  
                } catch (error) {
                    console.log(error)
                }
                }} isLoading={state.isLoading}>
                    Guardar
                </Button>
            </div>
            <div className='col ms-1'>
                <Button onClick={()=>{
                try {
        
                    // onSubmit()
                    //handleSubmit(onSubmit)
                } catch (error) {
                    console.log(error)
                }
                }} isLoading={false}>
                Agendar Cita
                </Button>
            </div>
            

         


        </div>
    </div>
    
  )
}
