import { Button,chakra, HStack, Icon, Input, InputGroup, InputRightElement, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { CheckIcon, SearchIcon } from "assets/icons";

import { usePatients, usePatientsSearch } from "modules/Patients/api";
import React, { createRef, useEffect, useLayoutEffect, useState } from 'react'
import { IoIosAddCircleOutline } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserMedical } from "types/user";
import { FaCheckCircle, FaEdit, FaLink } from "react-icons/fa";
import { BsCheck, BsThreeDotsVertical } from "react-icons/bs";
import { LuCalendarCheck2 } from "react-icons/lu";
import { Patient } from "types/patient";
import { Service, _Service } from "api/api";
import axios from "axios";
import { MdArrowBack, MdArrowForward, MdArrowLeft, MdArrowRight, MdClose, MdEdit } from "react-icons/md";
import { BiChat, BiSearch, BiTrash } from "react-icons/bi";
import { useToast } from '@chakra-ui/react'
import { LoadingOverlay } from "shared/LoadingPage/LoadingPage";
import Renderif from "utils/renderif";
import { FaRegUserCircle } from "react-icons/fa";
import { GiBubbles } from "react-icons/gi";
import { InputFile, Select } from "shared";

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML, CompositeDecorator, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import htmlToDraft from 'html-to-draftjs';
import { ImImage } from "react-icons/im";



function color() {
	return (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
}


  
  





export default function CreateCurso({route} : any) {
    


    const navigate = useNavigate()
    const location = useLocation();
    const _curso = location?.state?.curso
    
 

    const toast = useToast();

//   const {setUser} = useContext(AuthContext)
//   const {notify} = useContext(AlertContext)
    const [file, setFile] = useState<File | null>();
 
  const [state, setState] = useState({
	cursos:[{
		plan:"Plan",
		usuarios:"12",
		organizacion:'Organizacion'
	},
	{
		plan:"Plan",
		usuarios:"12",
		organizacion:'Organizacion'
	},
	{
		plan:"Plan",
		usuarios:"12",
		organizacion:'Organizacion'
	}],
	loading:false,
    firstName: "",
    lastName: "",
    email: "",
    password: "Mydiet.123",
    phone: "",
    ocupation: "",
    civilState: "",

    general_collapse:0,
    tema_collapse:0,
    editing:'',
    editing_obj:{
        name:'',
        temas:[]
    } as any,
    tema_editing_index:0,
    subtema_editing_index:0,
    plans:[],
    plan_selected:"" as any,
    tips:[],
    videos:[],
    category:"",
    category_selected:0,
    show_general:true
    
    
  })

  const setHtmlToDraftState =  (html:any) => {
    if(html == ''){
        EditorState.createEmpty()
    }
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
        
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap)
        )
    }
    return EditorState.createEmpty()
  }
  const [general, setGeneral] = useState({
    title:'',
    name:'',
    featureImage:'',
    shortDescription:'',
    description: setHtmlToDraftState('<p></p>') as any,
    duration:"0",
    monthlyPrice:0,
    quarterlyPrice:0,
    isPublic:false,
    plan:{
        id:1
    },
    modulos:[] as any,
    image:{
        id:68
    }
  })
  const  [cursos, setCursos] = useState([] as any)

  const [curso, setCurso] = useState({
    title:'',
    name:'',
    description: setHtmlToDraftState('<p></p>') as any,
    // duration:"0",
    // monthlyPrice:0,
    // quarterlyPrice:0,
    // isPublic:false,
    // plan:{
    //     id:1
    // },
   
    modulos:[] as any,
    // modulos:[
    //     {
    //         name:"Modulo 1",
    //         frase:"Frase motivadora del Modulo 1",
    //         temas:[
    //             { 
    //                 name:'Tema 1 del Modulo 1',
    //                 duration:0,
    //                 description: "<p>Tema 1 del Modulo 1</p>",
    //                 subtemas:[
    //                     {
    //                         name:'Subtema 1 del Tema 1 del Modulo 1',
    //                         duration:'0',
    //                         tip:'',
    //                         video:'',
    //                         documento:'',
    //                         pts:'0',
    //                         frase:'Frase motivafora',
    //                         image:'url',
    //                         description: '<p>Subtema 1 del Tema 1 del Modulo 1</p>'
    //                     }
    //                 ]
    //             }
    //         ]
    //     },
    //     {
    //         name:"Modulo 2",
    //         frase:"Frase motivadora del Modulo 2",
    //         temas:[
    //             { 
    //                 name:'Tema 1 del Modulo 2',
    //                 duration:0,
    //                 description: "<p>Tema 1 del Modulo 2</p>",
    //                 subtemas:[
    //                     {
    //                         name:'Subtema 1 del Tema 1 del Modulo 2',
    //                         duration:'0',
    //                         tip:'',
    //                         video:'',
    //                         documento:'',
    //                         pts:'0',
    //                         frase:'Frase motivafora',
    //                         image:'url',
    //                         description: '<p>Subtema 1 del Tema 1 del Modulo 1</p>'
    //                     }
    //                 ]
    //             },
    //             { 
    //                 name:'Tema 2 del Modulo 2',
    //                 duration:0,
    //                 description: "<p>Tema 2 del Modulo 2</p>",
    //                 subtemas:[
    //                     {
    //                         name:'Subtema 1 del Tema 2 del Modulo 2',
    //                         duration:'0',
    //                         tip:'',
    //                         video:'',
    //                         documento:'',
    //                         pts:'0',
    //                         frase:'Frase motivafora',
    //                         image:'url',
    //                         description: '<p>Subtema 1 del Tema 2 del Modulo 2</p>'
    //                     },
    //                     {
    //                         name:'Subtema 2 del Tema 2 del Modulo 2',
    //                         duration:'0',
    //                         tip:'',
    //                         video:'',
    //                         documento:'',
    //                         pts:'0',
    //                         frase:'Frase motivafora',
    //                         image:'url',
    //                         description: '<p>Subtema 2 del Tema 2 del Modulo 2</p>'
    //                     }
    //                 ]
    //             },
    //         ]
    //     }
    // ] as any,
    // image:{
    //     id:68
    // }
  })

  useEffect(()=>{
    init()
  },[])

  useEffect(()=>{


    
    cursos[state.category_selected] = {
        id:state.category_selected,
        category:state.category,
        curso:curso
    }

  

  },[curso])

  const init = async () => {

    let _planes = await _Service('GET', 'plans');
    console.log(_planes)
 
 


    const ACCESS_TOKEN = "rY46Ylrq96wuj2fPp90ok5Ku96C0LctV3j-furqSwCM";
    const SPACE_ID = "cyupqx8f0n3c";
    const { data } = await axios.get(
        `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/testing/entries`,
        {
            baseURL: "",
            params: {
                content_type: 'tip',
                access_token: ACCESS_TOKEN,
            },
        }

    );

    const  videos  = await axios.get(
        `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/testing/entries`,
        {
            baseURL: "",
            params: {
                content_type: 'video',
                access_token: ACCESS_TOKEN,
            },
        }

    );

    console.log(videos)

    state.videos = videos.data.items
    state.tips = data.items;
    state.plans = _planes.data
    setState({...state})
    

    if(_curso !== undefined){
     
        let _data = JSON.parse(_curso.data)

       

        _data.map((curso:any)=>{
    
            // console.log(curso.curso.description)
            curso.curso.description = setHtmlToDraftState(curso.curso.description)
      
            curso.curso.modulos.map((modulo : any, i:number) => {
                modulo.temas.map((tema :any, i:number)=>{
                    tema.description = setHtmlToDraftState ( tema.description)
                    tema.subtemas.map((subtema:any, i:number)=>{
                        subtema.description = setHtmlToDraftState ( subtema.description)
                        
                    })
        
                })
            })
        })

        // _modulos.map((modulo : any, i:number) => {
        //     modulo.temas.map((tema :any, i:number)=>{
        //         tema.description = setHtmlToDraftState(tema.description)
        //         tema.subtemas.map((subtema:any, i:number)=>{
        //             subtema.description = setHtmlToDraftState(subtema.description)
        //             if( subtema.tip === ""){
        //                 subtema.tip = []
        //             }
        //         })

        //     })
        // })
        console.log(_curso.plan?.id)

        if(_curso.plan?.id !== undefined){
            state.plan_selected = _curso?.plan?.id?.toString();
            setState({...state})
        }
        console.log(_data)
        state.category_selected = _data[0].id
        state.category = _data[0].category
        setState({...state})
        setCurso(_data[0].curso)
        setCursos(_data)

        general.description = setHtmlToDraftState(_curso.description)
        general.name = _curso.name;
        general.title = _curso.title;
        general.duration = _curso.duration;
        general.monthlyPrice = _curso.monthlyPrice;
        general.quarterlyPrice = _curso.quarterlyPrice;
        general.featureImage = _curso.featureImage;
        general.shortDescription = _curso.shortDescription;
      

   
        setGeneral({...general})
    }

  }




  



  const onSave = async () => {

    


 
    if(state.plan_selected === ""){
        toast({
            title: "Curso",
            description: "Seleccion un tipo de plan.",
            status: "warning",
            position: "bottom-left",
          });
        return
    }
    // toast({
    //     title: 'Nuevo curso',
    //     description: "Curso editada con éxito",
    //     status: "success",
    //     position: "bottom-left",
    //   });
    // console.log(curso)
    // // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));

    const _cursos = Array.from(cursos)
    let body = {
        ...general,
        data:_cursos
    }
  
    body.plan = {
        id:0
    }
    body.plan.id = parseInt(state.plan_selected)
    // let content = true
    // body.modulos.map((modulo : any, i:number) => {
    //     modulo.temas.map((tema :any, i:number)=>{
         
    //         tema.subtemas.map((subtema:any, i:number)=>{
    //             if(subtema.documento === "" && subtema.video === ""){
    //                 content = false
    //             }
                
                
    //         })

    //     })
    // })

    // if(!content){
    //     toast({
    //         title: "Curso",
    //         description: "Debes selecciona un video o documento.",
    //         status: "warning",
    //         position: "bottom-left",
    //       });
    //       return;
    // }

   
    // let _cursos = cursos;
    body.description = draftToHtml(convertToRaw(body.description.getCurrentContent()))

    body.data.map((curso:any)=>{

        // console.log(curso.curso.description)
        curso.curso.description = draftToHtml(convertToRaw(curso?.curso?.description?.getCurrentContent()))
  
        curso.curso.modulos.map((modulo : any, i:number) => {
            modulo.temas.map((tema :any, i:number)=>{
                tema.description = draftToHtml(convertToRaw(tema.description.getCurrentContent()))
                tema.subtemas.map((subtema:any, i:number)=>{
                    subtema.description = draftToHtml(convertToRaw(subtema.description.getCurrentContent()))
                    
                })
    
            })
        })
    })

   

  
    // body.data = _cursos

    delete body.modulos
    console.log(body)
 
    // return
   
    body.data = JSON.stringify(body.data) as any


    if(_curso === undefined){
        console.log("CREATE")
        let create = await _Service('POST', `courses`, JSON.stringify(body));
        if(create.status === 201){
            toast({
                title: "Curso",
                description: "Curso creado exitosamente.",
                status: "success",
                position: "bottom-left",
              });
              navigate(-1)
        }else{
            toast({
                title: "Curso",
                description: "No ha sido posible crear curso.",
                status: "error",
                position: "bottom-left",
              }); 
        }
        console.log(create);
    }else{
    
        let put = {
            title:body.title,
            name:body.name,
            description: body.description,
            duration:body.duration,
            monthlyPrice:body.monthlyPrice,
            quarterlyPrice:body.quarterlyPrice,
            isPublic:body.isPublic,
            plan:{
                id:body.plan.id
            },
            data:body.data,
            image:{
                id:body.image.id !== undefined ? body.image.id : 1
            }
        }
        console.log(put)

        let patch = await _Service('PUT', `courses/${_curso.id}`, JSON.stringify(put));
        if(patch.status === 200){
            toast({
                title: "Curso",
                description: "Curso editado exitosamente.",
                status: "success",
                position: "bottom-left",
              });
              navigate(-1)
        }else{
            toast({
                title: "Curso",
                description: "No ha sido posible editar este curso.",
                status: "error",
                position: "bottom-left",
              }); 
        }
        console.log(patch);
    }
    



   
  }

  const upload = async () => {
    console.log(file)

    // let body = new FormData();
    // body.append("file", file as any);

    // let upload_file = await _Service('POSTMEDIA', 'files/upload', body)
    // console.log(upload_file)
    // let _image = upload_file.data.id;
    // // newMeal.image = {id:_image}

  }



  const onInputPhoto = async (event: React.ChangeEvent<HTMLInputElement>, ) => {

    const _files = Object.values(event.target.files!);


    // setFile(_files[0]);
    
    let body = new FormData();
    body.append("file", _files[0]);

    let upload_file = await _Service('POSTMEDIA', 'files/upload', body)
    console.log(upload_file.data.name)

    let url = `https://mydiet-assets-2023.s3.amazonaws.com/${upload_file?.data?.name}`

    return url;

    // let _image = upload_file.data.id;
    // newMeal.image = {id:_image}

  };

  const getTip = (tip:any) => {

  
    
        return state.tips.find((item:any) => item.sys.id === tip) as any

        // return tip
  } 
  const getDocuments = (id_plan:number) => {
 
    let plan = state.plans.find((plan:any) => plan.id === id_plan) as any
    // return state.plans.find((plan:any) => plan.id === id_plan)
    return plan?.documents
  }

  const getAudio = (id_plan:string, name:String) => {
    console.log(state.plans)
    let plan = state.plans.find((plan:any) => plan.id === parseInt(id_plan)) as any
    console.log(plan)

    // return state.plans.find((plan:any) => plan.id === id_plan)
    let audio = plan?.documents.find((doc:any) => doc.name === name)

    console.log(audio.audio)
    return audio
  }
  


  const getVideo = (url:any) => {

    let video_entry = state.videos.find((entry:any)=> entry.fields.videoyoutube === url) as any;
   
    if(video_entry?.fields?.title){
        return video_entry?.fields?.title
    }
    return ""

  }


  const uploadCallback = (file:any, callback:any) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {

        const form_data = new FormData();
        form_data.append("file", file);

   

       
    
        let upload_file = await _Service('POSTMEDIA', 'files/upload', form_data)
        console.log(upload_file.data.name)
    
        let url = `https://mydiet-assets-2023.s3.amazonaws.com/${upload_file?.data?.name}`
    
      



        resolve({ data: { link: url } });
      };
      reader.readAsDataURL(file);
    });
  };


//   class CustomOption extends Component {
//     static propTypes = {
//       onChange: PropTypes.func,
//       editorState: PropTypes.object,
//     };
  
//     addStar: Function = (): void => {
//       const { editorState, onChange } = this.props;
//       const contentState = Modifier.replaceText(
//         editorState.getCurrentContent(),
//         editorState.getSelection(),
//         '⭐',
//         editorState.getCurrentInlineStyle(),
//       );
//       onChange(EditorState.push(editorState, contentState, 'insert-characters'));
//     };
  
//     render() {
//       return (
//         <div onClick={this.addStar}>⭐</div>
//       );
//     }
//   }

  



//   onEditorStateChange: Function = (editorState) => {
//     this.setState({
//       editorState,
//     });
//   };

  

 

  return (
    <div className='container-fluid overflow-auto h-100'>

		<Renderif isTrue={state.loading}>
            <LoadingOverlay></LoadingOverlay>
        </Renderif>

        <Renderif isTrue={state.show_general}>

             
            <div className="container-md mx-auto p-0 m-0 h-100">

                <div className="d-flex">
                        <div className="d-flex align-items-center cursor-pointer mb-2" onClick={()=>{
                            navigate(-1)
                        }}>
                        <div className="md-icon md-icon--mid">
                            <MdArrowBack color="#2CAD6E"></MdArrowBack>
                            </div>
                            <div className="text-green bold">Cursos</div>
                        </div>

                        <div className="col"></div>

                        <div>
                            <div className='bv-cta bv-cta--success w-100 align-items-center' onClick={()=>{ 
                                state.show_general = false;
                                setState({...state})
                                
                            }}>
                                <div>Contenido</div>
                                <div className="ms-2"><MdArrowForward color="#FFF"></MdArrowForward></div>
                            </div>

                            
                        </div>
                </div>

                <div className="f-18 text-green bold" >General</div>

                <div className="feature-image" style={{
                    height:general.featureImage !== '' ? '160px' : '0px',
                    backgroundImage:`url('${general.featureImage}')`
                    }}></div>

                <div className="d-flex align-items-end">
                    <div className="col">

                            <div className='mt-0'>
                                <div className='f-14 text-gray'>Titulo</div>
                                <input value={general.title} onChange={(evt)=>{
                                    setGeneral({...general, title:evt.currentTarget.value})
                                }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                            </div>

                    </div>
                    <div className="mx-2"></div>
                    <div className="col">
                    
                    <InputFile
                        id="cursogeneral"
                        label="Foto del curso"
                        value={""}
                        onChange={async (event) => {
                            
                            let _image = await onInputPhoto(event)
                            setGeneral({...general, name:_image})

                        }}
                        />

                    </div>
                    <div className="mx-2">
                        <img className="r-1" width={80} src={general.name}></img>
                    </div>
                    <div className="col">
                    
                    <InputFile
                        id="cursogeneraldestacada"
                        label="Imágen destacada"
                        value={""}
                        onChange={async (event) => {
                            
                            let _image = await onInputPhoto(event)
                            setGeneral({...general, featureImage:_image})

                        }}
                        />

                    </div>
                   
                </div>

                <div className="mt-3">
                    <div className='f-14 text-gray'>Descripción corta</div>
                    <textarea rows={5}  value={general.shortDescription} onChange={(evt)=>{
                        setGeneral({...general, shortDescription:evt.currentTarget.value})
                    }   } className='chakra-input css-1omz1ot py-2' placeholder=""></textarea>   
                </div>

                <div className="d-flex mt-3">
                    <div className='mt-0 w-100'>
                        <div className='f-14 text-gray'>Descripción</div>
                        <Editor
                    
                        editorState={general.description}
                            onEditorStateChange={(editorState)=>{
                                general.description = editorState;
                            setCurso({...curso})
                            }}
                            toolbar={{
                                image: { 
                                    uploadEnabled: true,
                                    uploadCallback: uploadCallback,
                                    previewImage: true,
                                 },     
                              }}
                         
                        ></Editor>
                    </div>

                </div>

                <div className="d-flex mt-3">
                    <div className="col pe-2">
                      
                            <div className='f-14 text-gray'>Duración (meses)</div>
                        <input value={general.duration} onChange={(evt)=>{
                            setGeneral({...general, duration:evt.currentTarget.value})
                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                    </div>
                    <div className="col pe-2">
                        <div className='mt-0 w-100'>
                            <div className='f-14 text-gray'>Precio Mensual</div>
                            <input value={general.monthlyPrice.toString()} onChange={(evt)=>{
                            setGeneral({...general, monthlyPrice:parseInt(evt.currentTarget.value)})
                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                        </div>
                    </div>
                    <div className="col pe-2">
                        <div className='mt-0 w-100'>
                            <div className='f-14 text-gray'>Precio Trimestral</div>
                            <input value={general.quarterlyPrice.toString()} onChange={(evt)=>{
                            setGeneral({...general, quarterlyPrice:parseInt(evt.currentTarget.value)})
                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                        </div>
                    </div>
                    <div className="col pe-2">
                        <Select label="Plan" value={state.plan_selected} onChange={(evt)=>{
                
                            state.plan_selected = evt.currentTarget.value;
                            setState({...state})
                        }}>
                            <option value="">Seleccionar</option>
                            {
                                state.plans.map((plan:any, i)=>{
                                    
                                    return (<option key={i} value={plan.id}>{plan.title}</option>)
                                })
                            }
                            
                        </Select>
                    </div>
                </div>

                <div className="mt-5"></div>



            </div>
        </Renderif>
        
        <Renderif isTrue={!state.show_general}>
            <div className="container-md mx-auto p-0 m-0 h-100">
                <div className="d-flex h-100">
                    <div className="col-8 overflow-auto hide-scroll">

                    <div className="container-fluid p-0">

                        <div className="d-flex">
                            <div className="d-flex align-items-center cursor-pointer mb-2" onClick={()=>{
                                state.show_general = true;
                                setState({...state})
                            }}>
                            <div className="md-icon md-icon--mid">
                                <MdArrowBack color="#2CAD6E"></MdArrowBack>
                                </div>
                                <div className="text-green bold">General</div>
                            </div>

                            <div className="col"></div>

                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
                                    onSave()
                                    
                                }}>
                                    <div>GUARDAR</div>
                                </div>

                                
                            </div>
                        </div>

                <div className="d-flex align-items-end">
                        <div className="col">
                            <div className="text-green bold">Catégoria.</div>
                            <input value={state.category} onChange={(evt)=>{

                                    state.category = evt.currentTarget.value
                                    setState({...state})

                                }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                    </div>
                
                </div>

                        <div className="mt-3"></div>

                        <div className="text-green bold">Detalles del curso.</div>

                        <div className="d-flex align-items-end">
                            <div className="col">

                                    <div className='mt-0'>
                                        <div className='f-14 text-gray'>Titulo</div>
                                        <input value={curso.title} onChange={(evt)=>{
                                            setCurso({...curso, title:evt.currentTarget.value})
                                        }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                    </div>

                            </div>
                            <div className="mx-2"></div>
                            <div className="col">
                        
                            <InputFile
                                id="cursoimage"
                                label="Foto del curso"
                                value={""}
                                onChange={async (event) => {
                                    
                                    let _image = await onInputPhoto(event)
                                    setCurso({...curso, name:_image})

                                }}
                                />

                            </div>
                            <div>
                                <img width={100} src={curso.name}></img>
                            </div>
                        </div>

                        <div className="d-flex mt-3">
                            <div className='mt-0 w-100'>
                                <div className='f-14 text-gray'>Descripcion</div>
                                <Editor
                            
                                editorState={curso.description}
                                onEditorStateChange={(editorState)=>{
                                    curso.description = editorState;
                                    setCurso({...curso})
                                }}
                                toolbar={{
                                    image: { 
                                        uploadEnabled: true,
                                        uploadCallback: uploadCallback,
                                        previewImage: true,
                                     },     
                                  }}
                                
                                ></Editor>
                            </div>

                        </div>

                        {/* <div className="d-flex mt-3 d-none">
                            <div className="col pe-2">
                              
                                <div className='f-14 text-gray'>Duración (meses)</div>
                                <input value={curso.duration} onChange={(evt)=>{
                                    setCurso({...curso, duration:evt.currentTarget.value})
                                }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                            </div>
                            <div className="col pe-2">
                                <div className='mt-0 w-100'>
                                    <div className='f-14 text-gray'>Precio Mensual</div>
                                    <input value={curso.monthlyPrice.toString()} onChange={(evt)=>{
                                    setCurso({...curso, monthlyPrice:parseInt(evt.currentTarget.value)})
                                }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                                </div>
                            </div>
                            <div className="col pe-2">
                                <div className='mt-0 w-100'>
                                    <div className='f-14 text-gray'>Precio Trimestral</div>
                                    <input value={curso.quarterlyPrice.toString()} onChange={(evt)=>{
                                    setCurso({...curso, quarterlyPrice:parseInt(evt.currentTarget.value)})
                                }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                                </div>
                            </div>
                            <div className="col pe-2">
                                <Select label="Plan" value={state.plan_selected} onChange={(evt)=>{
                    
                                    state.plan_selected = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Seleccionar</option>
                                    {
                                        state.plans.map((plan:any, i)=>{
                                        
                                            return (<option key={i} value={plan.id}>{plan.title}</option>)
                                        })
                                    }
                                    
                                </Select>
                            </div>
                        </div> */}

                        <div className="mt-4"></div>

                        <div className="text-green bold">Modulos.</div>

                        <Renderif isTrue={state.editing !== ''}>
                            <div className="mt-3"></div>

                            <div className="d-flex align-items-end">
                                <div className="col">
                                        <div className='mt-0'>
                                            <div className='f-14 text-gray'>Nombre General</div>
                                            <input value={state.editing_obj.name} onChange={(evt)=>{
                                                state.editing_obj.name = evt.currentTarget.value;
                                                setState({...state})
                                            }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                </div>
                                
                            </div>

                            <div className="d-flex align-items-end mt-3">
                            
                            
                                <div className="col">

                                    <div className='mt-0'>
                                        <div className='f-14 text-gray'>Frase motivadora</div>
                                        <input value={state.editing_obj.frase} onChange={(evt)=>{
                                                state.editing_obj.frase = evt.currentTarget.value;
                                                setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                    </div>

                                </div>
                                <div className="mx-2"></div>
                                <div className="col">

                                    {/* <input type="file" onChange={(event)=>{onInputPhoto(event)}}></input> */}

                                    <InputFile
                                        id="moduleimage"
                                        label="Foto del modulo"
                                        value={""}
                                        onChange={async (event) => {
                                    
                                            let _image =  await onInputPhoto(event)
                                        
                                            state.editing_obj.image = _image
                                            // state.editing_obj.image = `https://mydiet-assets-2023.s3.amazonaws.com/f6120581-d1b7-4b3c-8949-d5f0e10023dc.png`
                                            setState({...state})
                                            

                                        }}
                                        />

                                </div>
                                <div>
                                    <img width={100} src={state.editing_obj.image}></img>
                                </div>
                            </div>
                        </Renderif>

                        <Renderif isTrue={state.editing === 'tema'}>
                                <div className=" bg-gray-05 p-3 r-2 mt-4">
                                    <div className="text-green f-14 bold">{state.editing_obj?.name} / {state.editing_obj?.temas[state.tema_editing_index]?.name}</div>
                                    <div className="d-flex align-items-end">

                                        <div className="col">
                                                <div className='mt-0'>
                                                    <div className='f-14 text-gray'>Nombre del Tema</div>
                                                    <input value={state.editing_obj.temas[state.tema_editing_index]?.name} onChange={(evt)=>{
                                                        state.editing_obj.temas[state.tema_editing_index].name = evt.currentTarget.value;
                                                        setState({...state})
                                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                                </div>
                                        </div>
                                        <div className="mx-2"></div>
                                        <div className="col">
                                        <div className='f-14 text-gray'>Duración (dias)</div>
                                        <input value={state.editing_obj.temas[state.tema_editing_index]?.duration} onChange={(evt)=>{
                                                        state.editing_obj.temas[state.tema_editing_index].duration = evt.currentTarget.value;
                                                        setState({...state})
                                                    }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>

                                    
                                        </div>
                                        
                                        


                                    </div>

                            

                                    <div className='mt-0 w-100 mt-3'>
                                        <div className='f-14 text-gray'>Descripción AA</div>
                                        {/* <input value={''} onChange={(evt)=>{}} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input> */}
                                        <Editor
                                            editorState={state.editing_obj.temas[state.tema_editing_index]?.description}
                                            onEditorStateChange={(editorState)=>{
                                                state.editing_obj.temas[state.tema_editing_index].description = editorState
                                                setState({...state})
                                            }}
                                            toolbar={{
                                                image: { 
                                                    uploadEnabled: true,
                                                    uploadCallback: uploadCallback,
                                                    previewImage: true,
                                                 },     
                                              }}
                                    

                                        ></Editor>
                                    </div>

                                
                                </div>
                        </Renderif>

                        <Renderif isTrue={state.editing === 'subtema'}>
                                <div className=" bg-gray-05 p-3 r-2 mt-4">
                                
                                    <div className="text-green f-14 bold">{state.editing_obj?.name} / {state.editing_obj?.temas[state.tema_editing_index]?.name} / {
                                        state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.name
                                    }</div>
                                    <div className="d-flex align-items-end">

                                        <div className="col">
                                                <div className='mt-0'>
                                                    <div className='f-14 text-gray'>Nombre del subtema</div>
                                                    <input value={state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.name} onChange={(evt)=>{
                                                        state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].name = evt.currentTarget.value
                                                        setState({...state})
                                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                                </div>
                                        </div>
                                        <div className="mx-2"></div>
                                        <div className="col">
                                        <div className='f-14 text-gray'>Duración (min)</div>
                                                    <input value={state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.duration} onChange={(evt)=>{
                                                        state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].duration = evt.currentTarget.value
                                                        setState({...state})
                                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                        
                                        


                                    </div>

                                    <div className="d-flex align-items-start mt-3">

                                        <div className="col-4 pe-2">
                                        <Select label="Seleccionar Tip" value={''} onChange={(evt)=>{
                                         
                                            if(state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].tip === ""){
                                                state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].tip = []

                                            }

                                            state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].tip.push(evt.currentTarget.value)
                                            setState({...state})
        

                                        }}>

                                            
                                            <option value="">Seleccionar Tip</option>
                                            
                                            {
                                                state.tips.map((tip:any, i)=>{
                                                    return (<option key={i} value={tip.sys.id}>{tip?.fields?.title}</option>)
                                                })
                                            }
                                            
                                        </Select>
                                        <div className="d-flex flex-wrap">
                                            {
                                            state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.tip?.map((tip:any, i:number)=>{
                                                return <div key={i}  className="tag tag--green mt-1 me-1 cursor-pointer" onClick={()=>{
                                                    state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].tip = state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.tip.filter((item:any) => item !== tip)
                                                    setState({...state})
                                                }}>{getTip(tip)?.fields?.title}</div>
                                            })   
                                            }
                                        </div>

                                        </div>
                                        
                                        <div className="col-4 pe-2">
                                        <Select label="Seleccionar Video" value={state.editing_obj.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.video} onChange={(evt)=>{
                                            console.log(evt.currentTarget.value)
                                            state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].video = evt.currentTarget.value;
                                            setState({...state})
                                            

                                        }}>
                                            <option value="">Seleccionar Video</option>
                                            {
                                                state.videos.map((entry:any, i)=>{
                                                    return (<option key={i} value={entry.fields.videoyoutube}>{entry?.fields?.title}</option>)
                                                })
                                            }
                                            
                                        </Select>
                                        <div className="tag tag--green mt-1">
                                            {getVideo(state.editing_obj.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.video)}
                                        </div>
                                        </div>
                                        
                                        <div className="col-4">
                                        <Select label="Documento" value={''} onChange={(evt)=>{
                                            
                                            state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].documento = 'https://mydiet-assets-2023.s3.amazonaws.com/'+ evt.currentTarget.value;
                                            state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].audio = 'https://mydiet-assets-2023.s3.amazonaws.com/'+ getAudio(state.plan_selected, evt.currentTarget.value)?.audio;

                                            
                                            setState({...state})

                                        }}>
                                            <option value="">Seleccionar</option>

                                            {
                                                getDocuments(parseInt(state.plan_selected))?.map((item:any, i:number)=>{
                                                   
                                                        return (
                                                            <option key={i} value={item.name}>{item.infoName}</option>
                                                        )
                                                })
                                            
                                            }
                                    
                                            
                                        </Select>
                                        <Renderif isTrue={state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.documento !== ""}>
                                            <div className="tag tag--green mt-1 w-100 d-flex align-items-center" >
                                                <div className="overflow-hidden col cursor-pointer" onClick={()=>{
                                                window.open(state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.documento, '_blank')
                                            }}>
                                                    <div className="text-ellipsis">
                                                        {state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.documento}
                                                    </div>
                                                </div>

                                                <div className="icon ms-2 cursor-pointer" onClick={()=>{
                                                    state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].documento = ""
                                                    setState({...state})
                                                }}>
                                                    <MdClose size={18}></MdClose>
                                                </div>
                                            </div>
                                        </Renderif>

                                        </div>
                                    </div>

                                    <div className='mt-0 w-100 mt-3'>
                                        <div className='f-14 text-gray'>Descripcion</div>
                                        <Editor
                                        editorState={state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.description}
                                        onEditorStateChange={(editorState)=>{
                                            state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].description = editorState
                                            setState({...state})
                                        }}
                                        toolbar={{
                                            image: { 
                                                uploadEnabled: true,
                                                uploadCallback: uploadCallback,
                                                previewImage: true,
                                             },     
                                          }}
                                        ></Editor>

                                        
                                    </div>

                                    <div className="d-flex align-items-end mt-3">
                                        <div className="col">

                                                <div className='mt-0'>
                                                    <div className='f-14 text-gray'>Puntos por subtema</div>
                                                    <input value={state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.pts} onChange={(evt)=>{
                                                        state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].pts = evt.currentTarget.value
                                                        setState({...state})
                                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                                </div>

                                        </div>
                                        <div className="mx-2"></div>
                                        <div className="col">

                                                <div className='mt-0'>
                                                    <div className='f-14 text-gray'>Frase motivadora</div>
                                                    <input value={state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.frase} onChange={(evt)=>{
                                                        state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].frase = evt.currentTarget.value
                                                        setState({...state})
                                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                                </div>

                                        </div>
                                        <div className="mx-2"></div>
                                        <div className="col">

                                        <InputFile
                                            id="imagesubtema"
                                            label="Foto"
                                            value={""}
                                            onChange={async (event) => {
                                                let _image = await onInputPhoto(event);
                                                state.editing_obj.temas[state.tema_editing_index].subtemas[state.subtema_editing_index].image = _image;
                                                setState({...state})
                                            }}
                                            />

                                        </div>
                                        <div>
                                            <img width={100} src={state.editing_obj?.temas[state.tema_editing_index]?.subtemas[state.subtema_editing_index]?.image}></img>
                                        </div>
                                    </div>
                                </div>
                        </Renderif>
                        

                        <div style={{height:300}}></div>

                        

                        
                    
                                    
                    
                    </div>



                    </div>
                    <div className="col-4 ">

                        <div className="d-flex flex-column h-100 bg-light mx-2 p-3 r-2">

                            <div className="d-flex align-items-center">

                                <div className="bv-icon bv-icon--mid cursor-pointer" onClick={(evt)=>{
                                    console.log(state.category_selected)
                                    let _cursos = cursos.filter((cur:any) => cur.id !== state.category_selected )
                                    setCursos(_cursos)

                                   try {
                                        state.category_selected = cursos[0].id;
                                        state.category = cursos[0].category;
                                        setState({...state})
                                        setCurso(cursos[0].curso)
                                   } catch (error) {
                                        state.category_selected = 0;
                                        state.category = '';
                                        setState({...state})
                                    
                                   }
                                   
                                    
                                }}>
                                    <BiTrash color="red"></BiTrash>
                                </div>

                                <div className="col">
                                    {/* <div>{state.category_selected}</div>
                                    <div>{cursos.length}</div> */}
                                    {/* <div>{state.category}</div> */}
                                <Select label="" value={state.category_selected} onChange={(evt)=>{
                        
                                    state.category_selected = parseInt(evt.currentTarget.value);
                                
                                    let _curso = cursos.find((cur:any)=>cur.id === parseInt(evt.currentTarget.value));
                                    state.category = _curso.category
                                    console.log(cursos)
                                    console.log(_curso)
                                    setState({...state})
                                    
                                    setCurso(_curso.curso)

                                    //setState({...state})
                                }}>
                                    <option value="">Seleccionar</option>
                                    {
                                        cursos?.map((curso:any, i:number)=>{
                                        
                                            return (<option key={i} value={curso.id}>{curso.category}</option>)
                                        })
                                    }
                                    
                                </Select>
                                </div>
                        

                                <div className='pill pill--gren  cursor-pointer m-0 ms-2' style={{height:38}} onClick={()=>{
                               
                                    state.category_selected = state.category_selected + 1
                                    state.category = ""
                                    state.editing = ''
                                    // state.editing_obj= {
                                    //     name:'',
                                    //     temas:[]
                                    // }
                                    setCurso({
                                        title:'',
                                        name:'',
                                        description: setHtmlToDraftState('<p></p>') as any,
                                        // duration:"0",
                                        // monthlyPrice:0,
                                        // quarterlyPrice:0,
                                        // isPublic:false,
                                        // plan:{
                                        //     id:1
                                        // },
                                    
                                        modulos:[] as any,
                                        // image:{
                                        //     id:68
                                        // }
                                    })
                                }}>
                                    Nueva Catégoria
                                </div>

                            </div>


                            <div className="d-flex flex-column mt-1 mb-2">
                            

                                {
                                    curso?.modulos.map((item:any, i:number)=>{
                                        return (
                                            <div className=" b-l mt-2 r-1" key={i}>

                                                <div className=" bg-gray-05 px-1 cursor-pointer d-flex align-items-center py-2" onClick={()=>{
                                                    
                                                    if(state.general_collapse === item.id){
                                                        state.general_collapse = 0;
                                            
                                                        setState({...state})
                                                        return;
                                                    }
                                                    state.general_collapse = item.id;
                                                    state.editing_obj = item;
                                                    setState({...state})
                                                }}>
                                                    <div className={`dot ${state.general_collapse === item?.id ? 'dot--active': ''}`}></div>
                                                    <div className="f-14 bold ps-1">{item?.name}</div>

                                                    <div className="col"></div>
                                                    <div className="bv-icon cursor-pointer" onClick={()=>{
                                                        curso.modulos = curso.modulos.filter((_item:any)=> _item.id !== item.id )
                                                        setCurso({...curso})
                                                    }}>
                                                        <BiTrash color="red"></BiTrash>
                                                    </div>
                                                </div>

                                                <Renderif isTrue={state.general_collapse === item?.id}>

                                                {item?.temas.map((tema:any, j:any)=>{
                                                    return (
                                                        <div className="ms-3 b-l ps-2 " key={j}> 
                                                            <div className="d-flex align-items-center py-1">
                                                                <div className={`dot ${state.tema_editing_index === j ? 'dot--active': ''}`}></div>
                                                                <div className="ps-2 text-body-secondary f-14 bold text-decoration-underline cursor-pointer" onClick={()=>{
                                                                    state.editing = 'tema'
                                                                    state.editing_obj = item;
                                                                    state.tema_editing_index = j;
                                                                    setState({...state})
                                                                }}>{tema.name}</div>
                                                                <div className="col">
                                                                    <div className="bv-icon cursor-pointer" onClick={()=>{
                                                                    
                                                                        item.temas =  item.temas.filter((_tema:any)=> _tema !== tema)
                                                                        setCurso({...curso})
                                                                    }}>
                                                                        <BiTrash color="red"></BiTrash>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {tema.subtemas.map((subtema:any, k:any)=>{
                                                                return (
                                                                    <div className="ms-3 b-l ps-2 " key={k}> 
                                                                        <div className={`d-flex align-items-center hl-sky py-1 ps-2 r-1 mb-1 cursor-pointer 
                                                                        ${state.subtema_editing_index === k && state.tema_editing_index === j ? 'bg-sky' : ''} `} onClick={()=>{
                                                                                state.editing = 'subtema'
                                                                                state.editing_obj = item;
                                                                                state.tema_editing_index = j;
                                                                                state.subtema_editing_index  = k;
                                                                                setState({...state})
                                                                            }}>
                                                                            <div className={`dot ${state.subtema_editing_index === k && state.tema_editing_index === j ? 'dot--active': ''}`}></div>
                                                                            <div className="ps-2 text-body-secondary f-14">{subtema.name}</div>
                                                                            <div>
                                                                            <div className="bv-icon cursor-pointer" onClick={()=>{
                                                                    
                                                                                    tema.subtemas =  tema.subtemas.filter((_subtema:any)=> _subtema !== subtema)
                                                                                    setCurso({...curso})
                                                                                }}>
                                                                                    <BiTrash color="red"></BiTrash>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    
                                                                )
                                                            })}
                                                            <div className="ps-2">
                                                                <div className="pill pill--gren m-0 cursor-pointer ms-2 mt-2" onClick={()=>{
                                                                    curso.modulos[i].temas[j].subtemas.push({
                                                                        name:'Nuevo Subtema',
                                                                        duration:'0',
                                                                        tip:[],
                                                                        video:'',
                                                                        documento:'',
                                                                        audio:'',
                                                                        pts:'0',
                                                                        frase:'',
                                                                        image:'',
                                                                        description: EditorState.createEmpty()
                                                                        
                                                                        
                                                                    })
                                                                    state.editing_obj = item;
                                                                    state.editing = 'subtema'
                                                                    state.tema_editing_index = curso.modulos[i].temas.length - 1
                                                                    state.subtema_editing_index = curso.modulos[i].temas[j].subtemas.length - 1
                                                                    setCurso({...curso})
                                                                }}>Agregar Subtema</div>
                                                            </div>
                                                    
                                                        </div>
                                                    )
                                                })}
                                                <div className="pill pill--gren m-0 cursor-pointer ms-3 mt-2" onClick={()=>{
                                            
                                                    curso.modulos[i].temas.push({
                                                        name:'Nuevo Tema',
                                                        duration:0,
                                                        description: EditorState.createEmpty(),
                                                        subtemas:[]
                                                    })
                                                    
                                                    state.editing_obj = item;
                                                    state.editing = 'tema'
                                                    state.tema_editing_index = curso.modulos[i].temas.length - 1
                                                    state.subtema_editing_index = -1
                                                    
                                                    
                                                    setCurso({...curso})
                                                }}>Agregar Tema</div>
                                                </Renderif>
                                            </div>
                                            

                                        )
                                    })
                                }
                                
                            </div>

                            

                            <div className="pill pill--gren cursor-pointer" onClick={()=>{

                                    
                                    curso.modulos.push({
                                        id:curso.modulos.length + 1,
                                        image:"",
                                        name:"Modulo",
                                        frase:"",
                                        temas:[]
                                    })
                                    state.editing = 'general'
                                    state.editing_obj = curso.modulos[curso.modulos.length-1]
                                    state.general_collapse = curso.modulos.length;
                                    setCurso({...curso})
                                }} style={{height:38, marginBottom:2, marginTop:3}}>Nuevo Modulo</div>


                            
                            
                                                

                        </div>


                    </div>
                </div>
            </div>
        </Renderif>

    </div>
  )
}



// export { CursosView }