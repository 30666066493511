import * as React from "react";
import Chakra from "./Chakra";
import ReactQuery from "./ReactQuery";
import ReactRouter from "./ReactRouter";
import { AuthProvider } from "./AuthContext";

type ContextsProviderProps = {
  children?: React.ReactNode;
};

const ContextsProvider: React.FC<ContextsProviderProps> = ({ children }) => (
  <Chakra>
    <ReactRouter>
      <ReactQuery>
        <AuthProvider>{children}</AuthProvider>
      </ReactQuery>
    </ReactRouter>
  </Chakra>
);

export default ContextsProvider;
