import axios, { AxiosResponse, AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

const useDeleteMeal = (mealId: string) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError>(
    (params) =>
      axios.delete(`recipes/${mealId}`),
    { onSuccess: () => queryClient.invalidateQueries(["meals"]) }
  );
};

export { useDeleteMeal };
