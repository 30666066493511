import * as React from "react";
import {
  InputGroup,
  InputRightElement,
  Input as CkInput,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useToggle } from "hooks";

import { InputProps } from "../Input";

const InputPassword = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [showPassword, toggleShowPassword] = useToggle(false);

    const {
      children,
      width,
      label,
      variant = "filled",
      error,
      ...restInputProps
    } = props;
    return (
      <VStack width={width || "100%"}>
        <chakra.span alignSelf="baseline" fontSize="md" color="gray">
          {label}
        </chakra.span>
        <InputGroup>
          <CkInput
            ref={ref}
            variant={variant}
            isInvalid={error ? true : false}
            type={showPassword ? "text" : "password"}
            {...restInputProps}
          />
          <InputRightElement>
            {showPassword ? (
              <FaEye role="button" onClick={toggleShowPassword} />
            ) : (
              <FaEyeSlash role="button" onClick={toggleShowPassword} />
            )}
          </InputRightElement>
        </InputGroup>
        <chakra.span
          color="red.100"
          fontSize="sm"
          alignSelf="baseline"
          lineHeight="1"
        >
          {error}
        </chakra.span>
      </VStack>
    );
  }
);

InputPassword.displayName = "InputPassword";

export { InputPassword };
