import React, { useEffect, useState } from 'react'
import { BsCheck, BsCheck2, BsCheck2Square, BsCheckSquare, BsCheckSquareFill, BsFileEarmark, BsInbox, BsPerson, BsPlus, BsPlusCircle, BsPlusCircleDotted, BsSquare, BsThreeDotsVertical } from 'react-icons/bs'
import { FaCheckCircle, FaEdit, FaFile, FaHeartbeat, FaPlusCircle, FaRegFile } from 'react-icons/fa'
import { HiOutlineInbox } from 'react-icons/hi'
import { MdArrowBack, MdClose, MdEdit, MdOutlineScience } from 'react-icons/md'
import { Select } from 'shared'
import { ImFilesEmpty } from "react-icons/im";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { _Service } from 'api/api'
import { LoadingOverlay } from 'shared/LoadingPage/LoadingPage'
import { useToast } from '@chakra-ui/react'
import PlanAlimenticio from './patient_plan_alimenticio'
import { FaRegUserCircle } from "react-icons/fa";
import { FaLink } from "react-icons/fa";
import { _recipes_ } from 'receipe'
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";


const _measures = {
    gender: "",
    weight: 0,
    height: 0,
    hip: 0,
    abdomen: 0,
    waist: 0,
    bloodPressure: "",
    glucose: 0,
    averageOfFat: 0,
    averageOfMuscle: 0,
    averageOfWater: 0,
    visceralFat: 0,
    targetWeight: 0,
    targetWaist: 0,
    targetAverageOfMuscle: 0,
    targetAverageOfFat: 0
}

const YYYYMMDD = (_date:any) =>{

 
    if(_date === null || _date === undefined){
        return '';
    }
    let aux = _date.split('T')[0].split('-')
    return aux[0] + '-' + aux[1] + '-' + aux[2]
}


export default function PatientDetailView() {
    const navigate = useNavigate()
    const location = useLocation()
    const toast = useToast();
    const [state, setState] = useState({
        tab_active:0,
        tab_active_hitorial:0,
        loading:false,
        patient: null as any,
        sessionSelected: null as any,
        nutrional_plans:[] as any,
        aliments:[] as any,
        aliments_filter:[] as any,
        otras_bebidas:[] as any,
        _categories:[
			"SOP",
			"Endometriosis",
			"Control de Peso",
			"Resistencia a la insulina",
            "Salud 360",
			"Diabetes",
			"General"
		],
        _categoriesSelected:[] as any,
        // _subCategories: [],
        _subCategories:[
                "Hiperproteica",
                "Catogenica",
                "Libre de gluten",
      		    "Paleolítica y mediterránea",
                "Antiinflamatoria",
                "Basada en Plantas",
                "Low Carb",
                "General"
            ],
        _subCategoriesSelected: [] as any,
        week_aliments: [] as any,
        day_active:new Date(),
        recipes:[] as any,
        active_recipes: [],
        total_kcal:0,
        totales: {
            proteinas:{
                gramos:0,
                porcentaje:0
            }as any,
            hidratos:{
                gramos:0,
                porcentaje:0
            }as any,
          
            lipidos:{
                gramos:0,
                porcentaje:0
            } as any
        } as any ,
        plan_comidas:[] as any,
        custom_question:{
            question:'',
            value:''

        },
        r24_aliment_selected:[] as any,
        r24_aliment_porcion:'',
        r24_aliments:[],
        r24_mealTime_selected:"",
        evolution_notes:[],
        show_new_note:false,
        new_note:{
            id:0,
            prescribedKcal:"",
            consultationNotes:"",
            appraisalNotes:"",
            evolutionNotes:"",
            prescription:"",
            messages:[] as any,
         },
         message:"",
         editing_note:false,
         lifeSyleQuestion:{
            question:'',
            answer:''
         },
         kcal:0,
         meta:{
   
            kcal:0,
            pro:0,
            lip:0,
            hco:0
         },
         metag:{
   
            kcal:0,
            pro:0,
            lip:0,
            hco:0
         },

         equivalencias:[
            {alimento:'VERDURAS', kcal:25, pro:2, lip:0, hco:4, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'FRUTAS', kcal:60, pro:0, lip:0, hco:15, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'CEREALES S/G', kcal:70, pro:2, lip:0, hco:15, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'CEREALES C/G', kcal:115, pro:2, lip:5, hco:15, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'Leguminosas', kcal:120, pro:8, lip:1, hco:20, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'AOA MBAG', kcal:40, pro:7, lip:1, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'AOA BAG', kcal:55, pro:7, lip:3, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'AOA MAG', kcal:75, pro:7, lip:5, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'AOA AAG', kcal:100, pro:7, lip:8, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'LECHE DES', kcal:95, pro:9, lip:2, hco:12, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'LECHE SEMI', kcal:110, pro:9, lip:4, hco:12, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'LECHE ENTERA', kcal:150, pro:9, lip:8, hco:12, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'LECHE C/A', kcal:200, pro:8, lip:5, hco:30, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'ACEITES S/P', kcal:45, pro:0, lip:5, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'ACEITES C/P', kcal:70, pro:3, lip:5, hco:3, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'AZUCAR S/G', kcal:40, pro:0, lip:0, hco:10, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
            {alimento:'AZUCAR C/G', kcal:85, pro:0, lip:5, hco:10, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
          
        ],
        formula:'1',
        factor:'',
        factor_value:'',
        factor_termico_value:'0',
        requerimiento:'',
        citas:[],
        generatingia :false,
        generated:false,
        week_range:1
       

      
    })
    const {color} = location.state;
    const {id} = useParams();
    const questions = [
        {
            tab:0,
            question:'¿Dolor durante la relación sexual?',
            value:''
        },
        {
            tab:0,
            question:'Exceso de coágulos durante la menstruación',
            value:''
        },
        {
            tab:0,
            question:'Pérdida de cabello',
            value:''
        },
        {
            tab:0,
            question:'¿Infecciones vaginales recurrentes?',
            value:''
        },
        {
            tab:0,
            question:'Sangrados menstruales muy abundantes (menorragia)',
            value:''
        },
        {
            tab:0,
            question:'Uñas frágiles',
            value:''
        },
        {
            tab:0,
            question:'Colicos menstruales muy dolorosos (dismenorrea)',
            value:''
        },
        {
            tab:0,
            question:'Dolor de cabeza durante periodo menstrual',
            value:''
        },
        {
            tab:1,
            question:'Hirsutismo (exceso de vello)',
            value:''
        },
        {
            tab:1,
            question:'Presencia de manchas oscuras en cuello o codos (acantosis nigricans)',
            value:''
        },
        {
            tab:1,
            question:'Alopecia (caída importante de cabello)',
            value:''
        },
        {
            tab:1,
            question:'Intento de embarazo',
            value:''
        },
        {
            tab:1,
            question:'Presencia de acné (cara, pecho, espalda)',
            value:''
        }, 
        {
            tab:2,
            question:'Dolor de cabeza, sudor excesivo, exoftalmo, malestar intestinal, subir o pérdida de peso, cambios de ánimo',
            value:''
        }, 
        {
            tab:2,
            question:'Estreñimiento',
            value:''
        }, 
        {
            tab:2,
            question:'Mala regulación de la temperatura corporal.',
            value:''
        }, 
        {
            tab:2,
            question:'Piel seca o amarillenta (mala conversión de Caroteno a vitamina A)',
            value:''
        }, 
        {
            tab:2,
            question:'Sensación de frío o manos heladas (Frec. Cardíaca alta)',
            value:''
        }, 
        {
            tab:3,
            question:'Eructos',
            value:''
        }, 
        {
            tab:3,
            question:'Distensión abdominal',
            value:''
        }, 
        {
            tab:3,
            question:'Gases',
            value:''
        }, 
        {
            tab:3,
            question:'Colitis',
            value:''
        }, 
        {
            tab:3,
            question:'Estreñimiento',
            value:''
        }, 
        {
            tab:3,
            question:'Acidez',
            value:''
        },
        {
            tab:3,
            question:'Escala de bristol',
            value:''
        }
        
        
    ]

    const equivalencia = [
        {alimento:'VERDURAS', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'FRUTAS', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'Cereales S/G', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'Cereales C/G', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'Leguminosas', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'AOA MBAG', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'AOA BAG', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'AOA MAG', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'AOA AAG', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'LECHE DES', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'LECHE SEMI', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'LECHE ENTERA', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'LECHE C/A', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'ACEITES S/P', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'ACEITES C/P', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'AZUCAR S/G', kcal:1, pro:1, lip:1, hco:1, value:0},
        {alimento:'AZUCAR C/G', kcal:1, pro:1, lip:1, hco:1, value:0},
      
    ]



    useEffect(()=>{

        init()
        // onGetEvolutionNotes()
        
        return () => {}
    },[])

    const init = async () => {
        setState({...state, loading:true})
        let _citas = await _Service('POST', `appointments/findByPatient/${id}`);
        console.log(_citas)
        let _patient = await _Service('GET', `patients/${id}`)
        state.patient = _patient.data
        console.log(_patient)
        let _plan_comidas = await _Service('GET',`patients/${id}/meal-plans`);
        let _notes = await _Service('GET', `patients/${id}/evolution-notes`);

        state.evolution_notes = _notes.data
        state.citas = _citas.data;
     
       



        // let _nutrional_plan = await _Service('GET', 'nutrional-plan');
        let _aliments = await _Service('GET', 'aliments');
        console.log(_aliments)

        
      
       state.patient.measures =  _patient.data.measures.sort((a:any,b:any) => b.id - a.id)
       state.patient.medicalHistory = _patient.data.medicalHistory.sort((a:any,b:any) => b.id - a.id)
       state.patient.lifeStyle = _patient.data.lifeStyle.sort((a:any,b:any) => b.id - a.id)
       state.patient.foodHistory = _patient.data.foodHistory.sort((a:any,b:any) => b.id - a.id)
       state.patient.r24s = _patient.data.r24s.sort((a:any,b:any) => b.id - a.id)

        switch (state.tab_active) {
            case 1:
                state.sessionSelected = state.patient.measures.length === 0 ? 'Nuevo' : state.patient.measures[0].id
                break;
            case 2:
                state.sessionSelected = state.patient.medicalHistory.length === 0 ? 'Nuevo' : state.patient.medicalHistory[0].id
                break;
            case 3:
                state.sessionSelected = state.patient.lifeStyle.length === 0 ? 'Nuevo' : state.patient.lifeStyle[0].id
                break;
            case 4:
                state.sessionSelected = state.patient.foodHistory.length === 0 ? 'Nuevo' : state.patient.foodHistory[0].id
                break;
            case 5:
                state.sessionSelected = state.patient.r24s.length === 0 ? 'Nuevo' : state.patient.r24s[0].id
                break;
            default:
                break;
        }
       

      
        


        _patient.data.medicalHistory.map((mitem:any)=>{
            if(mitem.customQuestions !== ""){
                mitem.customQuestions = JSON.parse(mitem.customQuestions)
            }else{
                mitem.customQuestions = questions
            }
        })
        _patient.data.lifeStyle.map((mitem:any)=>{
            if(mitem.customQuestions !== ""){
                mitem.customQuestions = JSON.parse(mitem.customQuestions)
            }else{
                mitem.customQuestions = []
            }
        })

        setState({...state, loading:false, aliments:_aliments.data, plan_comidas:_plan_comidas.data})
    }
    useEffect(()=>{
 
        calculate()

    },[state.recipes, state.day_active]) 

    const calculate = () => {
        if(state.recipes.length !== 0){
            
            let _actve_recipes:any = []

            let total_macro = {
                proteinas:0,
                lipidos:0,
                hidratos:0,
                energia:0
            }
            let total_kcal = 0;
       
            state.plan_comidas.map((item:any)=>{
 
                if(YYYYMMDD(item.day) === YYYYMMDD(state.day_active.toISOString())){
                  _actve_recipes = [item.breakfast, item.meal, item.dinner]
                }
            })
            _actve_recipes.map((recipe:any)=>{
                // console.log(item)
                // let recipe = state.recipes.find((i:any)=>i.id === item)
                // console.log(recipe)
                let _macrosmicros = MacrosMicros(recipe.ingredients)
                total_macro.proteinas = total_macro.proteinas + _macrosmicros.proteinas
                total_macro.lipidos = total_macro.lipidos + _macrosmicros.lipidos
                total_macro.hidratos = total_macro.hidratos + _macrosmicros.hidratos
                total_macro.energia = total_macro.energia + _macrosmicros.energia
                


               
               
            })
           
            // total_kcal = (total_macro.hidratos * 4) + (total_macro.proteinas * 4) + (total_macro.hidratos * 9)
                total_kcal = total_macro.energia
            if(total_kcal === 0){
                total_kcal = 1
            }
            let totales = {
                proteinas:{
                    gramos:(total_macro.proteinas).toFixed(2),
                    porcentaje:(((total_macro.proteinas * 4) / total_kcal ) * 100).toFixed(2)
                },
                hidratos:{
                    gramos:(total_macro.hidratos).toFixed(2),
                    porcentaje:(((total_macro.hidratos * 4) / total_kcal ) * 100).toFixed(2)
                },
              
                lipidos:{
                    gramos:(total_macro.lipidos).toFixed(2),
                    porcentaje:(((total_macro.lipidos * 9) / total_kcal ) * 100).toFixed(2)
                }
            }
            
            

            // console.log(totales)
            console.log(0/1)
            console.log("===================")


            setState({...state, active_recipes:_actve_recipes, total_kcal, totales})

          
        }
    }

    const MacrosMicros = (aliemnts:any) =>{

   
        let proteinas = 0;
        let lipidos = 0;
        let hidratos = 0;
        let vitaminaA = 0;
        let acidoAscorbico = 0;
        let acidoFolico = 0;
        let energia = 0
  
        for (let i = 0; i < aliemnts.length; i++) {
        
            let q : string = aliemnts[i].quantity as string;
  
       
            proteinas = proteinas + (aliemnts[i].aliment?.proteina * parseInt(q) )
            lipidos = lipidos + (aliemnts[i].aliment?.lipidos * parseInt(q)  )
            hidratos = hidratos + (aliemnts[i].aliment?.hidratosDeCarbono * parseInt(q)  )
            vitaminaA = vitaminaA + (aliemnts[i].aliment?.vitaminaA * parseInt(q)  )
            acidoAscorbico = acidoAscorbico + (aliemnts[i].aliment?.acidoAscorbico * parseInt(q)  )
            acidoFolico = acidoFolico + (aliemnts[i].aliment?.acidoFolico * parseInt(q)  )
            energia = energia + (aliemnts[i].aliment?.energia * parseInt(q)  )
        }

        return {
            proteinas,
            lipidos,
            hidratos,
            energia
        }

       
  
        // state._macronutrientes.proteinas = proteinas
        // state._macronutrientes.lipidos = lipidos
        // state._macronutrientes.hidratos = hidratos
  
        // state._micronutrientes.vitaminaA = vitaminaA
        // state._micronutrientes.acidoAscorbico = acidoAscorbico
        // state._micronutrientes.acidoFolico = acidoFolico
    }

    const r24_kcal_totales = () => {

        let _energy_desayuno = 0
        let _energy_colacion_desayuno = 0
        let _energy_comida = 0;
        let _energy_colacion_comida = 0;
        let _energy_cena = 0

        let verduras = 0;
        let frutas = 0;
        let cereales = 0;
        let grasas = 0;
        let leguminosas = 0;
        let AOA = 0;
        let leche = 0;
        let azucares = 0;

        let proteinas = 0;
        let hidratos = 0;
        let lipidos = 0;



 
        r24s(state.sessionSelected)?.items.map((item:any)=>{
            console.log(item)

            proteinas = proteinas + (item.portion * item.aliment.proteina)
            hidratos = hidratos + (item.portion * item.aliment.hidratosDeCarbono)
            lipidos = lipidos + (item.portion * item.aliment.lipidos)

            switch (item.aliment.grupo) {
                

                    case 'Verduras':
                        verduras = verduras + 1;
                        break;
    
                    case 'Frutas':
                        console.log("HERE")
                        frutas = frutas + 1;
                        break;

                    case 'Cereales':
                        cereales = cereales + 1;
                        break;
    
                    case 'Grasas':
                        grasas = grasas + 1;
                        break;
    
                    case 'Leguminosas':
                        leguminosas = leguminosas + 1;
                        break;
    
                    case 'AOA':
                        AOA = AOA + 1;
                        break;

                    case 'Leche':
                        leche = leche + 1;
                        break;

                    case 'Azúcares':
                        azucares = azucares + 1;
                        break;

                        
            
                default:
                    break;
            }

            switch (item.mealTime) {
                case 'Desayuno':
                    _energy_desayuno = _energy_desayuno + (item.aliment.energia * item.portion)
                    break;
                case 'Colacion_Desayuno':
                    _energy_colacion_desayuno = _energy_colacion_desayuno + (item.aliment.energia * item.portion)
                    break; 
                case 'Comida':
                    _energy_comida = _energy_comida + (item.aliment.energia * item.portion)
                    break; 
                case 'Colacion_Comida':
                    _energy_colacion_comida = _energy_colacion_comida + (item.aliment.energia * item.portion)
                    break; 
                case 'Cena':
                    _energy_cena = _energy_cena + (item.aliment.energia * item.portion)
                    break;  
            
                default:
                    break;
            }
        })
        let _energy_total = _energy_desayuno + _energy_colacion_desayuno + _energy_comida + _energy_colacion_comida + _energy_cena

        return {
            _energy_desayuno,
            _energy_colacion_desayuno,
            _energy_comida,
            _energy_colacion_comida,
            _energy_cena,
            proteinas,
            lipidos,
            hidratos,
            _energy_total,
            verduras,
            frutas,
            cereales,
            grasas,
            leguminosas,
            AOA,
            leche,
            azucares
        }
    }

    const sumameta = () => {

        let suma = {
                kcal:0,
                pro:0,
                lip:0,
                hco:0
        }

        let meta = {
            kcal:state.meta.kcal > 0 ? state.meta.kcal : 0,
            pro:state.metag.pro > 0 ? state.metag.pro : 0,
            lip:state.metag.lip > 0 ? state.metag.lip : 0,
            hco:state.metag.hco > 0 ? state.metag.hco : 0,
        }
          
        
        state.equivalencias.map((item:any)=>{
          
            if(item.value > 0){
                suma.kcal = suma.kcal + (item.value * item.kcal)
                suma.pro = suma.pro +  (item.value * item.pro)
                suma.lip = suma.lip +  (item.value * item.lip)
                suma.hco = suma.hco +  (item.value * item.hco)
            }
      
        })

        return {
            suma,
            meta,
            percentage:{
                kcal: meta.kcal > 0  ?  (suma.kcal / meta.kcal) * 100 : 0,
                pro: meta.pro > 0 ?  (suma.pro / meta.pro)* 100 :0 ,
                lip: meta.lip > 0 ? (suma.lip / meta.lip)* 100 : 0,
                hco: meta.hco > 0 ? (suma.hco / meta.hco) * 100 : 0
            }
        };
    }
    const resumenkcal = (comida:any) => {

        
        let total  = 0;
        let _resumen  = {
            kcal:0,
            pro:0,
            lip:0,
            hco:0
        }
        state.equivalencias.map((item:any)=>{
            if(item.distribucion[comida] > 0){
            _resumen.kcal = _resumen.kcal + (item.kcal * item.distribucion[comida])
            _resumen.pro = _resumen.pro + (item.pro * item.distribucion[comida])
            _resumen.lip = _resumen.lip + (item.lip * item.distribucion[comida])
            _resumen.hco = _resumen.hco + (item.hco * item.distribucion[comida])
            }
        })
        return _resumen;


    }


    useEffect(()=>{
        

        // switch (state.tab_active) {
        //     case 1:
        //         state.sessionSelected = state.patient.measures.length === 0 ? 'Nuevo' : state.patient.measures[0].id
        //         break;
        //     case 2:
        //         state.sessionSelected = state.patient.medicalHistory.length === 0 ? 'Nuevo' : state.patient.medicalHistory[0].id
        //         break;
        //     case 3:
        //         state.sessionSelected = state.patient.lifeStyle.length === 0 ? 'Nuevo' : state.patient.lifeStyle[0].id
        //         break;
        //     case 4:
        //         state.sessionSelected = state.patient.foodHistory.length === 0 ? 'Nuevo' : state.patient.foodHistory[0].id
        //         break;
        //     default:
        //         break;
        // }
        // setState({...state})

        return () => {}

    },[state.sessionSelected])

    const onSavePatient = async () => {
        setState({...state, loading:true})
        if(id){
            let body = {
                // ...state.patient
                firstName:state.patient.firstName,
                lastName:state.patient.lastName,
                email:state.patient.email,
                phone:state.patient.phone,
                ocupation:state.patient.ocupation,
                civilState:state.patient.civilState,
                emergencyContactName:state.patient.emergencyContactName,
                emergencyContactLastName:state.patient.emergencyContactLastName,
                emergencyContactPhone:state.patient.emergencyContactPhone,
                emergencyContactEmail:state.patient.emergencyContactEmail,
                emergencyContactBirthdate:state.patient.emergencyContactBirthdate,
                emergencyContactRelationship:state.patient.emergencyContactRelationship,
                objective:state.patient.objective,
                branchOffices: [],
                birthdate: state.patient.birthdate,
            }

            try {
                let _update = await _Service('PUT', `patients/${id}`, body)
                console.log(_update)

                if(_update.status === 200){
                    toast({title: 'Datos del Paciente', description: "Paciente actualizado con exito.", status: "success", position: "bottom-left",});
                }else{
                    toast({title: 'Datos del Paciente', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                }
                setState({...state, loading:false})
            } catch (error) {
                toast({title: 'Datos del Paciente', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                setState({...state, loading:false})
            }
          
        }else{



        }
    }
    const onSaveMeasures = async () => {
        console.log(state.sessionSelected)
        setState({...state, loading:true})
        

    
        if(state.sessionSelected !== 'Nuevo'){
            console.log("UPDATE")
            let body = {
                ...meaasure(state.sessionSelected)
            }
            try {
                let _update = await _Service('PUT', `patients/${id}/measures/${state.sessionSelected}`, body)
                console.log(_update)
    
                if(_update.status === 200){
                    toast({title: 'Medidas', description: "Medidas actualizado con exito.", status: "success", position: "bottom-left",});
                }else{
                    toast({title: 'Medidas', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                }
                setState({...state, loading:false})
            } catch (error) {
                toast({title: 'Medidas', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                setState({...state, loading:false})
            }
        }

    }
    const onSaveMedicalHistory = async () => {
       
        setState({...state, loading:true})
        let body = {
            obesity:[],
            ...medicalHistory(state.sessionSelected)
        }
        body.customQuestions = JSON.stringify(medicalHistory(state.sessionSelected).customQuestions)

       
        if(state.sessionSelected !== 'Nuevo'){
            try {
                let _update = await _Service('PUT', `patients/${id}/medical-history/${state.sessionSelected}`, body)
                console.log(_update)
    
                if(_update.status === 200){
                    toast({title: 'Historial Médico', description: "Historial Médico actualizado con exito.", status: "success", position: "bottom-left",});
                }else{
                    toast({title: 'Historial Médico', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                }
                setState({...state, loading:false})
            } catch (error) {
                toast({title: 'Historial Médico', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                setState({...state, loading:false})
            }
        }
    }
    const onSaveLifeStyle = async () => {
       
        setState({...state, loading:true})
        let body = {
            ...lifeStyle(state.sessionSelected)
        }
        body.customQuestions = JSON.stringify(lifeStyle(state.sessionSelected).customQuestions)

   
       
        if(state.sessionSelected !== 'Nuevo'){
            try {
                let _update = await _Service('PUT', `patients/${id}/life-style/${state.sessionSelected}`, body)
                console.log(_update)
    
                if(_update.status === 200){
                    toast({title: 'Estilo de Vida', description: "Estilo de Vida actualizado con exito.", status: "success", position: "bottom-left",});
                }else{
                    toast({title: 'Estilo de Vida', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                }
                setState({...state, loading:false})
            } catch (error) {
                toast({title: 'Estilo de Vida', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                setState({...state, loading:false})
            }
        }
    }
    const onSaveFoodHistory = async () => {
       
        setState({...state, loading:true})
        let body = {
        
            ...foodHistory(state.sessionSelected)
        }
   
       
        if(state.sessionSelected !== 'Nuevo'){
            try {
                let _update = await _Service('PUT', `patients/${id}/food-history/${state.sessionSelected}`, body)
                console.log(_update)
    
                if(_update.status === 200){
                    toast({title: 'Historia Alimentaria', description: "Historia Alimentaria actualizado con exito.", status: "success", position: "bottom-left",});
                }else{
                    toast({title: 'Historia Alimentaria', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                }
                setState({...state, loading:false})
            } catch (error) {
                toast({title: 'Historia Alimentaria', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                setState({...state, loading:false})
            }
        }
    }

    const onSaveR24 = async () => {
        setState({...state, loading:true})
        let items = r24s(state.sessionSelected)?.items
        for (let i = 0; i < items.length; i++) {
            
            if(items[i].id === undefined){
                console.log('SUBIR')
                let body = {
                    mealTime:items[i].mealTime,
                    alimentId:items[i].aliment.id,
                    portion: parseInt(items[i].portion)

                }
                let _add_item = await _Service('POST', `patients/${id}/r24/${state.sessionSelected}/items`, body)
                
            }else{
                console.log('NO SUBIR')
            }
            
        }

        toast({title: 'R24', description: "R24 actualizado con exito.", status: "success", position: "bottom-left",});
        init()
        // setState({...state, loading:false})



    }
    const onCreateMeasures = async () => {
        setState({...state, loading:true})

            let body = {
                gender: "",
                weight: 0,
                height: 0,
                hip: 0,
                abdomen: 0,
                waist: 0,
                bloodPressure: "",
                glucose: 0,
                averageOfFat: 0,
                averageOfMuscle: 0,
                averageOfWater: 0,
                visceralFat: 0,
                targetWeight: 0,
                targetWaist: 0,
                targetAverageOfMuscle: 0,
                targetAverageOfFat: 0
            }
          
            try {
                let _create = await _Service('POST', `patients/${id}/measures`, body)
                console.log(_create)
    
                if(_create.status === 201){
                    toast({title: 'Medidas', description: "Medidas actualizado con exito.", status: "success", position: "bottom-left",});
                    init()
                }else{
                    toast({title: 'Medidas', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                }
                setState({...state, loading:false})
            } catch (error) {
                toast({title: 'Medidas', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
                setState({...state, loading:false})
            }
        

    }
    const onCreateMedicalHistory = async () => {
        setState({...state, loading:true})
        let body = {
                medicalDiagnoses: "test",
                consumeSupplements: "test",
                obesity: [],
                diabetes: [],
                cardiovascular: [],
                dyslipidemias: [ ],
                endometriosis: [ ],
                cancer: [],
                typeOfCancer: "",
                endocrinopathies: [],
                typeOfEndocrinopathies: "",
                neck: "",
                hair: "",
                nails: "",
                edema: false,
                edemaType:0,
                eyes: "",
                tongue: "",
                skin: "",
                teeth: "",
                lips: "",
                menarche: "2024-01-01",
                isRegular: false,
                lastMenstrualCycle: "2024-01-01",
                durationOfTheMenstrualCycle:  0,
                durationOfMenstruation: 0,
                dysmenorrhea: false,
                migrainesDuringMenstruation: false,
                menorrhagia: false,
                humorChanges: false,
                clotsDuringMenstruation: false,
                cravingsForSweetThings: false,
                painWhenOvulating: false,
                sensitiveBreasts: false,
                endometriosisSymptoms: false,
                sop: false,
                thyroid: false,
                digestives: false,
                customQuestions: ""
            }
    

       
     
        try {
            let _create = await _Service('POST', `patients/${id}/medical-history`, body)
            console.log(_create)

            if(_create.status === 201){
                toast({title: 'Historial Médico', description: "Historial Médico actualizado con exito.", status: "success", position: "bottom-left",});
                init()
            }else{
                toast({title: 'Historial Médico', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            }
            setState({...state, loading:false})
        } catch (error) {
            toast({title: 'Historial Médico', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            setState({...state, loading:false})
        }
   
        

    }
    const onCreateLifeStyle = async () => {
       
        setState({...state, loading:true})
        let body = {
            wakeUpTime: "00:00",
            sleepTime: "00:00",
            physicalActivity: "",
            frequencyAndDuration: "",
            dream: "",
            doYouWakeUpRested: "",
            stresslevel: 0,
            whatTriggersIt: "",
            alcohol: "",
            tobacco: "",
            coffee: "",
            soda: "",
            customQuestions:""

        }
   
       
    
        try {
            let _update = await _Service('POST', `patients/${id}/life-style`, body)
            console.log(_update)

            if(_update.status === 201){
                toast({title: 'Estilo de Vida', description: "Estilo de Vida actualizado con exito.", status: "success", position: "bottom-left",});
                init()
            }else{
                toast({title: 'Estilo de Vida', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            }
            setState({...state, loading:false})
        } catch (error) {
            toast({title: 'Estilo de Vida', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            setState({...state, loading:false})
        }
      
    }
    const onCreateFoodHistory = async () => {
       
        setState({...state, loading:true})
        let body = {
            foodsYouDontLike: [],
            foodThatCausesAllergies: [],
            whatConsumesTheMost: [],
            whatConsumesTheLeast: [],
            waterConsumption: 0,
            placeOfConsumption: ""
        }
   
       
      
        try {
            let _create = await _Service('POST', `patients/${id}/food-history`, body)
            console.log(_create)

            if(_create.status === 201){
                toast({title: 'Historia Alimentaria', description: "Historia Alimentaria actualizado con exito.", status: "success", position: "bottom-left",});
                init();
            }else{
                toast({title: 'Historia Alimentaria', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            }
            setState({...state, loading:false})
        } catch (error) {
            toast({title: 'Historia Alimentaria', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            setState({...state, loading:false})
        }
    
    }
    const onCreateR24 = async () => {

        setState({...state, loading:true})
 
       
      
        try {
            let _create = await _Service('POST', `patients/${id}/r24`)
            console.log(_create)

            if(_create.status === 201){
                toast({title: 'R24', description: "R24 actualizado con exito.", status: "success", position: "bottom-left",});
                init();
            }else{
                toast({title: 'R24', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            }
            setState({...state, loading:false})
        } catch (error) {
            toast({title: 'R24', description: "No ha sido posible acualizar este paciente.", status: "error", position: "bottom-left",});
            setState({...state, loading:false})
        }

    }
    





    const meaasure = (session:any) => { 
     
        let _meaasure = state.patient?.measures.find((item:any) => item.id == parseInt(session))
        return _meaasure
    }
    const medicalHistory = (session:any) => { 
        
        let _medicalHistory = state.patient?.medicalHistory.find((item:any) => item.id == parseInt(session))
    
        return _medicalHistory
    }
    const foodHistory = (session:any) => { 
        let _foodHistory = state.patient?.foodHistory.find((item:any) => item.id == parseInt(session))
        return _foodHistory
    }
    const lifeStyle = (session:any) => { 
        let _lifeStyle = state.patient?.lifeStyle.find((item:any) => item.id == parseInt(session))
        return _lifeStyle
    }
    const r24s = (session:any) => { 
     
        let _r24s = state.patient?.r24s.find((item:any) => item.id == parseInt(session))
        
        return _r24s
    }

  
    const onTabChange = (tab:any) => {
        setState({...state, tab_active:tab})
        switch (tab) {
            case 1:
                if(state.patient.measures.length === 0){
                    state.tab_active = tab;
                    onCreateMeasures()
                  
                    return;
                }
                state.sessionSelected = state.patient.measures.length === 0 ? 'Nuevo' : state.patient.measures[0].id
                setState({...state, tab_active:tab})
                break;
            case 2:
                if(state.patient.medicalHistory.length === 0){
                    state.tab_active = tab;
                    onCreateMedicalHistory()
                  
                    return;
                }
                state.sessionSelected = state.patient.medicalHistory.length === 0 ? 'Nuevo' : state.patient.medicalHistory[0].id
                setState({...state, tab_active:tab})
                break;
            case 3:
                if(state.patient.lifeStyle.length === 0){
                    state.tab_active = tab;
                    onCreateLifeStyle()
                   
                    return;
                }
                state.sessionSelected = state.patient.lifeStyle.length === 0 ? 'Nuevo' : state.patient.lifeStyle[0].id
                setState({...state, tab_active:tab})
                break;
            case 4:
                if(state.patient.foodHistory.length === 0){
                    state.tab_active = tab;
                    onCreateFoodHistory()
                    setState({...state, tab_active:tab})
                    return;
                }
                state.sessionSelected = state.patient.foodHistory.length === 0 ? 'Nuevo' : state.patient.foodHistory[0].id
                setState({...state, tab_active:tab})
                break;
            case 5:
                if(state.patient.r24s.length === 0){
                    state.tab_active = tab;
                    onCreateR24()
                    setState({...state, tab_active:tab})
                    return;
                }
                state.sessionSelected = state.patient.r24s.length === 0 ? 'Nuevo' : state.patient.r24s[0].id
                setState({...state, tab_active:tab})
                break;

            case 6:
                state.sessionSelected = state.nutrional_plans.length === 0 ? 'Nuevo' : state.nutrional_plans[0].id
                setState({...state, tab_active:tab})
                break;
        
            default:
                setState({...state, tab_active:tab})
                break;
        }
        
        

    }

    const IMC = () => {

        let ranges = [

        ]

      

        let _h = parseInt(meaasure(state.sessionSelected)?.height)
        if(_h === 0){
            return {
                imc:0,
                color:'gray',
                indicador:'',
                rango:'',
                per:'0'
            }
        }
        
        let _imc = (parseInt(meaasure(state.sessionSelected)?.weight) / ((_h / 100) * (_h/100)) )
        let color = ""
        let indicador = ""
        let rango = ''
        let per = ''

        if(_imc < 18.5){
            color = '#A7DDD5'
            indicador = "Bajo de Peso"
            rango = '<18.6'
            per = '20'
         
        }
        if(_imc >= 18.5 && _imc < 25){
            color = '#96E58D'
            indicador = "Peso Normal"
            rango = '18.5 - 24.9'
            per = '35'
        }
        if(_imc >= 25 && _imc < 30){
            color = '#F0E753'
            indicador = 'Sobrepeso'
            rango = '15 - 29.9'
            per = '50'
        }
        if(_imc >= 30 && _imc < 35){
            color = '#F5CB63'
            indicador = 'Obesidad tipo 1'
            rango = '30 - 34.9'
            per = '65'
        }
        if(_imc >= 35 && _imc < 40){
            color = '#FCB70C'
            indicador = 'Obesidad tipo 2'
            rango = '35 - 39.9'
            per = '85'
        }
        if(_imc >= 40){
            color = '#FF6106'
            indicador = 'Obesidad tipo 3'
            rango = '>39.9'
            per = '100'
        }

    
 
        
        return {
            imc:_imc,
            color,
            indicador,
            rango,
            per

        }
    }
    const onGetEvolutionNotes = async () => {
        let _notes = await _Service('GET', `patients/${id}/evolution-notes`);
 
        state.evolution_notes = _notes.data
        
    }
    const onSaveEvolutionNote = async () => {
        setState({...state, loading:true})
         let body = {
            prescribedKcal:parseInt(state.new_note.prescribedKcal),
            consultationNotes:state.new_note.consultationNotes,
            appraisalNotes:state.new_note.appraisalNotes,
            evolutionNotes:state.new_note.evolutionNotes,
            prescription:state.new_note.prescription,
            messages:state.new_note.messages
         }
         let _note;
         if(state.editing_note){
             _note = await _Service('PUT', `patients/${id}/evolution-notes/${state.new_note?.id}`, body);
            console.log(_note)
         }else{
             _note = await _Service('POST', `patients/${id}/evolution-notes`, body);
            console.log(_note)
         }

     

        if(_note.status === 201 || _note.status === 200){
            toast({title: 'Historia Alimentaria', description: "Nueva Nota de evolucion agregada.", status: "success", position: "bottom-left",});
            state.editing_note = false;
            state.new_note = {
                id:0,
                prescribedKcal:"",
                consultationNotes:"",
                appraisalNotes:"",
                evolutionNotes:"",
                prescription:"",
                messages:[] as any,
             }
             state.show_new_note = false
             init()
        
        }else{
            toast({title: 'Historia Alimentaria', description: "No ha sido posible añadir una nueva nota de evolucíon.", status: "error", position: "bottom-left",});
            setState({...state, loading:false, show_new_note:false})
        }
        
        
    }
    const recipes = () => {
        _recipes_.map((recipe:any)=>{
            if(recipe.mealTime?.includes('BREAKFAST')){
                console.log(recipe.name)
            }
        })
        return <></>
    }
    const addDays = (days:number, date:any) => {

        date.setDate(date.getDate() + days);
  
        return date;
    }
    var weekday=new Array(7);
    weekday[0]="Domingo";
    weekday[1]="Lunes";
    weekday[2]="Martes";
    weekday[3]="Miercoles";
    weekday[4]="Jueves";
    weekday[5]="Viernes";
    weekday[6]="Sabado";

    var months=new Array(7);
    months[0]="Enero";
    months[1]="Febrero";
    months[2]="Marzo";
    months[3]="Abril";
    months[4]="Mayo";
    months[5]="Junio";
    months[6]="Julio";
    months[7]="Agosto";
    months[8]="Septiembre";
    months[9]="Octubre";
    months[10]="Noviembre";
    months[11]="Diciembre";
    // const customQuestions = () => {
    //     let _questions = medicalHistory(state.sessionSelected)?.customQuestions
    //     if(_questions !== undefined && _questions !== "" && _questions !== null){
          
    //         JSON.parse(_questions)
    //         // medicalHistory(state.sessionSelected).customQuestions = JSON.parse(_questions);
    //     }else{
           
    //         _questions = questions;
    //     }
 

    //     // if(_questions === "" || _questions === null){
    //     //     medicalHistory(state.sessionSelected).customQuestions = questions;
    //     // }else{
    //     //     // console.log(_questions)
    //     //     medicalHistory(state.sessionSelected).customQuestions = JSON.parse(_questions);
    //     // }
        
    //     return _questions
    // }

   
  

  return (
    <div className='container-fluid container-xl mx-auto  p-0'>
        <Renderif isTrue={state.loading}>
            <LoadingOverlay></LoadingOverlay>
        </Renderif>

        <div className="d-flex align-items-center cursor-pointer mb-2" onClick={()=>{
            navigate(-1)
        }}>
         <div className="md-icon md-icon--mid">
            <MdArrowBack color="#2CAD6E"></MdArrowBack>
            </div>
            <div className="text-green bold">Pacientes</div>
        </div>

        <div className='patient_tabs d-flex pb-3'>

            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 0 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(0)}}>Datos del paciente</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 1 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(1)}}>Medidas</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 2 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(2)}}>Historial Clinico</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 3 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(3)}}>Estilo de Vida</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 4 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(4)}}>Historia Alimentaria</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 5 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(5)}}>R24 hrs.</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 6 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(6)}}>Plan Alimenticio</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 7 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(7)}}>Notas Evolución</div>
            <div className={`f-16  tab cursor-pointer me-2 ${state.tab_active === 8 ? ' text-green tab--active':'text-gray'}`} onClick={()=>{onTabChange(8)}}>Citas</div>

        </div>

        <Renderif isTrue={state.tab_active === 0}>
            <div className='container-fluid p-0 bg-light p-3 r-2'>

                <div className='d-flex flex-wrap'>
                    

                    
                   
                 

                    <div className='col '>

                        <div className='d-flex'>
                        <div className=''>
                            <div className="md-icon md-icon--big">
                                <FaRegUserCircle color="2CAD6E" size={50}></FaRegUserCircle>
                            </div>
                        </div>
                            
                            <div className='col'></div>
                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
                                    onSavePatient()
                                }}>
                                    <div>Guardar</div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap'>
                            <div className='col pe-2'>

                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Nombre</div>
                                    <input value={state.patient?.firstName} onChange={(evt)=>{
                                        state.patient.firstName = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Correo electrónico</div>
                                    <input value={state.patient?.email} onChange={(evt)=>{
                                        state.patient.email = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                                <div className='mt-2'>
                                   
                                  
                                    <Select label="Estado civil" value={state.patient?.civilState} onChange={(evt)=>{
                                            state.patient.civilState = evt.currentTarget.value;
                                            setState({...state})
                                        }}>
                                        <option value="">Seleccionar</option>
                                        <option value="Solter@">Solter@</option>
                                        <option value="Casad@">Casad@</option>
                                        <option value="Viud@">Viud@</option>
                                        <option value="Union libre">Union libre</option>
                                        <option value="N/D">N/D</option>
                                    </Select>

                                    
                                </div>

                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Apellido</div>
                                    <input value={state.patient?.lastName} onChange={(evt)=>{
                                        state.patient.lastName = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Télefono</div>
                                    <input value={state.patient?.phone} onChange={(evt)=>{
                                        state.patient.phone = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Ocupacion (Opcional)</div>
                                    <input value={state.patient?.ocupation} onChange={(evt)=>{
                                        state.patient.ocupation = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Fecha de nacimiento</div>
                                    <input value={state.patient?.birthdate.split('T')[0]} className='chakra-input css-1omz1ot' type={'date'} onChange={(evt)=>{
                                         state.patient.birthdate = evt.currentTarget.value;
                                         setState({...state})
                                    }} placeholder=""></input>
                                </div>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Asignar especialista</div>
                                    <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Asignar sucursal</div>
                                    <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Contacto de emergencia:</div>
                        </div>

                        <div className='d-flex flex-wrap'>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Nombre</div>
                                    <input value={state.patient?.emergencyContactName} onChange={(evt)=>{
                                        state.patient.emergencyContactName = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Correo electrónico</div>
                                    <input value={state.patient?.emergencyContactEmail} onChange={(evt)=>{
                                        state.patient.emergencyContactEmail = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div> 
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Apellidos</div>
                                    <input value={state.patient?.emergencyContactLastName} onChange={(evt)=>{
                                        state.patient.emergencyContactLastName = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>  
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Fecha de naciemiento</div>
                                    <input value={state.patient?.emergencyContactBirthdate} onChange={(evt)=>{
                                        state.patient.emergencyContactBirthdate = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'date'} placeholder=""></input>
                                </div>    
                            </div> 
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Teléfono</div>
                                    <input value={state.patient?.emergencyContactPhone} onChange={(evt)=>{
                                        state.patient.emergencyContactPhone = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div> 
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Parentesco (Opcional)</div>
                                    <input value={state.patient?.emergencyContactRelationship} onChange={(evt)=>{
                                        state.patient.emergencyContactRelationship = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div> 

                        </div>

                        <div className='d-flex flex-wrap align-items-start mt-4'>

                            <div className='col-6 d-flex align-items-end'>

                                {/* <div className='col pe-2'>

                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Nombre del familiar.</div>
                                        <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                    </div>

                                

                                </div> */}
                                {/* <div className='col pe-2'>

                                
                                    
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Fecha de nacimieno.</div>
                                        <input className='chakra-input css-1omz1ot' type={'date'} placeholder=""></input>
                                    </div>

                                </div> */}

                                {/* <div className='d-flex justify-content-center align-items-center'>
                                    <div className='bv-icon bv-icon--md cursor-pointer'>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>
                    
                                </div> */}
                            </div>

                            <div className='col-12 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Objetivo de consulta</div>
                                    <textarea  value={state.patient?.objective} onChange={(evt)=>{
                                        state.patient.objective = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input border r-1 p-2' rows={5} cols={5} placeholder=""></textarea>
                                </div>  
                            </div>
                        </div>

                    </div>

                </div>
                
               
               


            
            </div>
        </Renderif>

        <Renderif isTrue={state.tab_active === 1}>
             <div className='container-fluid p-0 bg-light p-3 r-2'>

                <div className='d-flex flex-wrap'>

                    <div className='px-3'>
                        <div className="md-icon md-icon--big">
                            <FaRegUserCircle color="2CAD6E" size={50}></FaRegUserCircle>
                        </div>
                    </div>

                    <div className='col'>

                        
                        <div className='d-flex '>
                            <div className=''>
                                <div>
                                    <span className='f-14 bold'>Fecha de registro:</span>
                                    <span className='f-14 text-gray mx-2'>{meaasure(state.sessionSelected)?.createdAt?.split('T')[0]}</span>
                                </div>
                                <div className='mt-0 d-flex align-items-end w-100'>
                                
                                    <div className='w-100'>
                                    
                                    <Select
                                        error={""}
                                        label="Sesión"
                                        value={state.sessionSelected}
                                        onChange={(evt)=>{ 
                                            state.sessionSelected = evt.currentTarget.value
                                            setState({...state})
                                        }}
                                    
                                    >
                                    
                                    {state.patient?.measures.map((item:any, i:number)=>{
                                  
                                        return (<option key={item.id} value={item.id}>Sesión {state.patient?.measures.length - i}</option>)
                                    })}
                                    {/* <option  value="Nuevo">Nuevo</option> */}

                                    </Select>
                                    </div>
                                    <div className='bv-icon bv-icon--md cursor-pointer pt-1' onClick={()=>{
                                         onCreateMeasures()
                                    }}>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{
                                    onSaveMeasures()
                                }}>
                                    <div>Guardar</div>
                                </div>
                            </div>
                        </div>


                    <div className='d-flex mt-3'>

                        

                        <div className='col '>

                        

                            <div className='d-flex justify-content-center'>

                                <div className="pie no-round" style={{"--p":`${IMC().per}`,"--c":`${IMC().color}`} as any}>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <div className='f-20' style={{color:IMC().color}}>{IMC().indicador}</div>
                                        <div className='f-16 text-gray'>{IMC().rango}</div>
                                        <div className='f-32' style={{color:IMC().color}}>{IMC().imc.toFixed(2)}</div>
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex flex-wrap mt-3'>
                                <div className='f-16 bold text-green'>IMC:</div>
                            </div>

                            <div className='d-flex flex-wrap'>
                                <div className='col pe-2'>
                                    
                                    <div className='mt-2'>
                              
                                    
                                        <Select label="Genero" value={meaasure(state.sessionSelected)?.gender}
                                            onChange={(evt)=>{
                                                
                                                meaasure(state.sessionSelected).gender = evt.currentTarget.value;
                                                setState({...state})
                                            }} >
                                            <option  value="">Seleccionar</option>
                                            <option  value="FEMALE">Mujer</option>
                                            <option  value="MALE">Hombre</option>
                                            <option  value="OTRO">Otro</option>
                                        </Select>

                                    </div>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Circunferencia de cadera (cm)</div>
                                        <input value={meaasure(state.sessionSelected)?.hip} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).hip = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 60"></input>
                                    </div>
                                

                                </div>
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Peso (kg)</div>
                                        <input value={meaasure(state.sessionSelected)?.weight} onChange={(evt)=>{
                                            
                                             meaasure(state.sessionSelected).weight = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }}  className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej 50"></input>
                                    </div>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Circunferencia de abdomen (cm)</div>
                                        <input value={meaasure(state.sessionSelected)?.abdomen} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).abdomen = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 50"></input>
                                    </div>
                                    
                                </div>
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Altura (cm)</div>
                                        <input  value={meaasure(state.sessionSelected)?.height} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).height = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 170"></input>
                                    </div>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Circunferencia de cintura (cm)</div>
                                        <input value={meaasure(state.sessionSelected)?.waist} onChange={(evt)=>{
                                   
                                             meaasure(state.sessionSelected).waist = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                         
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 60"></input>
                                    </div>
                                
                                </div>
                            </div>

                            <div className='d-flex flex-wrap mt-3'>
                                <div className='f-16 bold text-green'>Obejetivos del paciente:</div>
                            </div>

                        

                            <div className='d-flex flex-wrap'>
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Peso (kg)</div>
                                        <input value={meaasure(state.sessionSelected)?.targetWeight} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).targetWeight = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 50"></input>
                                    </div>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>% de grasa</div>
                                        <input value={meaasure(state.sessionSelected)?.targetAverageOfFat} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).targetAverageOfFat = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                                    </div>
                                
                                </div> 
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Circunferencia de cintura (cm)</div>
                                        <input value={meaasure(state.sessionSelected)?.targetWaist} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).targetWaist = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 60"></input>
                                    </div>  
                                
                                </div> 
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>% de musculo</div>
                                        <input value={meaasure(state.sessionSelected)?.targetAverageOfMuscle} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).targetAverageOfMuscle = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                                    </div> 
                                
                                </div> 

                            </div>

                        

                        </div>

                        <div className='col-4'>

                            <div className='d-flex flex-wrap'>
                                <div className='col me-2'>

                                    <div className=' shadow-sm bg-white r-1 p-3'>
                                        <div className='d-flex  align-items-center'>

                                            <div className='bv-icon bv-icon--md'>
                                                <FaHeartbeat size={26} color='red'></FaHeartbeat>

                                            </div>
                                            <div className='text-gray line-0'>Presión arterial</div>


                                        </div>
                                        <div className='d-flex flex-column px-3 mt-3'>
                                            <div><span className='f-22 text-dark bold'>{meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]}</span> <span className='f-12 text-gray'>/ {meaasure(state.sessionSelected)?.bloodPressure.split('/')[1]} mmhg</span></div>
                                            
                                            <Renderif isTrue={parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) <= 120 && parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[1]) <= 80 }>
                                                <div className='tag tag--skyblue'>Normal</div>
                                            </Renderif>

                                            <Renderif isTrue={(parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) > 120 && parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) <= 129) && parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[1]) < 80  }>
                                                <div className='tag tag--yellow'>Elevado</div>
                                            </Renderif>

                                            <Renderif isTrue={(parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) >= 130 && parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) <= 139) }>
                                                <div className='tag tag--orange'>Alto 1</div>
                                            </Renderif>

                                            <Renderif isTrue={parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) >= 140 && parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) < 180 }>
                                                <div className='tag tag--danger'>Alto 2</div>
                                            </Renderif>
                                            <Renderif isTrue={(parseInt(meaasure(state.sessionSelected)?.bloodPressure.split('/')[0]) >= 180) }>
                                                <div className='tag tag--danger'>Alto 3</div>
                                            </Renderif>


                                       
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Presión Arteriala</div>
                                        <input  value={meaasure(state.sessionSelected)?.bloodPressure} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).bloodPressure = evt.currentTarget.value
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type='text' placeholder="Ej. 120/80"></input>
                                    </div> 
                                    
                                </div>
                                <div className='col me-2'>

                                    <div className=' shadow-sm bg-white r-1 p-3'>
                                        <div className='d-flex  align-items-center'>

                                            <div className='bv-icon bv-icon--md'>
                                                <FaHeartbeat size={26} color='orange'></FaHeartbeat>

                                            </div>
                                            <div className='text-gray line-0'>Glucosa</div>


                                        </div>
                                        <div className='d-flex flex-column px-3 mt-3'>
                                            <div><span className='f-22 text-dark bold'>{meaasure(state.sessionSelected)?.glucose}</span> <span className='f-12 text-gray'> mg / dL</span></div>
                                            <Renderif isTrue={meaasure(state.sessionSelected)?.glucose < 70}>
                                                <div className='tag tag--danger'>hipoglucemia</div>
                                            </Renderif>
                                            <Renderif isTrue={meaasure(state.sessionSelected)?.glucose >= 70 && meaasure(state.sessionSelected)?.glucose <= 90}>
                                                <div className='tag tag--skyblue'>Normal</div>
                                            </Renderif>
                                            <Renderif isTrue={meaasure(state.sessionSelected)?.glucose >= 90 && meaasure(state.sessionSelected)?.glucose <= 120}>
                                                <div className='tag tag--orange'>Precaución</div>
                                            </Renderif>
                                            <Renderif isTrue={meaasure(state.sessionSelected)?.glucose > 120}>
                                                <div className='tag tag--danger'>Elevado</div>
                                            </Renderif>
                                           

                                        </div>
                                    </div>

                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Glucusa</div>
                                        <input value={meaasure(state.sessionSelected)?.glucose} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).glucose = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 80"></input>
                                    </div> 

                                </div>
                            </div>

                            <div className='d-flex flex-wrap mt-4 pt-1'>
                                <div className='f-16 bold text-green'>Bascula:</div>
                            </div>

                            <div className='d-flex flex-wrap'>
                                    <div className='col pe-2'>

                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>% de Grasa</div>
                                            <input value={meaasure(state.sessionSelected)?.averageOfFat} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).averageOfFat = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej- 50"></input>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>% de Agua</div>
                                            <input value={meaasure(state.sessionSelected)?.averageOfWater} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).averageOfWater = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej 50"></input>
                                        </div>
                                    

                                    </div>
                                    <div className='col pe-2'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>% de Musculo</div>
                                            <input value={meaasure(state.sessionSelected)?.averageOfMuscle} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).averageOfMuscle = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 50"></input>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>Grasa viceral</div>
                                            <input value={meaasure(state.sessionSelected)?.visceralFat} onChange={(evt)=>{
                                             meaasure(state.sessionSelected).visceralFat = parseInt(evt.currentTarget.value);
                                             setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 30"></input>
                                        </div>
                                        
                                    </div>
                                    
                            </div>
                        </div>

                    </div>

                    </div>

                </div>
                
               
               


            
            </div>
        </Renderif>

        <Renderif isTrue={state.tab_active === 2}>
             <div className='container-fluid p-0 bg-light p-3 r-2'>

                <div className='d-flex flex-wrap'>

                    <div className='px-3'>

                        <div className="md-icon md-icon--big">
                            <FaRegUserCircle color="2CAD6E" size={50}></FaRegUserCircle>
                        </div>
                    </div>

                    <div className='col '>

                        <div className='d-flex'>
                            <div>
                                <div>
                                    <span className='f-14 bold'>Fecha de registro:</span>
                                    <span className='f-14 text-gray mx-2'>{medicalHistory(state.sessionSelected)?.createdAt?.split('T')[0]}</span>
                                </div>
                                <div className='mt-2 d-flex align-items-end'>
                                 
                                    <div className='w-100'>
                                    <Select
                                        error={""}
                                        label="Sesión"
                                        value={state.sessionSelected}
                                        onChange={(evt)=>{ 
                                        
                                            state.sessionSelected = evt.currentTarget.value
                                            setState({...state})
                                        }}
                                    
                                    >
                                    
                                    {state.patient?.medicalHistory.map((item:any, i:number)=>{
                                  
                                        return (<option key={item.id} value={item.id}>Sesión {state.patient.medicalHistory.length - i}</option>)
                                    })}
                               
                                
                                                    
                            

                                    </Select>
                                    </div>
                                    <div className='bv-icon bv-icon--md cursor-pointer pt-1' onClick={()=>{
                                         onCreateMedicalHistory()
                                    }}>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
                                    onSaveMedicalHistory()
                                }}>
                                    <div>Guardar</div>
                                </div>
                            </div>
                        </div>

                       

                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Antecedentes patológicos generales:</div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Diagnósticos médicos</div>
                                    <input value={medicalHistory(state.sessionSelected)?.medicalDiagnoses} onChange={(evt)=>{
                                        
                                         medicalHistory(state.sessionSelected).medicalDiagnoses = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Consume Suplementos, Medicamentos, etc.</div>
                                    <input value={medicalHistory(state.sessionSelected)?.consumeSupplements} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).consumeSupplements = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                        </div>


                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Antecedentes familiares (Heredados)</div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='col pe-2'>
                                <div className='mt-2 position-relative'>
                                    
                                    <MultiSelect text="Obesidad" value={medicalHistory(state.sessionSelected)?.obesity} onChange={(value:any)=>{
                                          medicalHistory(state.sessionSelected).obesity = value
                                          setState({...state})
                                    }}></MultiSelect>
                                    
                                </div>
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2 position-relative'>
                               
                                    <MultiSelect text="Diabetes Mellitus" value={medicalHistory(state.sessionSelected)?.diabetes} onChange={(value:any)=>{
                                          medicalHistory(state.sessionSelected).diabetes = value
                                          setState({...state})
                                    }}></MultiSelect>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2 position-relative'>
                              
                                    <MultiSelect text="Enfermedades Cardiovasculares" value={medicalHistory(state.sessionSelected)?.cardiovascular} onChange={(value:any)=>{
                                          medicalHistory(state.sessionSelected).cardiovascular = value
                                          setState({...state})
                                    }}></MultiSelect>

                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2 position-relative'>
                                   
                                    <MultiSelect text="Dislipidemias" value={medicalHistory(state.sessionSelected)?.dyslipidemias} onChange={(value:any)=>{
                                          medicalHistory(state.sessionSelected).dyslipidemias = value
                                          setState({...state})
                                    }}></MultiSelect>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2 position-relative'>
                                 
                                    <MultiSelect text="Endometriosis" value={medicalHistory(state.sessionSelected)?.endometriosis} onChange={(value:any)=>{
                                          medicalHistory(state.sessionSelected).endometriosis = value
                                          setState({...state})
                                    }}></MultiSelect>
                                </div>   
                            </div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='col-4 pe-2'>
                                <div className='mt-2 position-relative'>
                                  
                                    <MultiSelect text="Cáncer" value={medicalHistory(state.sessionSelected)?.cancer} onChange={(value:any)=>{
                                          medicalHistory(state.sessionSelected).cancer = value
                                          setState({...state})
                                    }}></MultiSelect>
                                </div>
                            </div>
                            <div className='col-4 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Tipo de Cáncer</div>
                                    <input value={medicalHistory(state.sessionSelected)?.typeOfCancer} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).typeOfCancer = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                      
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='col-4 pe-2'>
                                <div className='mt-2 position-relative'>
                                  
                                    <MultiSelect text="Endocrinopatías" value={medicalHistory(state.sessionSelected)?.endocrinopathies} onChange={(value:any)=>{
                                          medicalHistory(state.sessionSelected).endocrinopathies = value
                                          setState({...state})
                                    }}></MultiSelect>

                                </div>
                            </div>
                            <div className='col-4 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Describe el típo</div>
                                    <input value={medicalHistory(state.sessionSelected)?.typeOfEndocrinopathies} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).typeOfEndocrinopathies = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                    
                                </div>   
                            </div>
                      
                        </div>
                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Exploración física</div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Cuello</div>
                                    <input  value={medicalHistory(state.sessionSelected)?.neck} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).neck = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Cabello</div>
                                    <input  value={medicalHistory(state.sessionSelected)?.hair} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).hair = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Uñas</div>
                                    <input value={medicalHistory(state.sessionSelected)?.nails} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).nails = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    {/* <div className='f-14 text-gray'>Edema</div>
                                    <input  className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input> */}
                                   
                                    <Select
                                        error={""}
                                        label="Edema"
                                        value={medicalHistory(state.sessionSelected)?.edema.toString()}
                                        onChange={(evt)=>{ 
                                            

                                            medicalHistory(state.sessionSelected).edema = evt.currentTarget.value === 'true' ? true : false
                                            setState({...state})
                                        }}
                                    
                                    >
                                    
                                  
                                    <option  value="">Seleccionar</option>
                                    <option  value="true">SI</option>
                                    <option  value="false">NO</option>
                                
                                                    
                            

                                    </Select>
                                </div>   
                            </div>
                            <Renderif isTrue={medicalHistory(state.sessionSelected)?.edema === true}>
                                <div className='col-12 bg-gray-05 r-1 mt-3 p-3'>
                                    <div className='f-16 bold text-green'>Tipo de edema</div>
                                    <div className='d-flex mt-3'>

                                        <div className='col-3'>
                                            <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${medicalHistory(state.sessionSelected)?.edemaType === 0 ? 'border-green': '' }`} onClick={()=>{
                                                medicalHistory(state.sessionSelected).edemaType = 0;
                                                setState({...state})
                                            }}>
                                                <img width="100px" src='/assets/edema1.png'></img>
                                                <div className='f-14 text-center text-gray'>Fóvea ligera, sin distorsión visible, desaparece rápidamente</div>
                                            </div>
                                        </div>

                                        <div className='col-3 ps-2'>
                                            <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${medicalHistory(state.sessionSelected)?.edemaType === 1 ? 'border-green': '' }`} onClick={()=>{
                                                medicalHistory(state.sessionSelected).edemaType = 1;
                                                setState({...state})
                                            }}>
                                                <img width="100px" src='/assets/edema2.png'></img>
                                                <div className='f-14 text-center text-gray'>Fóvea algo más profunda que en 1+ pero, una vez más no hay distorsión detectable con facilidad: desaparece en 10 a 15s</div>
                                            </div>
                                        </div>

                                        <div className='col-3 ps-2'>
                                            <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${medicalHistory(state.sessionSelected)?.edemaType === 2 ? 'border-green': '' }`} onClick={()=>{
                                                medicalHistory(state.sessionSelected).edemaType = 2;
                                                setState({...state})
                                            }}>
                                                <img width="100px" src='/assets/edema3.png'></img>
                                                <div className='f-14 text-center text-gray'>Fóvea claramente profunda que puede durar más de 1 min; la extremidad en posición inferior parece más llena y tumefacta.</div>
                                            </div>
                                        </div>

                                        <div className='col-3 ps-2'>
                                            <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${medicalHistory(state.sessionSelected)?.edemaType === 3 ? 'border-green': '' }`} onClick={()=>{
                                                medicalHistory(state.sessionSelected).edemaType = 3;
                                                setState({...state})
                                            }}>
                                                <img width="100px" src='/assets/edema4.png'></img>
                                                <div className='f-14 text-center text-gray'>Fóvea muy profunda que dura hasta 2 - 5 min; la extremidad en posición inferior está muy distorisonada. </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Renderif>
                            

                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Ojos</div>
                                    <input value={medicalHistory(state.sessionSelected)?.eyes} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).eyes = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Lengua</div>
                                    <input value={medicalHistory(state.sessionSelected)?.tongue} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).tongue = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Piel</div>
                                    <input value={medicalHistory(state.sessionSelected)?.skin} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).skin = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Dientes</div>
                                    <input value={medicalHistory(state.sessionSelected)?.teeth} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).teeth = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Labios</div>
                                    <input value={medicalHistory(state.sessionSelected)?.lips} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).lips = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                      
                        </div>


                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Salud hormonal femenina</div>
                        </div>
                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Menarquía (Primer Ciclo Menstrual)</div>
                                    <input className='chakra-input css-1omz1ot' value={medicalHistory(state.sessionSelected)?.menarche?.split('T')[0]} onChange={(evt)=>{
                                        console.log(evt.currentTarget.value)
                                        medicalHistory(state.sessionSelected).menarche = evt.currentTarget.value
                                        setState({...state})

                                    }} type={'date'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    
                                    <Select
                                        error={""}
                                        label="¿Eres regular?"
                                        value={medicalHistory(state.sessionSelected)?.isRegular.toString()}
                                        onChange={(evt)=>{ 
                                            medicalHistory(state.sessionSelected).isRegular = evt.currentTarget.value === 'true' ? true : false
                                            setState({...state})
                                        }}
                                    
                                    >
                                    
                                  
                                    <option  value="">Seleccionar</option>
                                    <option  value="true">SI</option>
                                    <option  value="false">NO</option>
                                
                                                    
                            

                                    </Select>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Último ciclo menstrual</div>
                                    <input className='chakra-input css-1omz1ot' type={'date'} value={medicalHistory(state.sessionSelected)?.lastMenstrualCycle?.split('T')[0]} onChange={(evt)=>{
                                             medicalHistory(state.sessionSelected).lastMenstrualCycle = evt.currentTarget.value
                                             setState({...state})
                                    }} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Duración del ciclo menstrual (dias)</div>
                                    <input value={medicalHistory(state.sessionSelected)?.durationOfTheMenstrualCycle} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).durationOfTheMenstrualCycle = parseInt(evt.currentTarget.value)
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 28"></input>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Duración de la menstruación (dias)</div>
                                    <input value={medicalHistory(state.sessionSelected)?.durationOfMenstruation} onChange={(evt)=>{
                                         medicalHistory(state.sessionSelected).durationOfMenstruation = parseInt(evt.currentTarget.value)
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 7"></input>
                                </div>   
                            </div>
                      
                        </div>
                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Síntomas menstruales</div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='col pe-2'>
                                <div className='mt-2 d-flex flex-wrap'>
                               
                                
                                    <Checkbox text="Dismenorrea (dolor menstrual)" checked={medicalHistory(state.sessionSelected)?.dysmenorrhea} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).dysmenorrhea = !value
                                         setState({...state})
                                    }}></Checkbox>

                                    <Checkbox text="Migrañas durante la menstruación" checked={medicalHistory(state.sessionSelected)?.migrainesDuringMenstruation} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).migrainesDuringMenstruation = !value
                                         setState({...state})
                                    }}></Checkbox>
                                    <Checkbox text="Menorragia (menstruación abundante" checked={medicalHistory(state.sessionSelected)?.menorrhagia} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).menorrhagia = !value
                                         setState({...state})
                                    }}></Checkbox>
                                    <Checkbox text="Cambios de humor" checked={medicalHistory(state.sessionSelected)?.humorChanges} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).humorChanges = !value
                                         setState({...state})
                                    }}></Checkbox>
                                    <Checkbox text="Presencia de coágulos en menstruación" checked={medicalHistory(state.sessionSelected)?.clotsDuringMenstruation} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).clotsDuringMenstruation = !value
                                         setState({...state})
                                    }}></Checkbox>
                                    <Checkbox text="Antojos por cosas dulces" checked={medicalHistory(state.sessionSelected)?.cravingsForSweetThings} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).cravingsForSweetThings = !value
                                         setState({...state})
                                    }}></Checkbox>
                                    <Checkbox text="Dolor al ovular/ sangrado intermenstrual" checked={medicalHistory(state.sessionSelected)?.painWhenOvulating} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).painWhenOvulating = !value
                                         setState({...state})
                                    }}></Checkbox>
                                    <Checkbox text="Pechos sensibles" checked={medicalHistory(state.sessionSelected)?.sensitiveBreasts} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).sensitiveBreasts = !value
                                         setState({...state})
                                    }}></Checkbox>

                                </div>
                            </div>
                         
                      
                        </div>
                        <div className='d-flex flex-wrap mt-3 '>
                            <div className='f-16 bold text-green'>Signos y Síntomas</div>
                        </div>
                        <div className='d-flex flex-wrap d-none'>
                            <div className='col-2 pe-2'>
                                <div className='mt-2'>
                              
                                    <Checkbox text="Endometriosis" checked={medicalHistory(state.sessionSelected)?.endometriosisSymptoms} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).endometriosisSymptoms = !value
                                         setState({...state})
                                    }}></Checkbox>
                                </div>
                            </div>
                            <div className='col-2 pe-2'>
                                <div className='mt-2'>
                                    <Checkbox text="SOP" checked={medicalHistory(state.sessionSelected)?.sop} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).sop = !value
                                         setState({...state})
                                    }}></Checkbox>
                                </div>   
                            </div>
                            <div className='col-2 pe-2'>
                                <div className='mt-2'>
                                  
                                    <Checkbox text="SOP" checked={medicalHistory(state.sessionSelected)?.thyroid} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).thyroid = !value
                                         setState({...state})
                                    }}></Checkbox>
                                </div>   
                            </div>
                            <div className='col-2 pe-2'>
                                <div className='mt-2'>
                                    
                                    <Checkbox text="Digestivos" checked={medicalHistory(state.sessionSelected)?.digestives} onChange={(value:any)=>{
                                         medicalHistory(state.sessionSelected).digestives = !value
                                         setState({...state})
                                    }}></Checkbox>
                                </div>   
                            </div>
                    
                      
                        </div>


                        <div className='patient_tabs d-flex mt-2 mb-2'>
                            <div className={` tab  cursor-pointer me-2 ${state.tab_active_hitorial === 0 ? 'tab--active text-green':'text-gray'}`} onClick={()=>{setState({...state, tab_active_hitorial:0})}}>Endometriosis</div>
                            <div className={` tab  cursor-pointer me-2 ${state.tab_active_hitorial === 1 ? 'tab--active text-green':'text-gray'}`} onClick={()=>{setState({...state, tab_active_hitorial:1})}}>SOP</div>
                            <div className={` tab  cursor-pointer me-2 ${state.tab_active_hitorial === 2 ? 'tab--active text-green':'text-gray'}`} onClick={()=>{setState({...state, tab_active_hitorial:2})}}>Tiroides</div>
                            <div className={` tab  cursor-pointer me-2 ${state.tab_active_hitorial === 3 ? 'tab--active text-green':'text-gray'}`} onClick={()=>{setState({...state, tab_active_hitorial:3})}}>Digestivos</div>
                        </div>

                        <Renderif isTrue = {state.tab_active_hitorial == 0}>

                            <div className='d-flex flex-wrap bg-gradient bg-body-secondary p-3 r-2'>
                                {medicalHistory(state.sessionSelected)?.customQuestions.map((item:any, i:any)=>{
                                    if(item.tab !== 0) return;
                                    return (
                                    <div className='col-4 pe-2 d-flex align-items-end'>
                                        <div className='mt-2 w-100'>
                                            <div className='f-14 text-gray'>{item?.question}</div>
                                            <input value={item.value} onChange={(evt)=>{
                                                item.value = evt.currentTarget.value
                                                setState({...state})
                                                // console.log(item)
                                                // console.log(medicalHistory(state.sessionSelected).customQuestions)
                                            }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    )
                                })}
                                

                 
                            </div>
        
                        </Renderif>
                        <Renderif isTrue = {state.tab_active_hitorial == 1}>
                            <div className='d-flex flex-wrap bg-gradient bg-body-secondary p-3 r-2'>
                                {medicalHistory(state.sessionSelected)?.customQuestions.map((item:any, i:any)=>{
                                    if(item.tab !== 1) return;
                                    return (
                                    <div className='col-4 pe-2 d-flex align-items-end'>
                                        <div className='mt-2 w-100'>
                                            <div className='f-14 text-gray'>{item?.question}</div>
                                            <input value={item.value} onChange={(evt)=>{
                                                item.value = evt.currentTarget.value
                                                setState({...state})
                                                // console.log(item)
                                                // console.log(medicalHistory(state.sessionSelected).customQuestions)
                                            }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
        
                        </Renderif>
                        <Renderif isTrue = {state.tab_active_hitorial == 2}>
                            <div className='d-flex flex-wrap bg-gradient bg-body-secondary p-3 r-2'>
                            {medicalHistory(state.sessionSelected)?.customQuestions.map((item:any, i:any)=>{
                                    if(item.tab !== 2) return;
                                    return (
                                    <div className='col-4 pe-2 d-flex align-items-end'>
                                        <div className='mt-2 w-100'>
                                            <div className='f-14 text-gray'>{item?.question}</div>
                                            <input value={item.value} onChange={(evt)=>{
                                                item.value = evt.currentTarget.value
                                                setState({...state})
                                                // console.log(item)
                                                // console.log(medicalHistory(state.sessionSelected).customQuestions)
                                            }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    )
                                })}
                               
                            </div>
        
                        </Renderif>
                        <Renderif isTrue = {state.tab_active_hitorial == 3}>
                            <div className='d-flex flex-wrap bg-gradient bg-body-secondary p-3 r-2'>
                                {medicalHistory(state.sessionSelected)?.customQuestions.map((item:any, i:any)=>{
                                    if(item.tab !== 3) return;
                                    if(item.question === 'Escala de bristol'){
                                        return(
                                            <div className='col-12 pe-2 d-flex align-items-end'>
                                                <div className='mt-2 w-100'>
                                                    

                                                    
                                                        <div className='col-12 bg-gray-05 r-1 mt-3 p-3'>
                                                           
                                                            <div className='f-14 text-gray'>{item?.question}</div>
                                                            <div className='d-flex flex-wrap mt-3'>

                                                                <div className='col-2'>
                                                                    <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${item.value === '1' ? 'border-green': '' }`} onClick={()=>{
                                                                        item.value = '1'
                                                                        setState({...state})
                                                                    }}>
                                                                        <img width="100px" src='/assets/popo1.png'></img>
                                                                        <div className='f-14 text-center text-gray'>Tipo 1 : Pedazos duros separados. </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2 ps-2'>
                                                                    <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${item.value === '2' ? 'border-green': '' }`} onClick={()=>{
                                                                        item.value = '2'
                                                                        setState({...state})
                                                                    }}>
                                                                        <img width="100px" src='/assets/popo2.png'></img>
                                                                        <div className='f-14 text-center text-gray'>Tipo 2 : Con forma de salchicha, pero grumosa.</div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2 ps-2'>
                                                                    <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${item.value === '3' ? 'border-green': '' }`} onClick={()=>{
                                                                        item.value = '3'
                                                                        setState({...state})
                                                                    }}>
                                                                        <img width="100px" src='/assets/popo3.png'></img>
                                                                        <div className='f-14 text-center text-gray'>Tipo 3 :con forma de serpiente pero con grietas en la superficie </div>
                                                                    </div>
                                                                </div>
                                                             
                                                                <div className='col-2 ps-2'>
                                                                    <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${item.value === '4' ? 'border-green': '' }`} onClick={()=>{
                                                                        item.value = '4'
                                                                        setState({...state})
                                                                    }}>
                                                                        <img width="100px" src='/assets/popo4.png'></img>
                                                                        <div className='f-14 text-center text-gray'>Tipo 4: Con forma de serpiente pero lisa y suave</div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2 ps-2'>
                                                                    <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${item.value === '5' ? 'border-green': '' }`} onClick={()=>{
                                                                        item.value = '5'
                                                                        setState({...state})
                                                                    }}>
                                                                        <img width="100px" src='/assets/popo5.png'></img>
                                                                        <div className='f-14 text-center text-gray'>Tipo 5: Trozos pastosos con bordes bien definidos </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2 ps-2'>
                                                                    <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${item.value === '6' ? 'border-green': '' }`} onClick={()=>{
                                                                        item.value = '6'
                                                                        setState({...state})
                                                                    }}>
                                                                        <img width="100px" src='/assets/popo6.png'></img>
                                                                        <div className='f-14 text-center text-gray'>Tipo 6: Pedazos bandos y esponjosos con bordes irregulares </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2 pt-2'>
                                                                    <div className={`d-flex flex-column align-items-center bg-white r-2 cursor-pointer h-100 ${item.value === '7' ? 'border-green': '' }`} onClick={()=>{
                                                                        item.value = '7'
                                                                        setState({...state})
                                                                    }}>
                                                                        <img width="100px" src='/assets/popo7.png'></img>
                                                                        <div className='f-14 text-center text-gray'>Tipo 7: Pedazos bandos y esponjosos con bordes irregulares </div>
                                                                    </div>
                                                                </div>

                                                              

                                                            </div>
                                                        </div>
                                                    
                                                    
                                                </div>
                                            </div>

                                        )
                                    }
                                    return (
                                    <div className='col-4 pe-2 d-flex align-items-end'>
                                        <div className='mt-2 w-100'>
                                            <div className='f-14 text-gray'>{item?.question}</div>
                                            <input value={item.value} onChange={(evt)=>{
                                                item.value = evt.currentTarget.value
                                                setState({...state})
                                                // console.log(item)
                                                // console.log(medicalHistory(state.sessionSelected).customQuestions)
                                            }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
        
                        </Renderif>


                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Preguntas personalizadas:</div>
                        </div>
                        <div className='d-flex flex-wrap align-items-end bg-body-secondary p-3 r-2'>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Pregunta</div>
                                    <input value={state.custom_question.question} onChange={(evt)=>{
                                        state.custom_question.question = evt.currentTarget.value
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Respuesta</div>
                                    <input value={state.custom_question.value} onChange={(evt)=>{
                                        state.custom_question.value = evt.currentTarget.value
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='bv-icon bv-icon--md cursor-pointer' onClick={()=>{

                                let _custom_question = {
                                    tab:4,
                                    ...state.custom_question
                                }
                                medicalHistory(state.sessionSelected).customQuestions.push(_custom_question)
                                setState({...state})

                                console.log(medicalHistory(state.sessionSelected))
                            }}>
                                <BsPlusCircle size={24} color="green"></BsPlusCircle>
                            </div>
                         
                        </div>

                        {
                            medicalHistory(state.sessionSelected)?.customQuestions.map((item:any,i:any)=>{
                                if(item?.tab !== 4){
                                    return
                                }
                                return (
                                    <div key={i} className='d-flex flex-wrap m-1 border-top pt-3'>
                                        <div className='px-2 bold'>•</div>
            
                                        <div className='col pe-3'>
                                            <div>
                                                {item?.question}
                                            </div>
                                        </div>
                                        <div className='col pe-3'>
                                            <div className='text-green'>
                                            {item?.value}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                      


                      
                        
                        

                    
                    </div>

               

                </div>
                

            </div>
        </Renderif>

        <Renderif isTrue={state.tab_active === 3}>
             <div className='container-fluid p-0 bg-light p-3 r-2'>

                <div className='d-flex flex-wrap'>

                    <div className='px-3'>
                        <div className="md-icon md-icon--big">
                            <FaRegUserCircle color="2CAD6E" size={50}></FaRegUserCircle>
                        </div>
                    </div>

                    <div className='col '>

                        <div className='d-flex'>
                            <div>
                                <div>
                                    <span className='f-14 bold'>Fecha de registro:</span>
                                    <span className='f-14 text-gray mx-2'>{lifeStyle(state.sessionSelected)?.createdAt?.split('T')[0]}</span>
                                </div>
                                <div className='mt-2 d-flex align-items-end'>
                                 
                                    
                                    <div className='w-100'>
                                    <Select
                                        error={""}
                                        label="Sesión"
                                        value={state.sessionSelected}
                                        onChange={(evt)=>{ 
                                            state.sessionSelected = evt.currentTarget.value
                                            setState({...state})
                                        }}
                                    
                                    >
                                    
                                    {state.patient?.lifeStyle.map((item:any, i:number)=>{
                                  
                                        return (<option key={item.id} value={item.id}>Sesión {state.patient?.lifeStyle.length - i}</option>)
                                    })}
                                    {/* <option  value="">Nuevo</option> */}
                                
                                                    
                            

                                    </Select>
                                    </div>
                                    <div className='bv-icon bv-icon--md cursor-pointer pt-1' onClick={()=>{
                                         onCreateLifeStyle()
                                    }}>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
                                    onSaveLifeStyle()
                                }}>
                                    <div>Guardar</div>
                                </div>
                            </div>
                        </div>

                       

                        {/* <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Antecedentes patológicos generales:</div>
                        </div> */}
                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Hora Despertar</div>
                                    <input value={lifeStyle(state.sessionSelected)?.wakeUpTime} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).wakeUpTime = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'time'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Hora Dormir</div>
                                    <input value={lifeStyle(state.sessionSelected)?.sleepTime} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).sleepTime = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'time'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Actividad física/ ejercicio</div>
                                    <input value={lifeStyle(state.sessionSelected)?.physicalActivity} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).physicalActivity = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder="Ej. Pilates, Gym"></input>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Frecuencia y duración</div>
                                    <input value={lifeStyle(state.sessionSelected)?.frequencyAndDuration} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).frequencyAndDuration = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder="Ej. 2h al dia"></input>
                                </div>   
                            </div>
                        </div>
                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Sueño</div>
                                    <input value={lifeStyle(state.sessionSelected)?.dream} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).dream = evt.currentTarget.value
                                         setState({...state})
                                    }}  className='chakra-input css-1omz1ot' type={'text'} placeholder="Ej. Continuo"></input>
                                </div>
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>¿Amanece descansado?</div>
                                    <input value={lifeStyle(state.sessionSelected)?.doYouWakeUpRested} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).doYouWakeUpRested = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder="Ej. Ocasinalmente"></input>
                                </div>   
                            </div>
                        </div>
                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Nivel de estrés</div>

                                 

                                    <div className='d-flex justify-content-between'>

                                        {[1,2,3,4,5].map((item:any, i:any)=>{
                                             return (
                                                <div>
                                                    <div key={i} onClick={()=>{
                                                        lifeStyle(state.sessionSelected).stresslevel = item
                                                        setState({...state})
                                                    }} className={`bv-icon bv-icon--big rounded-circle cursor-pointer ${lifeStyle(state.sessionSelected)?.stresslevel === item ? 'bg-green-alpha border-green':'border'}`}>
                                                        <div className={`f-16 bold ${lifeStyle(state.sessionSelected)?.stresslevel === item ? 'text-green':'text-gray'}`}>{item}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        
                                        

                                    </div>

                                </div>
                            </div>
                            <div className='col-3 pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>¿Qué lo detona?</div>
                                    <input  value={lifeStyle(state.sessionSelected)?.whatTriggersIt} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).whatTriggersIt = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                        </div>
                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Adicciones:</div>
                        </div>
                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Alcohol</div>
                                    <input value={lifeStyle(state.sessionSelected)?.alcohol} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).alcohol = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Tabaco</div>
                                    <input value={lifeStyle(state.sessionSelected)?.tobacco} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).tobacco = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Café</div>
                                    <input value={lifeStyle(state.sessionSelected)?.coffee} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).coffee = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Refresco</div>
                                    <input value={lifeStyle(state.sessionSelected)?.soda} onChange={(evt)=>{
                                         lifeStyle(state.sessionSelected).soda = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                        </div>


                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-16 bold text-green'>Preguntas personalizadas:</div>
                        </div>
                        <div className='d-flex flex-wrap align-items-end bg-body-secondary p-3 r-2'>
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Pregunta</div>
                                    <input value={state.lifeSyleQuestion.question} onChange={(evt)=>{
                                        state.lifeSyleQuestion.question = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                            
                            <div className='col pe-2'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Respuesta</div>
                                    <input value={state.lifeSyleQuestion.answer} onChange={(evt)=>{
                                        state.lifeSyleQuestion.answer = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>   
                            </div>
                            <div className='bv-icon bv-icon--md cursor-pointer' onClick={()=>{
                           
                            //    let questions = [];

                            //    if(lifeStyle(state.sessionSelected).customQuestions !== ""){
                            //     questions = JSON.parse(lifeStyle(state.sessionSelected)?.customQuestions)
                                  
                            //    }
                            //    questions.push(state.lifeSyleQuestion)

                               lifeStyle(state.sessionSelected).customQuestions.push({
                                question:state.lifeSyleQuestion.question,
                                answer:state.lifeSyleQuestion.answer
                               })
                               state.lifeSyleQuestion.question = ''
                               state.lifeSyleQuestion.answer = ''
                               
                               setState({...state})

                            }}>
                                <BsPlusCircle size={24} color="green"></BsPlusCircle>
                            </div>
                         
                        </div>

                        {lifeStyle(state.sessionSelected)?.customQuestions.map((item:any, i:any)=>{
                            console.log(item)
                            return (
                                <div key={i} className='d-flex flex-wrap m-1 border-top pt-3'>
                                    <div className='px-2 bold'>•</div>
                                    
        
                                    <div className='col pe-3'>
                                        <div>
                                            {item.question}
                                        </div>
                                    </div>
                                    <div className='col pe-3'>
                                        <div className='text-green'>
                                            {item.answer}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                      
                    
                        
                        

                    
                    </div>

               

                </div>
                

            </div>
        </Renderif>

        <Renderif isTrue={state.tab_active === 4}>
             <div className='container-fluid p-0 bg-light p-3 r-2'>

                <div className='d-flex flex-wrap'>

                    <div className='px-3'>
                        <div className="md-icon md-icon--big">
                            <FaRegUserCircle color="2CAD6E" size={50}></FaRegUserCircle>
                        </div>
                    </div>

                    <div className='col'>

                        
                    <div className='d-flex'>
                            <div>
                                <div>
                                    <span className='f-14 bold'>Fecha de registro:</span>
                                   
                                    <span className='f-14 text-gray mx-2'>{foodHistory(state.sessionSelected)?.createdAt?.split('T')[0]}</span>
                                </div>
                                <div className='mt-2 d-flex align-items-end'>
                                 
                                    
                                   <div className='w-100'>
                                   <Select
                                        error={""}
                                        label="Sesión"
                                        value={state.sessionSelected}
                                        onChange={(evt)=>{ 
                                            state.sessionSelected = evt.currentTarget.value
                                            setState({...state})
                                        }}
                                    
                                    >
                                    
                                    {state.patient?.foodHistory.map((item:any, i:number)=>{
                                  
                                        return (<option key={item.id} value={item.id}>Sesión {state.patient?.foodHistory.length - i}</option>)
                                    })}
                                    {/* <option  value="">Nuevo</option> */}
                                
                                                    
                            

                                    </Select>
                                   </div>
                                    <div className='bv-icon bv-icon--md cursor-pointer pt-1' onClick={()=>{
                                         onCreateFoodHistory()
                                    }}>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
                                    onSaveFoodHistory()
                                }}>
                                    <div>Guardar</div>
                                </div>
                            </div>
                        </div>

                    <div className='d-flex'>

                        

                        <div className='col'>

            

                            <div className='d-flex flex-wrap mt-3'>
                                <div className='f-16 bold text-green'>Consumos y Alergias:</div>
                            </div>

                            <div className='d-flex flex-wrap'>
                                <div className='col pe-2'>
                                    <div className='mt-2 position-relative'>
                                        <SearchAliment text="Alimentos que no le gustan" aliments={state.aliments} onSelect={(aliment:any)=>{
                                            foodHistory(state.sessionSelected).foodsYouDontLike.push(aliment.id)
                                            setState({...state})
                                        }} />
                                        <div className='d-flex flex-wrap my-2'>
                                            
                                            {foodHistory(state.sessionSelected)?.foodsYouDontLike.map((aliment:any, i:any)=>{
                                                let _aliment = state.aliments.find((item:any)=> item.id === aliment)
                                                
                                                return (
                                                    <div className='tag tag--green d-flex align-items-center ps-2 py-0 me-1 mt-1' key={i}>
                                                        <div>{_aliment.alimentos}</div>
                                                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                                                            
                                                            foodHistory(state.sessionSelected).foodsYouDontLike = foodHistory(state.sessionSelected).foodsYouDontLike.filter((_item:any)=>_item !== aliment)
                                                            setState({...state})
                                                        }}>
                                                            <MdClose size={18}></MdClose>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='mt-2 position-relative'>
                                        
                                        <SearchAliment text="Alimentos que causan Alergias o Intolerancia" aliments={state.aliments} onSelect={(aliment:any)=>{
                                            foodHistory(state.sessionSelected).foodThatCausesAllergies.push(aliment.id)
                                            setState({...state})
                                        }} />
                                        <div className='d-flex flex-wrap my-2'>
                                            
                                            {foodHistory(state.sessionSelected)?.foodThatCausesAllergies.map((aliment:any, i:any)=>{
                                                let _aliment = state.aliments.find((item:any)=> item.id === aliment)
                                                
                                                return (
                                                    <div className='tag tag--green d-flex align-items-center ps-2 py-0 me-1 mt-1' key={i}>
                                                        <div>{_aliment.alimentos}</div>
                                                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                                                            
                                                            foodHistory(state.sessionSelected).foodThatCausesAllergies = foodHistory(state.sessionSelected).foodThatCausesAllergies.filter((_item:any)=>_item !== aliment)
                                                            setState({...state})
                                                        }}>
                                                            <MdClose size={18}></MdClose>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap mt-3'>
                                <div className='f-16 bold text-green'>Frecuencia Alimentaria a la Semana:</div>
                            </div>

                            <div className='d-flex flex-wrap'>
                                <div className='col pe-2'>
                                    <div className='mt-2 position-relative'>
                                     
                                        <SearchAliment text="Lo que más consume" aliments={state.aliments} onSelect={(aliment:any)=>{
                                            foodHistory(state.sessionSelected).whatConsumesTheMost.push(aliment.id)
                                            setState({...state})
                                        }} />
                                        <div className='d-flex flex-wrap my-2'>
                                            
                                            {foodHistory(state.sessionSelected)?.whatConsumesTheMost.map((aliment:any, i:any)=>{
                                                let _aliment = state.aliments.find((item:any)=> item.id === aliment)
                                                
                                                return (
                                                    <div className='tag tag--green d-flex align-items-center ps-2 py-0 me-1 mt-1' key={i}>
                                                        <div>{_aliment.alimentos}</div>
                                                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                                                            
                                                            foodHistory(state.sessionSelected).whatConsumesTheMost = foodHistory(state.sessionSelected).whatConsumesTheMost.filter((_item:any)=>_item !== aliment)
                                                            setState({...state})
                                                        }}>
                                                            <MdClose size={18}></MdClose>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='mt-2 position-relative'>
                                      
                                        <SearchAliment text="Lo que menos consume" aliments={state.aliments} onSelect={(aliment:any)=>{
                                            foodHistory(state.sessionSelected).whatConsumesTheLeast.push(aliment.id)
                                            setState({...state})
                                        }} />
                                        <div className='d-flex flex-wrap my-2'>
                                            
                                            {foodHistory(state.sessionSelected)?.whatConsumesTheLeast.map((aliment:any, i:any)=>{
                                                let _aliment = state.aliments.find((item:any)=> item.id === aliment)
                                                
                                                return (
                                                    <div className='tag tag--green d-flex align-items-center ps-2 py-0 me-1 mt-1' key={i}>
                                                        <div>{_aliment.alimentos}</div>
                                                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                                                            
                                                            foodHistory(state.sessionSelected).whatConsumesTheLeast = foodHistory(state.sessionSelected).whatConsumesTheLeast.filter((_item:any)=>_item !== aliment)
                                                            setState({...state})
                                                        }}>
                                                            <MdClose size={18}></MdClose>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap'>
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Consumo de agua simple (litros/dia)</div>
                                        <input value={foodHistory(state.sessionSelected)?.waterConsumption} onChange={(evt)=>{
                                         foodHistory(state.sessionSelected).waterConsumption = parseInt(evt.currentTarget.value)
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'number'} placeholder="Ej. 2"></input>
                                    </div>
                                </div>
                                <div className='col pe-2'>
                                    <div className='mt-2 position-relative'>
                                    
                                     
                                        <SearchAliment text="Otras bebidas" aliments={state.aliments} onSelect={(aliment:any)=>{
                                            state.otras_bebidas.push(aliment.id)
                                            setState({...state})
                                        }} />
                                        <div className='d-flex flex-wrap my-2'>
                                            
                                            {state.otras_bebidas.map((aliment:any, i:any)=>{
                                                let _aliment = state.aliments.find((item:any)=> item.id === aliment)
                                                
                                                return (
                                                    <div className='tag tag--green d-flex align-items-center ps-2 py-0 me-1 mt-1' key={i}>
                                                        <div>{_aliment.alimentos}</div>
                                                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                                                            
                                                            state.otras_bebidas = state.otras_bebidas.filter((_item:any)=>_item !== aliment)
                                                            setState({...state})
                                                        }}>
                                                            <MdClose size={18}></MdClose>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap'>
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Lugar de consumo</div>
                                        <input value={foodHistory(state.sessionSelected)?.placeOfConsumption} onChange={(evt)=>{
                                         foodHistory(state.sessionSelected).placeOfConsumption = evt.currentTarget.value
                                         setState({...state})
                                    }} className='chakra-input css-1omz1ot' type={'text'} placeholder="Ej. Casa, Trabajo"></input>
                                    </div>
                                </div>
                            </div>

                
                        
                        

                        </div>

                        {/* <div className='col-5'>

                            <div className='d-flex flex-wrap mt-3'>
                                <div className='f-16 bold text-green'>Alimentos:</div>
                            </div>

                            <div className='d-flex flex-wrap align-items-end'>
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Alimento</div>
                                        <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                    </div>
                                </div>
                                <div className='col pe-2'>
                                    <div className='mt-2'>
                                        <div className='f-14 text-gray'>Cantidad</div>
                                        <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                    </div>
                                </div>
                                <div className='bv-icon bv-icon--md cursor-pointer'>
                                    <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                </div>
                            </div>

                            <div className='mt-3'></div>

                            <div className='d-flex flex-wrap align-items-center ps-3 bg-body-secondary'>
                                <div className='col'>
                                    <div className='f-16 text-gray'>Plátano</div>
                                </div>
                                <div className='col'>
                                    <div className='f-16 text-gray'>12</div>
                                </div>
                                <div className='bv-icon bv-icon--md cursor-pointer'>
                                    <MdClose size={24} color="red"></MdClose>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap align-items-center ps-3'>
                                <div className='col'>
                                    <div className='f-16 text-gray'>Manzana</div>
                                </div>
                                <div className='col'>
                                    <div className='f-16 text-gray'>3</div>
                                </div>
                                <div className='bv-icon bv-icon--md cursor-pointer'>
                                    <MdClose size={24} color="red"></MdClose>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap align-items-center ps-3 bg-body-secondary'>
                                <div className='col'>
                                    <div className='f-16 text-gray'>Arroz</div>
                                </div>
                                <div className='col'>
                                    
                                    <BsCheck color='green' size={20}></BsCheck>
                                </div>
                                <div className='bv-icon bv-icon--md cursor-pointer'>
                                    <MdClose size={24} color="red"></MdClose>
                                   
                                </div>
                            </div>
                           

                           
                        </div> */}

                    </div>

                    </div>

                </div>
                
               
               


            
            </div>
        </Renderif>

        <Renderif isTrue={state.tab_active === 5}>

                          <div className='container-fluid p-0 bg-light p-3 r-2'>

                <div className='d-flex flex-wrap'>

                    <div className='px-3'>
                        <div className="md-icon md-icon--big">
                            <FaRegUserCircle color="2CAD6E" size={50}></FaRegUserCircle>
                        </div>
                    </div>

                    <div className='col'>

                        
                        <div className='d-flex '>
                            <div className=''>
                           
                                <div>
                                    <span className='f-14 bold'>Fecha de registro:</span>
                                    <span className='f-14 text-gray mx-2'>{r24s(state.sessionSelected)?.createdAt?.split('T')[0]}</span>
                                </div>
                                <div className='mt-0 d-flex align-items-end w-100'>
                                
                                    <div className='w-100'>
                                    <Select
                                        error={""}
                                        label="Sesión"
                                        value={state.sessionSelected}
                                        onChange={(evt)=>{ 
                                            state.sessionSelected = evt.currentTarget.value
                                            setState({...state})
                                        }}
                                    
                                    >
                                    
                                    {state.patient?.r24s.map((item:any, i:number)=>{
                                        
                                        return (<option key={item.id} value={item.id}>Sesión {state.patient?.r24s.length - i}</option>)
                                    })}
                                    {/* <option  value="Nuevo">Nuevo</option> */}

                                    </Select>
                                    </div>
                                    <div className='bv-icon bv-icon--md cursor-pointer pt-1' onClick={()=>{
                                         onCreateR24()
                                    }}>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{
                                    onSaveR24()
                                }}>
                                    <div>Guardar</div>
                                </div>
                            </div>
                        </div>


                


                        <div className='d-flex mt-3 b-b pb-3'>
                            <div className='col-3'>
                                <div className='f-14 text-gray'>Kilocalorias Finales: <span className='text-green bold'>{r24_kcal_totales()?._energy_total.toFixed(2)}</span></div>
                            </div>
                            <div className='col-3'>
                                <div className='f-14 text-gray'>Proteinas (Gr): <span className='text-green bold'>{r24_kcal_totales()?.proteinas.toFixed(2)}</span></div>
                            </div>
                            <div className='col-3'>
                                <div className='f-14 text-gray'>Hidratos de Carbono(Gr): <span className='text-green bold'>{r24_kcal_totales()?.hidratos.toFixed(2)}</span></div>
                            </div>
                            <div className='col-3'>
                                <div className='f-14 text-gray'>Lipidos(Gr): <span className='text-green bold'>{r24_kcal_totales()?.lipidos.toFixed(2)}</span></div>
                            </div>
                        </div>

                        <div className='d-flex mt-3 b-b pb-4'>
                            <div className='col'>

                                <div className='ps-2'>
                                    <div className='text-green bold'>Kilocalorias totales por Tiempo de comida</div>
                                </div>

                                <div className='d-flex flex-wrap mt-3'>

                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className='bv-icon bv-icon--mid'>
                                                <img width={"80%"} src='/assets/icons/desayuno.svg'></img>
                                            </div>
                                            <div className='f-14 text-gray'>Desayuno: <span className='text-green bold'>{r24_kcal_totales()?._energy_desayuno.toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className='bv-icon bv-icon--mid'>
                                                <img width={"80%"} src='/assets/icons/desayuno2.svg'></img>
                                            </div>
                                            <div className='f-14 text-gray'>Colación media mañana: <span className='text-green bold'>{r24_kcal_totales()?._energy_colacion_desayuno.toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className='bv-icon bv-icon--mid'>
                                                <img width={"80%"} src='/assets/icons/comida.svg'></img>
                                            </div>
                                            <div className='f-14 text-gray'>Comida: <span className='text-green bold'>{r24_kcal_totales()?._energy_comida.toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className='bv-icon bv-icon--mid'>
                                                <img width={"80%"} src='/assets/icons/comida2.svg'></img>
                                            </div>
                                            <div className='f-14 text-gray'>Colación media tarde: <span className='text-green bold'>{r24_kcal_totales()?._energy_colacion_comida.toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className='bv-icon bv-icon--mid'>
                                                <img width={"80%"} src='/assets/icons/cena.svg'></img>
                                            </div>
                                            <div className='f-14 text-gray'>Cena: <span className='text-green bold'>{r24_kcal_totales()?._energy_cena.toFixed(2)}</span></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col'>
                                <div className='ps-0'>
                                    <div className='text-green bold'>Conteo de Porciones Totales</div>
                                </div>
                                <div className='d-flex mt-3'>

                                    <div className='d-flex flex-column align-items-center pe-2'>
                                        <div className='f-10 bold text-gray'>Verduras</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="100%" src='/assets/icons/verduras.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.verduras}</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-2'>
                                        <div className='f-10 bold text-gray'>Frutas</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="100%" src='/assets/icons/frutas.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.frutas}</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-3'>
                                        <div className='f-10 bold text-gray'>Cereal</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="100%" src='/assets/icons/cereal.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.cereales}</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-2'>
                                        <div className='f-10 bold text-gray'>Grasa</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="90%" src='/assets/icons/grasas.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.grasas}</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-2'>
                                        <div className='f-10 bold text-gray'>Legumbres</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="90%" src='/assets/icons/legumbres.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.leguminosas}</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-2'>
                                        <div className='f-10 bold text-gray'>A.O.A</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="100%" src='/assets/icons/aoa.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.AOA}</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-2'>
                                        <div className='f-10 bold text-gray'>Leche</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="80%" src='/assets/icons/leche.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.leche}</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-2'>
                                        <div className='f-10 bold text-gray'>Azucares</div>
                                        <div className='bv-icon bv-icon--big'>
                                            <img width="100%" src='/assets/icons/azucares.svg'></img>
                                        </div>
                                        <div className='f-14 text-gray bold'>{r24_kcal_totales()?.azucares}</div>
                                    </div>


                                </div>

                            </div>
                        </div>
                           
                        <div className='d-flex mt-4'>
                                <div className='col-3'>
                                        <Select
                                            error={""}
                                            label="Tiempo de comida"
                                            value={state.r24_mealTime_selected}
                                            onChange={(evt)=>{ 
                                                state.r24_mealTime_selected = evt.currentTarget.value
                                                setState({...state})
                                              
                                             }}
                                        
                                        >
                                        
                                           <option  value="">Seleccionar</option>

                                           <option  value="Desayuno">Desayuno</option>

                                           <option  value="Colacion_Desayuno">Colación medio día</option>

                                           <option  value="Comida">Comida</option>

                                           <option  value="Colacion_Comida">Colacón media tarde</option>
                                           <option  value="Cena">Cena</option>

                                        </Select>
                                </div>
                                <div className='col ps-3 '>
                                    <SearchAliment text="Alimento:" aliments={state.aliments} onSelect={(aliment:any)=>{
                                        console.log(aliment)
                                        state.r24_aliment_selected=[aliment];
                                        
                                        setState({...state})
                                    }} />
                                   
                                    {
                                        state.r24_aliment_selected.map((item:any, i:any)=>{
                                            return (
                                                <div key={i} className='tag tag--green d-flex align-items-center ps-2 py-0 me-1 mt-1'>
                                                    <div>{item.alimentos}</div>
                                                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                                                        
                                                        state.r24_aliment_selected = []
                                                        

                                                        setState({...state})
                                                    }}>
                                                        <MdClose size={18}></MdClose>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    

                                    
                                </div>

                                <div className='col-1 ps-3'>
                                    <div className='f-14 text-gray'>Porción</div>
                                    <input className='chakra-input css-1omz1ot' type={'number'} value={state.r24_aliment_porcion} onChange={(evt)=>{
                                        state.r24_aliment_porcion = evt.currentTarget.value;
                                        setState({...state})
                                    }} placeholder="0"></input>
                                </div>
                                <div className='col-1 pt-3'>

                                    <div className='bv-icon bv-icon--md cursor-pointer' onClick={()=>{
                                      
                                        r24s(state.sessionSelected).items.push({
                                            mealTime:state.r24_mealTime_selected,
                                            portion:state.r24_aliment_porcion,
                                            aliment:state.r24_aliment_selected[0]
                                        })

                                        state.r24_mealTime_selected = ''
                                        state.r24_aliment_porcion = ''
                                        state.r24_aliment_selected = []


                                        setState({...state})


                                      }}>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>

                                </div>
                                
                        </div>

                        <div className='d-flex mt-5'>
                            <div className='col'>
                                <div className='d-flex align-items-center'>
                                    <div className='bv-icon bv-icon--mid'>
                                        <img width={"80%"} src='/assets/icons/desayuno.svg'></img>
                                    </div>
                                    <div className='f-14 text-gray bold'>Desayuno</div>
                                </div>
                                <div className=' ps-3'>

                               

                                        {r24s(state.sessionSelected)?.items.filter((item:any) => item.mealTime === 'Desayuno').map((aliment:any, i:any) =>{


                                             
                                            return (
                                                <div className='d-flex align-items-center b-b py-2'>
                                                    <div className='f-14 text-gray'>{aliment.aliment.alimentos}</div>
                                                    <div className='col'></div>
                                                    <div className='f-14 text-green bold line-0'>{aliment.portion}</div>
                                                    <div className='mx-2'></div>
                                                    <div className='bv-icon bv-icon--mid m-0 cursor-pointer' onClick={ async ()=>{ 

                                                            r24s(state.sessionSelected).items = r24s(state.sessionSelected)?.items.filter((el:any)=>el.aliment.id !== aliment.aliment.id)
                                                            setState({...state});
                                                            if(aliment.id !== undefined){
                                                                let _delete_item = await _Service('DELETE', `patients/${id}/r24/${state.sessionSelected}/items/${aliment.id}`)
                                                                console.log(_delete_item)
                                                            }

                                                     }}>
                                                        <MdClose size={18} color="red"></MdClose>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                       
                                    
                                    

                                  
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center'>
                                    <div className='bv-icon bv-icon--mid'>
                                    <img width={"80%"} src='/assets/icons/desayuno2.svg'></img>
                                    </div>
                                    <div className='f-14 text-gray bold'>Colación media mañana</div>
                                </div>
                                <div className=' ps-3'>
                                    {r24s(state.sessionSelected)?.items.filter((item:any) => item.mealTime === 'Colacion_Desayuno').map((aliment:any, i:any) =>{
                                            return (
                                                <div className='d-flex align-items-center b-b py-2'>
                                                    <div className='f-14 text-gray'>{aliment.aliment.alimentos}</div>
                                                    <div className='col'></div>
                                                    <div className='f-14 text-green bold line-0'>{aliment.portion}</div>
                                                    <div className='mx-2'></div>
                                                    <div className='bv-icon bv-icon--mid m-0 cursor-pointer' onClick={ async ()=>{ 
                                                         r24s(state.sessionSelected).items = r24s(state.sessionSelected)?.items.filter((el:any)=>el.aliment.id !== aliment.aliment.id)
                                                         setState({...state});
                                                         if(aliment.id !== undefined){
                                                             let _delete_item = await _Service('DELETE', `patients/${id}/r24/${state.sessionSelected}/items/${aliment.id}`)
                                                             console.log(_delete_item)
                                                         }
                                                     }}>
                                                        <MdClose size={18} color="red"></MdClose>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    

                                  
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center'>
                                    <div className='bv-icon bv-icon--mid'>
                                        <img width={"80%"} src='/assets/icons/comida.svg'></img>
                                    </div>
                                    <div className='f-14 text-gray bold'>Comida</div>
                                </div>
                                <div className=' ps-3'>
                                    {r24s(state.sessionSelected)?.items.filter((item:any) => item.mealTime === 'Comida').map((aliment:any, i:any) =>{
                                            return (
                                                <div className='d-flex align-items-center b-b py-2'>
                                                    <div className='f-14 text-gray'>{aliment.aliment.alimentos}</div>
                                                    <div className='col'></div>
                                                    <div className='f-14 text-green bold line-0'>{aliment.portion}</div>
                                                    <div className='mx-2'></div>
                                                    <div className='bv-icon bv-icon--mid m-0 cursor-pointer' onClick={async ()=>{ 
                                                         r24s(state.sessionSelected).items = r24s(state.sessionSelected)?.items.filter((el:any)=>el.aliment.id !== aliment.aliment.id)
                                                         setState({...state});
                                                         if(aliment.id !== undefined){
                                                             let _delete_item = await _Service('DELETE', `patients/${id}/r24/${state.sessionSelected}/items/${aliment.id}`)
                                                             console.log(_delete_item)
                                                         }
                                                     }}>
                                                        <MdClose size={18} color="red"></MdClose>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center'>
                                    <div className='bv-icon bv-icon--mid'>
                                        <img width={"80%"} src='/assets/icons/comida2.svg'></img>
                                    </div>
                                    <div className='f-14 text-gray bold'>Colación media tarde</div>
                                </div>
                                <div className=' ps-3'>
                                {r24s(state.sessionSelected)?.items.filter((item:any) => item.mealTime === 'Colacion_Comida').map((aliment:any, i:any) =>{
                                            return (
                                                <div className='d-flex align-items-center b-b py-2'>
                                                    <div className='f-14 text-gray'>{aliment.aliment.alimentos}</div>
                                                    <div className='col'></div>
                                                    <div className='f-14 text-green bold line-0'>{aliment.portion}</div>
                                                    <div className='mx-2'></div>
                                                    <div className='bv-icon bv-icon--mid m-0 cursor-pointer' onClick={async ()=>{ 
                                                         r24s(state.sessionSelected).items = r24s(state.sessionSelected)?.items.filter((el:any)=>el.aliment.id !== aliment.aliment.id)
                                                         setState({...state});
                                                         if(aliment.id !== undefined){
                                                             let _delete_item = await _Service('DELETE', `patients/${id}/r24/${state.sessionSelected}/items/${aliment.id}`)
                                                             console.log(_delete_item)
                                                         }
                                                     }}>
                                                        <MdClose size={18} color="red"></MdClose>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                 
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center'>
                                    <div className='bv-icon bv-icon--mid'>
                                        <img width={"80%"} src='/assets/icons/cena.svg'></img>
                                    </div>
                                    <div className='f-14 text-gray bold'>Cena</div>
                                </div>
                                <div className=' ps-3'>
                                        {r24s(state.sessionSelected)?.items.filter((item:any) => item.mealTime === 'Cena').map((aliment:any, i:any) =>{
                                            return (
                                                <div className='d-flex align-items-center b-b py-2'>
                                                    <div className='f-14 text-gray'>{aliment.aliment.alimentos}</div>
                                                    <div className='col'></div>
                                                    <div className='f-14 text-green bold line-0'>{aliment.portion}</div>
                                                    <div className='mx-2'></div>
                                                    <div className='bv-icon bv-icon--mid m-0 cursor-pointer' onClick={async ()=>{ 
                                                         r24s(state.sessionSelected).items = r24s(state.sessionSelected)?.items.filter((el:any)=>el.aliment.id !== aliment.aliment.id)
                                                         setState({...state});
                                                         if(aliment.id !== undefined){
                                                             let _delete_item = await _Service('DELETE', `patients/${id}/r24/${state.sessionSelected}/items/${aliment.id}`)
                                                             console.log(_delete_item)
                                                         }
                                                     }}>
                                                        <MdClose size={18} color="red"></MdClose>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    
                                </div>
                            </div>

                           
                        </div>

                        <div className='my-5'></div>
                        

                    

                  

                    </div>

                </div>
                
               
                <div className='my-5'></div>


            
            </div>
        </Renderif>
      
        <Renderif isTrue={state.tab_active === 6}>
             <div className='container-fluid p-0 bg-light p-3 r-2'>
              

                <div className='d-flex'>
                    <div>
                    
        
    
                        <input type='date' value={state.day_active?.toISOString().split('T')[0]} onChange={(evt)=>{

                        setState({...state, day_active:new Date(evt.currentTarget.value)})
                

                        }} className='chakra-input css-1omz1ot text-gray'></input>
                        {/* <div className='mt-3'>
                            <span className='text-gray bold f-14'>kcal. Totales</span>: <span className='text-green f-14'>{state.total_kcal}</span>
                        </div> */}
                    
                    </div>
                    <div className='col'></div>
                    <div>
                        <div className='bv-cta bv-cta--success w-100' onClick={()=>{
                            // onSaveMeasures()
                        }}>
                            <div>Guardar</div>
                        </div>
                    </div>
                </div>




                <div className='d-flex flex-wrap'>


               
                    <div className='col-3 pe-3 b-r'>

                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-14 bold text-green'>Tipo de Plan:</div>
                        </div>

                        <div className='d-flex flex-wrap'>
                            <Checkbox checked={true} text="Recetas (Automatico)"></Checkbox>
                            <Checkbox checked={true} text="Equivalencias"></Checkbox>
                            <Checkbox text="Personalizado"></Checkbox>
                        </div>

                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-14 bold text-green'>Asignar Tipo de Dieta:</div>
                        </div>

                        <div className='mt-2'>
                            {/* <div className='f-14 text-gray'>Categoria</div>
                            <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input> */}
                            <Select
                                // error={errors.categoryId?.message || ""}
                                label="Categoria"
                                value={""}
                                onChange={(evt)=>{
                                console.log(evt.currentTarget.value);
                                let _categorie = state._categories.find((item) => item === evt.currentTarget.value);
                                

                                if(state._categoriesSelected.find((item:any) => item === evt.currentTarget.value)){
                                    return
                                }


                                state._categoriesSelected.push(_categorie as string);
                                setState({...state});
                                
                                }}
                            
                            >
                            <option value="">Seleccionar</option>
                            {state._categories.map((item:string, i:number)=>{
                                                return (
                                                
                                                    <option value={item} key={i}>{item}</option>
                                                )
                                            })}
                                            
                    

                            </Select>
                            <div className="d-flex flex-wrap">
                                {
                                state._categoriesSelected.map((item:any,i:any)=>{
                                    return (
                                    <div key={i} className="pill pill--gren">
                                        <div>{item}</div>
                                        <div className="md-icon cursor-pointer" onClick={()=>{
                                        state._categoriesSelected = state._categoriesSelected.filter((cat:any)=> cat !== item);
                                        setState({...state});
                                        }}>
                                        <MdClose size={14}></MdClose>
                                        </div>
                                    </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                        <div className='mt-2'>
                            {/* <div className='f-14 text-gray'>Sub Categoria</div>
                            <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input> */}
                            <Select
                   
                                label="Sub Categoria"
                                value={""}
                                onChange={(evt)=>{
                                console.log(evt.currentTarget.value);
                                let _subcategory = state._subCategories.find((item) => item === evt.currentTarget.value);
                                console.log(_subcategory)
                                if(state._subCategoriesSelected.includes(_subcategory as string)){
                                    return
                                }
                                state._subCategoriesSelected.push(_subcategory as string);
                                setState({...state});
                                
                                }}
                            >
                            <option value="">Seleccionar</option>
                            {state._subCategories.map((item:string, i:number)=>{
                                                return (
                                                
                                                    <option  value={item} key={i}>{item}</option>
                                                )
                                            })}

                            </Select>

                            <div className="d-flex flex-wrap">
                                {
                                state._subCategoriesSelected.map((item:any,i:any)=>{
                                    return (
                                    <div key={i} className="pill pill--gren">
                                        <div>{item}</div>
                                        <div className="md-icon cursor-pointer" onClick={()=>{
                                        state._subCategoriesSelected = state._subCategoriesSelected.filter((cat:any)=> cat !== item);
                                        setState({...state});
                                        }}>
                                        <MdClose size={14}></MdClose>
                                        </div>
                                    </div>
                                    )
                                })
                                }
                            </div>
                        </div>

                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-14 bold text-green'>Horario de Ingesta:</div>
                        </div>

                        <div className='d-flex flex-wrap align-items-center mt-2'>
                            <div className='col'>
                                <Checkbox text="Desayuno"></Checkbox>
                            </div>
                            <div className='col-4'>
                            <input className='chakra-input css-1omz1ot' type={'time'} placeholder=""></input>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap align-items-center mt-2'>
                            <div className='col'>
                                <Checkbox text="Colación Media Mañana"></Checkbox>
                            </div>
                            <div className='col-4'>
                            <input className='chakra-input css-1omz1ot' type={'time'} placeholder=""></input>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap align-items-center mt-2'>
                            <div className='col'>
                                <Checkbox text="Comida"></Checkbox>
                            </div>
                            <div className='col-4'>
                            <input className='chakra-input css-1omz1ot' type={'time'} placeholder=""></input>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap align-items-center mt-2'>
                            <div className='col'>
                                <Checkbox text="Colación Media Tarde"></Checkbox>
                            </div>
                            <div className='col-4'>
                            <input className='chakra-input css-1omz1ot' type={'time'} placeholder=""></input>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap align-items-center mt-2'>
                            <div className='col'>
                                <Checkbox text="Cena"></Checkbox>
                            </div>
                            <div className='col-4'>
                            <input className='chakra-input css-1omz1ot' type={'time'} placeholder=""></input>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-14 bold text-green'>Requerimiento de agua</div>
                        </div>

                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='col-8'>
                                <div className='f-12 text-gray'>Agua a tomar diario.</div>
                            </div>
                            <div className='col-4'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Total (ml)</div>
                                    <input disabled value={state.meta.kcal.toFixed(2) } className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap mt-3'>
                            <div className='f-14 bold text-green'>Requerimiento de fibra</div>
                        </div>
                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='col-8'>
                                <div className='f-12 text-gray'>Fibra a consumir diario</div>
                            </div>
                            <div className='col-4'>
                                <div className='mt-2'>
                                    <div className='f-14 text-gray'>Total (g)</div>
                                    <input disabled value={((state.meta.kcal/1000) * 14)?.toFixed(2) } className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                </div>
                            </div>
                        </div>




                    </div>
                    <div className='col-3 b-r'>
                        <div className='d-flex flex-wrap '>

                            <div className='col-12 px-3'>
                                <div className='d-flex flex-column mt-3'>
                                    <div className='f-14 bold text-green'>Dieta Diaria</div>
                                </div>

                                <div className='d-flex flex-wrap'>
                                    <div className='col-6'>
                                        <Select
                                            
                                            label="Formula"
                                            value={state.formula}
                                            onChange={(evt)=>{
                                                state.formula = evt.currentTarget.value
                                                if(evt.currentTarget.value === '1'){
                                                    state.meta.kcal = 0;
                                                }
                                                if(evt.currentTarget.value === '3'){
                                                    console.log(state.patient?.measures[0])
                                                    let kcal = 0
                                                    let peso = state.patient?.measures[0]?.targetWeight;
                                                    console.log(peso)
                                                    if(peso === undefined){
                                                        peso = 0;
                                                    }
                                                    let edad = 30
                                                    let altura = state.patient?.measures[0]?.height;
                                                    if(altura === undefined){
                                                        altura = 0;
                                                    }
                                                    let gender = state.patient?.measures[0]?.gender;
                                                    if(gender === undefined){
                                                        gender = 'FEMELE';
                                                    }
                                                    // let peso = state.patient?.measures[state.patient?.measures.length - 1]?.weight;
                                                    if(gender === 'FEMELE'){
                                                        kcal = (161 + (10*peso)) + (6.25 * altura) - (5*edad)
                                                    }else{
                                                        kcal = (5 + (10*peso)) + (6.25 * altura) - (5*edad)
                                                    }
                                                    state.meta.kcal = kcal;
                                                    state.kcal = kcal;
                                                }
                                                setState({...state})
                                            }}>
                                            
                                                <option value="1">Porcentaje</option>
                                                <option value="2">Método práctico</option>
                                                <option value="3">Mifflin -St. Jeor,1990</option>

                                                        
                                        </Select>
                                       
                                      
                                       
                                        
                                    </div>

                                    
                                    <div className='col-6 ps-2'>
                                        <div className='f-14 text-gray'>Energia (kcal)</div>
                                     
                                        <input value={state.meta.kcal} onChange={(evt)=> {
                                            state.meta.kcal = parseInt( evt.currentTarget.value)
                                            state.factor = ''
                                            state.factor_value = ''
                                            state.requerimiento = ''
                                            state.kcal = parseInt( evt.currentTarget.value)
                                            setState({...state})
                                        }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>

                                

                                    </div>

                                   <Renderif isTrue={state.formula === '2'}>
                                    <div className='col-12 mt-2'>
                                    <Select
                                            
                                            label="Requerimiento"
                                            value={state.requerimiento}
                                            onChange={(evt)=>{
                                                state.requerimiento = evt.currentTarget.value
                                                state.factor = ''
                                                state.factor_value = ''
                                                let peso = state.patient?.measures[state.patient?.measures.length - 1]?.weight;
                                                if(peso === undefined){
                                                    peso = 0;
                                                }
                                                let kcal = 0;
                                                switch (evt.currentTarget.value) {
                                                    case '1':
                                                        kcal = 20 * peso;
                                                        break;
                                            
                                                    case '2':
                                                        kcal = 25 * peso;
                                                        break;
                                            
                                                    case '3':
                                                        kcal = 30 * peso;
                                                        break;
                                                
                                                    default:
                                                        break;
                                                }

                                                state.meta.kcal = kcal
                                                state.kcal = kcal


                                             


                                                setState({...state})
                                            }}>
                                                <option value="">Seleccionar</option>
                                                        
                                                <option value="1">Bajar de peso</option>
                                                <option value="2">Mantenimiento</option>
                                                <option value="3">Aumentar peso</option>
                                                        
                                

                                        </Select>
                                        </div>
                                   </Renderif>

                                    <div className='col-6 mt-2'>
                                        <Select
                                            
                                            label="Factor"
                                            value={state.factor}
                                            onChange={(evt)=>{
                                                if(state.factor !== ''){
                                                    // state.meta.kcal = state.meta.kcal * (parseFloat(state.factor) - 1);
                                                    console.log(state.meta.kcal)
                                                }

                                                state.factor = evt.currentTarget.value;
                                                switch (state.factor) {
                                                    case '1':
                                                        state.meta.kcal = state.kcal * (1.3 + 0.1)
                                                        state.factor_value = '1.3'
                                                        state.factor_termico_value = '0.1'
                                                        break;
                                                    case '2':
                                                        state.meta.kcal = state.kcal * (1.4 + 0.1)
                                                        state.factor_value = '1.4'
                                                        state.factor_termico_value = '0.1'
                                                        break;
                                                    case '3':
                                                        state.meta.kcal = state.kcal * (1.5 + 0.1)
                                                        state.factor_value = '1.5'
                                                        state.factor_termico_value = '0.1'
                                                        break;
                                                
                                                    default:
                                                        state.meta.kcal = state.kcal;
                                                        state.factor_value = '0'
                                                        state.factor_termico_value = '0'
                                                        break;
                                                }
                                                setState({...state})
                                            }}>
                                                <option value="">Seleccionar</option>
                                                        
                                                <option value="1">Sedentario</option>
                                                <option value="2">Ligero o moderado</option>
                                                <option value="3">Intensa</option>
                                                        
                                

                                        </Select>
                                    </div>
                                    <div className='col-6 ps-2 mt-2'>
                                        <div className='f-14 text-gray'>Ftor. de Actividad</div>
                                        <input disabled value={state.factor_value} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>

                                        <div className='f-14 text-gray'>Ftor de Termico (%)</div>
                                        <input disabled={state.factor === ''} onChange={(evt)=>{
                                             state.meta.kcal = state.kcal * (parseFloat(state.factor_value) + (parseFloat(evt.currentTarget.value)))
                                             state.factor_termico_value = evt.currentTarget.value

                                            console.log(state.kcal)
                                            setState({...state})
                                        }} value={state.factor_termico_value} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                    </div>




                                </div>
                                
                            </div>

                            

                            <div className='col-12 px-3'>

                              


                                <div className='d-flex flex-column mt-3'>
                                    <div className='f-14 bold text-green'>Hidrátos de Carbono</div>
                                    <div className='f-12 text-gray'>Distribucion de Hidrátos de Carbono</div>
                                </div>

                                <div className='d-flex flex-wrap align-items-end b-b pb-3'>
                                    <div className='col-6 pe-2'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>Pocentaje %</div>
                                            <input defaultValue={'0'} onChange={(evt)=>{
                                                state.meta.hco = (parseInt(evt.currentTarget.value) / 100) * state.meta.kcal
                                                state.metag.hco = ((parseInt(evt.currentTarget.value) / 100) * state.meta.kcal) / 4
                                                setState({...state})
                                            }} className='chakra-input css-1omz1ot' type={'number'} placeholder=""></input>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>(Kcal)</div>
                                            <input disabled value={state.meta.hco} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>(g)</div>
                                            <input disabled value={state.metag.hco.toFixed(1)} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className='col-12 px-3'>

                                <div className='d-flex flex-column mt-3'>
                                    <div className='f-14 bold text-green'>Proteínas</div>
                                    <div className='f-12 text-gray'>Distribucion de Proteínas. </div>
                                </div>

                                <div className='d-flex flex-wrap align-items-end b-b pb-3'>
                                    <div className='col-6 pe-2'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>Porcentaje %</div>
                                            <input defaultValue={'0'} onChange={(evt) =>{
                                                 state.meta.pro = (parseInt(evt.currentTarget.value) / 100) * state.meta.kcal
                                                 state.metag.pro = ((parseInt(evt.currentTarget.value) / 100) * state.meta.kcal) / 4
                                                 setState({...state})
                                            }} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>(kcal)</div>
                                            <input disabled value={state.meta.pro} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>(g)</div>
                                            <input disabled value={state.metag.pro.toFixed(1)} className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-12 px-3'>

                                <div className='d-flex flex-column mt-3'>
                                    <div className='f-16 bold text-green'>Lipidos</div>
                                    <div className='f-14 text-gray'>Distribucion de Lípidos. </div>
                                </div>

                                <div className='d-flex flex-wrap align-items-end b-b pb-3'>
                                    <div className='col-6 pe-2'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>Porcentaje %</div>
                                            <input defaultValue={'0'} onChange={(evt) =>{
                                                 state.meta.lip = (parseInt(evt.currentTarget.value) / 100) * state.meta.kcal
                                                 state.metag.lip = ((parseInt(evt.currentTarget.value) / 100) * state.meta.kcal) / 9
                                                 setState({...state})
                                            }}  className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>(Kcal)</div>
                                            <input disabled value={state.meta.lip}  className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>(g)</div>
                                            <input disabled value={state.metag.lip.toFixed(1)}  className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='d-flex flex-wrap align-items-end mt-3'>
                                    <div className='col pe-2'>
                                        <div className='f-16 bold text-green'>Proteína por Kg </div>
                                        <div className='f-14 text-gray'> (Genero) de 1.2 a 2.5 </div>
                                    </div>

                                    <div className='col-3'>
                                        <div className='mt-2'>
                                            <div className='f-14 text-gray'>g</div>
                                            <input className='chakra-input css-1omz1ot' type={'text'} placeholder=""></input>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-column flex-wrap mt-3'>
                                    
                                    <AlimentosSelect text="Desayuno"></AlimentosSelect>
                                    <AlimentosSelect text="Colación Media Mañana"></AlimentosSelect>
                                    <AlimentosSelect text="Comida"></AlimentosSelect>
                                    <AlimentosSelect text="Colación Media Tarde"></AlimentosSelect>
                                    <AlimentosSelect text="Cena"></AlimentosSelect>
                                </div> */}
                            
                                

                            </div>


                            <div className='col-12 px-3 mt-3'>
                                    <div className='d-flex'>
                                        <div className='col'>
                                            <div className='f-14 bold'></div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>KCAL</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>PRO</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>LIP</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>HCO</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-1'>
                                        <div className='col'>
                                            <div className='f-14 bold'>SUMA</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().suma.kcal.toFixed(1)}</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().suma.pro.toFixed(1)}</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().suma.lip.toFixed(1)}</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().suma.hco.toFixed(1)}</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-1'>
                                        <div className='col'>
                                            <div className='f-14 bold'>META</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().meta.kcal.toFixed(1)}</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().meta.pro.toFixed(1)}</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().meta.lip.toFixed(1)}</div>
                                        </div>
                                        <div className='col'>
                                            <div className='f-12 bold text-center'>{sumameta().meta.hco.toFixed(1)}</div>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-1'>
                                        <div className='col'>
                                            <div className='f-14 bold'>%</div>
                                        </div>
                                        <div className='col'>
                                            <div className={`f-12 bold text-center ${sumameta().percentage.kcal > 100 ? 'text-danger': 'text-success'}`}>{sumameta().percentage.kcal.toFixed(1)}%</div>
                                        </div>
                                        <div className='col'>
                                            <div className={`f-12 bold text-center ${sumameta().percentage.pro > 100 ? 'text-danger': 'text-success'}`}>{sumameta().percentage.pro.toFixed(1)}%</div>
                                        </div>
                                        <div className='col'>
                                            <div className={`f-12 bold text-center ${sumameta().percentage.lip > 100 ? 'text-danger': 'text-success'}`}>{sumameta().percentage.lip.toFixed(1)}%</div>
                                        </div>
                                        <div className='col'>
                                            <div className={`f-12 bold text-center ${sumameta().percentage.hco > 100 ? 'text-danger': 'text-success'}`}>{sumameta().percentage.hco.toFixed(1)}%</div>
                                        </div>
                                    </div>
                            </div>

                       


                        </div>

                    </div>
                    <div className='col-6 ps-3'>
                        <div className='d-flex justify-content-center align-items-center'>
                                <div className='col-2'>
                                    <div className='f-14 bold'></div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold'>Equivalencia</div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>KCAL.</div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>PRO</div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>LIP</div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>HCO</div>
                                </div>
                            </div>
                        {state.equivalencias.map((item, i)=>{
                            return (
                            <div className='d-flex justify-content-center align-items-center pb-1'>
                                <div className='col-2'>
                                    <div className='f-14 bold'>{item.alimento}</div>
                                </div>
                                <div className='col-2'>
                                    <input value={item.value} onChange={(evt)=>{
                                        item.value = parseInt(evt.currentTarget.value);
                                        setState({...state})
                                    }} type="number" className='chakra-input css-1omz1ot input-sm'></input>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>{item.value > 0 ? item.value * item.kcal : "-" }</div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>{item.value > 0 ? item.value * item.pro : "-" }</div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>{item.value > 0 ? item.value * item.lip : "-" }</div>
                                </div>
                                <div className='col-2'>
                                    <div className='f-14 bold text-center'>{item.value > 0 ? item.value * item.hco : "-" }</div>
                                </div>
                            </div>
                            )
                        })}
                     
                    
                        
                    </div>

                </div>

                <div className='d-flex flex-column mt-3 b-t pt-3'>
                    <div className='f-14 bold text-green'>Equivalencias</div>
                    <div className='f-12 text-gray'>Districución</div>
                </div>

                <div className='d-flex'>
                    <div className='col-9'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='col mx-1'>
                                <div className='f-14 bold'></div>
                            </div>
                            <div className='col-1 mx-1'>
                                <div className='f-14 bold'></div>
                            </div>
                            <div className='col mx-1'>
                            <div className='f-14 bold pb-2'>Desayuno</div>
                            </div>
                            <div className='col mx-1'>
                            <div className='f-14 bold pb-2'>Colación Mañana</div>
                            </div>
                            <div className='col mx-1'>
                            <div className='f-14 bold pb-2'>Comida</div>
                            </div>
                            <div className='col mx-1'>
                            <div className='f-14 bold pb-2'>Colación Tarde</div>
                            </div>
                            <div className='col mx-1'>
                            <div className='f-14 bold pb-2'>Cena</div>
                            </div>
                        </div>
                        {state.equivalencias.map((item:any, i:any) =>{
                            let _value = item.value > 0 ?  item.value - item.distribucion.a - item.distribucion.b - item.distribucion.c - item.distribucion.d - item.distribucion.e : 0;
                            return (
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='col'>
                                        <div className='f-14 bold'>{item.alimento}</div>
                                    </div>
                                    <div className='col-1 m-1'>
                                        <div  className={`chakra-input css-1omz1ot input-sm d-flex justify-content-center align-items-center ${_value < 0 ? 'text-danger':'text-success'}`}>{_value}</div>
                                    </div>
                                   
                                    <div className='col m-1'>
                                
                                        <input value={item.distribucion.a} onChange={(evt)=>{
                                            item.distribucion.a = parseInt(evt.currentTarget.value)
                                            setState({...state})

                                         }} type="number" className='chakra-input css-1omz1ot input-sm'></input>
                                    </div>
                                    <div className='col m-1'>
                                        <input value={item.distribucion.b} onChange={(evt)=>{
                                            item.distribucion.b = parseInt(evt.currentTarget.value)
                                            setState({...state})

                                         }}   type="number" className='chakra-input css-1omz1ot input-sm'></input>
                                    </div>
                                    <div className='col m-1'>
                                        <input value={item.distribucion.c} onChange={(evt)=>{
                                            item.distribucion.c = parseInt(evt.currentTarget.value)
                                            setState({...state})

                                         }}  type="number" className='chakra-input css-1omz1ot input-sm'></input>
                                    </div>
                                    <div className='col m-1'>
                                        <input value={item.distribucion.d} onChange={(evt)=>{
                                            item.distribucion.d = parseInt(evt.currentTarget.value)
                                            setState({...state})

                                         }}  type="number" className='chakra-input css-1omz1ot input-sm'></input>
                                    </div>
                                    <div className='col m-1'>
                                        <input value={item.distribucion.e} onChange={(evt)=>{
                                            item.distribucion.e = parseInt(evt.currentTarget.value)
                                            setState({...state})

                                         }}  type="number" className='chakra-input css-1omz1ot input-sm'></input>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='col-3'>

                        <div className='d-flex mt-3'>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img  src='/assets/icons/desayuno.svg'></img>
                                    <div className='f-12'>Desayuno</div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='text-gray f-14'>Energia: <span className='f-14 bold'>{resumenkcal('a').kcal.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Pro: <span className='f-14 bold'>{resumenkcal('a').pro.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Lip: <span className='f-14 bold'>{resumenkcal('a').lip.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>HCO: <span className='f-14 bold'>{resumenkcal('a').hco.toFixed(1)}</span></div>
                            </div>
                           
                
                        </div>

                        <div className='d-flex mt-3'>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img  src='/assets/icons/desayuno2.svg'></img>
                                    <div className='f-12'>Colación Mañana</div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='text-gray f-14'>Energia: <span className='f-14 bold'>{resumenkcal('b').kcal.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Pro: <span className='f-14 bold'>{resumenkcal('b').pro.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Lip: <span className='f-14 bold'>{resumenkcal('b').lip.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>HCO: <span className='f-14 bold'>{resumenkcal('b').hco.toFixed(1)}</span></div>
                            </div>
                           
                
                        </div>
                        <div className='d-flex mt-3'>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img  src='/assets/icons/comida.svg'></img>
                                    <div className='f-12'>Comdia</div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='text-gray f-14'>Energia: <span className='f-14 bold'>{resumenkcal('c').kcal.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Pro: <span className='f-14 bold'>{resumenkcal('c').pro.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Lip: <span className='f-14 bold'>{resumenkcal('c').lip.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>HCO: <span className='f-14 bold'>{resumenkcal('c').hco.toFixed(1)}</span></div>
                            </div>
                           
                
                        </div>

                        <div className='d-flex mt-3'>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img  src='/assets/icons/comida2.svg'></img>
                                    <div className='f-12'>Colación Tarde</div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='text-gray f-14'>Energia: <span className='f-14 bold'>{resumenkcal('d').kcal.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Pro: <span className='f-14 bold'>{resumenkcal('d').pro.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Lip: <span className='f-14 bold'>{resumenkcal('d').lip.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>HCO: <span className='f-14 bold'>{resumenkcal('d').hco.toFixed(1)}</span></div>
                            </div>
                           
                
                        </div>
                        <div className='d-flex mt-3'>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img  src='/assets/icons/cena.svg'></img>
                                    <div className='f-12'>Cena</div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='text-gray f-14'>Energia: <span className='f-14 bold'>{resumenkcal('e').kcal.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Pro: <span className='f-14 bold'>{resumenkcal('e').pro.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>Lip: <span className='f-14 bold'>{resumenkcal('e').lip.toFixed(1)}</span></div>
                                <div className='text-gray f-14'>HCO: <span className='f-14 bold'>{resumenkcal('e').hco.toFixed(1)}</span></div>
                            </div>
                           
                
                        </div>

                    </div>
                </div>

                <div className='d-flex px-3 align-items-center mt-5'>
                            <div className='col'>
                                <Renderif isTrue={state.generatingia}>
                                    <div className='d-flex align-items-center'>
                                        <div className="spinner-border text-primary"></div>
                                        <div className="ms-3">
                                            <div className='f-14 text-green bold'>Generando Plan Alimenticio</div>
                                            <div className='f-14 text-gray'>Podria tardar unos minutos. no cierre esta pestaña.</div>
                                        </div>
                                    </div>
                                </Renderif>
                            </div>

                            <div>
                                <div className={`bv-cta ${state.generatingia ? 'bv-cta--disabled' : 'bv-cta--success'} w-100 ms-2`} onClick={()=>{ 

                                    // equivalencias:[
                                    //     {alimento:'VERDURAS', kcal:25, pro:2, lip:0, hco:4, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'FRUTAS', kcal:60, pro:0, lip:0, hco:15, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'CEREALES S/G', kcal:70, pro:2, lip:0, hco:15, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'CEREALES C/G', kcal:115, pro:2, lip:5, hco:15, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'Leguminosas', kcal:120, pro:8, lip:1, hco:20, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'AOA MBAG', kcal:40, pro:7, lip:1, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'AOA BAG', kcal:55, pro:7, lip:3, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'AOA MAG', kcal:75, pro:7, lip:5, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'AOA AAG', kcal:100, pro:7, lip:8, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'LECHE DES', kcal:95, pro:9, lip:2, hco:12, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'LECHE SEMI', kcal:110, pro:9, lip:4, hco:12, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'LECHE ENTERA', kcal:150, pro:9, lip:8, hco:12, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'LECHE C/A', kcal:200, pro:8, lip:5, hco:30, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'ACEITES S/P', kcal:45, pro:0, lip:5, hco:0, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'ACEITES C/P', kcal:70, pro:3, lip:5, hco:3, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'AZUCAR S/G', kcal:40, pro:0, lip:0, hco:10, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    //     {alimento:'AZUCAR C/G', kcal:85, pro:0, lip:5, hco:10, value:0, distribucion:{a:0, b:0, c:0, d:0, e:0}},
                                    
                                    // ],


                                    let body = {
                                        verduras: {
                                            desayuno: state.equivalencias[0].distribucion.a,
                                            colacionManana: state.equivalencias[0].distribucion.b,
                                            comida: state.equivalencias[0].distribucion.c,
                                            colacionTarde: state.equivalencias[0].distribucion.d,
                                            cena: state.equivalencias[0].distribucion.e,
                                        },
                                        frutas: {
                                            desayuno: state.equivalencias[1].distribucion.a,
                                            colacionManana: state.equivalencias[1].distribucion.b,
                                            comida: state.equivalencias[1].distribucion.c,
                                            colacionTarde: state.equivalencias[1].distribucion.d,
                                            cena: state.equivalencias[1].distribucion.e,
                                        },
                                        cerealesSG: {
                                            desayuno: state.equivalencias[2].distribucion.a,
                                            colacionManana: state.equivalencias[2].distribucion.b,
                                            comida: state.equivalencias[2].distribucion.c,
                                            colacionTarde: state.equivalencias[2].distribucion.d,
                                            cena: state.equivalencias[2].distribucion.e,

                                        },
                                        cerealesCG: {
                                            desayuno: state.equivalencias[3].distribucion.a,
                                            colacionManana: state.equivalencias[3].distribucion.b,
                                            comida: state.equivalencias[3].distribucion.c,
                                            colacionTarde: state.equivalencias[3].distribucion.d,
                                            cena: state.equivalencias[3].distribucion.e,
                                        },
                                        leguminosas: {
                                            desayuno: state.equivalencias[4].distribucion.a,
                                            colacionManana: state.equivalencias[4].distribucion.b,
                                            comida: state.equivalencias[4].distribucion.c,
                                            colacionTarde: state.equivalencias[4].distribucion.d,
                                            cena: state.equivalencias[4].distribucion.e,
                                        },
                                        aoaMBAG: {
                                            desayuno: state.equivalencias[5].distribucion.a,
                                            colacionManana: state.equivalencias[5].distribucion.b,
                                            comida: state.equivalencias[5].distribucion.c,
                                            colacionTarde: state.equivalencias[5].distribucion.d,
                                            cena: state.equivalencias[5].distribucion.e,
                                        },
                                        aoaBAG: {
                                            desayuno: state.equivalencias[6].distribucion.a,
                                            colacionManana: state.equivalencias[6].distribucion.b,
                                            comida: state.equivalencias[6].distribucion.c,
                                            colacionTarde: state.equivalencias[6].distribucion.d,
                                            cena: state.equivalencias[6].distribucion.e,
                                        },
                                        aoaMAG: {
                                            desayuno: state.equivalencias[7].distribucion.a,
                                            colacionManana: state.equivalencias[7].distribucion.b,
                                            comida: state.equivalencias[7].distribucion.c,
                                            colacionTarde: state.equivalencias[7].distribucion.d,
                                            cena: state.equivalencias[7].distribucion.e,
                                        },
                                        aoaAAG: {
                                            desayuno: state.equivalencias[8].distribucion.a,
                                            colacionManana: state.equivalencias[8].distribucion.b,
                                            comida: state.equivalencias[8].distribucion.c,
                                            colacionTarde: state.equivalencias[8].distribucion.d,
                                            cena: state.equivalencias[8].distribucion.e,
                                        },
                                        lecheDes: {
                                            desayuno: state.equivalencias[9].distribucion.a,
                                            colacionManana: state.equivalencias[9].distribucion.b,
                                            comida: state.equivalencias[9].distribucion.c,
                                            colacionTarde: state.equivalencias[9].distribucion.d,
                                            cena: state.equivalencias[9].distribucion.e,
                                        },
                                        lecheSemi: {
                                            desayuno: state.equivalencias[10].distribucion.a,
                                            colacionManana: state.equivalencias[10].distribucion.b,
                                            comida: state.equivalencias[10].distribucion.c,
                                            colacionTarde: state.equivalencias[10].distribucion.d,
                                            cena: state.equivalencias[10].distribucion.e,
                                        },
                                        lecheEntera: {
                                            desayuno: state.equivalencias[11].distribucion.a,
                                            colacionManana: state.equivalencias[11].distribucion.b,
                                            comida: state.equivalencias[11].distribucion.c,
                                            colacionTarde: state.equivalencias[11].distribucion.d,
                                            cena: state.equivalencias[11].distribucion.e,
                                        },
                                        lecheCA: {
                                            desayuno: state.equivalencias[12].distribucion.a,
                                            colacionManana: state.equivalencias[12].distribucion.b,
                                            comida: state.equivalencias[12].distribucion.c,
                                            colacionTarde: state.equivalencias[12].distribucion.d,
                                            cena: state.equivalencias[12].distribucion.e,
                                        },
                                        aceitesSP: {
                                            desayuno: state.equivalencias[13].distribucion.a,
                                            colacionManana: state.equivalencias[13].distribucion.b,
                                            comida: state.equivalencias[13].distribucion.c,
                                            colacionTarde: state.equivalencias[13].distribucion.d,
                                            cena: state.equivalencias[13].distribucion.e,
                                        },
                                        aceitesCP: {
                                            desayuno: state.equivalencias[14].distribucion.a,
                                            colacionManana: state.equivalencias[14].distribucion.b,
                                            comida: state.equivalencias[14].distribucion.c,
                                            colacionTarde: state.equivalencias[14].distribucion.d,
                                            cena: state.equivalencias[14].distribucion.e,
                                        },
                                        azucarSG: {
                                            desayuno: state.equivalencias[15].distribucion.a,
                                            colacionManana: state.equivalencias[15].distribucion.b,
                                            comida: state.equivalencias[15].distribucion.c,
                                            colacionTarde: state.equivalencias[15].distribucion.d,
                                            cena: state.equivalencias[15].distribucion.e,
                                        },
                                        azucarCG: {
                                            desayuno: state.equivalencias[16].distribucion.a,
                                            colacionManana: state.equivalencias[16].distribucion.b,
                                            comida: state.equivalencias[16].distribucion.c,
                                            colacionTarde: state.equivalencias[16].distribucion.d,
                                            cena: state.equivalencias[16].distribucion.e,
                                        },
                                        FoodPlanDto: [
                                          {
                                            order: "desayuno",
                                            energy: parseFloat(resumenkcal('a').kcal.toFixed(1)),
                                            protein: parseFloat(resumenkcal('a').pro.toFixed(1)),
                                            fat: parseFloat(resumenkcal('a').lip.toFixed(1)),
                                            carbohydrates: parseFloat(resumenkcal('a').hco.toFixed(1))
                                          },
                                          {
                                            order: "colacionManana",
                                            energy: parseFloat(resumenkcal('b').kcal.toFixed(1)),
                                            protein: parseFloat(resumenkcal('b').pro.toFixed(1)),
                                            fat: parseFloat(resumenkcal('b').lip.toFixed(1)),
                                            carbohydrates: parseFloat(resumenkcal('b').hco.toFixed(1))
                                          },
                                          {
                                            order: "comida",
                                            energy: parseFloat(resumenkcal('c').kcal.toFixed(1)),
                                            protein: parseFloat(resumenkcal('c').pro.toFixed(1)),
                                            fat: parseFloat(resumenkcal('c').lip.toFixed(1)),
                                            carbohydrates: parseFloat(resumenkcal('c').hco.toFixed(1))
                                          },
                                          {
                                            order: "colacionTarde",
                                            energy: parseFloat(resumenkcal('d').kcal.toFixed(1)),
                                            protein: parseFloat(resumenkcal('d').pro.toFixed(1)),
                                            fat: parseFloat(resumenkcal('d').lip.toFixed(1)),
                                            carbohydrates: parseFloat(resumenkcal('d').hco.toFixed(1))
                                          },
                                          {
                                            order: "cena",
                                            energy: parseFloat(resumenkcal('e').kcal.toFixed(1)),
                                            protein: parseFloat(resumenkcal('e').pro.toFixed(1)),
                                            fat: parseFloat(resumenkcal('e').lip.toFixed(1)),
                                            carbohydrates: parseFloat(resumenkcal('e').hco.toFixed(1))
                                          }
                                        ]
                                      }

                                      console.log(body);


                                    setState({...state, generatingia:true, generated:false})
                                    setTimeout(()=>{
                                        setState({...state, generatingia:false, generated:true})
                                    },5000)
                                }}>
                                    <div>Generar Plan Alimenticio IA</div>
                                </div>
                                
                            </div>
                            <div className='mx-2'></div>
                          
                                <div>
                                    <div className={`bv-cta ${state.generated ? 'bv-cta--success': 'bv-cta--disabled'} w-100`} onClick={async ()=>{
                                        // onSaveMeasures()

                                        let _breakfast = _recipes_.filter((item) => item.mealTime.includes('BREAKFAST'))
                                        let _lunch = _recipes_.filter((item) => item.mealTime.includes('LUNCH'))
                                        let _dinner = _recipes_.filter((item) => item.mealTime.includes('DINNER'))

                                        // let body = {
                                        //     day: YYYYMMDD(state.day_active.toISOString()),
                                        //     breakfast: state.recipes.breakfast.id,
                                        //     meal: state.recipes.meal.id,
                                        //     dinner: state.recipes.dinner.id
                                        // }

                                        for (let i = 0; i < 7; i++) {

                                             let body = {
                                                day: YYYYMMDD(addDays(state.week_range + i, new Date()).toISOString()),
                                                breakfast: _breakfast[i].id,
                                                meal: _lunch[i].id,
                                                dinner: _dinner[i].id
                                            }
                                            let save = await _Service('POST', `patients/${id}/meal-plans`, body)
                                            console.log(save)
                                           
                                            
                                        }
                                    }}>
                                        <div>Guardar</div>
                                    </div>
                                </div>
                           
                            
                </div>
                <Renderif isTrue={state.generated === true && !state.generatingia}>
                    <div className='d-flex mt-4'>
                       
                   
                       <div className='col'>
                            <div className='d-flex'>
                               {/* <Renderif isTrue={state.week_range > 1}> */}
                                <div className='bv-icon bv-icon--mid cursor-pointer me-2' onClick={()=>{
                                    setState({...state, week_range:state.week_range - 1})
                                }}>
                                    <RxDoubleArrowLeft size={30} color="#2CAD6E"></RxDoubleArrowLeft>
                                </div>
                               {/* </Renderif> */}
                                <div className='mt-1 ps-2'>
                                    <div className='f-14 text-gray'>{weekday[addDays(state.week_range, new Date()).getDay()]}</div>
                                    <div className='bold'>{addDays(state.week_range, new Date()).getDate()}<span className='f-10 text-gray'> / {months[addDays(state.week_range, new Date()).getMonth()]}</span></div>
                                </div>
                            </div>
                       </div>
                       <div className='col'>
                            <div className='mt-1 ps-2'>
                                <div className='f-14 text-gray'>{weekday[addDays(state.week_range + 1, new Date()).getDay()]}</div>
                                <div className='bold'>{addDays(state.week_range + 1, new Date()).getDate()}<span className='f-10 text-gray'> / {months[addDays(state.week_range + 1, new Date()).getMonth()]}</span></div>
                            </div>
                       </div>
                       <div className='col'>
                            <div className='mt-1 ps-2'>
                                <div className='f-14 text-gray'>{weekday[addDays(state.week_range + 2, new Date()).getDay()]}</div>
                                <div className='bold'>{addDays(state.week_range + 2, new Date()).getDate()}<span className='f-10 text-gray'> / {months[addDays(state.week_range + 2, new Date()).getMonth()]}</span></div>
                            </div>
                       </div>
                       <div className='col'>
                            <div className='mt-1 ps-2'>
                                <div className='f-14 text-gray'>{weekday[addDays(state.week_range + 3, new Date()).getDay()]}</div>
                                <div className='bold'>{addDays(state.week_range + 3, new Date()).getDate()}<span className='f-10 text-gray'> / {months[addDays(state.week_range + 3, new Date()).getMonth()]}</span></div>
                            </div>
                       </div>
                       <div className='col'>
                            <div className='mt-1 ps-2'>
                                <div className='f-14 text-gray'>{weekday[addDays(state.week_range + 4, new Date()).getDay()]}</div>
                                <div className='bold'>{addDays(state.week_range + 4, new Date()).getDate()}<span className='f-10 text-gray'> / {months[addDays(state.week_range + 4, new Date()).getMonth()]}</span></div>
                            </div>
                       </div>
                       <div className='col'>
                            <div className='mt-1 ps-2'>
                                <div className='f-14 text-gray'>{weekday[addDays(state.week_range + 5, new Date()).getDay()]}</div>
                                <div className='bold'>{addDays(state.week_range + 5, new Date()).getDate()}<span className='f-10 text-gray'> / {months[addDays(state.week_range + 5, new Date()).getMonth()]}</span></div>
                            </div>
                       </div>
                       <div className='col'>
                            <div className='d-flex'>
                                <div className='mt-1 ps-2'>
                                    <div className='f-14 text-gray'>{weekday[addDays(state.week_range + 6, new Date()).getDay()]}</div>
                                    <div className='bold'>{addDays(state.week_range + 6, new Date()).getDate()}<span className='f-10 text-gray'> / {months[addDays(state.week_range + 6, new Date()).getMonth()]}</span></div>
                                </div>
                                <div className='col'></div>
                                <div className='bv-icon bv-icon--mid cursor-pointer me-2' onClick={()=>{
                                    setState({...state, week_range:state.week_range + 1})
                                }}>
                                    <RxDoubleArrowRight size={30} color="#2CAD6E"></RxDoubleArrowRight>
                                </div>
                            </div>
                            
                       </div>
                    </div>
                    <div className='d-flex flex-column mt-0'>
                    
                    
                                    <div className='col d-flex mt-2'>

                                        {_recipes_.filter((item) => item.mealTime.includes('BREAKFAST')).map((recipe, i)=>{
                                            return (

                                                <div key={i} className='col'>
                                                    <div className='bg-white p-2 h-100 m-1 r-2 bv-shadow '>
                                                    <div className='f-14 text-green bold'>{recipe?.name}</div>
                                                    <div className='f-14 text-gray text-ellipsis-2'>{recipe?.preparation}</div>
                                                    <div className="d-flex flex-wrap mt-2">
                                                        {
                                                        [...recipe.mealCategories, ...recipe.mealSubCategories].map((item:any,i:any)=>{
                                                            return (
                                                            <div key={i} className="pill pill--sm pill--gren">
                                                                <div>{item}</div>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>

                                    <div className='col d-flex mt-2'>

                                        {_recipes_.filter((item) => item.mealTime.includes('LUNCH')).map((recipe, i)=>{
                                            return (

                                                <div key={i} className='col'>
                                                    <div className='bg-white p-2 h-100 m-1 r-2 bv-shadow '>
                                                    <div className='f-14 text-green bold'>{recipe?.name}</div>
                                                    <div className='f-14 text-gray text-ellipsis-2'>{recipe?.preparation}</div>
                                                    <div className="d-flex flex-wrap mt-2">
                                                        {
                                                        [...recipe.mealCategories, ...recipe.mealSubCategories].map((item:any,i:any)=>{
                                                            return (
                                                            <div key={i} className="pill pill--sm pill--gren">
                                                                <div>{item}</div>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>

                                    <div className='col d-flex mt-2'>

                                        {_recipes_.filter((item) => item.mealTime.includes('DINNER')).map((recipe, i)=>{
                                            return (

                                                <div key={i} className='col'>
                                                    <div className='bg-white p-2 h-100 m-1 r-2 bv-shadow '>
                                                    <div className='f-14 text-green bold'>{recipe?.name}</div>
                                                    <div className='f-14 text-gray text-ellipsis-2'>{recipe?.preparation}</div>
                                                    <div className="d-flex flex-wrap mt-2">
                                                        {
                                                        [...recipe.mealCategories, ...recipe.mealSubCategories].map((item:any,i:any)=>{
                                                            return (
                                                            <div key={i} className="pill pill--sm pill--gren">
                                                                <div>{item}</div>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                        
                        
                    




                    </div>
                </Renderif>



                
               
               


            
            </div>
        </Renderif>

        <Renderif isTrue={state.tab_active === 7}>

            <div className="container-fluid p-0 mt-3">

                
                <div className='d-flex mb-2'>
                    
                    <Renderif isTrue={!state.show_new_note}>
                        <div className='d-flex w-100'>
                            <div className='col'></div>
                            <div>
                                <div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
                                    // onSavePatient()
                                    state.show_new_note = true;
                                    setState({...state})
                                }}>
                                    <div>NUEVA NOTA</div>
                                </div>
                            </div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={state.show_new_note}>
                        <div className='ps-0 d-flex borde w-100'>
                        <div className="d-flex align-items-center cursor-pointer mb-2" onClick={()=>{
                             state.new_note = {
                                id:0,
                                prescribedKcal:"",
                                consultationNotes:"",
                                appraisalNotes:"",
                                evolutionNotes:"",
                                prescription:"",
                                messages:[] as any,
                             }
                             state.editing_note = false;
                             state.show_new_note = false
                            setState({...state})
                        }}>
                        <div className="md-icon md-icon--mid">
                            <MdArrowBack color="#2CAD6E"></MdArrowBack>
                            </div>
                            <div className="text-green bold">Notas</div>
                        </div>
                        <div className='col'></div>
                            <div>
                            <div className='bv-cta bv-cta--success w-100 ms-2' onClick={()=>{ 
                                onSaveEvolutionNote()
                            }}>
                                <div>GUARDAR</div>
                            </div>
                            </div>
                        </div>
                    </Renderif>
                </div>
                <Renderif isTrue={state.show_new_note}>

            
                <div className='container bg-light py-3 r-2'>
                        <div className='d-flex'>
                            {/* <div className='col-2'>
                                <div className='d-flex flex-column'>
                                    <div className='f-14'>Fecha de registro</div>
                                    <input className='chakra-input css-1omz1ot text-gray' type='date'></input>
                                </div>
                            </div> */}
                            <div className='col-3'>
                                <div className='d-flex flex-column'>
                                    <div className='f-14 text-gray'>Kcal. Prescritas</div>
                                    <input value={state.new_note.prescribedKcal} onChange={(evt)=>{
                                        state.new_note.prescribedKcal = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='chakra-input css-1omz1ot text-gray' type='number'></input>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='col'>
                                <div>
                                    <div className='d-flex flex-column'>
                                        <div className='f-14 text-gray'>Notas de consulta</div>
                                        <textarea value={state.new_note.consultationNotes} onChange={(evt)=>{
                                        state.new_note.consultationNotes = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='border  r-2 text-gray p-1' rows={5}></textarea>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex flex-column'>
                                        <div className='f-14 text-gray'>Notas de valoración</div>
                                        <textarea value={state.new_note.appraisalNotes} onChange={(evt)=>{
                                        state.new_note.appraisalNotes = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='border  r-2 text-gray p-1' rows={5}></textarea>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex flex-column'>
                                        <div className='f-14 text-gray'>Notas de evolución</div>
                                        <textarea value={state.new_note.evolutionNotes} onChange={(evt)=>{
                                        state.new_note.evolutionNotes = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='border  r-2 text-gray p-1' rows={5}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='col ps-3'>
                                <div>
                                    <div className='d-flex flex-column'>
                                        <div className='f-14 text-gray'>Preescripción</div>
                                        <textarea value={state.new_note.prescription} onChange={(evt)=>{
                                        state.new_note.prescription = evt.currentTarget.value;
                                        setState({...state})
                                    }} className='border  r-2 text-gray p-1' rows={5}></textarea>
                                    </div>
                                </div>

                                <div className='d-flex align-items-end'>
                                    <div className='col'>
                                        <div className='d-flex flex-column'>
                                            <div className='f-14'>Mensajes</div>
                                            <input value={state.message} onChange={(evt) => {
                                                state.message = evt.currentTarget.value
                                                setState({...state})
                                            }} className='chakra-input css-1omz1ot text-gray' type='text'></input>
                                        </div>
                                    </div>
                                    <div className='bv-icon bv-icon--md cursor-pointer pt-1' onClick={()=>{
                                         state.new_note.messages.push(state.message)
                                         state.message = ""
                                         setState({...state})
                                    }}>
                                        <BsPlusCircle size={24} color="green"></BsPlusCircle>
                                    </div>
                                 
                                </div>

                                <div>
                                    {state.new_note.messages.map((msg:any, i:any)=>{
                                        return (
                                            <div className='d-flex b-b py-1'>
                                                <div className='f-14 text-gray bold'>{msg}</div>
                                            </div>
                                        )
                                    })}
                                </div>

                               
                            </div>
                        </div>
                       
                </div>
                </Renderif>
                <Renderif isTrue={!state.show_new_note}>
                <div>
                    <table className="">
                        <thead className="bg-light rounded-3">
                            <tr>
                                <td>Fecha</td>
                                <td>Kilocalorias Prescritas</td>
                                <td>Notas de Consulta</td>
                                <td>Notas de Valoración</td>
                                <td>Notas Evolución</td>
                                <td>Prescripción</td>
                             
                                <td>Mensajes</td>
                                
                            </tr>
                        </thead>
                        
                        <tbody>
                            {state.evolution_notes.map((item:any, i:any)=>{
                               
                                return (
                                    <tr>
                                    <td>{item.createdAt}</td>
                                    <td>{item.prescribedKcal}</td>
                                    <td>
                                            <div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.consultationNotes !== ''  ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
                                    </td>
                                    <td>
                                            <div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.appraisalNotes !== ''  ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
                                    </td>
                                    <td>
                                            <div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.appraisalNotes !== ''  ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
                                    </td>
                                    
                                    <td>
                                        <div className='bv-icon bv-icon--md'>
                                            <FaRegFile  size={20} color="green"></FaRegFile>
                                        </div>
                                    </td>
                                   
                                    <td>
                                        <div className='bv-icon bv-icon--md cursor-pointer' onClick={()=>{
                                            console.log(item)
                                            state.editing_note = true
                                            state.show_new_note = true
                                            state.new_note = item;
                                            setState({...state})
                                        }}>
                                            <HiOutlineInbox  size={25} color="green"></HiOutlineInbox>
                                        </div>
                                    </td>
                                
                                    
                                </tr>
                                )
                            })}
                           
                        
                        
                        </tbody>
                    </table>
                </div>
                </Renderif>
			</div>

        </Renderif>

        <Renderif isTrue={state.tab_active === 8}>

                        {/* AQUI */}

            <div className="container-fluid p-0 mt-3">
                    <table className="">
                        <thead className="bg-light rounded-3">
                            <tr>
                                <td>
                                    Especialista
                                </td>
                                <td>Correo electrónico</td>
                              
                                <td>Sucursal</td>
                                <td>Fecha</td>
                                <td>Hora</td>
                                <td>Link</td>
                                
                            </tr>
                        </thead>
                        
                        <tbody>
                            {state.citas.map((item: any, i)=>{
                                console.log(item)
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='bv-icon bv-icon--mid'>
                                                    <FaRegUserCircle size={50} color="#2CAD6E"></FaRegUserCircle>
                                                </div>
                                                <div className='ps-2'>{item?.specialistName}</div>

                                            </div>
                                        </td>
                                        <td>{item?.specialist}</td>
                        
                                        <td>{item?.isOnline === true ? 'Online' : 'Presencial'}</td>
                                        <td>{item?.init.split('T')[0]}</td>
                                        <td>{item?.init.split('T')[1].replace('.000Z','')}</td>
                                        <td className='text-center'>
                                            <div className="bv-icon bv-icon--md cursor-pointer" onClick={()=>{
                                                window.open('_blank', item?.link)
                                            }}>
                                                <FaLink size={18} color="#2CAD6E"></FaLink>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            
                           
                           
                            
                        
                        </tbody>
                    </table>
            </div>

        </Renderif>

        <div className='my-5 py-5'></div>
        <div className='my-5 py-5'></div>


    </div>
  )
}

const Renderif = ({children, isTrue}:any) => {
    return isTrue ? children :null
} 

const Checkbox = ({checked = false, text = "", onChange = () =>{}}: any) => {

    const [state, setState] = useState({
        checked:false
    })

    const _onChange = () => {
        setState({...state, checked:!state.checked})
        onChange(state.checked)
    }

    useEffect(()=>{
        setState({...state, checked})
    },[checked])

    return (
        <div className='d-flex cursor-pointer align-items-center flex-row w-content me-3' onClick={()=>{
            _onChange()
        }}>
           
            <div>
                <div className='bv-icon'>
                    <Renderif isTrue={state.checked}>
                        <BsCheckSquareFill color='green' size={30}></BsCheckSquareFill>
                    </Renderif>
                    <Renderif isTrue={!state.checked}>
                        <BsSquare color="gray" size={30}></BsSquare>
                    </Renderif>
                    
                </div>
            </div>
            <div>
                <div className='text-gray f-14'>{text}</div>
            </div>
        </div>
    
    )
}  

const AlimentosSelect = ({text=""}) => {

    const [state, setState] = useState({
        show:false
    })

    return (
        <div className='d-flex flex-column flex-wrap mt-3'>
            <Checkbox text={text} onChange={(value:boolean)=>{
                setState({...state, show:!value})
            }}></Checkbox>
            <Renderif isTrue={state.show}>
                <div className='d-flex flex-wrap'>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>Verduras</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="100%" src='/assets/icons/verduras.svg'></img>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>Fruta</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="100%" src='/assets/icons/frutas.svg'></img>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>Cereal</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="100%" src='/assets/icons/cereal.svg'></img>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>Grasas</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="100%" src='/assets/icons/grasas.svg'></img>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>Legumbres</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="100%" src='/assets/icons/legumbres.svg'></img>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>A.O.A</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="100%" src='/assets/icons/aoa.svg'></img>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>Leche</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="80%" src='/assets/icons/leche.svg'></img>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center pe-1'>
                        <div className='f-10 bold text-gray'>Azucares</div>
                        <div className='bv-icon bv-icon--mid'>
                            <img width="100%" src='/assets/icons/azucares.svg'></img>
                        </div>
                    </div>
                </div>
            </Renderif>
        
        </div>
    )
}

const MultiSelect = ({text="", onChange = () => {}, value, placeholder}:any) => {

    const [state, setState] = useState({
        open:false,
        value: value as any
    })

    const onSelect = (key:any, _value:any) => {
      
      
        if(!_value){
            console.log(state.value)
            state.value.push(key)
        }else{
            state.value = state.value.filter((i:any) => i !== key)
        }
        setState({...state})
        onChange(state.value);
        console.log(state.value)
    } 

    useEffect(()=>{
        setState({...state, value})
        return () => {}
    },[value])


    return (
        <>
            <Renderif isTrue={state.open}>
            <div className='input-overlay' onClick={()=>{setState({...state, open:false})}}></div>
            </Renderif>
            <div className='f-14 text-gray'>{text}</div>
            <div className='chakra-input css-1omz1ot cursor-pointer bg-white d-flex align-items-center' onClick={()=>{
                setState({...state, open:true})
            }}>
                <div className='col'>
                    <div className='f-14 text-gray'>Selección</div>
                </div>
                <div className='bv-badge'>{state.value?.length}</div>
            </div>

            
           <Renderif isTrue={state.open}>
                <div className='multi-select'>

                    <Checkbox checked={state.value?.includes('Hijos')} text="Hijos" onChange={(value:any)=>{onSelect('Hijos', value)}} ></Checkbox>   
                    <Checkbox checked={state.value?.includes('Hermanos')} text="Hermanos" onChange={(value:any)=>{onSelect('Hermanos', value)}}></Checkbox>                           
                    <Checkbox checked={state.value?.includes('Madre')} text="Madre" onChange={(value:any)=>{onSelect('Madre', value)}}></Checkbox>   
                    <Checkbox checked={state.value?.includes('Padre')} text="Padre" onChange={(value:any)=>{onSelect('Padre', value)}}></Checkbox>   
                    <Checkbox checked={state.value?.includes('Tios Maternos')} text="Tios Maternos" onChange={(value:any)=>{onSelect('Tios Maternos', value)}}></Checkbox>   
                    <Checkbox checked={state.value?.includes('Tios Paternos')} text="Tios Paternos" onChange={(value:any)=>{onSelect('Tios Paternos', value)}}></Checkbox>   
                    <Checkbox checked={state.value?.includes('Abuela Materna')} text="Abuela Materna" onChange={(value:any)=>{onSelect('Abuela Materna', value)}}></Checkbox>   
                    <Checkbox checked={state.value?.includes('Abuelo Materno')} text="Abuelo Materno" onChange={(value:any)=>{onSelect('Abuelo Materno', value)}}></Checkbox>   
                    <Checkbox checked={state.value?.includes('Abuela Paterna')} text="Abuela Paterna" onChange={(value:any)=>{onSelect('Abuela Paterna', value)}}></Checkbox>   
                    <Checkbox checked={state.value?.includes('Abuelo Paterno')} text="Abuelo Paterno" onChange={(value:any)=>{onSelect('Abuelo Paterno', value)}}></Checkbox>   
                </div>
           </Renderif>
        </>
    )
}
 const SearchAliment = ({text="", value = [], aliments, onSelect = () => {}}:any) => {
    const [state, setState] = useState({
        aliments_filter:[] as any,
        search: ""
    })
   const _onSelect = (aliment:any) => {
        setState({...state, search:""})
        onSelect(aliment)
   }


    return (
        <div className='d-flex flex-column  position-relative'>
            <div className='f-14 text-gray'>{text}</div>
            <input className='chakra-input css-1omz1ot' type={'text'} value={state.search} onChange={(evt)=>{
              
                if(evt.currentTarget.value.length > 3){
                    let aliemnts = aliments.filter((item:any)=> item.alimentos.toUpperCase().includes(evt.currentTarget.value.toUpperCase()))
                    setState({...state, aliments_filter:aliemnts, search:evt.currentTarget.value})
                }else{
                    setState({...state, aliments_filter:[], search:evt.currentTarget.value})
                }

            }} placeholder="Buscar alimentos"></input>
            
        

            <Renderif isTrue={state.search.length > 3}>
                <div className='options-aliments'>
                    <Renderif isTrue={state.aliments_filter.length === 0}>
                        <div className='p-2 text-gray'>Sin concidencias</div>

                    </Renderif>
                    
                    {
                        state.aliments_filter.map((aliment:any, i:any)=>{
                            return (
                                <div className='aliment-item text-gray' onClick={()=>{
                                    _onSelect(aliment)
                                }} key={i}>{aliment.alimentos}</div>
                            )
                        })
                    }
                </div>
            </Renderif>
        </div>
    )
 }
