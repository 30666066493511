import * as React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  HStack,
  Button,
  useToast,
  ModalFooter,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { Input } from "shared";
import { useCreateCategory } from "../../api";
import { CreateCategoryForm } from "types/categories";

type CreateCategoryModalProps = Readonly<{
  isOpen: boolean;
  onClose(): void;
}>;

type CategoryValues = Readonly<{
  category: string;
}>;

const CreateCategoryModal: React.FC<CreateCategoryModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoryValues>({
    mode: "onSubmit",
  });
  const { mutateAsync: createCategory, isLoading: isCreatingCategory } =
    useCreateCategory();
  const toast = useToast();

  const onSubmit = async (values: CategoryValues) => {
    try {
      const formData = new FormData();
      const newCategory:CreateCategoryForm = {
        // TO-DO: implement image input, or make this a constant outside of this page
        imageRef:"IMGs-Plan_Test_My_Diet.jpg",
        name: values.category
      }
      formData.append("metadata",JSON.stringify(newCategory));
      await createCategory({file:formData});
      toast({
        title: `"${values.category}" cread@ existosamente`,
        status: "success",
        position: "bottom-left",
      });
      onClose();
    } catch (error) {
      console.error(error);
      toast({
        title: `Error al crear "${values.category}"`,
        status: "error",
        position: "bottom-left",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW="65%" bg="gray.5">
        <ModalHeader color="gray.15" fontSize="md">
          Nueva Categoría
        </ModalHeader>
        <ModalBody>
          <Input
            label="Nombre de Nueva Categoría"
            error={errors.category?.message || ""}
            {...register("category", { required: "Introduce un nombre" })}
          />
        </ModalBody>
        <ModalFooter>
          <HStack w="100%" spacing={30} justifyContent="space-between">
            <Button
              onClick={handleSubmit(onSubmit)}
              isLoading={isCreatingCategory}
            >
              Crear
            </Button>
            <Button variant="warning" onClick={onClose}>
              Cancelar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateCategoryModal };
