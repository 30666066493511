import * as React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  HStack,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { Input } from "shared";
import { PlanDocument } from "types/plan";

type DocumentModalProps = Readonly<{
  isOpen: boolean;
  onClose(): void;
  onFinish(document: PlanDocument): void;
  file: File;
}>;

type DocumentValues = Readonly<{
  name: string;
}>;

const DocumentModal: React.FC<DocumentModalProps> = ({
  isOpen,
  onClose,
  onFinish,
  file,
}) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm<DocumentValues>({
    mode: "onSubmit",
  });

  const done = () => {
    const document: PlanDocument = {
        id: 0,
        name: (getValues() as DocumentValues).name,
        fileName: file.name,
        infoName: file.name,
        file
    };
    onFinish(document);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW="65%" bg="gray.5">
        <ModalHeader color="gray.15" fontSize="md">
          Nueva Documento
        </ModalHeader>
        <ModalBody>
          <Input
            label="Nombre del Nueva Documento"
            error={errors.name?.message || ""}
            {...register("name", { required: "Introduce un nombre" })}
          />
        </ModalBody>
        <ModalFooter>
          <HStack w="100%" spacing={30} justifyContent="space-between">
            <Button
              onClick={done}
            >
              Crear
            </Button>
            <Button variant="warning" onClick={onClose}>
              Cancelar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { DocumentModal };
