import { Box, Heading, HStack, Input, Radio, RadioGroup, SimpleGrid, Stack, VStack } from "@chakra-ui/react";
import { usePatientFormDetail } from "modules/Patients/api/queries/usePatientFormDetail";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "shared";

type RadioAnswer = {
    questionId: string;
    selectedAnswer: string;
}
type InputAnswer = {
    questionId: string;
    value: string;
    answerId: string;
}

const PatientFormDetail: React.FC = () => {
    const { id: patientId, userFormId: userFormId } = useParams();
    const { data, isLoading } = usePatientFormDetail(userFormId!);
    const [radioAnswersData, setRadioAnswersData] = React.useState<RadioAnswer[]>([]);
    const [inputAnswersData, setInputAnswersData] = React.useState<InputAnswer[]>([]);
    const navigate = useNavigate();
    React.useEffect(() => {
        if(data && data.data){
            setRadioAnswersData(data.data.questions.filter(ques => ques.type == "SingleAnswer").map<RadioAnswer>(question => {
                return {
                    questionId: question.id,
                    selectedAnswer: question.answers.find(item => item.selected)!.id
                };
            }));
            setInputAnswersData(data.data.questions.filter(ques => ques.type == "TextField").map<InputAnswer>(question => {
                return {
                    questionId: question.id,
                    value: question.answers[0].label,
                    answerId: question.answers[0].id
                };
            }));
        }
    }, [data]);
    if(isLoading){
        return <>Cargando...</>;
    }else{
        if(data!.status != 200)
        {
            navigate(-1);
        }
        
        return <VStack height="100%" spacing={3} overflow="scroll">
        <HStack width="100%" alignItems="center">
            <BackButton path={-1} />
            <Heading>{data?.data.title}</Heading>
        </HStack>
        
        <SimpleGrid columns={1}>
            {
                data?.data.questions.map((question) => {
                    if (question.type == "TextField") {
                        return (
                            <Box height="auto">
                                <Heading fontSize="xl">{question.title}</Heading>
                                <Input
                                    mx="3"
                                    placeholder=""
                                    w="80%"
                                    value={inputAnswersData?.find(item => item.questionId == question.id)?.value}
                                    isDisabled={true}
                                />
                            </Box>
                        )
                    }
                    else if (question.type == "SingleAnswer") {
                        return (
                            <Box height="auto">
                                <Heading fontSize="xl">{question.title}</Heading>
                                <RadioGroup
                                style={{alignContent:"start"}}
                                    py={2}
                                    name={question.title}
                                    isDisabled={true}
                                    value={radioAnswersData?.find(item => item.questionId == question.id)?.selectedAnswer}>
                                    {question.answers.map((answer) => {
                                        return <Stack
                                        direction="column">
                                            <Radio value={answer.id} my={1} isDisabled={true}>
                                                {answer.label}
                                            </Radio>
                                        </Stack>;
                                    })}
                                </RadioGroup>
                            </Box>
                        )
                    }
                })
            }
        </SimpleGrid>

    </VStack>;
    }
}

export { PatientFormDetail };