import * as React from "react";

import { useAuth } from "contexts";
import { LoadingPage } from "shared";

import AuthenticatedRoutes from "./authenticated-routes";
import AuthRoutes from "./unauthenticated-routes";

const Routes = () => {
  const { tokens, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingPage />;
  }
  console.log(tokens.userId)

  return (
    <React.Fragment>
      {tokens.userId ? (
        <AuthenticatedRoutes role={"admin"} />
      ) : (
        // <AuthenticatedRoutes role={tokens.role!} />
        <AuthRoutes />
      )}
    </React.Fragment>
  );
};

export default Routes;
