import * as React from "react";
import { useToast } from "@chakra-ui/react";

import { UserForm, UserTokens, UserProfile } from "types/user";
import { useLogin, useProfile } from "api";
import { LogIn } from "api/api";

type AuthContextValue = {
  user?: UserProfile;
  tokens: UserTokens;
  isLoading: boolean;
  isLogginIn: boolean;
  login: (form: UserForm) => Promise<void>;
  logout(): void;
  setUser():void;
};

type AuthProviderProps = {
  children?: React.ReactNode;
};

const DEFAULT_TOKENS = {
  planId: undefined,
  accessToken: undefined,
  userId: undefined,
  role: undefined,
};

const ROLES = ["admin", "nutritionist", "gynecologist", "customer"];

const AuthContext = React.createContext<AuthContextValue | null>(null);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [tokens, setTokens] = React.useState<UserTokens>(DEFAULT_TOKENS);
  const { mutateAsync: userLogin, isLoading: isLoginLoading } = useLogin();
  const {
    data: user,
    isLoading: isProfileLoading,
    refetch,
  } = useProfile(tokens.userId!);

  const isLoading = isProfileLoading;

  const toast = useToast();

  React.useEffect(() => {
    const _tokens = localStorage.getItem("userTokens");
    if (_tokens) {
      setTokens(JSON.parse(_tokens));
      refetch();
    }
  }, [isProfileLoading]);

  const setUser = React.useCallback(()=>{
    const _tokens = {
      planId: '0',
      accessToken: '',
      userId: '1',
      role: '',
    };
    setTokens(_tokens);
  },[userLogin,toast])

  

  const login = React.useCallback(
    async (form: UserForm) => {
      try {

        let _user:any = await LogIn(form.email, form.password)
        if(_user.status === 200){
         
          localStorage.setItem('token', _user.data.token)
          const _tokens = {
            planId: '0',
            accessToken: '',
            userId: _user.data.id,
            role: '',
          };
          setTokens(_tokens);
          toast({ title: "¡Bienvenido!", status: "success" });
        }
     
        // const res = await userLogin(form);
        // if (res.data) {
        //   const _tokens = {
        //     planId: res.data.planId,
        //     accessToken: res.data.tokens.accessToken,
        //     userId: res.data.userId,
        //     role: res.data.userType,
        //   };
        //   if (_tokens.role !== 'nutritionist') {
        //     toast({ title: "Usuario no autorizado", status: "error" });
        //     logout();
        //     return;
        //   }
        //   setTokens(_tokens);
        //   localStorage.setItem("userTokens", JSON.stringify(_tokens));
        // }
        // toast({ title: "¡Bienvenido!", status: "success" });
      } catch (error) {
        console.error(error);
        toast({ title: "Ocurrió un error", status: "error" });
      }
    },
    [LogIn, toast]
  );

  const logout = React.useCallback(() => {
    setTokens(DEFAULT_TOKENS);
    localStorage.removeItem("token");
  }, []);

  const value: AuthContextValue = React.useMemo(
    () => ({
      setUser,
      login,
      logout,
      user: user?.data,
      tokens,
      isLoading,
      isLogginIn: isLoginLoading,
    }),
    [login, logout, user, tokens, isLoading, isLoginLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === null) {
    throw new Error("useAuth is not initialized");
  }

  return context;
};

export { AuthProvider, useAuth };
