import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { Meal } from "types/meal";

type MealsResponse = {
  firstPage: boolean;
  lastPage: boolean;
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  content: Meal[];
  size: number;
};

export type MealsParams = {
  page: number;
  search?: string;
};

function useMeals(params: MealsParams) {
  return useQuery<AxiosResponse<MealsResponse>, AxiosError>(
    ["meals", params.page, params?.search],
    () =>
      axios.get(`recipes`, {
        params: {
          pageSize: 300,
          page: params.page,
          search: params?.search,
        },
      }),
    { refetchOnMount: true }
  );
}

export { useMeals };
