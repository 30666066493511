import { Box, Center, chakra, HStack, Icon, Spinner, VStack } from "@chakra-ui/react"
import { usePlanList } from "modules/Plans/api";
import { MealSearchBar } from "modules/Recipes/components/MealSearchBar";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "shared";
import { SCROLL_BAR_STYLES } from "theme/styling/scrollbar";
import { PlanItem } from "types/plan";
import { PlanItemCard } from "./PlanItem";
import * as _ from 'lodash';
import axios from "axios";
import { Service, _Service } from "api/api";


const PlanList: React.FC = () => {
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [planListDisplay, setPlanListDisplay] = useState<PlanItem[]>([]);

	const { data: planList, isLoading: isPlanLoading } = usePlanList({ page: 0, search });

	const onSearch = React.useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const filter = event.target.value;
      setPlanListDisplay([]);

      if (!filter) {
        setSearch('');
        return;
      }

      setSearch(filter);
    }, 275),
    []
  );

	const [state, setSatate] = useState({
		planList:[]
	});


	useEffect(() => {
		console.log("CALL PLANS")

		const init = async () =>{

			let _planes = await _Service('GET', 'plans');
			console.log(_planes)

			// let _planList = await  axios.get(`plan`, {
			// 	params: {
			// 		pageSize: 100,
			// 		page: 0,
			// 		search: ''
			// 	}
			// })
			// console.log(_planList);
			setSatate({...state, planList:_planes.data})

			
		}
		init();
		return () => {}
		// if (planList?.data.content) {
			
			// setPlanListDisplay(planList?.data.content);
		// }
	}, []);

	const memoizedPlanList = React.useMemo(() => {
		return (
			
			<React.Fragment>
				<div className="container">
					<div className="d-flex flex-wrap">
					
						{state.planList?.map((plan: any) => (
							<div className="col-3 px-2">
								<PlanItemCard key={plan.id} plan={plan} />
							</div>
						))}
							
					</div>
				</div>
				{/* <Box
					className="border"
					display="grid"
					gridTemplateColumns="repeat(3, 1fr)"
					gridColumnGap="20"
					gridRowGap="5"
					w="100%"
					h="100%"
					p="0"
					overflowY="auto"
					id="meal-list"
					sx={SCROLL_BAR_STYLES}
				>
					{state.planList?.map((plan: any) => (
					
						<PlanItemCard key={plan.id} plan={plan} />
					))}
				</Box> */}
				{isPlanLoading && (
					<Center w="100%">
						<Spinner size="md" color="green.15" />
					</Center>
				)}
			</React.Fragment>
		);
	}, [state.planList, isPlanLoading]);


	return (
		<>
		<div className="container px-4 pb-3">
			<div className="d-flex">
				<chakra.span fontSize="xl" color="gray.10" me={'1rem'}>
					Mis Planes
				</chakra.span>
				<div className="col"></div>
				<HStack>
					<Icon
						as={IoIosAddCircleOutline}
						role="button"
						color="green.15"
						boxSize="2rem"
						_active={{ transform: "scale(0.8)" }}
						onClick={() => navigate("create")}
					/>
					<chakra.span fontSize="md" color="gray.25">
						Crear Nuevo Plan
					</chakra.span>
				</HStack>

			</div>

		</div>

		<VStack w="100%" h="100%" className="">
			
			{!isPlanLoading ? memoizedPlanList : <LoadingPage />}
		</VStack>
		</>
	);
};

export { PlanList };