import * as React from "react";
import { useEffect, useState } from "react";
import {
  HStack,
  VStack,
  Box,
  Icon,
  Button,
  useToast,
  useDisclosure,
  chakra,
  Spacer,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdClose, MdOutlineCancel } from "react-icons/md";

import {
  Input,
  InputFile,
  Textarea,
  BackButton,
  Select,
  LoadingPage,
  Checkbox,
} from "shared";
import { Meal, MealBaseForm, MealCreateForm } from "types/meal";
import { Nutrient } from "types/nutrient";
import { SCROLL_BAR_STYLES } from "theme/styling/scrollbar";

import { useCategories, useCreateMeal, useDeleteMeal, useMeal, useNutrients } from "../../api";
import { CreateCategoryModal } from "../CreateCategoryModal";
import { useUpdateMeal } from "modules/Recipes/api/mutations/useUpdateMeal";
import { TrashIcon } from "assets/icons/Trash";
import { Service, _Service } from "api/api";
import { Category } from "types/categories";
import { BsTrash } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";

import { LoadingOverlay } from "shared/LoadingPage/LoadingPage";
import Renderif from "utils/renderif";


type NutrientBadgeProps = Readonly<{
  label: string;
  onClick(): void;
}>;

interface Aliment {
	acidoAscorbico: number;
  acidoFolico: number;
  agMonoInsaturados: number;
  agSaturados: number;
  alimentos: string;
  azucarPorEquivalente: number;
  calcio: number;
  cantidadSugerida: string;
  cantidadSugeridaNum: number;
  cargaGlicemica: number;
  colesterol: number;
  energia: number;
  etanol: number;
  fibra: number;
  fosforo: number;
  grupo: string;
  hidratosDeCarbono: number;
  hierro: number;
  hierroNoHem: number;
  id: number;
  ioce: number;
  lipidos: number;
  pesoBrutoRedondeado: number;
  pesoNeto: number;
  poliInsaturado: number;
  potasio: number;
  proteina: number;
  selenio: number;
  sodio: number;
  subGrupo: string;
  unidad: string;
  vitaminaA: number;
};
interface AlimentSelected extends Aliment {
  quantity?: string; 
}
interface Macronutrientes {
  proteinas: number; 
  lipidos: number; 
  hidratos: number;
}
interface Micronutrientes {
  vitaminaA: number; 
  acidoAscorbico: number; 
  acidoFolico: number;
}
interface MealState {
  _loading: boolean,
  _grupos: any[],
  _mealTimes: string[],
  _mealTimesSelected: string[],
	_categories: string[];
  _categoriesSelected: string[];
	_subCategories: string[];
  _subCategoriesSelected: string[]
  
  _aliments: Aliment[];
  _alimentdFiltered: Aliment[];
  _alimentSubCategorias: string[];
  _alimentCategoriaSelected: string;
  _alimentSelected: Aliment | null;
  _alimentsSelected: AlimentSelected[]
  _quantitySelected: string
  _macronutrientes: Macronutrientes
  _micronutrientes: Micronutrientes

};

interface MealFrom extends Meal {

}



const NutrientBadge: React.FC<NutrientBadgeProps> = ({ label, onClick }) => (
  <HStack
    role="button"
    borderRadius="full"
    border="2px solid"
    borderColor="green.15"
    maxW="-webkit-max-content"
    p="0.2rem 0.5rem"
    onClick={onClick}
    mb={1}
    _active={{ transform: "scale(0.95)" }}
  >
    <chakra.span color="gray.15" fontWeight="500">
      {label}
    </chakra.span>
    <Icon as={MdOutlineCancel} color="green.15" boxSize="1.5rem" />
  </HStack>
);

const CreateMeal = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Meal>({
    mode: "onSubmit",
  });
  
  const location = useLocation();
  
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const createCategoryModalDisclose = useDisclosure();

  const {
    data: meal,
    isLoading: isMealLoading,
    isRefetching: isMealRefetching,
    refetch: refetchMeal,
  } = useMeal({ id });
  
  const { mutateAsync: createMeal, isLoading: isCreatingMeal } =
    useCreateMeal();
  const {
    mutateAsync: updateMeal
  } = useUpdateMeal(id || '');
  const {
    mutateAsync: deleteMeal
  } = useDeleteMeal(id || '');
  const { data: categories } = useCategories();
  const { data: nutrients } = useNutrients();

  const [selectedNutrients, setSelectedNutrients] =
    React.useState<Nutrient[]>();
  const [mealPhoto, setMealPhoto] = React.useState<File | null>();

  const [state, setSatate] = useState<MealState>({
    _grupos:[],
    _loading:true,
    _mealTimes:[
      "Desayuno",
      "Comida",
      "Cena",
      "Colación de la Mañana",
      "Colación de la Tarde"
    ],
    _mealTimesSelected:[],
    _categories:[
			"SOP",
			"Endometriosis",
			"Control de Peso",
			"Resistencia a la insulina",
			"Diabetes",
      "Salud 360",
      "Healthy Kids",
			"General"
		],
    _categoriesSelected:[],
    // _subCategories: [],
    _subCategories:[
			"Hiperproteica",
			"Cetogenica",
			"Antiinflamatoria",
      "Libre de gluten",
      "Paleolítica y mediterránea",
			"Basada en Plantas",
			"Low Carb",
			"General"
		],
    _subCategoriesSelected: [],
    _aliments:[],
    _alimentdFiltered:[],
    _alimentSubCategorias: [],
    _alimentCategoriaSelected:'',
  
    _alimentSelected: null,
    _alimentsSelected:[],
    _quantitySelected:'',
    _macronutrientes: {
      proteinas: 0,
      lipidos: 0,
      hidratos: 0
    },
    _micronutrientes: {
      vitaminaA: 0, 
      acidoAscorbico: 0, 
      acidoFolico: 0
    }
    // _micronutrientes: 0
	})

  // /** Fills default values */
  // React.useEffect(() => {
  //   if (meal?.data && id) {
  //     reset(meal?.data);
  //     const nutrients = meal.data.goals.map(goal => {
  //       return {
  //         id: goal.id,
  //         name: goal.name,
  //         key: `${goal.amount}mg ${goal.name}`,
  //         amount: `${goal.amount}`
  //       } as Nutrient
  //     });
  //     setSelectedNutrients(nutrients);
  //   }

  //   return () => reset();
  // }, [meal, reset, refetchMeal, id]);

  // /** Triggers the meal refetch on ID change */
  // React.useEffect(() => {
  //   if (id) {
  //     refetchMeal();
  //   }

  //   return () => reset();
  // }, [id, refetchMeal, reset]);


  useEffect(() => {
   
    const init = async () => {
  
      // let _categories = await Service('GET', 'meal-category');
			// let _subcategories = await Service('GET', 'meal-sub-category');
			let _aliments = await _Service('GET', 'aliments');
      console.log(_aliments)
      let _alimentsSelected : AlimentSelected[] = [];
      if(id){
        
        let mealTime = '';
        let meal = {...location.state}
 
        
        console.log(meal);
        // for (const [key, value] of Object.entries(location.state.mealTimes)) {
         
        //   if(value){
           
        //     mealTime = key;
        
        //   }
        // }

        meal.ingredients.map((aliment: any) =>{
          let _aliment = _aliments.data.find((item: any) => item.id === aliment.alimentId);
          _aliment.quantity = aliment.quantity;
       

          _alimentsSelected.push(_aliment);
          // console.log(aliment)
        })

        state._alimentsSelected = _alimentsSelected;
        state._categoriesSelected = meal.mealCategories;
        state._subCategoriesSelected = meal.mealSubCategories;
        state._mealTimesSelected = meal.mealTime;
        MacrosMicros();
        // meal.mealTimes = mealTime;
          
        reset(meal);

  
      }





      let grupos: string[] = [];

      for (let i = 0; i < _aliments.data.length; i++) {
        if(!grupos.includes(_aliments.data[i].grupo)){
          grupos.push(_aliments.data[i].grupo)
        }
      }

      let subGrupos: string[] = [];

      for (let i = 0; i < _aliments.data.length; i++) {
        if(!subGrupos.includes(_aliments.data[i].subGrupo)){
          subGrupos.push(_aliments.data[i].subGrupo)
        }
      }

      
      console.log(subGrupos);
      

      setSatate({...state, _aliments:_aliments.data, _grupos:grupos, _loading:false})
          
     
			
		};
		init();
    return () => {}
  }, []);

  const MacrosMicros = () =>{

   
      let proteinas = 0;
      let lipidos = 0;
      let hidratos = 0;
      let vitaminaA = 0;
      let acidoAscorbico = 0;
      let acidoFolico = 0;

      for (let i = 0; i < state._alimentsSelected.length; i++) {
          console.log(state._alimentsSelected[i])
          let q : string = state._alimentsSelected[i].quantity as string;

     
          proteinas = proteinas + (state._alimentsSelected[i].proteina * parseFloat(q) )
          lipidos = lipidos + (state._alimentsSelected[i].lipidos * parseFloat(q)  )
          hidratos = hidratos + (state._alimentsSelected[i].hidratosDeCarbono * parseFloat(q)  )
          vitaminaA = vitaminaA + (state._alimentsSelected[i].vitaminaA * parseFloat(q)  )
          acidoAscorbico = acidoAscorbico + (state._alimentsSelected[i].acidoAscorbico * parseFloat(q)  )
          acidoFolico = acidoFolico + (state._alimentsSelected[i].acidoFolico * parseFloat(q)  )
      }

      state._macronutrientes.proteinas = proteinas
      state._macronutrientes.lipidos = lipidos
      state._macronutrientes.hidratos = hidratos

      state._micronutrientes.vitaminaA = vitaminaA
      state._micronutrientes.acidoAscorbico = acidoAscorbico
      state._micronutrientes.acidoFolico = acidoFolico
  }

  const onSubmit = () => {
   
    if (id) {
    
      updateRecipe();
    
  
    } else {
     
      saveRecipe();
    }

  };

  const updateRecipe = async () => {
    setSatate({...state, _loading:true})
    
    let data = getValues();
    try {

     
   
      let ingredients = []
      
      for (let i = 0; i < state._alimentsSelected.length; i++) {
        
        ingredients.push(
          {
            alimentId: state._alimentsSelected[i].id,
            portion: parseInt(state._alimentsSelected[i].quantity as string),
            quantity: parseInt(state._alimentsSelected[i].quantity as string)
          }
        )
        
      }


      let newMeal = {
        name: data.name,
        image:{
          id:1
        },
        mealTime:state._mealTimesSelected,
        mealCategories:state._categoriesSelected,
        mealSubCategories:state._subCategoriesSelected,
        ingredients: ingredients,
  
 
        // mealGoals,
        preparation: data.preparation,
        // MealTimes: mealTimes
      };

      console.log(newMeal)

 
      // formData.append("metadata", JSON.stringify(newMeal));

      if (!mealPhoto) {
        console.log(data.image.id)
        newMeal.image = {
          id:data.image.id
        }
        
      }else{
        let body = new FormData();
        body.append("file", mealPhoto);
  
        let upload_file = await _Service('POSTMEDIA', 'files/upload', body)
        let _image = upload_file.data.id;
        newMeal.image = {id:_image}
      }
     


      let save = await _Service('PUT', `recipes/${id}`, JSON.stringify(newMeal));
      console.log(save)

      if(save.status === 200){
        toast({
          title: `"${data.name}"`,
          description: "Receta editada con éxito",
          status: "success",
          position: "bottom-left",
        });
  
        navigate("/recipes");
      }else{
        toast({
          title: "Ocurrió un error al editar la receta",
          status: "error",
          position: "bottom-left",
        });
        setSatate({...state, _loading:false})
      }

    } catch (error) {

      console.error(error);
      toast({
        title: "Ocurrió un error al actualizar la receta",
        status: "error",
        position: "bottom-left",
      });
      setSatate({...state, _loading:false})

    }
  }

  const saveRecipe = async () => {
    setSatate({...state, _loading:true})
    let data = getValues();
    try {

     
   
      let ingredients = []
      
      for (let i = 0; i < state._alimentsSelected.length; i++) {
        
        ingredients.push(
          {
            alimentId: state._alimentsSelected[i].id,
            portion: parseInt(state._alimentsSelected[i].quantity as string),
            quantity: parseInt(state._alimentsSelected[i].quantity as string)
          }
        )
        
      }


      let newMeal = {
        name: data.name,
        image:{
          id:1
        },
        mealTime:state._mealTimesSelected,
        mealCategories:state._categoriesSelected,
        mealSubCategories:state._subCategoriesSelected,
        ingredients: ingredients,
  
 
        // mealGoals,
        preparation: data.preparation,
        // MealTimes: mealTimes
      };

      console.log(newMeal)

 
      // formData.append("metadata", JSON.stringify(newMeal));

      if (!mealPhoto) {
        toast({
          title: "Todavía no has añadido una foto",
          status: "error",
          position: "bottom-left",
        });
        return;
      }
      let body = new FormData();
      body.append("file", mealPhoto);

      let upload_file = await _Service('POSTMEDIA', 'files/upload', body)
      let _image = upload_file.data.id;
      newMeal.image = {id:_image}


      let save = await _Service('POST', 'recipes', newMeal);
      console.log(save)

      if(save.status === 201){
        toast({
          title: `"${data.name}"`,
          description: "Receta creada con éxito",
          status: "success",
          position: "bottom-left",
        });
  
        navigate("/recipes");
      }else{
        toast({
          title: "Ocurrió un error al crear la receta",
          status: "error",
          position: "bottom-left",
        });
        setSatate({...state, _loading:false})
      }

  

    } catch (error) {
      console.error(error);
      toast({
        title: "Ocurrió un error al crear la receta",
        status: "error",
        position: "bottom-left",
      });
      setSatate({...state, _loading:false})
    }
  }

  const onRemoveNutrient = (nutrient: string) => {
    const newNutrients = selectedNutrients?.filter((n) => n.key !== nutrient);
    setSelectedNutrients(newNutrients);
  };

  const onAddNutrient = () => {
    // const n = getValues("nutrient");

    // /** Returns if a `nutrient` is not selected */
    // if (!n) {
    //   toast({
    //     title: "Selecciona un nutriente",
    //     status: "error",
    //     position: "bottom-left",
    //   });
    //   return;
    // }

    // const _nutrient: Nutrient = JSON.parse(n);
    // const _dose = getValues("dose").toString().trim();

    // /** Returns if the `dose` is an empty string */
    // if (!_dose) {
    //   toast({
    //     title: "Por favor, agrega una dosis",
    //     status: "error",
    //     position: "bottom-left",
    //   });
    //   return;
    // }

    // /** Returns if the `dose` is not a number */
    // if (isNaN(Number(_dose))) {
    //   toast({
    //     title: "Solo puedes introducir números",
    //     status: "error",
    //     position: "bottom-left",
    //   });
    //   return;
    // }

    // const nutrient = `${_dose}${_nutrient.nomenclature || "mg"} ${_nutrient.name} `;

    // /** Check if the nutrient to be added already exists */
    // const alreadyExists = selectedNutrients?.find((n) => n.key === nutrient);

    // if (alreadyExists) {
    //   toast({
    //     title: `El nutriente "${nutrient.trim()}" ya ha sido agregado`,
    //     status: "error",
    //     position: "bottom-left",
    //   });
    //   return;
    // }

    // setSelectedNutrients((prevState) => [
    //   { id: _nutrient.id, key: nutrient, amount: _dose },
    //   ...(prevState ?? []),
    // ]);
  };

  const onDeleteMeal = async () => {
    try {
    
      let _delete = await _Service('DELETE', 'recipes/'+id);
      console.log(_delete)

      toast({
        title: `"${location.state.name.trim()}" ha sido eliminado`,
        status: "success",
        position: "bottom-left",
      });
      navigate("/recipes");
    } catch {
      toast({
        title: `"${meal?.data.name.trim()}" no ha podido ser eliminado`,
        status: "error",
        position: "bottom-left",
      });
    }
  }

  const onInputPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _files = Object.values(event.target.files!);
    setMealPhoto(_files[0]);
  };

  // if (isMealLoading || isMealRefetching || state._loading) {
  //   return <LoadingPage />;
  // }

  return (
    <VStack h="100%" overflow={'auto'}>
      <Renderif isTrue={state._loading}>
        <LoadingOverlay></LoadingOverlay>
      </Renderif>
      <HStack w="100%" >
        <BackButton path="/recipes" />
        <chakra.span color="gray.10" fontSize="xl">
          {id ? meal?.data.name : 'Nueva Receta'}
        </chakra.span>
        <Spacer />
        {id &&
          <HStack _hover={{ cursor: 'pointer' }} onClick={onDeleteMeal}>
           
            <Text textColor="#F33939">Eliminar</Text>
            <div className="md-icon md-icon--mid">
              <FaTrash color="#F33939" ></FaTrash>
            </div>
          </HStack>
        }
      </HStack>

      <VStack w="100%" height={'calc(100%)'} overflow={'auto'} p={'1.5rem'} bg="#efefef" borderRadius="md" boxShadow="md" >
        
        <Flex w="100%" gap={2}>
          <Box flex={2}>
            <Input
              label="Título"
              error={errors?.name?.message || ""}
              placeholder="Receta Nueva"
              {...register("name", {
                required: "El nombre es obligatorio",
                minLength: {
                  value: 5,
                  message: "El nombre debe tener mínimo 5 caracteres",
                },
              })}
            />
            </Box>
            <Box flex={1}>
              <InputFile
                  label="Foto"
                  value={mealPhoto?.name || "Haz click para escoger foto"}
                  onChange={(event) => onInputPhoto(event)}
                />
            </Box>
          {/* <Input
            label="Kilocalorías"
            error={errors?.kilocalories?.message || ""}
            {...register("kilocalories", {
              required: "Las calorías son obligatorias",
            })}
          /> */}
          
          {/* <HStack spacing={5} pt={5}>
            <Checkbox
              {...register("mealTimes.breakfast")}
            >
              Desayuno
            </Checkbox>
            <Checkbox
              {...register("mealTimes.meal")}
            >
              Comida
            </Checkbox>
            <Checkbox
              {...register("mealTimes.dinner")}
            >
              Cena
            </Checkbox>
          </HStack> */}
        </Flex>

        <Flex w="100%" gap={2}>
          <Box flex={1}>

              <Select
                error={errors.categoryId?.message || ""}
                label="Categoria"
                value={""}
                onChange={(evt)=>{
                  console.log(evt.currentTarget.value);
                  let _categorie = state._categories.find((item) => item === evt.currentTarget.value);
                 

                  if(state._categoriesSelected.find((item) => item === evt.currentTarget.value)){
                    return
                  }


                  state._categoriesSelected.push(_categorie as string);
                  setSatate({...state});
                  
                }}
            
              >
              <option value="">Seleccionar</option>
              {state._categories.map((item:string, i:number)=>{
								return (
								
									<option value={item} key={i}>{item}</option>
								)
							})}
							
       

              </Select>
              <div className="d-flex flex-wrap">
                {
                  state._categoriesSelected.map((item,i)=>{
                    return (
                      <div key={i} className="pill pill--gren">
                        <div>{item}</div>
                        <div className="md-icon cursor-pointer" onClick={()=>{
                          state._categoriesSelected = state._categoriesSelected.filter((cat)=> cat !== item);
                          setSatate({...state});
                        }}>
                          <MdClose size={14}></MdClose>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
          </Box>
          <Box flex={1}>
              <Select
                error={errors.categoryId?.message || ""}
                label="Sub Categoria"
                value={""}
                onChange={(evt)=>{
                  console.log(evt.currentTarget.value);
                  let _subcategory = state._subCategories.find((item) => item === evt.currentTarget.value);
                  console.log(_subcategory)
                  if(state._subCategoriesSelected.includes(_subcategory as string)){
                    return
                  }
                  state._subCategoriesSelected.push(_subcategory as string);
                  setSatate({...state});
                  
                }}
              >
              <option value="">Seleccionar</option>
              {state._subCategories.map((item:string, i:number)=>{
								return (
								
									<option  value={item} key={i}>{item}</option>
								)
							})}

              </Select>

              <div className="d-flex flex-wrap">
                {
                  state._subCategoriesSelected.map((item,i)=>{
                    return (
                      <div key={i} className="pill pill--gren">
                        <div>{item}</div>
                        <div className="md-icon cursor-pointer" onClick={()=>{
                          state._subCategoriesSelected = state._subCategoriesSelected.filter((cat)=> cat !== item);
                          setSatate({...state});
                        }}>
                          <MdClose size={14}></MdClose>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

          </Box>
          <Box flex={1}>
              <Select
                error={errors.categoryId?.message || ""}
                value={""}
                label="Tiempo de comida"
                onChange={(evt)=>{
               
                  let _mealTime = state._mealTimes.find((item) => item === evt.currentTarget.value);
            
                  if(state._mealTimesSelected.includes(_mealTime as string)){
                    return
                  }
                  state._mealTimesSelected.push(_mealTime as string);
                  setSatate({...state});
                  
                }}
                // {...register("mealTimes", {
                //   required: "obligatorio",
                // })}
              
              >
                 <option value="">Seleccionar</option>
              {state._mealTimes.map((item:string, i:number)=>{
								return (
								
									<option  value={item} key={i}>{item}</option>
								)
							})}

              </Select>
              <div className="d-flex flex-wrap">
                {
                  state._mealTimesSelected.map((item,i)=>{
                    return (
                      <div key={i} className="pill pill--gren">
                        <div>{item}</div>
                        <div className="md-icon cursor-pointer" onClick={()=>{
                          state._mealTimesSelected = state._mealTimesSelected.filter((cat)=> cat !== item);
                          setSatate({...state});
                        }}>
                          <MdClose size={14}></MdClose>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
          </Box>
        
        </Flex>


        {/* <Textarea
          error={errors?.ingredients?.message || ""}
          label='Ingredientes (al usar coma "," creas una nueva línea)'
          {...register("ingredients", {
            required: "Los ingredientes son obligatorios",
          })}
        /> */}
        <Textarea
          error={errors?.preparation?.message || ""}
          h="10rem"
          label="Preparación"
          {...register("preparation", {
            required: "La preparación es obligatoria",
          })}
        />

        <Flex w="100%">
        <Box flex={1}>
          <Flex w="100%" gap={1}>
            <Box flex={1}>
                <Select
                  // error={errors.categoryId?.message || ""}
                  value ={state._alimentCategoriaSelected}
                  label="Categoria"
                  onChange={(evt)=>{
                  // console.log(state._aliments)
                    let grupo = evt.currentTarget.value
                    let categoryFiletered = state._aliments.filter((item)=>item.grupo === grupo)

                    console.log(categoryFiletered)
                  
                    let subGrupos: string[] = [];

                    for (let i = 0; i < categoryFiletered.length; i++) {
                      if(!subGrupos.includes(categoryFiletered[i].subGrupo)){
                        subGrupos.push(categoryFiletered[i].subGrupo)
                      }
                    }


                    console.log(subGrupos);
                    setSatate({...state, _alimentSubCategorias:subGrupos, _alimentdFiltered:categoryFiletered, _alimentCategoriaSelected:grupo});

                  }}

                  // {...register("categoryId", {
                  //   required: "Tiempo de comida es obligatorio",
                  // })}
              
                >
                <option value="">Seleccionar</option>
                {
                  state._grupos.map((item:string, i:number)=>{
                    return (
                      <option key={i} value={item}>{item}</option>
                    )
                  })
                }
                {/* <option value="Aceites y grasas">Aceites y grasas</option>
                <option value="Alimentos libres en energía">Alimentos libres en energía</option>
                <option value="Alimentos Origen Animal">Alimentos Origen Animal</option>
                <option value="Azucares">Azucares</option>
                <option value="Bebidas ">Bebidas</option>
                <option value="Cereales">Cereales</option>
                <option value="Frutas">Frutas</option>
                <option value="Leche">Leche</option>
                <option value="Leguminosas">Leguminosas</option>
                <option value="Verduras">Verdura</option> */}
              

                </Select>
            </Box>
            {/* <Box flex={1}>
                <Select
                  // error={errors.categoryId?.message || ""}
                  label="Sub Categoria"
                  onChange={(evt)=>{
                    let subGrupo = evt.currentTarget.value;

                    let subCategoryFiletered = state._alimentdFiltered.filter((item)=>item.subGrupo === subGrupo);
                    setSatate({...state, _alimentdFiltered:subCategoryFiletered})

                  }}
                  // {...register("categoryId", {
                  //   required: "Tiempo de comida es obligatorio",
                  // })}
                
                >
                <option value="">Seleccionar</option>
      
                {state._alimentSubCategorias.map((item, i)=>{
                  return (
                  
                    <option value={item} key={i}>{item}</option>
                  )
                })}

                </Select>
            </Box> */}
            <Box flex={1}>
            
                <Select
                  // error={errors.categoryId?.message || ""}
                  label="Alimento"
                  value={state._alimentSelected === null ? '' : state._alimentSelected?.id}
                  onChange={(evt)=>{
                    console.log(evt.currentTarget.value)
                    let aliment = state._alimentdFiltered.filter((item)=>item.id === parseInt(evt.currentTarget.value))[0];
                    state._alimentSelected = aliment
                    setSatate({...state});
                    
                  }}
                
                
                >
              <option value="">Seleccionar</option>
                
                {state._alimentdFiltered.map((item, i)=>{
                  return (
                  
                    <option value={item.id} key={i}>{item.alimentos}</option>
                  )
                })}

                </Select>
            </Box>
            <Box width="120px">
            <Input
                  width="100%"
                label="Porcion SMAE"
                // error={errors?.name?.message || ""}
                placeholder=""
                value={state._alimentSelected  === null ? '' : state._alimentSelected?.unidad}
                // {...register("name", {
                //   required: "El nombre es obligatorio",
                //   minLength: {
                //     value: 5,
                //     message: "El nombre debe tener mínimo 5 caracteres",
                //   }
                // }
                // )}
              />
            </Box>  
            <Box width="120px">
            <Input
                  
                  label="Cantidad"
                  // error={errors?.name?.message || ""}
                  placeholder="0"
                  value={state._quantitySelected}
                  onChange={(evt)=>{ setSatate({...state, _quantitySelected:evt.currentTarget.value}) }}

                  //  {...register("name", {
                  //    required: "El nombre es obligatorio",
                  //    minLength: {
                  //      value: 5,
                  //      message: "El nombre debe tener mínimo 5 caracteres",
                  //    },
                  //  })}

                />
            </Box>

            <Box>
                <Icon
                    as={IoIosAddCircleOutline}
                    role="button"
                    color="green.15"
                    boxSize="2rem"
                    mt="2rem !important"
                    me={'0.5rem !important'}
                    _active={{ transform: "scale(0.8)" }}
                    // onClick={() => onAddNutrient()}
                    onClick={(evt) => {
                        if(state?._alimentSelected !== null){
                          let _alimentSelected: AlimentSelected  = state._alimentSelected

                      

                          let f = state._alimentsSelected.filter((item)=>item.id === state._alimentSelected?.id)
                          console.log(f)

                          state._alimentsSelected = state._alimentsSelected.filter((item)=>item.id !== state._alimentSelected?.id)

                          

                          _alimentSelected.quantity = state._quantitySelected;
                          state._quantitySelected = '';
                          state._alimentSelected = null;
                          state._alimentCategoriaSelected = '';
                          state._alimentsSelected?.push(_alimentSelected);

                        

                          MacrosMicros();

        
                          
                          setSatate({...state});
                        }
                        
                        
                    }}
                  />
            </Box>
          
          </Flex>
              {
                state._alimentsSelected?.map((item, i) =>{
                  return (
                   
                    <Flex key={i} w="100%" gap={1}>
                      <Box flex={1}>
                        <div>{item.grupo}</div>
                      </Box>
                      {/* <Box flex={1}>
                        <div>{item.subGrupo}</div>
                      </Box> */}
                      <Box flex={1}>
                        <div>{item.alimentos}</div>
                      </Box>
                      <Box  width="120px">
                        <div>{(item.cantidadSugeridaNum * parseFloat(item.quantity === undefined ? '0' :item.quantity))} {item.unidad} </div>
                      </Box>
                      <Box  width="120px">
                        <div>{item.quantity}</div>
                      </Box>
                      <Box  width="32px" height="35px">
                        <div className="cursor-pointer" onClick={()=>{
                          console.log(item)
                          state._alimentsSelected =  state._alimentsSelected.filter((aliment)=> aliment.id !== item.id)
                          MacrosMicros()
                          setSatate({...state});
                        }}>
                          <BsTrash color="red"></BsTrash>
                        </div>
                      </Box>
                      
                    </Flex>
                  )
                })
              }
        

        </Box>

        <Box w={'400px'} bg={'white'}  p={'1.5rem'} borderRadius={10}>
            <Text pb={'1rem'} fontWeight={'bold'} color={'#2CAD6E'}>Datos Generales de la Receta</Text>

            <Text fontWeight={'bold'} color={'gray'}>Macronutrientes:</Text>
            
            <Flex width={'100%'} ps={'0.5rem'} py={'0.5rem'}>
              <Box flex={1}>
                <Text fontWeight={'400'} color={'#2CAD6E'}>{state._macronutrientes.proteinas.toFixed(1)}</Text>
                <Text fontWeight={'400'} color={'#2CAD6E'}>{state._macronutrientes.lipidos.toFixed(1)}</Text>
                <Text fontWeight={'400'} color={'#2CAD6E'}>{state._macronutrientes.hidratos.toFixed(1)}</Text>
              </Box>
              <Box flex={1}>
                <Text fontWeight={'400'} color={'#777777'}>Proteina</Text>
                <Text fontWeight={'400'} color={'#777777'}>Lípidos</Text>
                <Text fontWeight={'400'} color={'#777777'}>Hidrátos de Carbono</Text>
              </Box>
            </Flex>
            

            <Text fontWeight={'bold'} color={'gray'}>Micronutrientes:</Text>


            <Flex width={'100%'} ps={'0.5rem'}  py={'0.5rem'}>
              <Box flex={1}>
                <Text fontWeight={'400'} color={'#2CAD6E'}>{state._micronutrientes.vitaminaA.toFixed(1)} </Text>
                <Text fontWeight={'400'} color={'#2CAD6E'}>{state._micronutrientes.acidoAscorbico.toFixed(1)} </Text>
                <Text fontWeight={'400'} color={'#2CAD6E'}>{state._micronutrientes.acidoFolico.toFixed(1)} </Text>
              </Box>
              <Box flex={1}>
                <Text fontWeight={'400'} color={'#777777'}>Vitamina A (µg RE)</Text>
                <Text fontWeight={'400'} color={'#777777'}>Ácido Ascórbico (mg)</Text>
                <Text fontWeight={'400'} color={'#777777'}>Ácido Fólico (µg)</Text>
              </Box>
            </Flex>

        </Box>

        </Flex>
      


        {/* <HStack w="100%" justifyContent="space-between">
          <VStack>
            <HStack alignSelf="flex-start" spacing={30}>
              <Select label="Selecciona Nutrientes" {...register("nutrient")}>
                <option selected value="" disabled>
                  Selecciona nutriente
                </option>
                {nutrients?.data.map((nutrient) => (
                  <option key={nutrient.id} value={JSON.stringify(nutrient)}>
                    {nutrient.name}
                  </option>
                )
                
                )}
              </Select>
              <Input
                label="Dosis"
                placeholder="Default -- Empty (Ejem. 25mg)"
                {...register("dose")}
              />
              <Icon
                as={IoIosAddCircleOutline}
                role="button"
                color="green.15"
                boxSize="2rem"
                mt="1rem !important"
                _active={{ transform: "scale(0.8)" }}
                onClick={() => onAddNutrient()}
              />
            </HStack>

            <HStack alignSelf="flex-start" spacing={15}>
              <Select
                error={errors.categoryId?.message || ""}
                label="Selecciona categoría"
                {...register("categoryId", {
                  required: "La categoría es obligatoria",
                })}
                w="20.5rem"
              >
                {categories?.data.map((category, index) => (
                  <option key={`category-select-${index}`} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
              <HStack mt="1rem !important">
                <Icon
                  as={IoIosAddCircleOutline}
                  role="button"
                  color="green.15"
                  boxSize="2rem"
                  _active={{ transform: "scale(0.8)" }}
                  onClick={createCategoryModalDisclose.onOpen}
                />
                <chakra.span fontSize="md" color="gray.25">
                  Crear Nueva Categoría
                </chakra.span>
              </HStack>
            </HStack>
          </VStack>

          <VStack h="100%">
            <chakra.span
              color="gray.25"
              alignSelf="flex-start"
              fontSize="small"
            >
              Nutrientes
            </chakra.span>
            <Box
              w="20.6rem"
              h="100%"
              maxH="8rem"
              overflowY="auto"
              bg="white"
              borderRadius="md"
              p="0.2rem 0.5rem"
              sx={SCROLL_BAR_STYLES}
            >
              {selectedNutrients?.map((nutrient, index) => (
                <NutrientBadge
                  label={nutrient.key!}
                  key={`nutrient-badge-${index}`}
                  onClick={() => onRemoveNutrient(nutrient.key!)}
                />
              ))}
            </Box>
          </VStack>
        </HStack> */}

        <Flex w="100%" gap={2} pt={'1rem'} pb={'4rem'} >
          <Box flex={1}></Box>
          <Box flex={1}></Box>
          <Box flex={1}></Box>
          <Box width="120px"></Box>
          <Flex flex={1.3} gap={2}>
            <Button onClick={()=>{
              try {
      
                onSubmit()
                //handleSubmit(onSubmit)
              } catch (error) {
                  console.log(error)
              }
            }} isLoading={isCreatingMeal}>
              Guardar
            </Button>
            <Button variant="warning" onClick={() => navigate("/recipes")}>
              Salir
            </Button>
          </Flex>
        </Flex>

      </VStack>

      {createCategoryModalDisclose.isOpen && (
        <CreateCategoryModal
          isOpen={createCategoryModalDisclose.isOpen}
          onClose={createCategoryModalDisclose.onClose}
        />
      )}

    </VStack>
  );
};

export { CreateMeal };


