import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { PatientForms } from "types/user";

function usePatientForms(patientId: string) {
  return useQuery<AxiosResponse<PatientForms[]>, AxiosError>(["patientForms"], () =>
    axios.get(`user-form/user/${patientId}`)
  );
}

export { usePatientForms };