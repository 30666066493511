import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { Category } from "types/categories";

function useCategories() {
  return useQuery<AxiosResponse<Category[]>, AxiosError>(["categories"], () =>
    axios.get(`meal-category`)
  );
}

export { useCategories };
