import * as React from "react";

const useToggle = (initialValue: boolean = true) => {
  const [value, setValue] = React.useState(initialValue);

  const toggle = () => setValue((prevValue) => !prevValue);

  return [value, toggle, { setValue }] as const;
};

export { useToggle };
