import { createIcon } from "@chakra-ui/react";

const FullLogo = createIcon({
  displayName: "FullLogo",
  viewBox: "0 0 750 250",
  path: [
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="117.4713"
      y1="22.3751"
      x2="117.4713"
      y2="188.1465"
    >
      <stop offset="0" stopColor="#E45B24" />
      <stop offset="0.3923" stopColor="#D51D55" />
    </linearGradient>,
    <path
      fill="url(#SVGID_1_)"
      d="M122.5,191.5c-2.5,2.5-6.5,2.5-9,0l-72.4-72.4C18.4,96.4,19.7,58.6,45,37.5C54.1,30,65.4,25.6,77.2,25
	c15.3-0.8,29.9,4.6,40.9,15.1c10.1-9,23-14,36.7-14c0,0,0,0,0,0c14.7,0,28.6,5.7,39,16.2c21.5,21.5,21.5,56.5,0,78L122.5,191.5z
	 M80.1,44.5c-9.5,0-18.4,3.7-25.2,10.4S44.5,70.6,44.5,80.1c0,9.5,3.7,18.4,10.4,25.2l63.1,63.1l61.9-61.9
	c6.7-6.7,10.4-15.7,10.4-25.2c0-9.5-3.7-18.4-10.4-25.2s-15.7-10.4-25.2-10.4c-9.5,0-18.4,3.7-25.2,10.4L118,67.7l-12.8-12.7
	C98.6,48.2,89.6,44.5,80.1,44.5z"
    />,
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="119.5778"
      y1="138.3038"
      x2="204.0899"
      y2="138.3038"
    >
      <stop offset="0.3316" stopColor="#2DAD6F" />
      <stop offset="0.9997" stopColor="#8BBE28" />
    </linearGradient>,
    <polygon
      fill="url(#SVGID_2_)"
      points="204.1,128.5 171.6,128.5 171.6,96 152.1,96 152.1,128.5 119.6,128.5 119.6,148.1 152.1,148.1 
	152.1,180.6 171.6,180.6 171.6,148.1 204.1,148.1 "
    />,
    <g>
      <path
        fill="#6F807E"
        d="M622.8,136.7c-1.8-3-4.3-5.4-7.3-7.1c-3.1-1.7-6.6-2.6-10.7-2.6c-6.1,0-11.2,2.1-15.2,6.2c-4,4.1-6,9.3-6,15.5
		c0,6.2,2,11.4,6,15.6c4,4.2,9.1,6.3,15.2,6.3c4,0,7.6-0.9,10.7-2.6c3.1-1.7,5.6-4.1,7.3-7.2v8.8h4.1v-41.6h-4.1V136.7z
		 M617.7,161.5c-3.4,3.5-7.6,5.2-12.5,5.2c-5.1,0-9.3-1.7-12.6-5.1c-3.3-3.4-5-7.7-5-12.8c0-5.2,1.6-9.4,4.9-12.9
		c3.3-3.4,7.5-5.1,12.6-5.1c4.9,0,9,1.7,12.5,5.2c3.4,3.5,5.2,7.7,5.2,12.8C622.8,153.8,621.1,158.1,617.7,161.5z"
      />
      <path
        fill="#6F807E"
        d="M656,127c-4,0-7.6,0.9-10.6,2.6c-3.1,1.7-5.5,4.1-7.3,7.1v-8.7H634v59.2h4.1v-26.5c1.7,3.1,4.2,5.5,7.3,7.2
		c3.1,1.7,6.7,2.6,10.7,2.6c6.1,0,11.2-2.1,15.2-6.3c4-4.2,6-9.4,6-15.6c0-6.2-2-11.4-6.1-15.5C667.2,129,662.1,127,656,127z
		 M668.2,161.6c-3.3,3.4-7.5,5.1-12.6,5.1c-4.9,0-9-1.7-12.5-5.2c-3.4-3.5-5.2-7.7-5.2-12.8c0-5,1.7-9.3,5.2-12.8
		c3.4-3.5,7.6-5.2,12.5-5.2c5.2,0,9.4,1.7,12.7,5.1c3.3,3.4,4.9,7.7,4.9,12.9C673.2,153.9,671.5,158.2,668.2,161.6z"
      />
      <path
        fill="#6F807E"
        d="M718.9,133.2c-4-4.1-9.1-6.2-15.2-6.2c-4,0-7.6,0.9-10.6,2.6c-3.1,1.7-5.5,4.1-7.3,7.1v-8.7h-4.1v59.2h4.1
		v-26.5c1.7,3.1,4.2,5.5,7.3,7.2c3.1,1.7,6.7,2.6,10.7,2.6c6.1,0,11.2-2.1,15.2-6.3c4-4.2,6-9.4,6-15.6
		C725,142.5,723,137.3,718.9,133.2z M715.9,161.6c-3.3,3.4-7.5,5.1-12.6,5.1c-4.9,0-9-1.7-12.5-5.2c-3.4-3.5-5.2-7.7-5.2-12.8
		c0-5,1.7-9.3,5.2-12.8c3.4-3.5,7.6-5.2,12.5-5.2c5.2,0,9.4,1.7,12.7,5.1c3.3,3.4,4.9,7.7,4.9,12.9
		C720.9,153.9,719.2,158.2,715.9,161.6z"
      />
    </g>,
    <g>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="305.988"
        y1="188.7641"
        x2="305.988"
        y2="55.9726"
      >
        <stop offset="3.189400e-04" stopColor="#8BBE28" />
        <stop offset="0.6684" stopColor="#2DAD6F" />
      </linearGradient>
      <path
        fill="url(#SVGID_3_)"
        d="M333.5,181c-5,0.5-2.2-9.6-2.2-9.6s17.6-83.9,20.4-94.4c2.8-10.5,2.3-16.4-1.6-19.1c-4-2.6-11.1-3.2-18,2.5
		c-6.9,5.7-13.6,20.7-13.6,20.7s2.7-12.1,1.9-18.7c-0.9-7.5-7.8-6.4-7.8-6.4c-11.4-0.6-19.3,10.2-19.3,10.2
		c-0.7-8.8-6.1-10.7-12.8-8.5c-6.7,2.2-15.1,13.1-15.7,15.1c-0.5,2,7.1,3.7,7.1,3.7c2.5-0.3,4.1-2.1,4.1-2.1
		c2.8-2.5,3.8-2.7,3.8-2.7l-21.1,94.8c0,0-0.3,6.5,6.7,7.6c7,1.1,7.6-3.2,7.6-3.2l10-46.8c9.4-53.3,22.4-49.6,22.4-49.6l-15.9,75.8
		c0,0-3.1,17.5-3.1,18.8c0,1.2,3.8,3.7,8.1,4c4.3,0.3,5.8-3.7,5.8-3.7c15.3-73.3,37.8-100.4,36.8-93c0,0-24.3,95.4-20.6,106.6
		c4,12.1,22.6,1.6,22.5-0.8C338.8,179.8,333.5,181,333.5,181z"
      />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="509.2893"
        y1="82.5422"
        x2="509.2893"
        y2="66.481"
      >
        <stop offset="3.189400e-04" stopColor="#8BBE28" />
        <stop offset="0.6684" stopColor="#2DAD6F" />
      </linearGradient>
      <circle fill="url(#SVGID_4_)" cx="509.3" cy="74.5" r="8" />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="415.2019"
        y1="225.0544"
        x2="415.2019"
        y2="44.2214"
      >
        <stop offset="3.189400e-04" stopColor="#8BBE28" />
        <stop offset="0.6684" stopColor="#2DAD6F" />
      </linearGradient>
      <path
        fill="url(#SVGID_5_)"
        d="M483.2,62.5C467,39.4,430.9,42,410.9,49.7c-19.9,7.7-24.7,25-17.3,34.6c7.4,9.6,21.5,2.6,22.7-2.8
		c1.2-5.4-5.4-7.1-8.2-5.6c-2.1,1.2-4.4-0.3-4.8-2.5c-0.4-2.2,1.4-4.4,2.7-5.9c8.8-10.6,25.7-13.7,38.7-12.4
		c29.8,3,34.5,27.3,28.9,50.7c-9.3,38.7-38.8,51.4-47.6,54.4c-1.1,0.4-2.2-0.6-1.9-1.7c3.1-14,18.1-82.3,18.4-83.5
		c0.3-1.4-2.6-5.9-6.9-7.1c-4.3-1.2-5.3,4.8-5.3,4.8l-10.4,47.8l-29,30.9c0,0,2.1-14.1,5-25.6c3-11.5,9.6-27.6,7.7-30.3
		c-1.9-2.7-6.1-2.9-8.9-2.9c-2.8,0-5.1,5.8-5.1,5.8s-27.9,52.9-29.8,56.3c-1.9,3.3-2.5,2.4-2.5,2.4c4.3-19.9,10-37.8,13-47.3
		c2.9-9.4,2.5-16.2-2.3-16.5c-4.8-0.3-9.4,2.6-12.4,7.7c-2.9,5.1,1.2,6.2,1.2,6.2l1.1,0.4c0,0-8.6,30.5-11.7,41.2
		c-3,10.7-4,22.4,7.2,23.3c11.1,0.8,15.2-9.1,15.2-9.1l10.9-18.5l-2.2,18.9l-19.2,16.9c-19.4,15.4-27,50.4-0.6,44.1
		c26.4-6.4,30.5-53.5,30.5-53.5l26.3-27.9c0,0-3.9,20.2-5.2,24.7c-1.2,4.5,2,4.6,2,4.6c16.9,2.7,38.9-6.2,55.3-22.2
		C482.7,133.9,499.4,85.7,483.2,62.5z M354.7,215.3c-10.5-3.1,6.9-27.8,20.1-35.3C374.8,180,365.2,218.4,354.7,215.3z"
      />
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="549.5918"
        y1="183.9008"
        x2="549.5918"
        y2="44.4093"
      >
        <stop offset="3.189400e-04" stopColor="#8BBE28" />
        <stop offset="0.6684" stopColor="#2DAD6F" />
      </linearGradient>
      <path
        fill="url(#SVGID_6_)"
        d="M601.8,66.7c-2.3,0.2-8.8,0.5-11.8,0.8l4.9-19.1c0,0-0.2-9.1-10.5,0c0,0-5.6,4.9-6.8,9.9
		c-0.3,1.1-1.4,5.1-2.5,9.1c-16.8,1.8-30,2.7-30,2.7s-17.9,3.6-4.1,9.2l33.2,0.9l-6,29.3c0,0-8.4,32.7-21.1,44
		c-13,11.5-21.5,3.7-22.4,0l32.5-40c2.9-3.6,4.8-10.1,2-14.2c-6.9-10-22.7-5.1-30.7,0.6c-13.7,9.8-19.6,28.5-18.7,44.7
		c0.1,1.8,0.3,3.7,0.7,5.5l0.3,0.1c0,0-5.2,8.8-9.4,7.8c-4.2-0.9-1.5-20.3,9.4-58c0,0,3.3-8.6-4.8-7.7c0,0-5.3-0.9-7.9,5.4
		c-2.6,6.3-13,55.3-13,55.3s-3.3,14.8,8.4,19.9c1.2,0.5,2.6,0.6,3.9,0.5c5.8-0.5,12.6-7.2,18-12.1c0,0,12.2,26.2,41.8-2.3
		c0,0-8,21,15.2,24.7c0,0,7.8-2.1,2-7.2c-5.7-5.1-6.1-10.4-2.8-24.6c3.3-14.1,15-69.3,15-69.3l0.6-2.6l19.4,0.3c0,0,5.1-2.7,6.5-7.5
		C614.5,68.1,618.8,65,601.8,66.7z M521.9,136.7c0.3-8.1,3-14.7,7.5-21.5c3.5-5.3,10.9-11,17.8-10.3c1.6,0.2,1.9,1.6,0.9,2.8
		c0,0-26.2,31.8-26.2,31.8C521.5,136.7,521.8,139.4,521.9,136.7z"
      />
    </g>,
  ],
});

export { FullLogo };
