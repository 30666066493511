import { Button,chakra, HStack, Icon, Input, InputGroup, InputRightElement, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { CheckIcon, SearchIcon } from "assets/icons";

import { usePatients, usePatientsSearch } from "modules/Patients/api";
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { UserMedical } from "types/user";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { BsCheck, BsThreeDotsVertical } from "react-icons/bs";
import { LuCalendarCheck2 } from "react-icons/lu";
import { Patient } from "types/patient";
import { Service, _Service } from "api/api";
import axios from "axios";
import { MdArrowBack, MdEdit } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { useToast } from '@chakra-ui/react'
import { LoadingOverlay } from "shared/LoadingPage/LoadingPage";
import Renderif from "utils/renderif";
import { FaRegUserCircle } from "react-icons/fa";

// interface PatientState {
// 	patients: Patient[]
// }
function color() {
	return (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
}
  
  
const PatientList: React.FC = () => {
	// const [search, setSearch] = React.useState("");
	// const [searchMode, setSearchMode] = React.useState(false);
	// const [patientsDisplay, setPatientsDisplay] = React.useState<UserMedical[]>([]);

	// const { data: patients, refetch: refetchPatients } = usePatients();
	// const { data: patientsSearch, refetch: refetchPatientsSearch } = usePatientsSearch(search);

	const navigate = useNavigate();

	const [state, setSatate] = useState({
		patients:[],
		search:'',
		add:false
	});

	// useLayoutEffect(() => {
	// 	refetchPatients();
	// }, [])

	// React.useEffect(() => {
	// 	if (!searchMode) {
	// 		refetchPatients();
	// 	}
	// }, [searchMode]);

	useEffect(() => {
		
		// const init = async () => {
		
		// 	let _patients = await _Service('GET', `patients`, )
		// 	console.log(_patients)
		// 	setSatate({...state, patients:_patients.data})
		// }
		init();
		return () => {}
		// console.log(patients)
		// if (!searchMode) {
		// 	setPatientsDisplay(patients?.data.content || []);
		// }
	}, []);

	const init = async  () => {
		let _patients = await _Service('GET', `patients`, )
			console.log(_patients)
			setSatate({...state, patients:_patients.data.sort((a:any,b:any) => b.id - a.id)})
	} 

	const filter = () => {

		if(state.search === ''){
			return state.patients
		}

		return state.patients.filter((item:any)=> {
			if(item.firstName.toUpperCase().includes(state.search.toUpperCase())){
				return true
			}
			if(item.lastName.toUpperCase().includes(state.search.toUpperCase())){
				return true
			}
			if(item.email.toUpperCase().includes(state.search.toUpperCase())){
				return true
			}
		})
	}



	// React.useEffect(() => {
	// 	if (searchMode) {
	// 		setPatientsDisplay(patientsSearch?.data || []);
	// 	}
	// }, [patientsSearch]);

	// const onSearchClick = () => {
	// 	setSearchMode(true);
	// 	refetchPatientsSearch();
	// }

	// const onPatientClick = (patient: UserMedical) => {
	// 	navigate(`${patient.id}`);
	// }

	// const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setSearch(event.target.value);
	// 	if (!search.trim()) {
	// 		setSearchMode(false);
	// 	} 
	// }

	if(state.add){
		return <RegisterView onRegister={()=>{
			state.add = false
			init();
		}}></RegisterView>
	}

	return (
		<div>

			<div className="d-flex mb-3">
		
				<div className="f-22 text-gray">Pacientes</div>

				<div className="col"></div>

				<div className="d-flex align-items-center">



					<div className="me-3">
						<div className='bv-cta bv-cta--success w-100' onClick={()=>{ 
							setSatate({...state, add:true})
						}}>
							<div>NUEVO</div>
						</div>
					</div>
					
					<div>
						<input value={state.search} onChange={(evt)=>{
							setSatate({...state, search:evt.currentTarget.value})
						}} type="text" className="input--light" placeholder="Buscar"></input>
					</div>
					<div className="bv-icon bv-icon--md">
						<BiSearch color="#CCC"></BiSearch>
					</div>
				</div>
				
			</div>
			
			<div className="container-fluid p-0">
				<table className="">
					<thead className=" bg-light rounded-5">
						<tr>
							<td>Nombre</td>
							<td>Correo Electrónico</td>
							<td>Teléfono</td>
							<td>Sucursal</td>
							<td>Medidas</td>
							<td>Historial <br></br> Clínico</td>
							<td>Estilo de <br></br> Vida</td>
							<td>Historial <br></br> Alimentaria</td>
							<td>R24H</td>
							<td>Plan <br></br> Alimenticio</td>
							<td>Citas</td>
						
							<td>Acciones</td>
						</tr>
					</thead>
					
					<tbody>
						
						
						{
							
							filter().map((item:any,i)=>{

							
								if(item.ocupation === "SPECIALIST") return;
							
								let _color = color();
							
								return (
									<tr key={i}>
										<td>
											<div className="d-flex align-items-center">
												{/* <div className="avatar" style={{backgroundImage: `url(https://source.boringavatars.com/beam/120/Stefan?colors=ffffff,${_color})`}}></div> */}

												<div className="md-icon md-icon--mid">
													<FaRegUserCircle color="2CAD6E" size={18}></FaRegUserCircle>
												</div>
												<div className="col">
													<div className="ps-2">{item.firstName} {item.lastName}</div>
												</div>
											</div>
										</td>
										<td>{item.email}</td>
										<td>{item.phone}</td>
										<td>{item.sucursalName}</td>
										<td>
											<div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.measures.length > 0 ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
										</td>
										<td>
											<div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.medicalHistory.length > 0  ? '#2CAD6E' : "#DADADA"}size={18}></FaCheckCircle>
											</div>
										</td>
										<td>
											<div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.lifeStyle.length > 0  ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
										</td>
										<td>
											<div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.foodHistory.length > 0 ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
										</td>
										<td>
											<div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.r24s?.length > 0 ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
										</td>
										<td>
											<div className="md-icon md-icon--mid">
												<FaCheckCircle color={item.planAliment ? '#2CAD6E' : "#DADADA"} size={18}></FaCheckCircle>
											</div>
										</td>
										<td>0</td>
										<td>
										<div className="bv-icon bv-icon--md cursor-pointer" onClick={()=>{
											navigate('/patient/'+item.id, {state:{color:_color}})
										}}>
											<FaEdit size={20} color="green"></FaEdit>
										</div>
										</td>
										
									</tr>
								)
							})
						
						}
					</tbody>
				</table>
				<div className="my-5 py-5"></div>
			</div>
			
		</div>
	)

	// return (
	// 	<React.Fragment>
	// 		<HStack>
	// 			<Text fontSize="32px" color="#C4C4C4">Mis Pacientes</Text>
	// 			<Spacer />
	// 			<InputGroup width="328px">
	// 				<Input
	// 					background={"linear-gradient(220.77deg, #FFFFFF 37.04%, #E9E9E9 90.74%)"}
	// 					size={"md"}
	// 					placeholder="Buscar aqui"
	// 					value={search}
	// 					onChange={onSearchChange}
	// 					onKeyDown={(event) => { if (event.code==='Enter') onSearchClick(); }}
	// 				/>
	// 				<InputRightElement
	// 					children={(
	// 						<Button size={"xs"} height="40px" background={"transparent"} onClick={onSearchClick}>
	// 							<SearchIcon color={"#2CAD6E"} fontSize="26px" />
	// 						</Button>
	// 					)}
	// 				/>
	// 			</InputGroup>
	// 		</HStack>
	// 		<Spacer height="20px" />
	// 		<TableContainer>
	// 			<Table variant={"simple"}>
	// 				<Thead>
	// 					<Tr>
	// 						<Th>Nombre del Paciente</Th>
	// 						<Th>Email</Th>
	// 						<Th>Telefono</Th>
	// 						<Th textAlign="center">Citas Previas</Th>
	// 						<Th textAlign="center">Notas</Th>
	// 						<Th textAlign="center">Historia Clinica</Th>
	// 					</Tr>
	// 				</Thead>
	// 				<Tbody>
	// 						{patientsDisplay.map(user => (
	// 							<Tr key={user.id}
	// 								cursor={"pointer"}
	// 								onClick={() => { onPatientClick(user) }}
	// 								_hover={{ 'background': '#2CAD6E', 'textColor': '#FFFFFF' }}
	// 							>
	// 								<Td>{user.name}</Td>
	// 								<Td>{user.email}</Td>
	// 								<Td>{user.telephone}</Td>
	// 								<Td textAlign="center">{user.pastMeetings}</Td>
	// 								<Td textAlign="center">{user.notes}</Td>
	// 								<Td textAlign="center">{user.hasMedicalRecord ? <CheckIcon fontSize="26px"/> : ''}</Td>
	// 							</Tr>
	// 						))}
	// 				</Tbody>
	// 			</Table>
	// 		</TableContainer>
	// 	</React.Fragment>
	// );
}



function RegisterView({onRegister = () => {}}) {
  const navigate = useNavigate()

  const toast = useToast();

//   const {setUser} = useContext(AuthContext)
//   const {notify} = useContext(AlertContext)
  
  const [state, setState] = useState({
	loading:false,
    firstName: "",
    lastName: "",
    email: "",
    password: "Mydiet.123",
    phone: "",
    ocupation: "",
    civilState: ""
  })

  const checkPassword = (password:any) => {
    var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-.#]).{8,}$/;
    return re.test(password);
  }

  const _onRegister =async () => {

	

	if (
		state.email === '' ||
		state.firstName === '' ||
		state.lastName === '' ||
		state.phone === '' ||
		state.password === ''
	
  
	  ) {
		toast({title: 'Nuevo Paciente', description: "Todos los camos son obligatorios.", status: "error", position: "bottom-left",});
		return;
	  }

	  if (!checkPassword(state.password)) {
		
		toast({title: 'Nuevo Paciente', description: "La contraseña debe contener 8 caracteres, al menos una letra mayúscula, minúscula y un símbolo (#?!@$%^&*-.#).", status: "error", position: "bottom-left",});
		return;
	  }
	  setState({...state, loading:true})
	let user = {
		firstName: state.firstName,
		lastName: state.lastName,
		email: state.email,
		password: state.password,
		phone: state.phone,
		ocupation: "",
    	civilState: ""
	}
    let _register = await _Service('POST', `auth/register`, user)

	if(_register.status === 200){
		toast({title: 'Nuevo Paciente', description: "Paciente actualizado con exito.", status: "success", position: "bottom-left",});
		onRegister()
	}else{
		toast({title: 'Nuevo Paciente', description: "No ha sido posible registra este paciente.", status: "error", position: "bottom-left",});
	}
	setState({...state, loading:false})



	

   


	
    
  }

  return (
    <div className='container-fluid overflow-auto'>

		<Renderif isTrue={state.loading}>
            <LoadingOverlay></LoadingOverlay>
        </Renderif>

   

      <div className='container-fluid container-xl'>

	<div className="d-flex align-items-center ">
		

		<div className="d-flex align-items-center cursor-pointer" onClick={()=>{}}>
			<div className="md-icon md-icon--mid">
				<MdArrowBack color="#2CAD6E"></MdArrowBack>
			</div>
			<div className="text-green bold">Pacientes</div>
		</div>

		<div className='f-18 bold text-gray ps-2'> Nuevo Paciente</div>

	</div>

      

		<div className="d-flex flex-wrap ps-3 mt-4">

			<div className='col-6 pe-3'>
			<div className='text-gray'>Nombre</div>
			<div>
				<input value={state.firstName} onChange={(evt)=>{setState({...state, firstName:evt.currentTarget.value})}} className='input--light' type='text'></input>
			</div>
			</div>

			<div className='col-6'>
			<div className='text-gray'>Apellidos</div>
			<div>
				<input value={state.lastName} onChange={(evt)=>{setState({...state, lastName:evt.currentTarget.value})}} className='input--light' type='text'></input>
			</div>
			</div>


			<div className='col-6 pe-3'>
			<div className='text-gray'>Correo electrónico</div>
			<div>
				<input value={state.email} onChange={(evt)=>{setState({...state, email:evt.currentTarget.value})}} className='input--light' type='text'></input>
			</div>
			</div>

			<div className='col-6'>
			<div className='text-gray'>Télefono</div>
			<div>
				<input value={state.phone} onChange={(evt)=>{setState({...state, phone:evt.currentTarget.value})}} className='input--light' type='text'></input>
			</div>
			</div>

		</div>

        {/* <div className='mt-2'>
          <div className='text-gray'>Contraeña</div>
          <div>
            <input value={state.password} onChange={(evt)=>{setState({...state, password:evt.currentTarget.value})}} className='input--light' type='password'></input>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Confirmar contraseña</div>
          <div>
            <input  className='input--light' type='password'></input>
          </div>
        </div> */}

       <div className='d-flex mt-3'>
		<div className="col"></div>
       <div>
	   	<div className='bv-cta bv-cta--success w-100' onClick={()=>{
          _onRegister();
          
        }}>
            <div>Registrar</div>
          </div>
	   </div>
       </div>

      

      </div>

    </div>
  )
}



export { PatientList }