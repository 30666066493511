export const SCROLL_BAR_STYLES = {
  "&::-webkit-scrollbar": {
    width: "6px",
    height:'8px',
    borderRadius: "8px",
    backgroundColor: `gray.10`,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: `green.20`,
  },
};
