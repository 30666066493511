import axios, { AxiosError, AxiosResponse } from "axios";
import { useAuth } from "contexts";
import { useQuery } from "react-query";
import { MedicalRecord } from "types/medicalrecord";

function useMedicalRecord(patientId: string) {
	const user = useAuth();
  return useQuery<AxiosResponse<MedicalRecord>, AxiosError>(["users"], () =>
    axios.get(`patient/${patientId}/medical-record/nutritionist/${user.tokens.userId}`)
  );
}

export { useMedicalRecord };
