import axios from "axios";

const api_base = 'https://api.mydiet.mx/';


const configMedia = () =>{
    let token = localStorage.getItem('token')
    return { headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+token

      }}
}

const auth = () =>{
    let token = localStorage.getItem('token')
    console.log(token)
    return { headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+token
      }}
}
const noauth = () =>{
    
    return { headers: {
        "Content-Type": "application/json" ,
      }}
}



// export const Service = (method, service, body) => {
//     var url  = `${api_base}${service}`
//     switch (method) {
//         case "GET":
      
//             return axios.get(url,config()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });

//         case "POSTMEDIA":

//             return axios.post(url,body,configMedia()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });

//         case "POST":

//             return axios.post(url,body,config()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });


           
//         case "PUT":

//             return axios.put(url,body,config()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });
           
            
//         case "DELETE":
//             let token = localStorage.getItem('token')
//             return axios.delete(url,{
//                 headers: {
//                     "Content-Type": "application/json",
//                     'Authorization': 'Bearer '+token
//                 },
//                 data:body
//             }).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });
            
          

//         default:
//             break;
//     }
// }
// {"planId":"735ed7ef-5b13-4acf-a5c6-0be2b3193556","accessToken":"eyJraWQiOiJxXC8wMUxmc041cEw4NjdWZlR0cTFXcFR2QjJUXC9NS3c4eXd2aHIyRnF3RUk9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJmYjFiNzkzZC0xMTg2LTQxZDMtYWYyYi1mM2I4YTE4YjY3NGQiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9hTVIwT0l4N0IiLCJjbGllbnRfaWQiOiI0YjNpNTZ2OTNuazZma2hjYjk0ZmdjMDRhZSIsIm9yaWdpbl9qdGkiOiIwOGQ4ODU3MC05NjMxLTQ2ZmQtOTFhNi04MTFhOTIyZGJjMzQiLCJldmVudF9pZCI6IjU2YTE0MDNlLTA3NTAtNGZlMi1iOTA1LWY3MDlmNzdiZjg4MCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MDE5MDAxMDEsImV4cCI6MTcwMTkwMzcwMSwiaWF0IjoxNzAxOTAwMTAxLCJqdGkiOiI4M2ViZTY3My05YzE5LTQ4NGItOTk0NC1hNDE5NmM4MzYxMjgiLCJ1c2VybmFtZSI6ImZiMWI3OTNkLTExODYtNDFkMy1hZjJiLWYzYjhhMThiNjc0ZCJ9.MDUKntxPl4WLvkAt03KIRjUZ1xLCHzplh4IpDwSv7S--gLw6UPP6lMJGP4bfkFM2hdF6tmwse9WAf0Dx0Psye_WYQtlAoyn1pdLu4e6cmlDvJ2Gj5SsGM5rmsgVa19L7rEjyMi0yb5IVx5DFA89QAURroBqgzx2GyYv4g38WBbU8lWtpczWNMPzvnZhja0Eld1rNTKed9zbtEjBHfGfSVhBrc8fqa7B4I_uZTszvMQKB7cvhgl1_nCUZATm3Y5HwhYTsvHNe4k9epDhRQzQVjGVJPm4Aaapp9qalW0c6T3YMkRH0gMf40XbiBZX6PaLAsvxtedBSD6SvlFHVJLKqUQ","userId":"fb1b793d-1186-41d3-af2b-f3b8a18b674d","role":"nutritionist"}

export const Service = (method: String, service: String , body: any = {}) => {
    var url  = `${api_base}${service}`
    switch (method) {
        case "GET":
      
            return axios.get(url,noauth()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
        
        case "POSTGET":
      
            return axios.post(url,{},{ headers: {
                "Content-Type": "application/json",
              }}).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });

            
        case "POST":
            console.log(body)
            return axios.post(url,body,noauth()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
        });
        
        case "CREATE":
       
            return axios.post(url,body,{ headers: {
                "Content-Type": "application/json",
              }}).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
        });
        
        case "POSTMEDIA":

            return axios.post(url,body,configMedia()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });


           
        case "PUT":

            return axios.put(url,body,noauth()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
           
            
        case "DELETE":
            
            let token = localStorage.getItem('token')
            return axios.delete(url,{
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer '+token
                },
                data:body
            }).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });

        case "PATCH":

            return axios.patch(url,body,noauth()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
      
          

        default:
            break;
    }
}

export const LogIn = async (email:String, password:String) => {
    var url  = `https://api.mydiet.mx/auth/login`

    try {
        const res = await axios.post(url, { email, password }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res;
    } catch (err) {
        return err;
    }
}


export const _Service = (method: String, service: String , body: any = {}) => {
    var url  = `${api_base}${service}`
    switch (method) {
        case "GET":
      
            return axios.get(url,auth()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });

        case "POST":
      
            return axios.post(url,body,auth()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
        });

        case "POSTMEDIA":
      
        return axios.post(url,body,configMedia()).then((res)=>{
            //return response(res)
            return res
        }).catch((err)=>{
            return err
         });

         case "PUT":

         return axios.put(url,body,auth()).then((res)=>{
             //return response(res)
             return res
         }).catch((err)=>{
             return err
         });

         case "DELETE":
            
     
         return axios.delete(url,auth()).then((res)=>{
             //return response(res)
             return res
         }).catch((err)=>{
             return err
         });
        
              
          

        default:
            break;
    }
}