import { BoxProps, chakra, HStack, VStack, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PlanItem } from "types/plan";

type PlanProps = Readonly<{
	plan: PlanItem;
}> &
	BoxProps;

const PlanItemCard: React.FC<any> = ({ plan }) => {
	const navigate = useNavigate();
	const onClick = () => {
		navigate(`edit/${plan.id}`)
	}

	
	return (
		<HStack
			className="mt-3"
			p={3}
			bg="gray.gradient.10"
			role="button"
			boxShadow="md"
			borderRadius="md"
		
			onClick={onClick}
			
		>
			<Image
				src={`https://mydiet-assets-2023.s3.amazonaws.com/${plan?.image?.name}`}
				w="5rem"
				h="5rem"
				objectFit="cover"
			
				loading="lazy"
				
			/>
			<VStack alignItems="flex-start" spacing={0}>
				<chakra.span
					fontWeight="bold"
					color="gray.15"
					fontSize="md"
					lineHeight={1}
					
				>
					{plan.title}
				</chakra.span>
				<chakra.span color="gray.15" fontSize="small">
					{plan.type}
				</chakra.span>
			</VStack>
		</HStack>
	)
};

export { PlanItemCard };