import { theme } from "@chakra-ui/react";

const baseCheckboxTheme = theme.components.Checkbox;

const defaultProps = {
  size: "md",
  colorScheme: "blue",
};

const baseStyle = {
  control: {
    borderColor: "red",
    borderRadius: "base",
    _checked: {
      bg: "red",
      borderColor: "red",
    },
    _indeterminate: {
      bg: "blue",
      borderColor: "blue",
    },
  },
};

const variants = {
  meal: {
    control: {
      color: "red",
      width: "unset",
      height: "unset",
      border: "2px solid",
      borderColor: "gray.500",
      borderRadius: "24px",
      padding: "7px 10px",
      outline: "none",
      transition: "all 200ms ease-in-out",
    },
  },
};

export const checkboxTheme = {
  ...baseCheckboxTheme,
};

export const checkbox = {
  baseStyle,
  variants,
  defaultProps,
};
