import axios, { AxiosResponse, AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

const useDeletePlan = (planId: string) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError>(
    (params) =>
      axios.delete(`plan/${planId}`),
    { onSuccess: () => queryClient.invalidateQueries(["plans"]) }
  );
};

export { useDeletePlan };
