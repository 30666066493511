import * as React from "react";
import {
  Textarea as CkTextarea,
  TextareaProps as CkTextareaProps,
  VStack,
  chakra,
} from "@chakra-ui/react";

type TextareaProps = {
  label?: string;
  error?: string;
} & CkTextareaProps;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      error,
      label,
      width,
      variant = "filled",
      ...restTextareaProps
    } = props;

    return (
      <VStack width={width || "100%"}>
        <chakra.span alignSelf="baseline" fontSize="sm" color={error ? "red.100" : "gray.25"}>
          {label}
        </chakra.span>
        <CkTextarea
          ref={ref}
          variant={variant}
          isInvalid={error ? true : false}
          {...restTextareaProps}
        />
        <chakra.span
          color="red.100"
          fontSize="sm"
          alignSelf="baseline"
          lineHeight="1"
        >
          {error}
        </chakra.span>
      </VStack>
    );
  }
);

Textarea.displayName = "Textarea";

export { Textarea };
