import * as React from "react";
import { Icon, Box, BoxProps as CkBoxProps } from "@chakra-ui/react";
import { AiFillCaretLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

type BackButtonProps = Readonly<{
  path: string | number;
}> &
  Omit<CkBoxProps, "_active" | "borderRadius" | "onClick">;

const BackButton: React.FC<BackButtonProps> = ({ path, ...restProps }) => {
  const navigate = useNavigate();

  const goTo = () => {
    if (typeof path === 'number') {
      navigate(path);
    } else {
      navigate(path);
    }
  }

  return (

    <div className="d-flex align-items-center cursor-pointer" onClick={()=>{goTo()}}>
      <div className="md-icon md-icon--mid">
        <MdArrowBack color="#2CAD6E"></MdArrowBack>
      </div>
      <div className="text-green bold">Atras</div>
    </div>
    
    // <Box
    //   role="button"
    //   borderRadius="full"
    //   w="6"
    //   h="6"
    //   _active={{ transform: "scale(0.8)" }}
    //   bg="green.10"
    //   onClick={goTo}
    //   {...restProps}
    // >
    //   <Icon as={AiFillCaretLeft} color="white" boxSize="4" m="3px" />
    // </Box>


  );
};

export { BackButton };
