import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { RecipeList, CreateMeal } from "modules/Recipes";
import { PanelLayout } from "layouts";
import { PatientList } from "modules/Patients/components/PatientList";
import { PatientForm } from "modules/Patients";
import { PatientMedicalInfo } from "modules/Patients/components/PatientMedicalInfo";
import { AppointmentInformation } from "modules/Patients/components/AppointmentInformation";
import { Hereditary } from "modules/Patients/components/Hereditary";
import { Antropometric } from "modules/Patients/components/Antropometric";
import { Dietetic } from "modules/Patients/components/Dietetic";
import { Lifestyle } from "modules/Patients/components/Lifestyle";
import { Diagnostic } from "modules/Patients/components/Diagnostic";
import { PlanList } from "modules/Plans";
import { Plan } from "modules/Plans/components/Plan";
import { PatientForms } from "modules/Patients/components/PatientForms/PatientForms";
import { PatientFormDetail } from "modules/Patients/components/PatientFormDetail/PatientFormDetail";
import CreatePatient from "modules/Patients/components/CreatePatient/CreatePatient";
import PatientDetailView from "modules/Patients/views/patient_detail_view";
import CursosView from "modules/Cursos/CursosList";
import CreateCurso from "modules/Cursos/CreateCurso";
import CommunityView from "modules/Community/CommunityView";

const AdminRoutes = () => (
  <Routes>
    <Route element={<PanelLayout />}>

      
      <Route path="community">
        <Route index element={<CommunityView />} />
      
        {/* <Route path="edit/:id" element={<CreateMeal />} /> */}
      </Route>

      <Route path="cursos">
        <Route index element={<CursosView />} />
        <Route path="create" element={<CreateCurso />} />
        {/* <Route path="edit/:id" element={<CreateMeal />} /> */}
      </Route>

      <Route path="recipes">
        <Route index element={<RecipeList />} />
        <Route path="create" element={<CreateMeal />} />
        <Route path="edit/:id" element={<CreateMeal />} />
      </Route>

      <Route path="plans">
        <Route index element={<PlanList />} />
        <Route path="create" element={<Plan />} />
        <Route path="edit/:id" element={<Plan />} />
      </Route>

      <Route path="patients" element={<PatientList />} />
      <Route path="patients" element={<PatientList />} />
      <Route path="patients/create" element={<CreatePatient />} />
      {/* <Route path="patients/:id" element={<PatientForm />} /> */}
      <Route path="patient/:id" element={<PatientDetailView />} />
      <Route path="patients/:id/medical-info" element={<PatientMedicalInfo />} />
      <Route path="patients/:id/medical-info/appointment" element={<AppointmentInformation />} />
      <Route path="patients/:id/medical-info/appointment/hereditary" element={<Hereditary />} />
      <Route path="patients/:id/medical-info/appointment/hereditary/antropometric" element={<Antropometric />} />
      <Route path="patients/:id/medical-info/appointment/hereditary/antropometric/dietetic" element={<Dietetic />} />
      <Route path="patients/:id/medical-info/appointment/hereditary/antropometric/dietetic/lifestyle" element={<Lifestyle />} />
      <Route path="patients/:id/medical-info/appointment/hereditary/antropometric/dietetic/lifestyle/diagnostic" element={<Diagnostic />} />
      <Route path="patients/:id/patient-forms" element={<PatientForms />} />
      <Route path="patients/:id/patient-forms/:userFormId" element={<PatientFormDetail />} />
    </Route>
    <Route path="*" element={<Navigate to="recipes" replace />} />
  </Routes>
);

export default AdminRoutes;
