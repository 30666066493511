import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMedicalRecord } from "modules/Patients/api";
import { useUpdateMedicalRecord } from "modules/Patients/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Input, Select } from "shared";
import { Information } from "types/medicalrecord";

const PatientMedicalInfo: React.FC = () => {
	const { id: patientId } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const {
		register,
		reset,
		getValues,
	} = useForm<Information>({
		mode: "onSubmit",
	});

	const { mutateAsync: updateMedicalRecord } = useUpdateMedicalRecord(patientId!);
	const { data: medicalRecord, refetch: refetchMedicalRecord } = useMedicalRecord(patientId!);

	React.useLayoutEffect(() => {
		refetchMedicalRecord();
	}, []);

	React.useEffect(() => {
		reset(medicalRecord?.data.information);
	}, [medicalRecord]);

	const save = async () => {
		const data = getValues() as Information;
		const medicalRecordSnapshot = medicalRecord!.data;
		medicalRecordSnapshot.information = data;

		try {
			await updateMedicalRecord(medicalRecordSnapshot);
			toast({
				title: `"Expediente Médico"`,
				description: "Expediente guardado con éxito",
				status: "success",
				position: "bottom-left",
			})
		} catch (error) {
			toast({
				title: "Ocurrió un error al crear el expediente medico",
				status: "error",
				position: "bottom-left",
			})
		}
	}

	const goBack = () => {
		navigate(-1);
	}

	const next = async () => {
		navigate('appointment');
	}

	return (
		<React.Fragment>
			<HStack>
				<BackButton path={-1} />
				<Text textColor="#C4C4C4" fontSize="22px">Historia Clínica Nutricional - Ficha de Identificación</Text>
			</HStack>
			<Box background="#E0E0E0" width="100%" height="90%" borderRadius="10px" padding="34px" marginTop="10px">
				<VStack height="100%">
					<VStack flex="1" alignItems="flex-end">
						<HStack>
							<Input label="Fecha y hora de elaboración" type="date" isReadOnly={true} {...register("updatedAt")} />
						</HStack>
						<HStack>
							<Input label="Apellido Paterno" {...register("paternalLastName")} />
							<Input label="Apellido Materno" {...register("maternalLastName")} />
							<Input label="Nombres" {...register("name")} />
						</HStack>
						<HStack>
							<Input label="Edad" type="number" {...register("age")} />
							<Select label="Género" {...register("gender")}>
								<option value={0}>Hombre</option>
								<option value={1}>Mujer</option>
								<option value={2}>Otro</option>
							</Select>
							<Input label="Estado Civil" {...register("maritalStatus")} />
						</HStack>
						<HStack>
							<Input label="Fecha de Nacimiento" type="date" {...register("dateOfBirth")} />
							<Input label="Lugar de Nacimiento" {...register("placeOfBirth")} />
							<Input label="Escolaridad" {...register("scholarity")} />
						</HStack>
						<HStack>
							<Input label="Teléfono 1" {...register("telephoneOne")} />
							<Input label="Teléfono 2" {...register("telephoneTwo")} />
							<Input label="Ocupación Actual" {...register("occupation")} />
						</HStack>
						<HStack>
							<Input label="Nombre del Padre o Tutor (Menores de edad)" {...register("tutor.name")} />
							<Input label="Parentesco con el paciente" {...register("tutor.relationTo")} />
						</HStack>
					</VStack>
					<HStack>
						<Button variant="gray" onClick={goBack}>Cancelar</Button>
						<Button onClick={save}>Guardar</Button>
						<Button onClick={next}>Siguiente</Button>
					</HStack>
				</VStack>
			</Box>
		</React.Fragment>
	);
};

export { PatientMedicalInfo };