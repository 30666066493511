import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Login } from "modules/Auth";

const AuthRoutes = () => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="*" element={<Navigate to="/login" replace />} />
  </Routes>
);

export default AuthRoutes;
