import * as React from "react";
import {
  InputProps as CkInputProps,
  Input as CkInput,
  VStack,
  chakra,
} from "@chakra-ui/react";

type InputProps = {
  label: string;
  error?: string;
} & CkInputProps;

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    error,
    label,
    width = "100%",
    variant = "filled",
    ...restInputProps
  } = props;

  return (
    <div className="felx flex-column w-100">
      {/* <chakra.span
        alignSelf="baseline"
        fontSize="sm"
        color={error ? "red.100" : "gray.25"}
      >
        {label}
      </chakra.span> */}
      <div className="f-14 text-gray"> {label}</div>
      <CkInput
        minW="20.5rem"
        ref={ref}
        variant={variant}
        isInvalid={error ? true : false}
        {...restInputProps}
      />
      <chakra.span
        color="red.100"
        fontSize="sm"
        alignSelf="baseline"
        lineHeight="1"
      >
        {error}
      </chakra.span>
    </div>
  );
});

Input.displayName = "Input";

export { Input };
export type { InputProps };
