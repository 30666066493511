import { theme } from "@chakra-ui/react";

const baseSelectTheme = theme.components.Select;

const baseStyle = {
  field: {
    width: "323px",
    height: "45px",
    fontColor: "#E5E5E5",
    border: "1px solid",
    borderColor: "red",
  },
};

const defaultProps = {
  variant: "filled",
};

const variants = {
  filled: {
    field: {
      border: "1px solid",
      borderColor: "#E5E5E5",
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _invalid: {
        border: "1px solid",
        borderColor: "red",
      },
    },
  },
};

export const selectTheme = {
  ...baseSelectTheme,
  variants: {
    ...baseSelectTheme.variants,
    filled: { ...baseSelectTheme.variants.filled, ...variants.filled },
  },
};

export const select = {
  baseStyle,
  variants,
  defaultProps,
};
