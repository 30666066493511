import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import { User, UserForm } from "types/user";

const useLogin = () =>
  useMutation<AxiosResponse<User>, AxiosError, UserForm>(
    ["login"],
    async (params) => await axios.post("auth/login", params)
  );

export { useLogin };
