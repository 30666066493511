import { extendTheme, theme as defaultTheme } from "@chakra-ui/react";
import { componentsTheme, extendedThemeComponents } from "./components";
import foundations from "./foundations";

const baseTheme = {
  ...defaultTheme,
  components: componentsTheme,
};

const colorModeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const overrides = {
  ...foundations,
  extendedThemeComponents,
  config: colorModeConfig,
};

const theme = extendTheme(overrides, baseTheme);

export default theme;
