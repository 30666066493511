import { Button,chakra, HStack, Icon, Input, InputGroup, InputRightElement, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { CheckIcon, SearchIcon } from "assets/icons";

import { usePatients, usePatientsSearch } from "modules/Patients/api";
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { IoIosAddCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { UserMedical } from "types/user";
import { FaCheckCircle, FaEdit, FaLink } from "react-icons/fa";
import { BsCheck, BsSend, BsThreeDotsVertical } from "react-icons/bs";
import { LuCalendarCheck2, LuListMinus } from "react-icons/lu";
import { Patient } from "types/patient";
import { Service, _Service } from "api/api";
import axios from "axios";
import { MdArrowBack, MdClose, MdEdit, MdKeyboardArrowDown, MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from "react-icons/md";
import { BiChat, BiLike, BiSearch, BiSend, BiTrash } from "react-icons/bi";
import { useToast } from '@chakra-ui/react'
import { LoadingOverlay } from "shared/LoadingPage/LoadingPage";
import Renderif from "utils/renderif";
import { FaRegUserCircle } from "react-icons/fa";
import { GiBubbles, GiOpenBook } from "react-icons/gi";
import { url } from "inspector";
import { RiArrowDownLine } from "react-icons/ri";
import { useAuth } from "contexts";

// interface PatientState {
// 	patients: Patient[]
// }
function color() {
	return (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
}
  
function _ramdom(min:number, max:number) {
    return Math.random() * (max - min) + min;
}

export const DDMMYYYY = (date:any) => {
    if(date === "" || date === null || date === undefined) return ""
    let _date = date.split('T')[0].split('-');

    let _time = date.split('T')[1].split(':');

    return  `${_date[2]}/${_date[1]}/${_date[0]} ${_time[0]}:${_time[1]}`
}





export default function CommunityView() {
  const navigate = useNavigate()


  const location = useLocation();
  const _comment = location?.state?.comment
  console.log(_comment)

  const toast = useToast(); 

  const user = useAuth()
  console.log(user)


//   const {setUser} = useContext(AuthContext)
//   const {notify} = useContext(AlertContext)
  
  const [state, setState] = useState({
    open: _comment !== undefined ? [_comment.id] : [] as any,
	cursos:[{
		plan:"Plan",
		usuarios:"12",
		organizacion:'Organizacion'
	},
	{
		plan:"Plan",
		usuarios:"12",
		organizacion:'Organizacion'
	},
	{
		plan:"Plan",
		usuarios:"12",
		organizacion:'Organizacion'
	}],
	loading:false,
    comentarios:[] as any,
    allcomentarios:[] as any,
    msg:"",
    tab:_comment !== undefined ? 4 : 0,
    comentario_selected: _comment !== undefined ? _comment : null as any

  })

  useEffect(()=>{
    init();
    return () => {}
  },[])

  const init = async () => {
    let comentarios = await _Service('GET', `commentary`)
    state.allcomentarios = comentarios.data;
    state.comentarios = comentarios.data.filter((item:any)=> item.parentId === null).sort((a:any,b:any) => b.id - a.id)
    setState({...state,})
  }



  const renderTag = () => {
    switch (state.tab) {
        case 0:
            return <div className="tag tag--green ms-2"></div>
            case 1:
                return <div className="tag tag--green ms-2">Nuevo</div>
                case 2:
                    return <div className="tag tag--skyblue ms-2">Abierto</div>
                        case 3:
                        return <div className="tag tag--orange ms-2">Sin respuesta</div>
    
        default:
            break;
    }
  }

  const answerCount = (id:number) =>{
    return state.allcomentarios.filter((item:any)=>item.parentId === id).length
  }
  const answers = (id:number) =>{
    return state.allcomentarios.filter((item:any)=>item.parentId === id)
  }
  const answersUsers = (id:number) =>{
    let _answers = [] as any
    let user = null as any
    state.allcomentarios.filter((item:any)=>item.parentId === id).map((answer:any)=>{
        if(answer.userId !== user){
            _answers.push(answer)
        }
        user = answer.userId


    })
    return  _answers;
  }
  const answersNew = () => {
    console.log(new Date().toLocaleString().split(',')[0])
    let today = new Date().toLocaleString().split(',')[0]
  



    return state.comentarios.filter((item:any) => DDMMYYYY(item.createdAt).split(' ')[0] === today)

  }
  const answersOpen = () => {

    return state.comentarios.filter((item:any) => state.allcomentarios.find((_item:any)=> _item.parentId === item.id) )
  }
  const answersNo = () => {

    return state.comentarios.filter((item:any) => !state.allcomentarios.find((_item:any)=> _item.parentId === item.id) )
  }
  const onDelete = async (id:number) =>{

    let _delete = await _Service('DELETE', `commentary/${id}`);
    toast({
        title: "Comentario",
        description: "Comentario eliminado exitosamente.",
        status: "success",
        position: "bottom-left",
      });
  
    init()
    // setState({...state})
  }
  const onLike = async  (id:number) => {

    let like = await _Service('POST', `commentary/${id}/like`);
    console.log(like)
    init()
    setState({...state})
  }
  const onSend = async (body:any) => {
    let send = await _Service('POST', `commentary`, body);
    state.msg = ""
    
    init()
    // setState({...state})
  }

  const filter = () => {

    switch (state.tab) {
        case 0:
            
            return state.comentarios;

        case 1:
        
            return answersNew()

        case 2:
        
            return answersOpen()

        case 3:
        
            return answersNo()
        case 4:
            state.open.push(state.comentario_selected.id)
            return [state.comentario_selected]
    
        default:
            return state.comentarios;
    }
  }

  return (
    <div className='container-fluid overflow-auto h-100'>

		<Renderif isTrue={state.loading}>
            <LoadingOverlay></LoadingOverlay>
        </Renderif>

        <div className="container-md mx-auto p-0 m-0 h-100">
       

        <div className="d-flex align-items-center cursor-pointer mb-2" onClick={()=>{
                            navigate(-1)
                        }}>
                <div className="md-icon md-icon--mid">
                    <MdArrowBack color="#2CAD6E"></MdArrowBack>
                </div>
                <div className="text-green bold">Atras</div>
        </div>

    
        

            <div className="d-flex h-100">

                
              
                <div className="col-3 p-0 m-0 overflow-auto hide-scroll">

                {/* <div className="ms-2 mb-2">
                    <div className="text-green f-24 bold">Comunidad</div>
                </div> */}

					<div className="d-flex flex-column h-100 bg-light r-2 p-2">

					

						<div className="d-flex flex-column">
							{
							state.comentarios.map((item:any, i:number)=>{
                                    let size = _ramdom(100, 300).toFixed(0)

									return (
										<div key={i} className="d-flex p-2 r-1 bv-shadow mb-2 bg-white cursor-pointer" onClick={()=>{
                                            setState({...state, comentario_selected:item, tab:4})
                                        }}>

                                            <div>
                                                <div className="avatar d-flex justify-content-center align-items-center">
                                                    <FaRegUserCircle size={24} color="#DDD"></FaRegUserCircle>
                                                    
                                                </div>
                                            </div>


											<div className="col ps-2">
												<div className="text-green f-14 bold">{item.user.firstName} {item.user.lastName}</div>
												<div className="f-12 text-gray">{item?.course?.title}</div>
												<div className="f-12 text-ellipsis-3">{item.content}</div>
												
												<div className="d-flex align-items-center mt-3">
													<div className="f-12 text-gray">{DDMMYYYY(item.createdAt)}</div>
													<div className="col"></div>
                                                      <div className="d-flex align-items-center">

                                                        <div className="bv-icon cursor-pointer" onClick={()=>{
                                                            onLike(item.id)
                                                        }}>
                                                            <BiLike color="#2CAD6E" size={18}></BiLike>
                                                        </div>

                                                        <div className="text-gray f-12 bold  line-1">{item.likes.length}</div>

                                                    </div>

                                                    <div className="d-flex align-items-center ms-2 cursor-pointer">
                                                        <div className="pt-1">
                                                            <BiChat color="#2CAD6E" size={18}></BiChat>
                                                        </div>

                                                        <div className="text-gray f-12 bold ps-1 text-decoration-underline">{answerCount(item.id)} respuestas</div>
                                                       
                                                        
                                                    </div>


													
												</div>
											
											</div>




										</div>
									)
								})
							}
						</div>

					</div>

                    <div className="my-5 py-5"></div>
                    <div className="my-5 py-5"></div>
                    <div className="my-5 py-5"></div>
				</div>
                <div className="col-9 py-3 overflow-auto hide-scroll">

                    <div className="d-flex mb-2 bg-white py-2 b-b px-3" style={{
                        position:'sticky',
                        top:-16,
                        zIndex:100,
                        backgroundColor:'#FFF'
                    }}>
                        <Renderif isTrue={state.comentario_selected !== null}>
                            <div onClick={()=>{setState({...state, tab:0, comentario_selected:null})}} className={`tab tab--light ms-2 cursor-pointer ps-2 d-flex align-items-center ${state.tab === 4 ? 'tab--active' : ''}`}> {state.comentario_selected?.content.slice(0,10)} ... <span className="ms-2"><MdClose size={18}></MdClose></span></div>
                        </Renderif>
                        <div onClick={()=>{setState({...state, tab:0, comentario_selected:null})}} className={`tab tab--light ms-2 cursor-pointer ps-2 ${state.tab === 0 ? 'tab--active' : ''}`}>Todos <div className="badge">{state.comentarios.length}</div></div>
                        <div onClick={()=>{setState({...state, tab:1, comentario_selected:null})}} className={`tab tab--light ms-2 cursor-pointer ps-2 ${state.tab === 1 ? 'tab--active' : ''}`}>Nuevos <div className="badge">{answersNew().length}</div></div>
                        <div onClick={()=>{setState({...state, tab:2, comentario_selected:null})}} className={`tab tab--light ms-2 cursor-pointer ps-2 ${state.tab === 2 ? 'tab--active' : ''}`}>Abiertos <div className="badge">{answersOpen().length}</div></div>
                        <div onClick={()=>{setState({...state, tab:3, comentario_selected:null})}} className={`tab tab--light ms-2 cursor-pointer ps-2 ${state.tab === 3 ? 'tab--active' : ''}`}>Sin respuesta <div className="badge">{answersNo().length}</div></div>

                    </div>

                        <div className="d-flex flex-column px-3">
							{
								filter().map((item:any, i:number)=>{

                                    let size = answerCount(item.id)

									return (
										<div key={i} className="d-flex p-2 r-1 bv-shadow mb-2 bg-white">


                                             <div>
                                                <div className="avatar d-flex justify-content-center align-items-center">
                                                    <FaRegUserCircle size={24} color="#DDD"></FaRegUserCircle>
                                                    
                                                </div>
                                            </div>


											<div className="col ps-2">
												<div className="d-flex align-items-end">
                                                    <div className="text-gray f-14 bold">{item.user.firstName} {item.user.lastName}</div>
                                                    {renderTag()}
                                                    
                                                </div>
												<div className="f-12 text-gray">{item?.course?.title}</div>
												<div className="f-12">{item.content}</div>
												
												<div className="d-flex mt-3">
													<div className="f-12 text-gray">{DDMMYYYY(item.createdAt)}</div>
													<div className="col"></div>
                                                    
													{/* <div className="pill pill--gren">Responder</div> */}
												</div>

                                                <div className="d-flex mt-3">

                                                    <div className="d-flex align-items-center px-3">
                                                        <Renderif isTrue={answersUsers(item.id).length === 0}>
                                                                <div>
                                                                    <div className="avatar avatar--overlap d-flex justify-content-center align-items-center">
                                                                        
                                                                        
                                                                        <FaRegUserCircle size={24} color={"#DDD"}></FaRegUserCircle>
                                        
                                                                    </div>
                                                                </div>
                                                        </Renderif>
                                                        {
                                                            answersUsers(item.id).map(()=>{
                                                                let _size = _ramdom(100, 300).toFixed(0)
                                                                return(
                                                                    <div>
                                                                        <div className="avatar avatar--overlap d-flex justify-content-center align-items-center">
                                                                            
                                                                            
                                                                            <FaRegUserCircle size={24} color={"#DDD"}></FaRegUserCircle>
                                            
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        
                                                        }
                                                        <div className="text-green ps-2 bold f-12 text-decoration-underline cursor-pointer">{answersUsers(item.id).length}</div>
                                                       

                                                    </div>
                                                    <div className="col"></div>
													<div className="d-flex align-items-center">
                                                        <div className="pt-1">
                                                            <BiChat color="#2CAD6E" size={22}></BiChat>
                                                            
                                                        </div>
                                                        <div className="text-gray f-14 bold ps-2 cursor-pointer text-decoration-underline">{size} respuestas</div>

                                                        <div className="pt-1 ms-4 cursor-pointer" onClick={()=>{
                                                            onDelete(item.id)
                                                        }}>
                                                            <BiTrash color="red" size={18}></BiTrash>
                                                        </div>
                                                    </div>
												

													{/* <div className="pill pill--gren ms-3">Responder</div> */}
												</div>
                                               

                                                <Renderif isTrue={state.open.includes(item.id)}>
                                                <div className="mt-3">
                                                    {
                                                    answers(item.id).map((answer:any, i:number)=>{
                                                        let __size = _ramdom(100, 300).toFixed(0)
                                                        return (
                                                            <div key={i} className="d-flex p-1 bg-light mb-1 r-1">

                                                                    <div>
                                                                       
                                                                        <div className="avatar d-flex justify-content-center align-items-center">
                                                                            <FaRegUserCircle size={24} color={answer.userId === user?.tokens?.userId ? '#2CAD6E': "#DDD"}></FaRegUserCircle>
                                                                            
                                                                        </div>
                                                                    </div>

                                                                <div className="col ps-2">
                                                                    <div className="d-flex align-items-end">
                                                                        <div className="text-gray f-14 bold">{answer.user.firstName} {answer.user.lastName}</div>
                                                                        <div className="f-12 text-gray ps-2">{DDMMYYYY(answer.createdAt)}</div>
                                                                        
                                                                    </div>
                                                                
                                                                    <div className="f-12">{answer.content}</div>
                                                                    
                                                                    <div className="d-flex align-items-center mt-2">

                                                                        <div className="bv-icon cursor-pointer" onClick={()=>{
                                                                            onLike(answer.id)
                                                                        }}>
                                                                            <BiLike color="#2CAD6E" size={18}></BiLike>
                                                                        </div>

                                                                        <div className="text-gray f-12 bold  line-1">{answer.likes.length}</div>

                                                                    </div>

                                                                </div>

                                                            
                                                                    

                                                            </div>
                                                            )
                                                        })
                                                    }
                                                    <textarea value={state.msg}  onChange={(evt)=>{
                                                        state.msg = evt.currentTarget.value;
                                                        setState({...state})
                                                    }} className='chakra-input border r-1 p-2 f-14' rows={4} cols={5} placeholder="Escribe una respueta"></textarea>
                                                    <div className="d-flex">
                                                        <div className="col"></div>
                                                        <div className="tag tag--green tag--cursos cursor-pointer align-items-center d-flex" onClick={()=>{
                                                            let send = {
                                                                "content": state.msg,
                                                                "courseId": item.courseId,
                                                                "parentId": item.id
                                                            }
                                                            onSend(send)
                                                        }}>
                                                            <div>Envar</div>
                                                            <div className="bv-icon">
                                                             <BsSend color="#2CAD6E" size={14}></BsSend>
                                                            </div>
                                                        </div>
                                                    </div>
											    </div>
                                                </Renderif>

                                                <div className="d-flex cursor-pointer bg-body-tertiary mt-2 r-2" onClick={()=>{
                                                    if(state.open.includes(item.id)){
                                                        state.open = state.open.filter((_item:number)=> _item !== item.id)
                                                     
                                                    }else{
                                                        state.open.push(item.id)
                                                    }
                                                    setState({...state})
                                                }}>
                                                    <div className="col"></div>
                                                    <div className="bv-icon">
                                                        <Renderif isTrue={!state.open.includes(item.id)}>
                                                            <MdKeyboardDoubleArrowDown color="#2CAD6E" size={22}></MdKeyboardDoubleArrowDown>
                                                        </Renderif>
                                                        <Renderif isTrue={state.open.includes(item.id)}>
                                                            <MdKeyboardDoubleArrowUp color="#2CAD6E" size={22}></MdKeyboardDoubleArrowUp>
                                                        </Renderif>
                                  
                                                      
                                                    </div>
                                                    <div className="col"></div>
                                                </div>
											</div>




										</div>
									)
								})
							}
						</div>

                  



				</div>
            </div>
        </div>
    </div>
  )
}



// export { CursosView }