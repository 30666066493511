import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { Nutrient } from "types/nutrient";

function useNutrients() {
  return useQuery<AxiosResponse<Nutrient[]>, AxiosError>(["nutrients"], () =>
    axios.get(`/meal-goal/select`)
  );
}

export { useNutrients };
