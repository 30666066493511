import axios, { AxiosResponse, AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

type CreateMealParams = Readonly<{
  file: FormData;
}>;

const useUpdateMeal = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, CreateMealParams>(
    (params) =>
      axios.put(`recipes/${id}`, params.file, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    { onSuccess: () => queryClient.invalidateQueries(["meals"]) }
  );
};

export { useUpdateMeal };
