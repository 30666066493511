import { createIcon } from "@chakra-ui/react";

const BellIcon = createIcon({
  displayName: "BellIcon",
  viewBox: "0 0 30 32",
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1662 26.0405C18.1662 27.4639 16.7159 28.6672 14.9996 28.6672C13.2832 28.6672 11.8329 27.4639 11.8329 26.0405V25.5005H18.1662V26.0405ZM28.4911 21.0783L25.6396 18.2236V11.1492C25.6396 5.63764 21.6781 0.957302 16.423 0.260635C13.3814 -0.143115 10.3097 0.784718 8.00597 2.80505C5.68797 4.83488 4.35955 7.75613 4.35955 10.8183L4.35797 18.2236L1.50797 21.0799C0.765388 21.8225 0.545304 22.9308 0.947471 23.9014C1.35122 24.872 2.29014 25.5006 3.34147 25.5006H8.66622V26.0405C8.66622 29.2356 11.5067 31.8339 14.9996 31.8339C18.4924 31.8339 21.3329 29.2356 21.3329 26.0405V25.5006H26.6561C27.7074 25.5006 28.6463 24.8736 29.0485 23.9014C29.4522 22.9308 29.2321 21.8225 28.4911 21.0783Z"
      fill="currentColor"
    />,
  ],
});

export { BellIcon };
