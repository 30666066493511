import * as React from "react";
import {
  Select as CkSelect,
  SelectProps as CkSelectProps,
  VStack,
  Icon,
  chakra,
} from "@chakra-ui/react";
import { RiArrowDownSFill } from "react-icons/ri";

type SelectProps = {
  label: string;
  error?: string;
  children: React.ReactNode;
} & CkSelectProps;

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const {
      children,
      error,
      label,
      variant = "filled",
      ...restSelectProps
    } = props;

    return (
      <div>

        <div className="f-14 text-gray"> {label}</div>
       
        
        <CkSelect
    
          icon={<Icon as={RiArrowDownSFill} />}
          color="red.125"
          textColor="gray.15"
          fontWeight="500"
          minW="20.5rem"
          ref={ref}
          variant={variant}
          isInvalid={error ? true : false}
          {...restSelectProps}
        >
          {children}
        </CkSelect>
        <chakra.span
          color="red.100"
          fontSize="sm"
          alignSelf="baseline"
          lineHeight="1"
        >
          {error}
        </chakra.span>
      </div>
    );
  }
);

Select.displayName = "Select";

export { Select };
