import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { Meal } from "types/meal";

type MealParams = Readonly<{
  id?: string;
}>;

function useMeal(params: MealParams) {
  return useQuery<AxiosResponse<Meal>, AxiosError>(["meal"], () =>
    axios.get(`meal/${params.id}`)
  );
}

export { useMeal };
