import * as React from "react";
import { Center, Spinner } from "@chakra-ui/react";

const LoadingPage: React.FC = () => {
  return (
    <Center h="100%">
      <Spinner size="xl" color="green.15" />
    </Center>
  );
};

const LoadingOverlay: React.FC = () => {
  return (
    <div className="loading-overlay">
      <Spinner size="xl" color="green.15" />
    </div>
  );
};

export { LoadingPage,LoadingOverlay };
