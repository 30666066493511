import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMedicalRecord } from "modules/Patients/api";
import { useUpdateMedicalRecord } from "modules/Patients/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Checkbox, Input, Select } from "shared";
import { DieteticIndicators } from "types/medicalrecord";

const Dietetic: React.FC = () => {
	const { id: patientId } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const {
		register,
		reset,
		getValues,
	} = useForm<DieteticIndicators>({
		mode: "onSubmit",
	});

	const { mutateAsync: updateMedicalRecord } = useUpdateMedicalRecord(patientId!);
	const { data: medicalRecord, refetch: refetchMedicalRecord } = useMedicalRecord(patientId!);

	React.useLayoutEffect(() => {
		refetchMedicalRecord();
	}, []);

	React.useEffect(() => {
		reset(medicalRecord?.data.dieteticIndicators);
	}, [medicalRecord]);

	const save = async () => {
		const data = getValues() as DieteticIndicators;
		const medicalRecordSnapshot = medicalRecord!.data;
		medicalRecordSnapshot.dieteticIndicators = data;

		try {
			await updateMedicalRecord(medicalRecordSnapshot);
			toast({
				title: `"Expediente Médico"`,
				description: "Expediente guardado con éxito",
				status: "success",
				position: "bottom-left",
			})
		} catch (error) {
			toast({
				title: "Ocurrió un error al crear el expediente medico",
				status: "error",
				position: "bottom-left",
			})
		}
	}

	const goBack = () => {
		navigate(-5);
	}

	const next = async () => {
		navigate('lifestyle');
	}

	return (
		<React.Fragment>
			<HStack>
				<BackButton path={-1} />
				<Text textColor="#C4C4C4" fontSize="22px">Historia Clínica Nutricional - Indicadores dietéticos 01</Text>
			</HStack>
			<Box background="#E0E0E0" width="100%" height="90%" borderRadius="10px" padding="34px" marginTop="10px">
				<VStack height="100%">
					<VStack flex="1" width="100%">
						<VStack>
							<HStack>
								<Input label="¿Cuántas comidas ingiere al día?" type="number" {...register("dailyMeals")} />
								<Input label="¿Cuáles?" {...register("whichMeals")} />
								<Input label="¿Quién prepara sus alimentos?" {...register("mealCook")} />
							</HStack>
							<HStack>
								<Input label="¿Come entre comidas? ¿Qué come?" {...register("betweenMealsMeals")} />
								<Select label="¿Cómo es su apetito?" {...register("appetite")}>
									<option value={"good"}>Bueno</option>
									<option value={"bad"}>Malo</option>
									<option value={"regular"}>Regular</option>
								</Select>
								<Input label="¿A qué hora siente más apetito?" {...register("highestHungerTime")} />
							</HStack>
							<Text alignSelf="flex-start" textColor="#C4C4C4" fontSize="22px">Comida</Text>
							<HStack>
								<Input label="Alimentos preferidos" {...register("mealIndicator.favoriteMeals")} />
								<Input label="Alimentos que NO le agradan" {...register("mealIndicator.notLikedMeals")} />
								<Input label="Alimentos que le causan molestias" {...register("mealIndicator.heavyMeals")} />
							</HStack>
							<HStack>
								<Input label="¿Alergia a alimentos?  ¿Cuáles?" {...register("mealIndicator.foodAllergies")} />
								<Input label="Vasos de agua  que  toma al día" type="number" {...register("mealIndicator.dailyWaterCups")} />
								<Input label="Vasos de bebidas al día (jugo, café, refresco, leche)" type="number" {...register("mealIndicator.dailyDrinks")} />
							</HStack>
						</VStack>
					</VStack>
					<HStack>
						<Button variant="gray" onClick={goBack}>Cancelar</Button>
						<Button onClick={save}>Guardar</Button>
						<Button onClick={next}>Siguiente</Button>
					</HStack>
				</VStack>
			</Box>
		</React.Fragment>
	);
};

export { Dietetic };