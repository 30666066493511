import * as React from "react";
import {
  Checkbox as CkCheckBox,
  CheckboxProps,
  HStack,
  chakra,
} from "@chakra-ui/react";

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const { children, value, ...restProps } = props;

    return (
      <HStack>
        <CkCheckBox
          cursor="pointer"
          sx={{
            bg: "white",
          }}
          _checked={{ borderColor: "green.15" }}
          iconColor="green.15"
          ref={ref}
          {...restProps}
        />
        <chakra.span fontSize="1.2rem" userSelect="none">
          {children}
        </chakra.span>
      </HStack>
    );
  }
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
