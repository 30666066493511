import { theme } from "@chakra-ui/react";

const baseButtonTheme = theme.components.Button;

const baseStyle = {
  fontWeight: "500",
  color: "white",
};

const sizes = {
  md: {
    minW: "140px",
  },
};

const variants = {
  solid: {
    ...baseButtonTheme.variants.solid,
    bg: "green.10",
    width: "100%",
    color: "white",
    fontSize: "md",
    borderRadius: "3px",
    height: "3.125rem",
    _disabled: {
      bg: "gray.50",
      opacity: 1,
    },
    _hover: {
      _disabled: {
        opacity: 1,
        bg: "gray.50",
      },
    },
    _active: {
      transform: "scale(0.9)",
    },
  },
  warning: {
    ...baseButtonTheme.variants.solid,
    bg: "red.50",
    width: "100%",
    color: "white",
    fontSize: "md",
    borderRadius: "3px",
    height: "3.125rem",
    _disabled: {
      bg: "gray.50",
      opacity: 1,
    },
    _hover: {
      _disabled: {
        opacity: 1,
        bg: "gray.50",
      },
    },
    _active: {
      transform: "scale(0.9)",
    },
  },
  gray: {
    ...baseButtonTheme.variants.solid,
    bg: "white",
    width: "100%",
    color: "#6E807C",
    fontSize: "md",
    borderRadius: "3px",
    height: "3.125rem",
    _disabled: {
      bg: "gray.50",
      opacity: 1,
    },
    _hover: {
      _disabled: {
        opacity: 1,
        bg: "gray.50",
      },
    },
    _active: {
      transform: "scale(0.9)",
    },
  },
};

const defaultProps = {
  variant: "solid",
  colorScheme: "blue",
};

export const buttonTheme = {
  ...baseButtonTheme,
  variants: {
    ...baseButtonTheme.variants,
    solid: variants.solid,
    warning: variants.warning,
    gray: variants.gray,
  },
  sizes: {
    ...baseButtonTheme.sizes,
    md: sizes.md,
  },
};

export const button = {
  baseStyle,
  variants,
  defaultProps,
  sizes,
};
