const colors = {
  blue: "#34AAED",
  gray: {
    gradient: {
      10: "linear-gradient(220.77deg, #FFFFFF 37.04%, #E9E9E9 90.74%);",
    },
    5: "#E0E0E0",
    10: "#C4C4C4",
    15: "#6E807C",
    25: "#8E8E8E",
    50: "#E5E5E5",
    75: "#555555",
  },
  red: {
    50: "linear-gradient(266.02deg, #E35205 0.65%, #E03C31 49.06%, #C5003E 97.9%)",
    100: "#F33939",
    125: "#C5003E",
  },
  orange: {
    15: "#E35205",
    25: "#E03C31",
  },
  green: {
    10: "linear-gradient(88.22deg, #2CAD6E 0%, #8ABD24 96.67%)",
    15: "#2CAD6E",
    20: "#8ABD24",
  },
  yellow: {
    10: "#F2C94C",
  },
};

export default colors;
