import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMedicalRecord } from "modules/Patients/api";
import { useUpdateMedicalRecord } from "modules/Patients/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Checkbox, Input, Select } from "shared";
import { AntropometricIndicator } from "types/medicalrecord";

const Antropometric: React.FC = () => {
	const { id: patientId } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const {
		register,
		reset,
		getValues,
		watch,
	} = useForm<AntropometricIndicator>({
		mode: "onSubmit",
	});

	const { mutateAsync: updateMedicalRecord } = useUpdateMedicalRecord(patientId!);
	const { data: medicalRecord, refetch: refetchMedicalRecord } = useMedicalRecord(patientId!);

	const watchFields = watch(['weight', 'height']);

	React.useLayoutEffect(() => {
		refetchMedicalRecord();
	}, []);

	React.useEffect(() => {
		reset(medicalRecord?.data.antropometricIndicator);
	}, [medicalRecord]);

	const save = async () => {
		const data = getValues() as AntropometricIndicator;
		const medicalRecordSnapshot = medicalRecord!.data;
		medicalRecordSnapshot.antropometricIndicator = data;

		try {
			await updateMedicalRecord(medicalRecordSnapshot);
			toast({
				title: `"Expediente Médico"`,
				description: "Expediente guardado con éxito",
				status: "success",
				position: "bottom-left",
			})
		} catch (error) {
			toast({
				title: "Ocurrió un error al crear el expediente medico",
				status: "error",
				position: "bottom-left",
			})
		}
	}

	const goBack = () => {
		navigate(-4);
	}

	const next = async () => {
		navigate('dietetic');
	}

	const getIMC = React.useMemo(() => {
		const values = getValues() as AntropometricIndicator;
		const weight = values.weight;
		const height = values.height;
		return (weight/(Math.pow(height, 2))).toFixed(2);
	}, [watchFields])

	return (
		<React.Fragment>
			<HStack>
				<BackButton path={-1} />
				<Text textColor="#C4C4C4" fontSize="22px">Historia Clínica Nutricional - Indicadores antropométricos</Text>
			</HStack>
			<Box background="#E0E0E0" width="100%" height="90%" borderRadius="10px" padding="34px" marginTop="10px">
				<VStack height="100%">
					<VStack flex="1" width="100%">
						<HStack>
							<Input label="Peso Actual (kg)" type="number" {...register("weight")} />
							<Input label="Peso Habitual (kg)" type="number" {...register("normalWeight")} />
							<Input label="Estatura (m)" type="number" {...register("height")} />
						</HStack>
						<HStack>
							<Input label="IMC" type="string" isReadOnly={true} value={getIMC} />
							<Input label="% Grasa Viceral" type="number" {...register("visceralFatPercentage")} />
							<Input label="% de Musculo" type="number" {...register("musclePercentage")} />
						</HStack>
						<HStack>
							<Input label="% de Agua" type="number" {...register("waterPercentage")} />
							<Input label="Peso Oseo (kg)" type="number" {...register("boneWeight")} />
							<Input label="Grasa (kg)" type="number" {...register("fat")} />
						</HStack>
					</VStack>
					<HStack>
						<Button variant="gray" onClick={goBack}>Cancelar</Button>
						<Button onClick={save}>Guardar</Button>
						<Button onClick={next}>Siguiente</Button>
					</HStack>
				</VStack>
			</Box>
		</React.Fragment>
	);
};

export { Antropometric };