import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMedicalRecord } from "modules/Patients/api";
import { useUpdateMedicalRecord } from "modules/Patients/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, Checkbox, Input, Select } from "shared";
import { HereditaryHealthCheckup } from "types/medicalrecord";

const Hereditary: React.FC = () => {
	const { id: patientId } = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	const {
		register,
		reset,
		getValues,
	} = useForm<HereditaryHealthCheckup>({
		mode: "onSubmit",
	});

	const { mutateAsync: updateMedicalRecord } = useUpdateMedicalRecord(patientId!);
	const { data: medicalRecord, refetch: refetchMedicalRecord } = useMedicalRecord(patientId!);

	React.useLayoutEffect(() => {
		refetchMedicalRecord();
	}, []);

	React.useEffect(() => {
		reset(medicalRecord?.data.hereditaryHealthCheckup);
	}, [medicalRecord]);

	const save = async () => {
		const data = getValues() as HereditaryHealthCheckup;
		const medicalRecordSnapshot = medicalRecord!.data;
		medicalRecordSnapshot.hereditaryHealthCheckup = data;

		try {
			await updateMedicalRecord(medicalRecordSnapshot);
			toast({
				title: `"Expediente Médico"`,
				description: "Expediente guardado con éxito",
				status: "success",
				position: "bottom-left",
			})
		} catch (error) {
			toast({
				title: "Ocurrió un error al crear el expediente medico",
				status: "error",
				position: "bottom-left",
			})
		}
	}

	const goBack = () => {
		navigate(-3);
	}

	const next = async () => {
		navigate('antropometric');
	}

	return (
		<React.Fragment>
			<HStack>
				<BackButton path={-1} />
				<Text textColor="#C4C4C4" fontSize="22px">Historia Clínica Nutricional - Antecedentes Heredo-Familiares patológicos y  exploración física</Text>
			</HStack>
			<Box background="#E0E0E0" width="100%" height="90%" borderRadius="10px" padding="34px" marginTop="10px">
				<VStack height="100%">
					<VStack flex="1" width="100%">
						<HStack width="100%" justifyContent="space-evenly">
							<Checkbox {...register("obesity")}>Obesidad</Checkbox>
							<Checkbox {...register("diabetes")}>Diabetes</Checkbox>
							<Checkbox {...register("cancer")}>Cáncer</Checkbox>
							<Checkbox {...register("dislipidemia")}>Dislipidemia</Checkbox>
						</HStack>
						<HStack width="100%">
							<Input label="Otros" width="328px" {...register("others")} />
							<Input label="Observaciones" {...register("observations")} />
						</HStack>
						<Input label="Describa aquí detalles de la exploración física actual" {...register("currentPhysicalExploration")} />
						<Text alignSelf="flex-start" textColor="#C4C4C4" fontSize="22px">Aspectos Ginecológicos</Text>
						<VStack width="100%">
							<HStack width="100%">
								<Input label="Edad de la 1ra menstruación" type="number" {...register("gynecologyAspect.firstMenstruationAge")} />
								<Input label="Fecha de última menstruación" type="date" {...register("gynecologyAspect.lastMenstruation")} />
								<Input label="¿Cuantos días de sangrado?" type="number" {...register("gynecologyAspect.bleedingDays")} />
							</HStack>
							<HStack width="100%">
								<Input label="Sintomatología en el periodo menstrual" {...register("gynecologyAspect.menstruationSymptoms")} />
								<Input label="Inicio de vida sexual" type="number" {...register("gynecologyAspect.sexlifeState")} />
								<Input label="¿Anticonceptivos?" {...register("gynecologyAspect.anticonceptives")} />
							</HStack>
							<HStack width="100%">
								<Input label="Embarazos" type="number" {...register("gynecologyAspect.pregnancies")} />
								<Input label="¿Esta embarazada actualmente?" {...register("gynecologyAspect.currentlyPregnant")} />
								<Select label="¿Cuantos Meses?" {...register("gynecologyAspect.monthsPregnant")} >
									<option value={0}>0</option>
									<option value={1}>1</option>
									<option value={2}>2</option>
									<option value={3}>3</option>
									<option value={4}>4</option>
									<option value={5}>5</option>
									<option value={6}>6</option>
									<option value={7}>7</option>
									<option value={8}>8</option>
									<option value={9}>9</option>
								</Select>
							</HStack>
							<HStack width="100%">
								<Checkbox {...register("gynecologyAspect.currentlyLactating")}>Esta Lactando</Checkbox>
							</HStack>
							<HStack width="100%">
								<Input label="¿Ya presentó menopausia? ¿Cuando inició?" type="date" {...register("gynecologyAspect.menopauseStart")} />
								<Input label="Fecha de terminación" type="date" {...register("gynecologyAspect.menopauseEnd")} />
								<Input label="Observaciones" {...register("gynecologyAspect.observations")} />
							</HStack>
						</VStack>
					</VStack>
					<HStack>
						<Button variant="gray" onClick={goBack}>Cancelar</Button>
						<Button onClick={save}>Guardar</Button>
						<Button onClick={next}>Siguiente</Button>
					</HStack>
				</VStack>
			</Box>
		</React.Fragment>
	);
};

export { Hereditary };