import * as React from "react";
import { Box, Center, Button, VStack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { Input, InputPassword } from "shared";
import { useAuth } from "contexts";
import { UserForm } from "types/user";
import { FullLogo } from "assets/icons";

import Background from "assets/img/metabolic-reset.jpg";
import { LogIn } from "api/api";

const Login: React.FC = () => {

  const toast = useToast();
  const { login, isLogginIn, setUser } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserForm>({
    defaultValues: {
      email: "egonzalez@mydiet.mx",
      password: "Aa.123456",
    },
    mode: "onSubmit",
  });

  const onSubmitLogin = async (form: UserForm) => {
    try {
      login(form);

      // let _user:any = await LogIn(form.email, form.password)
      // console.log(_user);

      // if(_user.status === 200){
      //   toast({ title: "¡Bienvenido!", status: "success" });
      //   localStorage.setItem('token', _user.token)
      //   login(form)
      // }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      h="100%"
      backgroundImage={Background}
      objectFit="cover"
      backgroundRepeat="repeat-x"
      backgroundPosition="center"
    >
      <Center h="100%">
        <form onSubmit={handleSubmit(onSubmitLogin)}>
          <VStack p={10} spacing={5} bgColor="white" borderRadius="md" w="sm">
            <FullLogo w="100" h="100" />
            <Input
              label="Correo electrónico"
              placeholder="Introduce tu e-mail"
              error={errors?.email?.message}
              {...register("email", {
                required: "El email es obligatorio",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "El email es obligatorio",
                },
              })}
            />
            <InputPassword
              label="Contraseña"
              placeholder="Introduce tu contraseña"
              error={errors?.password?.message}
              {...register("password", {
                required: "La contraseña es obligatoria",
              })}
            />
            <Button isLoading={isLogginIn} type="submit">
              INICIAR SESIÓN
            </Button>
          </VStack>
        </form>
      </Center>
    </Box>
  );
};

export { Login };
