import { HStack, Text, TableContainer, Table, Thead, Tr, Th, Tbody, Td, VStack } from "@chakra-ui/react";
import { usePatientForms } from "modules/Patients/api";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "shared";

type FormItem = {
    id: string;
    title:string;
    createdAt:string;
}

const PatientForms: React.FC = () => {
    const { id: patientId } = useParams();
    const navigate = useNavigate();

    const onFormClick = (userFormId: string) => {
        navigate(`${userFormId}`);
    }
    
    const { data: forms, isLoading } = usePatientForms(patientId!);
    const [formsDisplay, setFormsDisplay] = React.useState<FormItem[]>([]);

    React.useEffect(() => {
        if(forms && forms.data){
            setFormsDisplay(forms!.data!.map<FormItem>((form) => {
                return {
                    id: form.id,
                    title: form.title,
                    createdAt: form.createdAt
                };
            }));
        }
    },[forms]);
    
    return <VStack width="100%">
        <HStack width="100%" alignItems="center">
            <BackButton path={-1} />
            <Text fontSize="32px" color="#C4C4C4">Cuestionarios</Text>
        </HStack>
        <HStack width="100%" height="95%" alignItems="center">
            <TableContainer width="100%" alignItems="center">
                <Table width="100%" variant={"simple"}>
                    <Thead>
                        <Tr width="100%">
                            <Th width="80%">Nombre</Th>
                            <Th>Fecha de creación</Th>
                        </Tr>
                    </Thead>
                    <Tbody width="100%">
                        {formsDisplay?.map(form => (
                            <Tr key={form.id}
                                cursor={"pointer"}
                                onClick={() => { onFormClick(form.id) }}
                                _hover={{ 'background': '#2CAD6E', 'textColor': '#FFFFFF' }}
                            >
                                <Td width="80%">{form.title}</Td>
                                <Td>{new Date(form.createdAt).toLocaleDateString([])}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </HStack>
    </VStack>;
}

export { PatientForms };