import * as React from "react";
import { Center, Heading, chakra } from "@chakra-ui/react";

import { useAuth } from "contexts";

const ErrorPage: React.FC = () => {
  const { logout, tokens } = useAuth();

  return (
    <Center
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Heading>Error</Heading>
      <chakra.span>Ocurrió un error inesperado</chakra.span>
      <chakra.span>Tu rol de usuario: {tokens.role}</chakra.span>
      <chakra.span>Tu ID de usuario: {tokens.userId}</chakra.span>
      <chakra.span>
        Si el error persiste, por favor, contacta a soporte
      </chakra.span>
      <chakra.span as="button" onClick={logout}>
        Salir
      </chakra.span>
    </Center>
  );
};

export { ErrorPage };
