import * as React from "react";
import {
  Box,
  Flex,
  VStack,
  HStack,
  Icon,
  Avatar,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerProps,
  useDisclosure,
  chakra,
} from "@chakra-ui/react";
import { GiExitDoor, GiHamburgerMenu } from "react-icons/gi";
import { FaBell, FaCalendar, FaEnvelope } from "react-icons/fa";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

import {
  FullLogo,
  BellIcon,
  MealsIcon,
  RecipesIcon,
  PersonIcon,
} from "assets/icons";
import { useAuth } from "contexts";
import { IoMdLogOut } from "react-icons/io";
import { LuLogOut } from "react-icons/lu";

type DrawerPropsWithoutChildren = Omit<DrawerProps, "children">;

type NavItemProps = {
  isSelected: boolean;
  children?: React.ReactNode;
};

type SidebarItemProps = {
  isSelected: boolean;
  icon: any;
  color?: string;
  route: string;
};

type DrawerItemProps = {
  title: string;
  description: string;
} & SidebarItemProps;

const SELECTED_ITEM_STYLES = {
  color: "white",
  fontWeight: "bold",
  bg: "green.10",
  p: "3",
  borderRadius: "md",
};

const NavItem: React.FC<NavItemProps> = ({ isSelected, children }) =>
  isSelected ? (
    <chakra.span {...SELECTED_ITEM_STYLES}>{children}</chakra.span>
  ) : (
    <chakra.span fontWeight="bold" color="gray.15">
      {children}
    </chakra.span>
  );

const SidebarItem: React.FC<SidebarItemProps> = ({
  isSelected,
  icon,
  color,
  route,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      bg={isSelected ? "green.10" : color}
      role="button"
      _active={{ transform: `scale(${isSelected ? "1" : "0.9"})` }}
      onClick={() => {
        if (isSelected) return;
        navigate(route);
      }}
      borderRadius="xl"
      minW={isSelected ? "4rem" : "3rem"}
      textAlign="center"
      p={1}
    >
      <Icon as={icon} boxSize={6} margin="auto" color="white" />
    </Box>
  );
};

const DrawerItem: React.FC<DrawerItemProps> = ({
  icon,
  isSelected,
  route,
  title,
  description,
  color,
}) => {
  const navigate = useNavigate();
  return (
    <HStack
      w="100%"
      p={5}
      bg={isSelected ? "green.10" : "gray.25"}
      _active={{ transform: `scale(${isSelected ? "1" : "0.9"})` }}
      borderRadius="md"
      role="button"
      onClick={() => {
        if (isSelected) return;
        navigate(route);
      }}
    >
      <Box bg={color} role="button" borderRadius="xl" textAlign="center" p={3}>
        <Icon as={icon} boxSize={6} margin="auto" color="white" />
      </Box>

      <VStack spacing={0} alignItems="flex-start">
        <chakra.span color="white" fontSize="xl" lineHeight={1}>
          {title}
        </chakra.span>
        <chakra.span color="white" fontSize="sm">
          {description}
        </chakra.span>
      </VStack>
    </HStack>
  );
};

const MyDietDrawer: React.FC<
  DrawerPropsWithoutChildren & { currentRoute: string }
> = ({ finalFocusRef, isOpen, onClose, currentRoute, ...restProps }) => (
  <Drawer
    isOpen={isOpen}
    onClose={onClose}
    finalFocusRef={finalFocusRef}
    placement="left"
    {...restProps}
  >
    <DrawerContent
      borderRightRadius="xl"
      boxShadow="2xl"
      minW="27.5rem"
      marginTop="4rem"
    >
      <DrawerBody>
        <VStack spacing={5}>
          <DrawerItem
            isSelected={currentRoute === "recipes"}
            icon={RecipesIcon}
            color="#2CAD6E"
            route="/recipes"
            title="Mi Recetario"
            description="Explora tus Recetas y Meal Plans"
          />
          <DrawerItem
            isSelected={currentRoute === "plans"}
            icon={RecipesIcon}
            color="#C5003E"
            route="/plans"
            title="Mis Planes"
            description="Mis dietas Especializadas "
          />
          <DrawerItem
            isSelected={currentRoute === "patients"}
            icon={PersonIcon}
            color="yellow.10"
            route="/patients"
            title="Pacientes"
            description="Manejo de Personal y Pacientes"
          />
          
        </VStack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

const PanelLayout: React.FC = () => {
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const navigate = useNavigate();

  const location = useLocation();
  const currentRoute = location.pathname.split("/")[1];

  const auth = useAuth();

  return (
    <Flex flexDir="column" h="100%" className="">
      
      <HStack
        h="3.75rem"
        justifyContent="space-between"
        boxShadow="xl"
        maxW="100vw"
      >
        <HStack p="5" spacing={10} onClick={onDrawerOpen} role="button">
          {/* <Icon
            as={GiHamburgerMenu}
            minWidth="1rem"
            boxSize={6}
            _active={{ transform: "scale(0.5)" }}
            aria-label="mydiet-drawer"
            color="green.15"
          /> */}
          <FullLogo boxSize="40" />
        </HStack>
        <HStack p="5" spacing={10}>
          <HStack spacing={3}>
            {/* <Icon as={FaEnvelope} boxSize="6" color="gray.25" />
            <Icon as={FaBell} boxSize="6" color="gray.25" />
            <Icon as={FaCalendar} boxSize="6" color="gray.25" /> */}
          </HStack>
          {/* <Avatar size="sm" shadow="md" onClick={() => auth.logout()} _hover={{ cursor: 'pointer' }} /> */}
        </HStack>
      </HStack>

      <div  className="simemenu">
        
        <VStack minW="5.5rem" boxShadow="xl" p="2rem 0 2rem 0" spacing={30}>

          <div className="cursor-pointer" onClick={()=>{
              navigate('/patients');
            }}>
            <div className="md-icon md-icon--big">
              {currentRoute === "patients" ?
               <img width="45%" src="/assets/Pacientes_green.svg"></img> :
               <img width="45%" src="/assets/Pacientes.svg"></img>
              }
             
            </div>
            <div className={`f-12 text-center line-0 ${currentRoute === "patients" ? 'text-green' : 'text-gray'}`}>Pacientes</div>
          </div>

          <div className="cursor-pointer" onClick={()=>{
              navigate('/plans');
            }}>
            <div className="md-icon md-icon--big">
         
              {currentRoute === "plans" ?
                 <img width="60%" src="/assets/Planes_green.svg"></img>:
                 <img width="60%" src="/assets/Planes.svg"></img>
              }
            </div>
            <div className={`f-12 text-center line-0 ${currentRoute === "plans" ? 'text-green' : 'text-gray'}`}>Planes</div>
          </div>

          <div className="cursor-pointer" onClick={()=>{
              navigate('/recipes');
            }}>
            <div className="md-icon md-icon--big">
              {currentRoute === "recipes" ?
                 <img width="80%" src="/assets/Recetario_green.svg"></img>:
                 <img width="80%" src="/assets/Recetario.svg"></img>
              }
            </div>
            <div className={`f-12 text-center line-0 ${currentRoute === "recipes" ? 'text-green' : 'text-gray'}`}>Recetas</div>
          </div>

          <div className="cursor-pointer" onClick={()=>{
              navigate('/cursos');
            }}>
            <div className="md-icon md-icon--big">
              {currentRoute === "cursos" ?
                 <img width="65%" src="/assets/Group.svg"></img>:
                 <img width="65%" src="/assets/Group.svg"></img>
              }
            </div>
            <div className={`f-12 text-center line-0 ${currentRoute === "cursos" ? 'text-green' : 'text-gray'}`}>Cursos</div>
          </div>

          <div className="col"></div>

          <div className="cursor-pointer" onClick={()=>{
              auth.logout()
            }}>
            <div className="md-icon md-icon--big">
              <LuLogOut size={20} color="gray"></LuLogOut>
            </div>
            <div className={`f-12 text-center line-0 text-gray`}>Salir</div>
          </div>
          
          {/* <SidebarItem
            icon={RecipesIcon}
            isSelected={currentRoute === "recipes"}
            route="/recipes"
            color=""
          />
          <SidebarItem
            icon={MealsIcon}
            isSelected={currentRoute === "plans"}
            route="/plans"
            color="#C5003E"
          />
          <SidebarItem
            icon={PersonIcon}
            isSelected={currentRoute === "patients"}
            route="/patients"
            color="yellow.10"
          /> */}
        </VStack>
        {/* <div className="overflow-auto w-100 pb-5">
          <Outlet />
        </div> */}

        <Box p="2rem 2rem 2rem 2rem" w="100%" h=""className="" overflow={'auto'}>
          <Outlet />
        </Box>

        
      </div>

      {isDrawerOpen && (
        <MyDietDrawer
          currentRoute={currentRoute!}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
        />
      )}
    </Flex>
  );
};

export { PanelLayout };
