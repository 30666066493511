import * as React from "react";
import { Box } from "@chakra-ui/react";
import './theme/styling/overwrites.css';
import './theme/styling/app.css';
import { ContextsProvider } from "contexts";
import Routes from "routes";

export const App = () => (
  <ContextsProvider>
    <Box h="100vh" w="100vw" overflow="hidden">
      <Routes />
    </Box>
  </ContextsProvider>
);
